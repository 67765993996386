import React from 'react'

import { css } from '@emotion/react'
import { Spin, SpinProps } from 'antd'

interface Props extends SpinProps {
  margin?: string
  size?: 'small' | 'default' | 'large'
}

const LoadingIndicator: React.FC<Props> = ({
  margin = '100px auto',
  size = 'large',
  ...rest
}): JSX.Element => {
  return (
    <div
      css={css`
        display: grid;
        place-items: center;
        margin: ${margin};
        max-width: 100%;
      `}
    >
      <Spin size={size} {...rest} />
    </div>
  )
}

export default LoadingIndicator
