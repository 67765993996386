import React, { FC } from 'react'

import { Flex } from '@src/components/EmotionLayout'

export const ModalFooter: FC = ({ children }): JSX.Element => {
  return (
    <Flex gap="10" justifyContent="flex-end" mt="large">
      {children}
    </Flex>
  )
}
