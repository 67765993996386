import React, { useState, useEffect } from 'react'

import styled from '@emotion/styled'

const sliderColor = '#f5f5f5'
const rangeColor = '#1890ff'
const Wrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: 20px;
`

const Handle = styled.input<{
  width: string
  zIndex?: number
  height?: string
  fromPosition?: number
  toPosition?: number
  marginTop?: string
}>`
  position: absolute;
  appearance: none;
  outline: none;
  margin-top: ${(props) => props.marginTop || '0px'};
  width: ${(props) => props.width}};
  height: ${(props) => props.height || '4px'};
  z-index: ${(props) => props.zIndex || 0};
  border-radius: 7px;
  pointer-events: none;
  background-color: ${sliderColor};
  background: linear-gradient(to right, ${sliderColor} 0%, ${sliderColor} ${(
  props
) => props.fromPosition}%, ${rangeColor} ${(props) =>
  props.fromPosition}%, ${rangeColor} ${(props) =>
  props.toPosition}%, ${sliderColor} ${(props) =>
  props.toPosition}%, ${sliderColor} 100%);


  ::-webkit-slider-thumb {
    appearance: none;
    pointer-events: all;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid #1890ff;
    background-color: #fff;
    cursor: pointer;
  }
`

const Mark = styled.div`
  position: relative;
  margin-top: 20px;
  width: 100%;
  height: 14px;
`

const MarkText = styled.span<{
  left?: string
  right?: string
}>`
  position: absolute;
  left: ${(props) => props.left || undefined};
  right: ${(props) => props.right || undefined};
  font-size: 12px;
  color: #404040;
`

const Tooltip = styled.div<{
  left: string
}>`
  position: absolute;
  top: -40px;
  left: ${(props) => props.left};
  width: 40px;
  height: 30px;
  background-color: #240b36e6;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 4px;
`

type IProps = {
  max: number
  onLeftChange: (val: number) => void
  onRightChange: (val: number) => void
  leftValue: number
  rightValue: number
  maxRange: number
  minRange: number
  marks?: Record<string, string>
}

const Slider = ({
  max,
  onLeftChange,
  onRightChange,
  leftValue,
  rightValue,
  maxRange,
  minRange,
  marks
}: IProps): JSX.Element => {
  const [rightVal, setRightVal] = useState(rightValue)
  const [leftVal, setLeftVal] = useState(leftValue)
  const [fromPosition, setFromPosition] = useState(0)
  const [toPosition, setToPosition] = useState((rightValue * 100) / max)
  const [showLeftTooltip, setShowLeftTooltip] = useState(false)
  const [showRightTooltip, setShowRightTooltip] = useState(false)

  const handleLeftChange = (event) => {
    const val = parseFloat(event.target.value)
    if (rightVal - val >= minRange && rightVal - val <= maxRange) {
      setFromPosition((100 * val) / max)
      setToPosition((rightVal * 100) / max)
      setLeftVal(val)
      onLeftChange(val)
    } else onLeftChange(val)
  }

  const handleRightChange = (event) => {
    const val = parseFloat(event.target.value)
    if (val - leftVal >= minRange && val - leftVal <= maxRange) {
      setFromPosition((100 * leftVal) / max)
      setToPosition((val * 100) / max)
      setRightVal(val)
      onRightChange(val)
    } else onRightChange(val)
  }

  useEffect(() => {
    setLeftVal(leftValue)
    setFromPosition((100 * leftValue) / max)
    setToPosition((rightVal * 100) / max)
  }, [leftValue, rightVal, max])

  useEffect(() => {
    setRightVal(rightValue)
    setFromPosition((100 * leftVal) / max)
    setToPosition((rightValue * 100) / max)
  }, [rightValue, leftVal, max])

  useEffect(() => {
    const leftHandle = document.getElementById('handle-left')
    const rightHandle = document.getElementById('handle-right')
    leftHandle.addEventListener('mousedown', () => {
      if (!showLeftTooltip && !showRightTooltip) {
        setShowLeftTooltip(true)
        setShowRightTooltip(true)
      }
    })
    rightHandle.addEventListener('mousedown', () => {
      if (!showLeftTooltip && !showRightTooltip) {
        setShowLeftTooltip(true)
        setShowRightTooltip(true)
      }
    })
    leftHandle.addEventListener('mouseup', () => {
      setShowLeftTooltip(false)
      setShowRightTooltip(false)
    })
    rightHandle.addEventListener('mouseup', () => {
      setShowLeftTooltip(false)
      setShowRightTooltip(false)
    })
    leftHandle.addEventListener('mouseover', () => {
      setShowLeftTooltip(true)
    })
    rightHandle.addEventListener('mouseover', () => {
      setShowRightTooltip(true)
    })
    leftHandle.addEventListener('mouseout', () => {
      setShowLeftTooltip(false)
    })
    rightHandle.addEventListener('mouseout', () => {
      setShowRightTooltip(false)
    })

    return () => {
      leftHandle.removeEventListener('mousedown', null)
      rightHandle.removeEventListener('mousedown', null)
      leftHandle.removeEventListener('mouseup', null)
      rightHandle.removeEventListener('mouseup', null)
      leftHandle.removeEventListener('mouseover', null)
      rightHandle.removeEventListener('mouseover', null)
    }
  }, [showLeftTooltip, showRightTooltip])

  return (
    <Wrapper>
      <Handle
        id="handle-right"
        type="range"
        min="0"
        max={max}
        value={rightVal}
        step={0.1}
        width="100%"
        onChange={handleRightChange}
        fromPosition={fromPosition}
        toPosition={toPosition}
      />
      <Handle
        id="handle-left"
        type="range"
        min="0"
        max={max}
        value={leftVal}
        step={0.1}
        onChange={handleLeftChange}
        width="100%"
        zIndex={1}
        height="0"
        marginTop="2px"
      />
      <Mark>
        {Object.keys(marks).map((key, i) => {
          return i < Object.keys(marks).length - 1 ? (
            <MarkText
              key={key}
              left={`calc(${(parseFloat(key) * 100) / max + '%'} - 5px)`}
            >
              {marks[key]}
            </MarkText>
          ) : (
            <MarkText key={key} right="0px">
              {marks[key]}
            </MarkText>
          )
        })}
      </Mark>
      {showLeftTooltip && (
        <Tooltip left={`calc(${(leftVal * 100) / max + '%'} - 20px)`}>
          {leftVal.toFixed(1)}
        </Tooltip>
      )}
      {showRightTooltip && (
        <Tooltip left={`calc(${(rightVal * 100) / max + '%'} - 20px)`}>
          {rightVal.toFixed(1)}
        </Tooltip>
      )}
    </Wrapper>
  )
}

export default Slider
