import React from 'react'

import { css } from '@emotion/core'
import { Select } from 'formik-antd'

type IProps = {
  children: React.ReactNode
}

const FormSelect: React.FC<IProps> = ({ children, ...props }) => {
  return (
    <Select
      {...props}
      name=""
      css={css`
        .ant-select-single:not(.ant-select-customize-input),
        .ant-select-selector {
          height: 32px !important;
          line-height: 32px !important;
          border-radius: 4px !important;
        }
      `}
    >
      {children}
    </Select>
  )
}

export default FormSelect
