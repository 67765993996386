import React from 'react'

import { SelectCtrl } from '@src/components/ControlledFields'
import { availableLocales } from 'cldr-core/availableLocales.json'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const localeOptions = availableLocales.modern
  .map((locale) => ({
    value: locale,
    label: locale
  }))
  .sort((a, b) => a.label.localeCompare(b.label))

interface Props {
  control: Control<any>
}

const LocaleSelector: React.FC<Props> = ({ control }) => {
  const { t } = useTranslation()

  return (
    <SelectCtrl
      options={localeOptions}
      control={control}
      label={t('Locale')}
      name="locale"
      filterOption={(input, option) =>
        option?.key?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    />
  )
}

export default LocaleSelector
