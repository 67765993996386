import * as React from 'react'

import { css } from '@emotion/core'
import { Select } from 'antd'

import { Box } from '../EmotionLayout'

type Props = {
  onChange: (value: string) => void
  onBlur: () => void
  value: string
  name: string
}

const fonts = [
  {
    css: 'Avenir Next, sans-serif',
    value: null,
    display: 'Avenir Next'
  },
  {
    css: 'Lato, sans-serif',
    value: 'Lato',
    display: 'Lato'
  },
  {
    css: 'Lora, serif',
    value: 'Lora',
    display: 'Lora'
  },
  {
    css: 'Merriweather, serif',
    value: 'Merriweather',
    display: 'Merriweather'
  },
  {
    css: 'Montserrat, sans-serif',
    value: 'Montserrat',
    display: 'Montserrat'
  },
  {
    css: 'Noto Serif, serif',
    value: 'Noto Serif',
    display: 'Noto Serif'
  },
  {
    css: 'Open Sans, sans-serif',
    value: 'Open Sans',
    display: 'Open Sans'
  },
  {
    css: 'Oswald, sans-serif',
    value: 'Oswald',
    display: 'Oswald'
  },
  {
    css: 'Playfair Display, serif',
    value: 'Playfair Display',
    display: 'Playfair Display'
  },
  {
    css: 'Poppins, sans-serif',
    value: 'Poppins',
    display: 'Poppins'
  },
  {
    css: 'Roboto, sans-serif',
    value: 'Roboto',
    display: 'Roboto'
  },
  {
    css: 'Roboto Slab, serif',
    value: 'Roboto Slab',
    display: 'Roboto Slab'
  }
]

const linkClassName = 'font-family-styles'
const fontHref =
  'https://fonts.googleapis.com/css2?family=Lato&family=Lora&family=Merriweather&family=Montserrat&family=Noto+Serif&family=Open+Sans&family=Oswald&family=Playfair+Display&family=Poppins&family=Roboto&family=Roboto+Slab&display=swap'

const hasFonts = (): boolean => {
  return !!document.head.querySelector(`.${linkClassName}`)
}

const FontSelector: React.FC<Props> = (props): JSX.Element => {
  React.useEffect(() => {
    if (!hasFonts()) {
      const fragment = document.createDocumentFragment()
      const link1 = document.createElement('link')
      link1.rel = 'preconnect'
      link1.href = 'https://fonts.googleapis.com'
      const link2 = document.createElement('link')
      link2.rel = 'preconnect'
      link2.href = 'https://fonts.gstatic.com'
      const link3 = document.createElement('link')
      link3.rel = 'stylesheet'
      link3.className = linkClassName
      link3.href = fontHref
      fragment.appendChild(link1)
      fragment.appendChild(link2)
      fragment.appendChild(link3)
      document.head.appendChild(fragment)
    }
  }, [])

  return (
    <Select
      placeholder="Select a Font"
      defaultValue={null}
      css={css`
        width: 100%;
      `}
      {...props}
    >
      {fonts.map((f) => (
        <Select.Option key={f.display} value={f.value}>
          <Box fontFamily={f.css}>{f.display}</Box>
        </Select.Option>
      ))}
    </Select>
  )
}

export default FontSelector
