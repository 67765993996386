export default {
  get: (cname) => {
    const name = `${cname}=`
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return undefined
  },
  set: (name, value, expires = null) => {
    if (expires) {
      document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`
    } else {
      document.cookie = `${name}=${value}; path=/`
    }
    return value
  },
  remove: (name) => {
    document.cookie = `${name}=; path=/`
  }
}
