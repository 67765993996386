import { useEffect } from 'react'

/**
 * Loads the ava.js script for the fw-ava webcomponent.
 */

export const useAvaJs = (): void => {
  useEffect(() => {
    if (!customElements.get('fw-ava')) {
      const script = document.createElement('script')
      script.async = true
      script.type = 'text/javascript'
      script.src = process.env.REACT_APP_AVA_JS
      document.body.appendChild(script)
    }
  }, [])
}
