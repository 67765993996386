import { createAction, createSlice } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { Dispatch } from 'redux'

import api from '../utils/api'
import { removeUndefined } from '../utils/sanitize'

type IProps = {
  ids: string[]
  businesses: Record<string, any>
  paging: Record<string, any>
  searchIds: string[]
  searchBusinesses: Record<string, any>
  searchPaging: Record<string, any>
  defaultTracking: Record<string, string>
}

const initialState: IProps = {
  ids: [],
  businesses: {},
  paging: {},
  searchIds: [],
  searchBusinesses: {},
  searchPaging: {},
  defaultTracking: {}
}

const slice = createSlice({
  name: 'business',
  initialState: initialState,
  reducers: {
    fetchBusinessesSuccess(state, action) {
      const { businesses, paging, page } = action.payload
      state.paging = paging
      if (page) {
        businesses.forEach((business) => {
          state.ids.push(business.id)
        })
      } else {
        state.ids = businesses.map((business) => business.id)
      }
      businesses.forEach((business) => {
        state.businesses[business.id] = business
      })
    },
    fetchBusinessSuccess(state, action) {
      const { business } = action.payload
      if (state.ids.indexOf(business.id) === -1) {
        state.ids = state.ids.concat([business.id])
      }
      state.businesses[business.id] = business
    },
    createBusinessSuccess(state, action) {
      const { business } = action.payload
      state.ids.push(business.id)
      state.businesses[business.id] = business
    },
    updateBusinessSuccess(state, action) {
      const { business } = action.payload
      state.businesses[business.id] = business
    },
    searchBusinessesSuccess(state, action) {
      const { businesses, paging, page } = action.payload
      state.searchPaging = paging

      if (page) {
        businesses.forEach((business) => {
          state.searchIds.push(business.id)
        })
      } else {
        state.searchIds = businesses.map((business) => business.id)
      }
      businesses.forEach((business) => {
        state.searchBusinesses[business.id] = business
      })
    },
    setDefaultTrackingDataSuccess(state, action) {
      Object.keys(action.payload).forEach((key) => {
        if (action.payload[key]) {
          state.defaultTracking[key] = action.payload[key]
        }
      })
    }
  }
})

export default slice.reducer

export const {
  fetchBusinessesSuccess,
  fetchBusinessSuccess,
  createBusinessSuccess,
  updateBusinessSuccess,
  searchBusinessesSuccess,
  setDefaultTrackingDataSuccess
} = slice.actions

const fetchBusinessesRequest = createAction('business/fetchBusinessesRequest')
const fetchBusinessesFailure = createAction('business/fetchBusinessesFailure')

export function fetchBusinesses(page?: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchBusinessesRequest())
      const response = await api.get(page || '/bus')
      const { businesses, paging } = response.data
      dispatch(fetchBusinessesSuccess({ businesses, paging, page }))

      return response
    } catch (error) {
      dispatch(fetchBusinessesFailure())

      return error
    }
  }
}

const fetchBusinessRequest = createAction('business/fetchBusinessRequest')
const fetchBusinessFailure = createAction('business/fetchBusinessFailure')

export function fetchBusiness(businessId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchBusinessRequest())
      const response = await api.get(`/bus/${businessId}`)
      const business = response.data
      dispatch(fetchBusinessSuccess({ business }))

      return response
    } catch (error) {
      dispatch(fetchBusinessFailure())

      return error
    }
  }
}

const createBusinessRequest = createAction('business/createBusinessRequest')
const createBusinessFailure = createAction('business/createBusinessFailure')

export function createBusiness(data: any) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createBusinessRequest())
      const response = await api.post('/bus', {
        ...sanitizeBusinessParams(data),
        type: 'others'
      })
      const business = response.data
      dispatch(createBusinessSuccess({ business }))

      return response
    } catch (error) {
      dispatch(createBusinessFailure())

      return error
    }
  }
}

const updateBusinessRequest = createAction('business/updateBusinessRequest')
const updateBusinessFailure = createAction('business/updateBusinessFailure')

export function updateBusiness(businessId: string, data: any) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateBusinessRequest())
      const response = await api.patch(
        `/bus/${businessId}`,
        sanitizeBusinessParams(data)
      )
      const business = response.data
      dispatch(updateBusinessSuccess({ business }))

      return response
    } catch (error) {
      dispatch(updateBusinessFailure())

      return error
    }
  }
}

const sanitizeBusinessParams = (data) => {
  const sanitized = {
    name: data.name,
    presentation: data.presentation,
    website: data.website,
    primary_email_address: data.email,
    privacy_policy_url: data.privacyPolicy,
    country: data.country,
    remind_me_double_opt_in: data.remindMeDoubleOptIn
  }

  return removeUndefined(sanitized)
}

const searchBusinessRequest = createAction('bus/searchBusinessRequest')
const searchBusinessFailure = createAction('bus/searchBusinessFailure')

export function searchBusiness(q?: string, page?: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(searchBusinessRequest())
      const params: Record<string, any> = {}
      if (q) params.q = q
      const response = await api.get(page || `/bus`, {
        params
      })
      const { businesses, paging } = response.data
      dispatch(searchBusinessesSuccess({ businesses, paging, page }))

      return response
    } catch (error) {
      dispatch(searchBusinessFailure())

      return error
    }
  }
}

export function setDefaultTrackingData(data: Record<string, string>) {
  return (dispatch: Dispatch): void => {
    dispatch(setDefaultTrackingDataSuccess(data))
  }
}

export async function getBusinessActivationStatus(
  businessId: string
): Promise<
  AxiosResponse<{
    activation_status: boolean
  }>
> {
  try {
    if (businessId) return await api.get(`/bus/${businessId}/onboarding`)
  } catch (error) {
    return error
  }
}

export async function updateBusinessActivationStatus(
  businessId: string,
  activationStatus: boolean
): Promise<void> {
  try {
    return await api.patch(`/bus/${businessId}/onboarding`, {
      activation_status: activationStatus
    })
  } catch (error) {
    return error
  }
}
