import React, { FC } from 'react'

import { Box } from '@src/components/EmotionLayout'

type DividerProps = {
  my?: string
}

const Divider: FC<DividerProps> = ({ my = 'medium' }) => (
  <Box width="100%" height="1px" bg="#ebebeb" my={my} />
)

export default Divider
