import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

const DEFAULT_NAMESPACE = 'translation'
const NAMESPACES = ['translation', 'cta']

i18n
  .use(Backend) // Load translations from /public/locales
  .use(LanguageDetector) // Detects browser language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // debug: true, // Enable for logs
    backend: {
      loadPath: function (languages: string[], namespaces: string[]) {
        const namespaceSuffix =
          namespaces[0] !== DEFAULT_NAMESPACE ? `.${namespaces[0]}` : ''

        return `/locales/${languages[0]}${namespaceSuffix}.json`
      }
    },
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain'
      ]
    },
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: false
    },
    keySeparator: false,
    nsSeparator: false,
    ns: NAMESPACES,
    defaultNS: DEFAULT_NAMESPACE
  })

export default i18n
