import { useCallback, useMemo } from 'react'

import { DEFAULT_LS_POSTER } from '@src/constants'
import { getSortedVideoPosters } from '@src/utils/videoPoster'

export type FileType = 'jpg' | 'webp' | 'gif'

export const useVideoPoster = (
  video: {
    video_posters: globalLib.VideoPoster[]
    thumbnail_url?: string
  },
  fileTypes?: FileType[],
  aspectRatio?: string
): {
  getVideoPoster: {
    path: string
    aspectRatio?: string | undefined
  }
} => {
  const getVideoPosterFromVideo = useCallback(
    (
      video: {
        video_posters: globalLib.VideoPoster[]
        thumbnail_url?: string
      },
      fileTypes = ['jpg']
    ) => {
      if (!video)
        return {
          path: ''
        }
      if (Array.isArray(video.video_posters)) {
        let posters = video.video_posters.filter(
          (poster: { format: string }) => {
            return fileTypes.includes(poster.format)
          }
        )

        if (posters.length > 0) {
          posters = getSortedVideoPosters(posters)
          if (aspectRatio) {
            const updatedPosters = posters.filter((poster) => {
              if (aspectRatio === '16:9') {
                return (
                  poster.orientation === 'horizontal' ||
                  poster.aspect_ratio === aspectRatio
                )
              } else if (aspectRatio === '9:16') {
                return (
                  poster.orientation === 'vertical' ||
                  poster.aspect_ratio === aspectRatio
                )
              }

              return false
            })
            posters = updatedPosters.length > 0 ? updatedPosters : posters
          }

          return {
            path: posters[0].url,
            aspectRatio: posters[0].orientation
          }
        }
      }

      return { path: video?.thumbnail_url || DEFAULT_LS_POSTER }
    },
    [aspectRatio]
  )

  const getVideoPoster = useMemo(
    () => getVideoPosterFromVideo(video, fileTypes),
    [getVideoPosterFromVideo, video, fileTypes]
  )

  return {
    getVideoPoster
  }
}
