import React from 'react'

import { Box, Flex } from '@src/components/EmotionLayout'

type Props = {
  onClick: () => void
}

export const ModalCloseIcon = ({ onClick }: Props): JSX.Element => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onClick()
  }

  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        as="img"
        alt="close"
        src="/close_black.svg"
        width="16px"
        height="16px"
        onClick={handleClick}
        css={{ cursor: 'pointer' }}
      />
    </Flex>
  )
}
