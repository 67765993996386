import React, { useEffect, useMemo, useRef, useState } from 'react'

import styled from '@emotion/styled'
import { throttle } from 'lodash'
import ReactPlayer from 'react-player'

const Container = styled.div`
  width: 100%;
  height: 100%;
  & video {
    object-fit: cover;
  }
`

interface PosterPreviewProps {
  playerUrl: string
  startTime: number
  endTime: number
}

const PosterPreview = ({
  playerUrl,
  startTime,
  endTime
}: PosterPreviewProps): JSX.Element => {
  const player = useRef<ReactPlayer>(null)
  const [isReady, setIsReady] = useState<boolean>(false)

  const throttleSeek = useMemo(() => {
    return throttle((value: number) => {
      if (player.current && player.current.getInternalPlayer()) {
        player.current.seekTo(value)
      }
    }, 300)
  }, [])

  const handleOnProgress = (state) => {
    const { playedSeconds } = state
    if (playedSeconds >= endTime) {
      if (startTime < endTime) {
        throttleSeek(startTime)
      } else {
        player.current?.getInternalPlayer().pause()
      }
    }
  }

  useEffect(() => {
    if (isReady) {
      throttleSeek(startTime)
    }
  }, [isReady, startTime, throttleSeek])

  return (
    <Container>
      <ReactPlayer
        ref={player}
        url={playerUrl}
        muted
        playing={startTime < endTime}
        onProgress={handleOnProgress}
        progressInterval={200}
        controls={false}
        stopOnUnmount
        width="100%"
        height="100%"
        onReady={() => {
          setIsReady(true)
        }}
        onEnded={() => {
          if (startTime < endTime) {
            throttleSeek(startTime)
          }
        }}
      />
    </Container>
  )
}

export default PosterPreview
