import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  addBusinessFavoriteChannel,
  getFavoriteChannels,
  removeBusinessFavoriteChannel,
  renameBusinessFavoriteChannel
} from '@src/utils/channel'
import { Dispatch } from 'redux'

type IProps = {
  favoriteChannels: {
    encoded_id: string
    name: string
  }[]
}

const initialState: IProps = {
  favoriteChannels: []
}

const slice = createSlice({
  name: 'favoriteChannel',
  initialState: initialState,
  reducers: {
    fetchFavoriteChannelSuccess(state, action) {
      const { favoriteChannels } = action.payload
      state.favoriteChannels = favoriteChannels || []
    }
  }
})

export default slice.reducer
export const { fetchFavoriteChannelSuccess } = slice.actions

const fetchFavoriteChannelRequest = createAction(
  'favoriteChannel/fetchFavoriteChannelRequest'
)
const fetchFavoriteChannelFailure = createAction(
  'favoriteChannel/fetchFavoriteChannelFailure'
)

export function fetchFavoriteChannel(businessId: string, userId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchFavoriteChannelRequest())
      const favoriteChannels = getFavoriteChannels(businessId, userId)
      dispatch(
        fetchFavoriteChannelSuccess({
          favoriteChannels
        })
      )

      return favoriteChannels
    } catch (error) {
      dispatch(fetchFavoriteChannelFailure())
    }
  }
}

export function addFavoriteChannel(
  businessId: string,
  userId: string,
  channel: {
    encoded_id: string
    name: string
  }
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchFavoriteChannelRequest())
      addBusinessFavoriteChannel(businessId, userId, channel)
      const favoriteChannels = getFavoriteChannels(businessId, userId)
      dispatch(
        fetchFavoriteChannelSuccess({
          favoriteChannels
        })
      )

      return favoriteChannels
    } catch (error) {
      dispatch(fetchFavoriteChannelFailure())
    }
  }
}

export function removeFavoriteChannel(
  businessId: string,
  userId: string,
  channelEncodedId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchFavoriteChannelRequest())
      removeBusinessFavoriteChannel(businessId, userId, channelEncodedId)
      const favoriteChannels = getFavoriteChannels(businessId, userId)
      dispatch(
        fetchFavoriteChannelSuccess({
          favoriteChannels
        })
      )

      return favoriteChannels
    } catch (error) {
      dispatch(fetchFavoriteChannelFailure())
    }
  }
}

export function renameFavoriteChannel(
  businessId: string,
  userId: string,
  channel: {
    encoded_id: string
    name: string
  }
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchFavoriteChannelRequest())
      renameBusinessFavoriteChannel(businessId, userId, channel)
      const favoriteChannels = getFavoriteChannels(businessId, userId)
      dispatch(
        fetchFavoriteChannelSuccess({
          favoriteChannels
        })
      )

      return favoriteChannels
    } catch (error) {
      dispatch(fetchFavoriteChannelFailure())
    }
  }
}
