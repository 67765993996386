/* eslint-disable react-hooks/rules-of-hooks */
import { useMediaQuery } from 'react-responsive'

const breakpoints = {
  xs: 575,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
}

export const isXsScreen = () => {
  return useMediaQuery({
    query: `(max-width: ${breakpoints.xs}px)`
  })
}
export const isSmScreen = () => {
  return useMediaQuery({
    query: `(min-width: ${breakpoints.sm}px)`
  })
}
export const isMdScreen = () => {
  return useMediaQuery({
    query: `(min-width: ${breakpoints.md}px)`
  })
}
export const isLgScreen = () => {
  return useMediaQuery({
    query: `(min-width: ${breakpoints.lg}px)`
  })
}
export const isXlScreen = () => {
  return useMediaQuery({
    query: `(min-width: ${breakpoints.xl}px)`
  })
}
export const isXxlScreen = () => {
  return useMediaQuery({
    query: `(min-width: ${breakpoints.xxl}px)`
  })
}
/* eslint-enable react-hooks/rules-of-hooks */
