import { createSlice, createAction, Dispatch } from '@reduxjs/toolkit'
import { LIVESTREAM_STATUS } from '@src/constants'
import api from '@src/utils/api'
import { removeNullAndUndefined } from '@src/utils/sanitize'
import { unionBy } from 'lodash'
import moment from 'moment'

const SLICE_NAME = 'showrooms'

export type Showroom = {
  id?: string
  title: string
  description: string
  aspect_ratio: string
  video_posters: globalLib.VideoPoster[]
  video_hashtags: string[]
  video_primary_url: string
  provider: string
  stream_source: string
  nsfw_words: string[]
  viewers_count_enabled: boolean
  chat_enabled: boolean
  chat_in_replay_enabled: boolean
  replay_enabled: boolean
  chat_moderation_enabled: boolean
  content_moderation_enabled: boolean
  transcription_enabled: boolean
  announcement?: string
  content_moderation_settings: globalLib.ContentModerationSettings | null
  nsfw_replacement?: string
  business_id: string
  channel_id: string
  target_playlist_ids: string[]
  target_playlists?: globalLib.Playlist[]
  business_membership_id: string
  business_membership?: {
    id: string
    name: string
    avatar_url: string
    contact: string[]
  }
  channel?: globalLib.Channel
  access_code?: string
}

export interface ShowroomSession {
  color: string
  duration: number
  id: string
  product_counts: number
  replay_url: string
  repost_comments: string
  scheduled_at: string
  showroom_recurrent_event_id?: string
  source_live_stream: ChosenVideo
  status: LIVESTREAM_STATUS
  time_zone: string
  video: {
    id: string
    caption: string
    web_share_url: string
    thumbnail_url: string
  }
  video_source: string
  views_count: number
}

export interface RecurrentEvent extends ShowroomSession {
  ref_video_id: null
  exclusions: null
  ends_at: string
  starts_at: string
  event_title: 'Test Restream monday'
  by_day?: string[] | null
  frequency: 'DAILY' | 'WEEKLY'
  auto_response: 'disabled' | 'always_on'
  ai_assistant_username: string
  force_private_messaging_on_replay: boolean
  private_messaging: 'disabled' | 'always_on'
  replay_enabled: boolean
  replay_messaging_enabled: boolean
}

type SliceState = {
  interactions: { [key: string]: globalLib.VideoInteraction[] }
  showrooms: { [key: string]: Showroom }
  isLoading: boolean
  nextPage: { before_id?: number }
  error: string | null
  manageIsActive: boolean
  selectedShowroomIds: string[]
  products: { [key: string]: globalLib.Product[] }
  sessions: {
    [key: string]: {
      entries: ShowroomSession[]
      next_page?: { before_id?: string; since_id?: string }
    }
  }
  searchedSessions: {
    [key: string]: {
      entries: ShowroomSession[]
      next_page?: { before_id?: string; since_id?: string }
    }
  }
  recurrentEvents: {
    [key: string]: RecurrentEvent
  }
}

const initialState: SliceState = {
  interactions: {},
  isLoading: false,
  error: null,
  nextPage: { before_id: null },
  showrooms: {},
  products: {},
  sessions: {},
  searchedSessions: {},
  manageIsActive: false,
  selectedShowroomIds: [],
  recurrentEvents: {}
}

export interface ChosenVideo {
  caption: string
  started_at: string
  scheduled_at?: string
  video_id?: string
}

const fetchShowroomsRequest = createAction(
  `${SLICE_NAME}/fetchShowroomsRequest`
)
const fetchShowroomsFailure = createAction(
  `${SLICE_NAME}/fetchShowroomsFailure`
)

export function fetchShowrooms(businessId: string, beforeId?: number) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchShowroomsRequest())
      const response = await api.get(`/bus/${businessId}/showrooms`, {
        params: beforeId
          ? {
              before_id: beforeId
            }
          : {}
      })
      dispatch(
        fetchShowroomsSuccess({
          businessId,
          showrooms: response.data.entries,
          next_page: response.data.next_page
        })
      )
    } catch (error) {
      dispatch(fetchShowroomsFailure())

      return error
    }
  }
}

const fetchShowroomRequest = createAction(`${SLICE_NAME}/fetchShowroomRequest`)
const fetchShowroomFailure = createAction(`${SLICE_NAME}/fetchShowroomFailure`)

export function fetchShowroom(showroomId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchShowroomRequest())
      const response = await api.get(`/showrooms/${showroomId}`)

      dispatch(fetchShowroomSuccess({ data: response.data }))

      return response
    } catch (error) {
      dispatch(fetchShowroomFailure())

      return error
    }
  }
}

const createShowroomRequest = createAction(
  `${SLICE_NAME}/createShowroomRequest`
)
const createShowroomFailure = createAction(
  `${SLICE_NAME}/createShowroomFailure`
)

export function createShowroom(showroom: Showroom) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createShowroomRequest())
      const response = await api.post(`/showrooms`, showroom)

      dispatch(createShowroomSuccess())

      return response
    } catch (error) {
      dispatch(createShowroomFailure())

      return error
    }
  }
}

const updateShowroomRequest = createAction(
  `${SLICE_NAME}/updateShowroomRequest`
)
const updateShowroomFailure = createAction(
  `${SLICE_NAME}/updateShowroomFailure`
)

export function updateShowroom(showroomId: string, showroom: Showroom) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateShowroomRequest())

      const response = await api.put(`/showrooms/${showroomId}`, showroom)

      dispatch(updateShowroomSuccess({ data: response.data }))

      return response
    } catch (error) {
      dispatch(updateShowroomFailure())

      return error
    }
  }
}

const deleteShowroomRequest = createAction(
  `${SLICE_NAME}/deleteShowroomRequest`
)
const deleteShowroomFailure = createAction(
  `${SLICE_NAME}/deleteShowroomFailure`
)

export function deleteShowroom(showroomId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteShowroomRequest())
      const response = await api.delete(`/showrooms/${showroomId}`)

      dispatch(deleteShowroomSuccess({ data: showroomId }))

      return response
    } catch (error) {
      dispatch(deleteShowroomFailure())

      return error
    }
  }
}

const fetchPlaylistsForShowroomRequest = createAction(
  `${SLICE_NAME}/fetchPlaylistsForShowroomRequest`
)
const fetchPlaylistsForShowroomFailure = createAction(
  `${SLICE_NAME}/fetchPlaylistsForShowroomFailure`
)

export function fetchPlaylistsForShowroom(
  businessId: string,
  sourceId: string,
  before_id?: string
) {
  return async (
    dispatch: Dispatch
  ): Promise<{ playlists: any[]; paging: { next: string } }> => {
    try {
      dispatch(fetchPlaylistsForShowroomRequest())
      const params = {
        include_aggregator: true,
        page_size: 100
      }
      if (before_id) {
        params['before_id'] = before_id
      }
      const response = await api.get(
        `/bus/${businessId}/channels/${sourceId}/playlists`,
        {
          params
        }
      )

      dispatch(fetchPlaylistsForShowroomSuccess())

      return response.data
    } catch (error) {
      dispatch(fetchPlaylistsForShowroomFailure())

      return error
    }
  }
}

const fetchShowroomInteractionsRequest = createAction(
  `${SLICE_NAME}/fetchShowroomInteractionsRequest`
)
const fetchShowroomInteractionsFailure = createAction(
  `${SLICE_NAME}/fetchShowroomInteractionsFailure`
)

export function fetchShowroomInteractions(showroomId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchShowroomInteractionsRequest())
      const response = await api.get(`/showrooms/${showroomId}/interactions`)
      const interactions = response.data
      dispatch(fetchShowroomInteractionsSuccess({ interactions, showroomId }))

      return response
    } catch (error) {
      dispatch(fetchShowroomInteractionsFailure())

      return error
    }
  }
}

const createShowroomInteractionRequest = createAction(
  `${SLICE_NAME}/createShowroomInteractionRequest`
)
const createShowroomInteractionFailure = createAction(
  `${SLICE_NAME}/createShowroomInteractionFailure`
)

export function createShowroomInteraction(
  showroomId: string,
  interaction: globalLib.VideoInteraction
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createShowroomInteractionRequest())
      const response = await api.post(
        `/showrooms/${showroomId}/interactions`,
        interaction
      )

      dispatch(
        createShowroomInteractionSuccess({ data: response.data, showroomId })
      )

      return response
    } catch (error) {
      dispatch(createShowroomInteractionFailure())

      return error
    }
  }
}

const updateShowroomInteractionRequest = createAction(
  `${SLICE_NAME}/updateShowroomInteractionRequest`
)
const updateShowroomInteractionFailure = createAction(
  `${SLICE_NAME}/updateShowroomInteractionFailure`
)

export function updateShowroomInteraction(
  showroomId: string,
  interactionId: string,
  interaction: globalLib.VideoInteraction
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateShowroomInteractionRequest())

      const response = await api.put(
        `/showrooms/${showroomId}/interactions/${interactionId}`,
        interaction
      )

      dispatch(
        updateShowroomInteractionSuccess({ showroomId, data: response.data })
      )

      return response
    } catch (error) {
      dispatch(updateShowroomInteractionFailure())

      return error
    }
  }
}

const deleteShowroomInteractionRequest = createAction(
  `${SLICE_NAME}/deleteShowroomInteractionRequest`
)
const deleteShowroomInteractionFailure = createAction(
  `${SLICE_NAME}/deleteShowroomInteractionFailure`
)

export function deleteShowroomInteraction(
  showroomId: string,
  interactionId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteShowroomInteractionRequest())

      const response = await api.delete(
        `/showrooms/${showroomId}/interactions/${interactionId}`
      )

      dispatch(
        deleteShowroomInteractionSuccess({
          showroomId,
          interactionId
        })
      )

      return response
    } catch (error) {
      dispatch(deleteShowroomInteractionFailure())

      return error
    }
  }
}

const fetchShowroomProductsRequest = createAction(
  `${SLICE_NAME}/fetchShowroomProductsRequest`
)
const fetchShowroomProductsFailure = createAction(
  `${SLICE_NAME}/fetchShowroomProductsFailure`
)

export function fetchShowroomProducts(showroomId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchShowroomProductsRequest())
      const response = await api.get(`/showrooms/${showroomId}/products`)
      const products = response.data
      dispatch(fetchShowroomProductsSuccess({ products, showroomId }))

      return response
    } catch (error) {
      dispatch(fetchShowroomProductsFailure())

      return error
    }
  }
}

const createShowroomProductsRequest = createAction(
  `${SLICE_NAME}/createShowroomProductsRequest`
)
const createShowroomProductsFailure = createAction(
  `${SLICE_NAME}/createShowroomProductsFailure`
)

export function createShowroomProducts(showroomId: string, data?: any) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createShowroomProductsRequest())
      const response = await api.post(`/showrooms/${showroomId}/products`, data)

      return response
    } catch (error) {
      dispatch(createShowroomProductsFailure())

      return error
    }
  }
}

const updateShowroomProductRequest = createAction(
  `${SLICE_NAME}/updateShowroomProductRequest`
)
const updateShowroomProductFailure = createAction(
  `${SLICE_NAME}/updateShowroomProductFailure`
)

export function updateShowroomProduct(
  showroomId: string,
  productId: string,
  product: globalLib.Product
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateShowroomProductRequest())

      const response = await api.put(
        `/showrooms/${showroomId}/products/${productId}`,
        product
      )

      dispatch(
        updateShowroomProductSuccess({ showroomId, data: response.data })
      )

      return response
    } catch (error) {
      dispatch(updateShowroomProductFailure())

      return error
    }
  }
}

const deleteShowroomProductsRequest = createAction(
  `${SLICE_NAME}/deleteShowroomProductsRequest`
)
const deleteShowroomProductsFailure = createAction(
  `${SLICE_NAME}/deleteShowroomProductsFailure`
)

export function deleteShowroomProducts(
  showroomId: string,
  productIds: string[]
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteShowroomProductsRequest())

      const response = await api.delete(`/showrooms/${showroomId}/products`, {
        data: { product_ids: productIds }
      })

      dispatch(
        deleteShowroomProductsSuccess({
          showroomId,
          productIds
        })
      )

      return response
    } catch (error) {
      dispatch(deleteShowroomProductsFailure())

      return error
    }
  }
}

const sortShowroomProductsRequest = createAction(
  `${SLICE_NAME}/sortShowroomProductsRequest`
)
const sortShowroomProductsFailure = createAction(
  `${SLICE_NAME}/sortShowroomProductsFailure`
)

export function sortShowroomProducts(showroomId: string, data?: any) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(sortShowroomProductsRequest())
      dispatch(sortShowroomProductsSuccess({ showroomId, products: data }))

      const productIds = data.map((product) => product.id)
      await api.post(`/showrooms/${showroomId}/products`, {
        product_ids: productIds
      })
    } catch (error) {
      dispatch(sortShowroomProductsFailure())
    }
  }
}

export interface ShowroomSessionsFetchParams {
  before_id?: string
  since_id?: string
  order?: 'asc' | 'desc'
  statuses?: LIVESTREAM_STATUS[]
  video_sources?: string[]
  since?: string
  before?: string
  fresh?: boolean
  search?: boolean
}

const fetchSessionsForShowroomRequest = createAction(
  `${SLICE_NAME}/fetchSessionsForShowroomRequest`
)
const fetchSessionsForShowroomFailure = createAction(
  `${SLICE_NAME}/fetchSessionsForShowroomFailure`
)

export function fetchSessionsForShowroom(
  showroomId: string,
  params?: ShowroomSessionsFetchParams
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchSessionsForShowroomRequest())
      const { fresh, search, ...rest } = params

      const response = await api.get(`/showrooms/${showroomId}/live_streams`, {
        params: rest
      })

      dispatch(
        fetchSessionsForShowroomSuccess({
          showroomId,
          search,
          fresh,
          data: response.data
        })
      )

      return response
    } catch (error) {
      dispatch(fetchSessionsForShowroomFailure())
    }
  }
}

const deleteShowroomSessionRequest = createAction(
  `${SLICE_NAME}/deleteShowroomSessionRequest`
)
const deleteShowroomSessionFailure = createAction(
  `${SLICE_NAME}/deleteShowroomSessionFailure`
)

export function deleteShowroomSession(showroomId: string, sessionId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteShowroomSessionRequest())
      dispatch(
        deleteShowroomSessionSuccess({
          showroomId,
          deletedSessionId: sessionId
        })
      )
    } catch (error) {
      dispatch(deleteShowroomSessionFailure())
    }
  }
}

const createLivestreamFromShowroomRequest = createAction(
  `${SLICE_NAME}/createLivestreamFromShowroomRequest`
)
const createLivestreamFromShowroomFailure = createAction(
  `${SLICE_NAME}/createLivestreamFromShowroomFailure`
)

export function createLivestreamFromShowroom(showroomId: string, data?: any) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createLivestreamFromShowroomRequest())
      const payload = data ?? {
        scheduled_at: moment().add(10, 'minutes').format()
      }
      const response = await api.post(
        `/showrooms/${showroomId}/live_streams`,
        removeNullAndUndefined(payload)
      )

      dispatch(createLivestreamFromShowroomSuccess())

      return response
    } catch (error) {
      dispatch(createLivestreamFromShowroomFailure())

      return error
    }
  }
}

const updateLivestreamFromShowroomRequest = createAction(
  `${SLICE_NAME}/updateLivestreamFromShowroomRequest`
)
const updateLivestreamFromShowroomFailure = createAction(
  `${SLICE_NAME}/updateLivestreamFromShowroomFailure`
)

export function updateLivestreamFromShowroom(
  showroomId: string,
  livestreamId: string,
  data?: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateLivestreamFromShowroomRequest())
      const response = await api.patch(
        `/showrooms/${showroomId}/live_streams/${livestreamId}`,
        data
      )

      dispatch(
        updateLivestreamFromShowroomSuccess({
          showroomId,
          data: response.data
        })
      )

      return response
    } catch (error) {
      dispatch(updateLivestreamFromShowroomFailure())

      return error
    }
  }
}

const fetchRecurrentEventRequest = createAction(
  `${SLICE_NAME}/fetchRecurrentEventRequest`
)
const fetchRecurrentEventFailure = createAction(
  `${SLICE_NAME}/fetchRecurrentEventFailure`
)

export function fetchRecurrentEvent(
  showroomId: string,
  recurrentEventId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchRecurrentEventRequest())
      const response = await api.get(
        `/showrooms/${showroomId}/recurrent_events/${recurrentEventId}`
      )

      dispatch(
        fetchRecurrentEventSuccess({
          recurrentEventId,
          data: response.data
        })
      )

      return response
    } catch (error) {
      dispatch(fetchRecurrentEventFailure())

      return error
    }
  }
}

const createRecurrentEventRequest = createAction(
  `${SLICE_NAME}/createRecurrentEventRequest`
)
const createRecurrentEventFailure = createAction(
  `${SLICE_NAME}/createRecurrentEventFailure`
)

export function createRecurrentEvent(showroomId: string, data?: any) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createRecurrentEventRequest())
      const payload = data ?? {
        scheduled_at: moment().add(10, 'minutes').format()
      }
      const response = await api.post(
        `/showrooms/${showroomId}/recurrent_events`,
        removeNullAndUndefined(payload)
      )

      dispatch(createRecurrentEventSuccess())

      return response
    } catch (error) {
      dispatch(createRecurrentEventFailure())

      return error
    }
  }
}

const updateRecurrentEventRequest = createAction(
  `${SLICE_NAME}/updateRecurrentEventRequest`
)
const updateRecurrentEventFailure = createAction(
  `${SLICE_NAME}/updateRecurrentEventFailure`
)
export function updateRecurrentEvent(
  showroomId: string,
  recurrentEventId: string,
  data?: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateRecurrentEventRequest())
      const response = await api.patch(
        `/showrooms/${showroomId}/recurrent_events/${recurrentEventId}`,
        data
      )

      dispatch(updateRecurrentEventSuccess())

      return response
    } catch (error) {
      dispatch(updateRecurrentEventFailure())

      return error
    }
  }
}

const deleteRecurrentEventRequest = createAction(
  `${SLICE_NAME}/deleteRecurrentEventRequest`
)
const deleteRecurrentEventFailure = createAction(
  `${SLICE_NAME}/deleteRecurrentEventFailure`
)

export function deleteRecurrentEvent(
  showroomId: string,
  recurrentEventId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteRecurrentEventRequest())
      const response = await api.delete(
        `/showrooms/${showroomId}/recurrent_events/${recurrentEventId}`
      )

      dispatch(
        deleteRecurrentEventSuccess({
          showroomId,
          recurrentEventId
        })
      )

      return response
    } catch (error) {
      dispatch(deleteRecurrentEventFailure())

      return error
    }
  }
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    fetchShowroomsSuccess(state, action) {
      const { businessId, showrooms, next_page } = action.payload
      // need this to reset the state when fetching new business
      if (
        !Object.entries(state.showrooms).every(
          ([, showroom]) => showroom.business_id === businessId
        )
      ) {
        state.showrooms = {}
      }
      showrooms.forEach((room: Showroom) => {
        state.showrooms[room.id] = room
      })
      state.nextPage = next_page
      state.isLoading = false
    },
    createShowroomSuccess(state) {
      state.isLoading = false
    },
    deleteShowroomSuccess(state, action) {
      const showroomId = action.payload.data
      delete state.showrooms[showroomId]
      state.isLoading = false
    },
    fetchShowroomSuccess(state, action) {
      const showroom = action.payload.data
      state.showrooms[showroom.id] = showroom
      state.isLoading = false
    },
    updateShowroomSuccess(state, action) {
      const showroom = action.payload.data
      state.showrooms[showroom.id] = showroom
      state.isLoading = false
    },
    fetchPlaylistsForShowroomSuccess(state) {
      state.isLoading = false
    },
    fetchShowroomInteractionsSuccess(state, action) {
      const { interactions, showroomId } = action.payload
      state.interactions[showroomId] = interactions
      state.isLoading = false
    },
    createShowroomInteractionSuccess(state, action) {
      const { showroomId, data: interaction } = action.payload
      state.interactions[showroomId].push(interaction)
      state.isLoading = false
    },
    updateShowroomInteractionSuccess(state, action) {
      const { showroomId, data: interaction } = action.payload
      const index = state.interactions[showroomId].findIndex(
        (i) => i.id === interaction.id
      )
      const newState = [...state.interactions[showroomId]]
      newState[index] = interaction
      state.interactions[showroomId] = newState
      state.isLoading = false
    },
    deleteShowroomInteractionSuccess(state, action) {
      const { showroomId, interactionId } = action.payload
      const newInteractions = state.interactions[showroomId].filter(
        (interaction: globalLib.VideoInteraction) =>
          interaction.id !== interactionId
      )
      state.interactions[showroomId] = newInteractions
      state.isLoading = false
    },
    fetchShowroomProductsSuccess(state, action) {
      const { products, showroomId } = action.payload
      state.products[showroomId] = products
      state.isLoading = false
    },
    updateShowroomProductSuccess(state, action) {
      const { showroomId, data: product } = action.payload
      const index = state.products[showroomId].findIndex(
        (i) => i.id === product.id
      )
      const newState = [...state.products[showroomId]]
      newState[index] = product
      state.products[showroomId] = newState
      state.isLoading = false
    },
    deleteShowroomProductsSuccess(state, action) {
      const { showroomId, productIds } = action.payload
      const newProducts = state.products[showroomId].filter(
        (product: globalLib.Product) => !productIds.includes(product.id)
      )
      state.products[showroomId] = newProducts

      state.isLoading = false
    },
    sortShowroomProductsSuccess(state, action) {
      const { products, showroomId } = action.payload
      state.products[showroomId] = products
      state.isLoading = false
    },
    fetchSessionsForShowroomSuccess(state, action) {
      const { data, showroomId, fresh, search } = action.payload

      // entries should be searchedSessions if search is true
      // otherwise, entries should be sessions
      const entries = search
        ? state.searchedSessions[showroomId]
          ? state.searchedSessions[showroomId].entries
          : []
        : state.sessions[showroomId]
        ? state.sessions[showroomId].entries
        : []

      data.entries.forEach((entry: ShowroomSession) => {
        if (
          entry.showroom_recurrent_event_id &&
          !state.recurrentEvents[entry.showroom_recurrent_event_id]?.color
        ) {
          const recurrentEvent = entry as RecurrentEvent
          state.recurrentEvents[entry.showroom_recurrent_event_id] = {
            ...recurrentEvent,
            starts_at: recurrentEvent.scheduled_at,
            color: `#${Math.random().toString(16).slice(-6)}`
          }
        }
      })

      // a lot of these fields are filled here manually because the showroom
      // APIs are not consistent with the videos API, and it is cleaner to do
      // everything here instead of adding additional logic elsewhere
      const dataEntries = data.entries.map((entry: ShowroomSession) => ({
        ...entry,
        creator: {
          ...state.showrooms[showroomId].channel
        },
        products: [],
        interactions: [],
        video_type: 'live_stream',
        live_stream_status: entry.status,
        started_at: entry.scheduled_at,
        color:
          entry.showroom_recurrent_event_id &&
          state.recurrentEvents[entry.showroom_recurrent_event_id]
            ? state.recurrentEvents[entry.showroom_recurrent_event_id].color
            : `#${Math.random().toString(16).slice(-6)}`
      }))

      const newState = {
        entries: fresh
          ? dataEntries
          : unionBy(entries, dataEntries, 'scheduled_at'),
        next_page: data.next_page
      }

      if (search) {
        state.searchedSessions[showroomId] = newState
      } else {
        state.sessions[showroomId] = newState
      }
      state.isLoading = false
    },
    deleteShowroomSessionSuccess(state, action) {
      const { deletedSessionId, showroomId } = action.payload
      const { entries, next_page } = state.sessions[showroomId]
      state.sessions[showroomId] = {
        next_page,
        entries: entries.filter((session: ShowroomSession) =>
          session?.video ? session.video?.id !== deletedSessionId : true
        )
      }
      state.isLoading = false
    },
    createLivestreamFromShowroomSuccess(state) {
      state.isLoading = false
    },
    updateLivestreamFromShowroomSuccess(state, action) {
      const { data, showroomId } = action.payload
      const stateEntries = state.sessions[showroomId]
        ? state.sessions[showroomId].entries
        : []
      const next_page = state.sessions[showroomId]
        ? state.sessions[showroomId].next_page
        : {}

      const index = stateEntries.findIndex(
        (session: ShowroomSession) => session.id === data.id
      )
      const {
        video: { thumbnail_url, caption, web_share_url },
        ...rest
      } = data
      stateEntries[index] = {
        ...rest,
        video: {
          id: stateEntries[index].video.id,
          thumbnail_url,
          web_share_url,
          caption
        },
        color: stateEntries[index].color
      }

      state.sessions[showroomId] = {
        entries: stateEntries,
        next_page
      }
      state.isLoading = false
    },
    fetchRecurrentEventSuccess(state, action) {
      const { data, recurrentEventId } = action.payload
      state.recurrentEvents[recurrentEventId] = data
      state.isLoading = false
    },
    createRecurrentEventSuccess(state) {
      state.isLoading = false
    },
    updateRecurrentEventSuccess(state) {
      state.isLoading = false
    },
    deleteRecurrentEventSuccess(state, action) {
      const { recurrentEventId, showroomId } = action.payload
      // remove all sessions that are associated with this recurrent event
      const { entries } = state.sessions[showroomId]
      state.sessions[showroomId].entries = entries.filter(
        (session: ShowroomSession) =>
          session.showroom_recurrent_event_id !== recurrentEventId
      )
      delete state.recurrentEvents[recurrentEventId]
      state.isLoading = false
    }
  }
})

export default slice.reducer

export const {
  fetchShowroomsSuccess,
  createShowroomSuccess,
  deleteShowroomSuccess,
  fetchShowroomSuccess,
  updateShowroomSuccess,
  fetchPlaylistsForShowroomSuccess,
  fetchShowroomInteractionsSuccess,
  createShowroomInteractionSuccess,
  updateShowroomInteractionSuccess,
  deleteShowroomInteractionSuccess,
  fetchShowroomProductsSuccess,
  updateShowroomProductSuccess,
  deleteShowroomProductsSuccess,
  sortShowroomProductsSuccess,
  fetchSessionsForShowroomSuccess,
  deleteShowroomSessionSuccess,
  createLivestreamFromShowroomSuccess,
  updateLivestreamFromShowroomSuccess,
  fetchRecurrentEventSuccess,
  createRecurrentEventSuccess,
  updateRecurrentEventSuccess,
  deleteRecurrentEventSuccess
} = slice.actions
