import { Dispatch, createAction, createSlice } from '@reduxjs/toolkit'
import { MonitoringExclusionExpression } from '@src/oneToOne/components/Settings/Appearance/Advanced/MonitoringCensored'
import { convertToCameraPermission } from '@src/oneToOne/utils/chatChannel'

import api from '../../utils/api'

type IProps = {
  ids: string[]
  chatChannels: Record<string, ChatChannel>
  chatChannelAgentAvailabilities: Record<
    string,
    {
      lastFetched: string
      agents: ChatChannelAgentAvailability[]
    }
  >
  paging: Record<string, any>
}

const initialState: IProps = {
  ids: [],
  chatChannels: {},
  chatChannelAgentAvailabilities: {},
  paging: {}
}

const slice = createSlice({
  name: 'chatChannel',
  initialState,
  reducers: {
    fetchChatChannelsSuccess(state, action) {
      const { businessId, chat_channels, paging, page } = action.payload

      // Clear chat channels if the chat channels do not belong to the business the user is currently on
      if (
        Object.values(state.chatChannels).filter(
          (b) => b.business_id !== businessId
        ).length
      ) {
        state.ids = []
        state.chatChannels = {}
      }

      state.paging = paging
      if (page) {
        chat_channels.forEach((cc) => {
          if (state.ids.indexOf(cc.id) === -1) state.ids.push(cc.id)
        })
      } else {
        state.ids = chat_channels.map((cc) => cc.id)
      }
      state.ids = chat_channels.map((cc) => cc.id)
      chat_channels.forEach((cc) => {
        // backend currently returns values which are not fully resolved and may incorrect values,
        // so we need to use only id and name from chat channel list
        const chatChannel = { id: cc.id, name: cc.name }
        if (!state.chatChannels[chatChannel.id]) {
          state.chatChannels[chatChannel.id] = chatChannel
        } else {
          state.chatChannels[chatChannel.id] = {
            ...state.chatChannels[chatChannel.id],
            ...chatChannel
          }
        }
      })
    },
    fetchChatChannelsFailure(state) {
      state.ids = []
      state.chatChannels = {}
      state.paging = {}
    },
    fetchChatChannelSuccess(state, action) {
      const { businessId, chatChannel } = action.payload

      // Clear chat channels if the chat channels do not belong to the business the user is currently on
      if (
        Object.values(state.chatChannels).filter(
          (b) => b.business_id !== businessId
        ).length
      ) {
        state.ids = []
        state.chatChannels = {}
        state.paging = {}
      }

      if (!state.ids.includes(chatChannel.id)) {
        state.ids.push(chatChannel.id)
      }
      state.chatChannels[chatChannel.id] = chatChannel
    },
    updateChatChannelConfigSuccess(state, action) {
      const {
        chatChannelId,
        widget_style = undefined,
        widget_visibility_rules = undefined,
        legal_statement = undefined,
        camera_permission = undefined,
        scheduling_url = undefined, // `null` means remove `scheduling_url`
        waitlist_options = undefined,
        widget_availability = undefined,
        office_hours = undefined,
        automated_messages = undefined
      } = action.payload

      if (!state.ids.includes(chatChannelId)) return

      // Update Widget Style object
      if (widget_style !== undefined) {
        state.chatChannels[chatChannelId].config.widget_style = widget_style
      }

      // Update Widget Visibility Rules object
      if (widget_visibility_rules !== undefined) {
        state.chatChannels[
          chatChannelId
        ].config.widget_visibility_rules = widget_visibility_rules
      }

      // Update Legal Statement string which can be empty
      if (legal_statement !== undefined) {
        state.chatChannels[
          chatChannelId
        ].config.legal_statement = legal_statement
      }

      if (camera_permission !== undefined) {
        state.chatChannels[
          chatChannelId
        ].config.camera_permission = camera_permission
      }

      if (scheduling_url !== undefined) {
        state.chatChannels[chatChannelId].config.scheduling_url = scheduling_url
      }

      // Update Waitlist Capacity object
      if (waitlist_options !== undefined) {
        state.chatChannels[
          chatChannelId
        ].config.waitlist_options = waitlist_options
      }

      if (widget_availability !== undefined) {
        state.chatChannels[
          chatChannelId
        ].config.widget_availability = widget_availability
      }

      if (office_hours !== undefined) {
        state.chatChannels[chatChannelId].config.office_hours = office_hours
      }

      if (automated_messages !== undefined) {
        state.chatChannels[
          chatChannelId
        ].config.automated_messages = automated_messages
      }
    },
    fetchAgentAvailabilitiesSuccess(state, action) {
      const { chatChannelId, data } = action.payload
      state.chatChannelAgentAvailabilities[chatChannelId] = {
        lastFetched: new Date().toLocaleString(),
        agents: data.agent_availabilities
      }
    },
    updateAgentAvailabilitySuccess(state, action) {
      const { chatChannelId, userId, available } = action.payload
      const agent = state.chatChannelAgentAvailabilities[
        chatChannelId
      ]?.agents.find((agent) => agent.agent.id === userId)
      if (agent) {
        agent.available = available
        agent.last_online = new Date().toISOString()
      }
    },
    disconnectCalendlyAccountSuccess(state, action) {
      const { chatChannelId } = action.payload
      const calendlyAccount =
        state.chatChannels[chatChannelId]?.config?.calendly_account
      if (calendlyAccount) {
        calendlyAccount.connected = false
      }
    }
  }
})

export default slice.reducer
export const {
  fetchChatChannelsSuccess,
  fetchChatChannelSuccess,
  updateChatChannelConfigSuccess,
  fetchAgentAvailabilitiesSuccess,
  updateAgentAvailabilitySuccess,
  disconnectCalendlyAccountSuccess
} = slice.actions

/**
 * Fetch Chat Channel
 */
const fetchChatChannelsRequest = createAction(
  'chatChannel/fetchChatChannelsRequest'
)
const fetchChatChannelsFailure = createAction(
  'chatChannel/fetchChatChannelsFailure'
)

export function fetchChatChannels(
  businessId: string,
  page?: string,
  pageSize = 10
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChatChannelsRequest())
      let url = `bus/${businessId}/chat_channels?page_size=${pageSize}`
      if (page) {
        url = page
      }
      const response = await api.get(url)
      const { chat_channels, paging } = response.data
      dispatch(
        fetchChatChannelsSuccess({
          businessId,
          chat_channels,
          paging,
          page
        })
      )

      return response
    } catch (error) {
      dispatch(fetchChatChannelsFailure())

      return error
    }
  }
}

const fetchChatChannelRequest = createAction(
  'chatChannel/fetchChatChannelRequest'
)
const fetchChatChannelFailure = createAction(
  'chatChannel/fetchChatChannelFailure'
)

export function fetchChatChannel(businessId: string, chatChannelId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChatChannelRequest())
      const response = await api.get<ChatChannel>(
        `/api/bus/${businessId}/chat_channels/${chatChannelId}`
      )
      const chatChannel = response.data
      dispatch(fetchChatChannelSuccess({ businessId, chatChannel }))

      return response
    } catch (error) {
      dispatch(fetchChatChannelFailure())

      return error
    }
  }
}

const updateChatChannelConfigRequest = createAction(
  'chatChannel/updateChatChannelConfigRequest'
)
const updateChatChannelConfigFailure = createAction(
  'chatChannel/updateChatChannelConfigFailure'
)

interface ChatChannelWidgetStyleRequest {
  thumbnail_key?: string
  idle_state_text?: string
  expanded_state_text?: string
  waitlist_message?: string
  widget_position?: string
  primary_color?: string
}

// `ChatChannelConfig` request schema which is different from `ChatChannelConfig`
export interface ChatChannelConfigRequest {
  widget_style?: ChatChannelWidgetStyleRequest
  widget_visibility_rules?: ChatChannelWidgetVisibilityRules
  camera_permission?: 'always_allow' | 'invite_only' | 'never_allow'
  legal_statement?: string
  capture_lead_information?: boolean
  waitlist_options?: ChatChannelWaitlistOptions
  widget_availability?: WidgetAvailabiltyOption
  restrict_to_qualified_visitors?: boolean
  office_hours?: ChatChannelOfficeHours
  classify_conversation_intent?: boolean
  customer_support_redirect_message?: string
  welcome_message?: string
  automated_messages?: ChatChannelAutomatedMessage[]
  scheduling_provider?: ChatChannelConfig['scheduling_provider']
  scheduling_url?: null | string
  calendly_account?: ChatChannelCalendly
  monitoring_exclusion_rules?:
    | MonitoringExclusionExpression
    | Record<string, never>
}

export const updateChatChannelConfig = (
  businessId: string,
  chatChannelId: string,
  config: ChatChannelConfigRequest
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateChatChannelConfigRequest())
      const response = await api.patch(
        `/api/bus/${businessId}/chat_channels/${chatChannelId}`,
        config
      )
      handleChatChannelSuccess(dispatch, businessId, chatChannelId, response)

      return response
    } catch (error) {
      dispatch(updateChatChannelConfigFailure())

      return error
    }
  }
}

/// Helpers
const parserChatChannelConfig = (response: any) => {
  const config = response?.config?.data
  if (!config) return null

  return JSON.parse(config)
}

const handleChatChannelSuccess = async (
  dispatch: Dispatch,
  businessId: string,
  chatChannelId: string,
  response: any
) => {
  const configData = parserChatChannelConfig(response)
  if (configData) {
    // Need to convert `camera_permission`
    const camera_permission = configData.camera_permission
    if (camera_permission) {
      configData.camera_permission = convertToCameraPermission(
        camera_permission
      )
    }

    /**
     * 204 No response returned from PATCH api call, and
     * `thumbnail_key` is returned instead of `thumbnail_url` in the `response.config.data`
     *  so we need to fetch the chat channel again
     */
    dispatch(updateChatChannelConfigSuccess({}))
    dispatch(fetchChatChannel(businessId, chatChannelId) as any)
  } else {
    dispatch(updateChatChannelConfigSuccess({}))
    // No response returned from PATCH api call, so we need to fetch the chat channel again
    dispatch(fetchChatChannel(businessId, chatChannelId) as any)
  }
}

const fetchAgentAvailabilitiesRequest = createAction(
  'chatChannel/fetchAgentAvailabilitiesRequest'
)
const fetchAgentAvailabilitiesFailure = createAction(
  'chatChannel/fetchAgentAvailabilitiesFailure'
)

export function fetchChatChannelAgentAvailabilities(
  businessId: string,
  chatChannelId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchAgentAvailabilitiesRequest())
      const response = await api.get(
        `/api/bus/${businessId}/chat_channels/${chatChannelId}/agent_availabilities?page_size=1000`
      )
      const data = response.data
      dispatch(fetchAgentAvailabilitiesSuccess({ chatChannelId, data }))

      return response
    } catch (error) {
      dispatch(fetchAgentAvailabilitiesFailure())

      return error
    }
  }
}

const updateAgentAvailabilityRequest = createAction(
  'chatChannel/updateAgentAvailabilityRequest'
)
const updateAgentAvailabilityFailure = createAction(
  'chatChannel/updateAgentAvailabilityFailure'
)

export function updateAgentAvailability(
  businessId: string,
  chatChannelId: string,
  userId: string,
  available: boolean
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateAgentAvailabilityRequest())
      const response = await api.patch(
        `/api/bus/${businessId}/chat_channels/${chatChannelId}/agent_availability/${userId}`,
        { available }
      )
      dispatch(
        updateAgentAvailabilitySuccess({ chatChannelId, userId, available })
      )

      return response
    } catch (error) {
      dispatch(updateAgentAvailabilityFailure())

      return error
    }
  }
}

const disconnectCalendlyAccountRequest = createAction(
  'chatChannel/disconnectCalendlyAccountRequest'
)
const disconnectCalendlyAccountFailure = createAction(
  'chatChannel/disconnectCalendlyAccountFailure'
)

export function disconnectCalendlyAccount(
  businessId: string,
  chatChannelId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(disconnectCalendlyAccountRequest())
      const response = await api.delete(
        `/api/bus/${businessId}/chat_channels/${chatChannelId}/calendly_account`
      )
      dispatch(disconnectCalendlyAccountSuccess({ chatChannelId }))

      return response
    } catch (error) {
      dispatch(disconnectCalendlyAccountFailure())

      return error
    }
  }
}
