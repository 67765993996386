import { fillMissingDates } from '@src/utils/stats'
import { useTranslation } from 'react-i18next'

import theme from '../../../styles/theme'

const COLOR = [theme.red, '#FFC238', theme.linkBlue, theme.red]
const sumObject = (o) => {
  let result = 0
  for (const i in o) result += o[i]
  return result
}
export const LiveReplayData = (live, replay, key, key2) => {
  const { t } = useTranslation()
  const averageWatchTime = live['unique_viewers_count']
    ? Math.ceil(
        (live['ivs_total_watched_seconds'] || live['total_watched_seconds']) /
          live['unique_viewers_count']
      )
    : 0
  return [
    {
      type: t('Live viewers'),
      value:
        typeof live[key] === 'string'
          ? sumObject(JSON.parse(live[key]))
          : live[key],
      color: COLOR[0],
      [key2 ? key2 : '']: averageWatchTime
    },
    {
      type: t('Replay viewers'),
      value:
        typeof replay[key] === 'string'
          ? sumObject(JSON.parse(replay[key]))
          : replay[key],
      color: COLOR[1],
      [key2 ? key2 : '']: 0
    }
  ]
}
export const ShoppingData = (live, replay) => {
  const { t } = useTranslation()
  // Live and Replay active viewers counts
  const liveActiveViewers = live['active_viewers_count']
  const replayActiveViewers = replay['active_viewers_count']

  // Out-Stream data
  const outstreamAddToCart = live['uniq_product_click_vis_ct']
  const outstreamAddToCartRate =
    liveActiveViewers === 0
      ? 0
      : +((outstreamAddToCart / liveActiveViewers) * 100).toFixed(1)
  const outstreamReplayAddToCart = replay['uniq_product_click_vis_ct']
  const outstreamReplayAddToCartRate =
    replayActiveViewers === 0
      ? 0
      : +((outstreamReplayAddToCart / replayActiveViewers) * 100).toFixed(1)

  // In-Stream data
  const instreamAddToCart = live['total_a2c_visitor_count']
  const instreamAddToCartRate =
    liveActiveViewers === 0
      ? 0
      : +((instreamAddToCart / liveActiveViewers) * 100).toFixed(1)
  const instreamReplayAddToCart = replay['total_a2c_visitor_count']
  const instreamReplayAddToCartRate =
    replayActiveViewers === 0
      ? 0
      : +((instreamReplayAddToCart / replayActiveViewers) * 100).toFixed(1)

  return [
    {
      type: t('Product click rate (Live)'),
      value: isNaN(outstreamAddToCartRate) ? '-' : `${outstreamAddToCartRate}%`,
      chart: outstreamAddToCart,
      total: outstreamAddToCart,
      color: COLOR[0],
      dataType: 'pc'
    },
    {
      type: t('Product click rate (Replay)'),
      value: isNaN(outstreamReplayAddToCartRate)
        ? '-'
        : `${outstreamReplayAddToCartRate}%`,
      chart: outstreamReplayAddToCart,
      total: outstreamReplayAddToCart,
      color: COLOR[1],
      dataType: 'pc'
    },

    {
      type: t('Add to cart rate (Live)'),
      value: isNaN(instreamAddToCartRate) ? '-' : `${instreamAddToCartRate}%`,
      chart: instreamAddToCart,
      total: instreamAddToCart,
      color: COLOR[2],
      dataType: 'a2c'
    },
    {
      type: t('Add to cart rate (Replay)'),
      value: isNaN(instreamReplayAddToCartRate)
        ? '-'
        : `${instreamReplayAddToCartRate}%`,
      chart: instreamReplayAddToCart,
      total: instreamReplayAddToCart,
      color: COLOR[3],
      dataType: 'a2c'
    }
  ]
}
export const MessageData = (data) => {
  const { t } = useTranslation()
  const totalChats = data['total_chats']
  const chatParticipants = data['total_chat_participants']
  return [
    {
      type: t('Messages sent'),
      value: totalChats,
      color: theme.text
    },
    {
      type: t('Total viewers chatted'),
      value: chatParticipants,
      color: theme.text
    }
  ]
}

export const TriplePieData = (live = {}, replay = {}) => {
  const { t } = useTranslation()
  const { total_device_counts: liveDevices } = live
  const { total_device_counts: replayDevices } = replay
  const liveJSON = liveDevices ? JSON.parse(liveDevices) : {}
  const replayJSON = replayDevices ? JSON.parse(replayDevices) : {}
  const liveMobile = liveDevices === undefined ? '-' : liveJSON?.mobile || 0
  const liveDesktop = liveDevices === undefined ? '-' : liveJSON?.desktop || 0
  const liveUnknown = liveDevices === undefined ? '-' : liveJSON?.unknown ?? 0
  const replayMobile =
    replayDevices === undefined ? '-' : replayJSON?.mobile || 0
  const replayDesktop =
    replayDevices === undefined ? '-' : replayJSON?.desktop || 0
  const replayUnknown =
    replayDevices === undefined ? '-' : replayJSON?.unknown ?? 0
  const result = [
    [
      {
        title: t('Live'),
        type: t('Desktop'),
        value: liveDesktop
      },
      {
        title: t('Live'),
        type: t('Mobile'),
        value: liveMobile
      }
    ],
    [
      {
        title: t('Replays'),
        type: t('Desktop'),
        value: replayDesktop
      },
      {
        title: t('Replays'),
        type: t('Mobile'),
        value: replayMobile
      }
    ],
    [
      {
        title: t('Total'),
        type: t('Desktop'),
        value: liveDesktop + replayDesktop
      },
      {
        title: t('Total'),
        type: t('Mobile'),
        value: liveMobile + replayMobile
      }
    ]
  ]
  if (liveUnknown !== '-' && liveUnknown !== 0)
    result[0].push({
      title: t('Live'),
      type: t('Other'),
      value: liveUnknown
    })
  if (replayUnknown !== '-' && replayUnknown !== 0)
    result[1].push({
      title: t('Replay'),
      type: t('Other'),
      value: replayUnknown
    })
  if (liveUnknown > 0 || replayUnknown > 0)
    result[2].push({
      title: t('Total'),
      type: t('Other'),
      value: liveUnknown + replayUnknown
    })
  return result
}

export const EngagementLineData = (
  afterEventData,
  startDate,
  endDate,
  engagement
) => {
  var data = []
  if (afterEventData.length > 0) {
    const keys = Object.keys(afterEventData[0])
    const initData = {}
    keys.forEach((key) => {
      if (key !== 'report_date') initData[key] = 0
    })

    const filledData = fillMissingDates({
      data: afterEventData,
      dataInitFields: initData,
      dataKey: 'report_date',
      startDate,
      endDate
    })

    for (let i = 1; i < filledData.length; i++) {
      const metric = {
        time: `Day ${i}`,
        engagement
      }
      for (const j of keys) {
        if (j !== 'report_date') {
          metric[`${j}`] =
            typeof filledData[i][j] === 'number'
              ? filledData[i][j]
              : sumObject(JSON.parse(filledData[i][j]))
        }
      }
      data.push(metric)
    }
  }
  return data
}

export const LastXDaysEngagementLineData = (
  lastDaysData,
  startDate,
  endDate,
  engagement
) => {
  var data = []
  if (lastDaysData.length > 0) {
    const keys = Object.keys(lastDaysData[0])
    const initData = {}
    keys.forEach((key) => {
      if (key !== 'report_date') initData[key] = 0
    })

    const filledData = fillMissingDates({
      data: lastDaysData,
      dataInitFields: initData,
      dataKey: 'report_date',
      startDate,
      endDate
    })

    for (let i = 1; i < filledData.length; i++) {
      const metric = {
        time: `Day ${i}`,
        engagement
      }
      for (const j of keys) {
        if (j !== 'report_date') {
          metric[`${j}`] =
            typeof filledData[i][j] === 'number'
              ? filledData[i][j]
              : sumObject(JSON.parse(filledData[i][j]))
        }
      }
      data.push(metric)
    }
  }

  return data
}

export const sanitizedData = (data) => {
  return data.map((d) => {
    const result = {}
    Object.keys(d).forEach((key) => {
      if (d[key] === undefined) result[key] = 0
      else result[key] = d[key]
    })
    return result
  })
}

export const liveEngagementData = (data = {}) => {
  // take any key to measure its length. This is equal to livestream's minutes since each data point is 1 minute interval
  const minutes = data.click_add_to_cart?.length || 0
  const keys = Object.keys(data)
  const result = []
  for (let i = 0; i < minutes; i++) {
    const obj = {}
    for (const key of keys) {
      if (key !== 'live_stream') {
        obj[key] = data[key]?.[i] || 0
      }
    }
    obj['timestamp'] = i.toString()
    result.push(obj)
  }
  return result
}

export const realtimeMetricsData = (data = {}) => {
  if (Object.keys(data).length) {
    const {
      click_add_to_cart: a2c,
      click_heart: likes,
      send_chat: chats,
      share_video: shares,
      click_highlighted_product: clickHighlight,
      click_product: clickProduct,
      click_shopping_bag: clickShopping,
      concurrent_viewers: viewers
    } = data
    const length = Math.max(
      a2c?.length || 0,
      likes?.length || 0,
      chats?.length || 0,
      shares?.length || 0,
      clickHighlight?.length || 0,
      viewers?.length || 0
    )
    const newData = [
      {
        click_add_to_cart: 0,
        click_highlighted_product: 0,
        concurrent_viewers: 0,
        click_heart: 0,
        send_chat: 0,
        share_video: 0,
        click_product: 0,
        click_shopping_bag: 0,
        timestamp: '0'
      }
    ]
    for (let i = 0; i < length; i++) {
      const slot = {}

      slot['click_add_to_cart'] = a2c?.length > i ? a2c[i] : 0
      slot['click_heart'] = likes?.length > i ? likes[i] : 0
      slot['send_chat'] = chats?.length > i ? chats[i] : 0
      slot['share_video'] = shares?.length > i ? shares[i] : 0
      slot['click_highlighted_product'] =
        clickHighlight?.length > i ? clickHighlight[i] : 0
      slot['click_product'] = clickProduct?.length > i ? clickProduct[i] : 0
      slot['click_shopping_bag'] =
        clickShopping?.length > i ? clickShopping[i] : 0
      slot['timestamp'] = (i + 1).toString()
      slot['concurrent_viewers'] = viewers?.length > i ? viewers[i] : 0
      newData.push(slot)
    }
    return newData
  } else return []
}

export const totalViewWithTimeRangeData = (data) => {
  if (!data) return []
  const {
    live_stream_insights_summary,
    live_stream_replay_insights_summary
  } = data
  return [
    {
      type: 'Live minutes',
      value: Math.round(
        live_stream_insights_summary?.ivs_total_watched_minutes ||
          live_stream_insights_summary?.total_watched_minutes ||
          0
      ),
      color: COLOR[0]
    },
    {
      type: 'Replay minutes',
      value: Math.round(
        live_stream_replay_insights_summary?.total_watched_minutes || 0
      ),
      color: COLOR[1]
    }
  ]
}

export const playerVisibilityFillData = (data, startDate, endDate) => {
  const presentations = []
  const dataGroups = []
  const result = []
  data.forEach((d) => {
    if (!presentations.includes(d.presentation)) {
      presentations.push(d.presentation)
      dataGroups.push([d])
    } else {
      dataGroups[presentations.indexOf(d.presentation)].push(d)
    }
  })
  for (const i of dataGroups) {
    result.push(
      ...fillMissingDates({
        data: i,
        dataInitFields: {
          player_visibility_rate: 0,
          presentation: i[0]?.presentation
        },
        startDate,
        endDate
      })
    )
  }
  return result
}
