import api from './api'
import { sessionStorageProvider } from './storageProvider'

export const initEmbedApp = (): void => {
  const url = new URL(window.location.href)
  const params = url.searchParams
  const searchParamToken = params.get('token')
  const businessId = params.get('businessId')
  const businessStoreId = params.get('businessStoreId')
  const oauthApp = params.get('oauthApp')
  if (searchParamToken) api.setToken(searchParamToken)
  if (businessId) sessionStorageProvider.setItem('businessId', businessId)
  if (businessStoreId)
    sessionStorageProvider.setItem('businessStoreId', businessStoreId)
  if (oauthApp) sessionStorageProvider.setItem('oauthApp', oauthApp)
}
