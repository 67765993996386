import React, { useState } from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { css } from '@emotion/core'
import { Card, Flex } from '@src/components/EmotionLayout'
import { isMdScreen, isXxlScreen } from '@src/hooks/useMediaQuery'
import { Tooltip, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import {
  useRolesAndPermissions,
  PERMISSIONS
} from '../../hooks/useRolesAndPermissions'
import AppearanceDefault from './AppearanceDefault'
import AppearanceEdit from './AppearanceEdit'

interface Props {
  channel: globalLib.Channel
}

const Settings: React.FC<Props> = ({ channel }): JSX.Element => {
  const [editable, setEditable] = useState(false)
  const { userCan } = useRolesAndPermissions()
  const canEdit = userCan(PERMISSIONS.CHANNEL_EDIT)
  const { t } = useTranslation()
  const mdScreen = isMdScreen()
  const xxlScreen = isXxlScreen()

  const toggleEditable = () => {
    setEditable(!editable)
  }

  return channel?.config ? (
    <Card
      flex="1"
      maxWidth={mdScreen ? '540px' : 'auto'}
      minWidth={xxlScreen ? 'auto' : '45%'}
      minHeight="640"
      position="relative"
    >
      <Flex alignItems="center" mb="large">
        <Typography.Title
          level={4}
          css={css`
            margin: 0 5px 0 0 !important;
          `}
        >
          {t('Appearance')}
        </Typography.Title>
        <Tooltip
          placement="bottom"
          trigger={['hover', 'click']}
          title={t(
            'Appearance Settings lets you customize how your player and embeds will look across your entire Channel. Changes may take up to 30 minutes to appear.'
          )}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </Flex>

      {!editable ? (
        <AppearanceDefault
          canEdit={canEdit}
          config={channel.config}
          openModal={toggleEditable}
        />
      ) : (
        <AppearanceEdit config={channel.config} closeModal={toggleEditable} />
      )}
    </Card>
  ) : null
}

export default Settings
