import React from 'react'

import loadable from '@loadable/component'
import { Route, Outlet } from 'react-router-dom'

const OTO = loadable(() => import('../components/Main'))
const VisitorsContainer = loadable(
  () => import('../components/Feed/VisitorsContainer')
)
const OTOInsights = loadable(() => import('../components/Insights'))
const OTOTeam = loadable(() => import('../components/Team'))
const OTOSettings = loadable(() => import('../components/Settings'))
const OTOAppearance = loadable(
  () => import('../components/Settings/Appearance')
)
const OTOUserSegments = loadable(
  () => import('../components/Settings/UserSegments')
)
const OTOFeeds = loadable(() => import('../components/Settings/Feeds'))
const OTOSnippets = loadable(() => import('../components/Settings/Snippets'))
const OTOAutomation = loadable(
  () => import('../components/Settings/Automation')
)

export const OneToOneRoutes = (
  <Route
    path="/business/:businessId/chat_channel/:chatChannelId"
    element={<OTO />}
  >
    <Route index element={<VisitorsContainer />} />
    <Route path="visitors" element={<VisitorsContainer />}>
      <Route index element={<Outlet />} />
      <Route path=":visitorId" element={<Outlet />} />
    </Route>
    <Route path="insights" element={<OTOInsights />} />
    <Route path="team" element={<OTOTeam />} />
    <Route path="settings" element={<OTOSettings />}>
      <Route index element={<OTOAppearance />} />
      <Route path="appearance" element={<OTOAppearance />} />
      <Route path="user_segments" element={<OTOUserSegments />} />
      <Route path="feeds" element={<OTOFeeds />} />
      <Route path="snippets" element={<OTOSnippets />} />
      <Route path="automation" element={<OTOAutomation />} />
    </Route>
  </Route>
)
