import { localStorageProvider } from '@src/utils/storageProvider'

export function useLocalStorage() {
  const setLocalStorageItem = (key, value) => {
    localStorageProvider.setItem(key, JSON.stringify(value))
  }

  const getLocalStorageItem = (key) => {
    return JSON.parse(localStorageProvider.getItem(key))
  }

  const removeLocalStorageItem = (key) => {
    localStorageProvider.removeItem(key)
  }

  return {
    setLocalStorageItem,
    getLocalStorageItem,
    removeLocalStorageItem
  }
}
