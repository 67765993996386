import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  addLocalFavoriteBusiness,
  getFavoriteBusinessList,
  removeLocalFavoriteBusiness,
  renameLocalFavoriteBusiness
} from '@src/utils/business'
import { Dispatch } from 'redux'

type IProps = {
  favoriteBusinessList: {
    id: string
    name: string
  }[]
}

const initialState: IProps = {
  favoriteBusinessList: []
}

const slice = createSlice({
  name: 'favoriteBusiness',
  initialState: initialState,
  reducers: {
    fetchFavoriteBusinessSuccess(state, action) {
      const { favoriteBusinessList } = action.payload
      state.favoriteBusinessList = favoriteBusinessList || []
    }
  }
})

export default slice.reducer
export const { fetchFavoriteBusinessSuccess } = slice.actions

const fetchFavoriteBusinessRequest = createAction(
  'favoriteBusiness/fetchFavoriteBusinessRequest'
)
const fetchFavoriteBusinessFailure = createAction(
  'favoriteBusiness/fetchFavoriteBusinessFailure'
)

export function fetchFavoriteBusiness(userId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchFavoriteBusinessRequest())
      const favoriteBusinessList = getFavoriteBusinessList(userId)
      dispatch(
        fetchFavoriteBusinessSuccess({
          favoriteBusinessList
        })
      )

      return favoriteBusinessList
    } catch (error) {
      dispatch(fetchFavoriteBusinessFailure())
    }
  }
}

export function addFavoriteBusiness(
  userId: string,
  business: {
    id: string
    name: string
  }
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchFavoriteBusinessRequest())
      addLocalFavoriteBusiness(userId, business)
      const favoriteBusinessList = getFavoriteBusinessList(userId)
      dispatch(
        fetchFavoriteBusinessSuccess({
          favoriteBusinessList
        })
      )

      return favoriteBusinessList
    } catch (error) {
      dispatch(fetchFavoriteBusinessFailure())
    }
  }
}

export function removeFavoriteBusiness(
  userId: string,
  businessEncodedId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchFavoriteBusinessRequest())
      removeLocalFavoriteBusiness(userId, businessEncodedId)
      const favoriteBusinessList = getFavoriteBusinessList(userId)
      dispatch(
        fetchFavoriteBusinessSuccess({
          favoriteBusinessList
        })
      )

      return favoriteBusinessList
    } catch (error) {
      dispatch(fetchFavoriteBusinessFailure())
    }
  }
}

export function renameFavoriteBusiness(
  userId: string,
  business: {
    id: string
    name: string
  }
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchFavoriteBusinessRequest())
      renameLocalFavoriteBusiness(userId, business)
      const favoriteBusinessList = getFavoriteBusinessList(userId)
      dispatch(
        fetchFavoriteBusinessSuccess({
          favoriteBusinessList
        })
      )

      return favoriteBusinessList
    } catch (error) {
      dispatch(fetchFavoriteBusinessFailure())
    }
  }
}
