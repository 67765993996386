const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
}

const mq = Object.keys(breakpoints).reduce((acc, key): any => {
  acc[key] = `@media (min-width: ${breakpoints[key]}px)`

  return acc
}, {})

export default mq
