import {
  createAsyncThunk,
  createSlice,
  Slice,
  SliceCaseReducers
} from '@reduxjs/toolkit'
import api from '@src/utils/api'

const ApiClient = () => {
  const path = '/webhooks_api'

  return {
    endpoints: {
      list: (businessId: string) =>
        api.get(`${path}/businesses/${businessId}/endpoints`),
      fetchSingle: (businessId: string, id: string) =>
        api.get(`${path}/businesses/${businessId}/endpoints/${id}`),
      create: (
        businessId: string,
        data: {
          callback_url: string
          description: string
          event_type_ids: string[]
        }
      ) => api.post(`${path}/businesses/${businessId}/endpoints`, data),
      delete: (businessId: string, id: string) =>
        api.delete(`${path}/businesses/${businessId}/endpoints/${id}`),
      update: (
        businessId: string,
        id: string,
        data: {
          description: string
          is_disabled: boolean
        }
      ) => api.patch(`${path}/businesses/${businessId}/endpoints/${id}`, data),
      fetchDeliveries: (businessId: string, id: string, page?: string) =>
        api.get(
          page || `${path}/businesses/${businessId}/endpoints/${id}/deliveries`
        )
    },
    subscriptions: {
      create: (
        businessId: string,
        data: { endpoint_id: string; event_type_id: string }
      ) =>
        api.post(`${path}/businesses/${businessId}/event_subscriptions`, data),
      delete: (
        businessId: string,
        data: { endpoint_id: string; event_type_id: string }
      ) =>
        api.delete(`${path}/businesses/${businessId}/event_subscriptions`, {
          data
        })
    },
    event_types: {
      list: () => api.get(`${path}/event_types`)
    }
  }
}

const SLICE_NAME = 'webhooks'

interface WebhookState {
  webhooks: globalLib.Webhook[]
  webhook: globalLib.Webhook
  loading: boolean
  error: string
  eventTypes: globalLib.Subscription[]
  webhookDeliveries: globalLib.WebhookDelivery[]
  webhookDeliveriesPaging: globalLib.Paging
}

const initialState: WebhookState = {
  webhooks: [],
  webhook: null,
  loading: false,
  error: null,
  eventTypes: [],
  webhookDeliveries: [],
  webhookDeliveriesPaging: null
}

export const fetchWebhooks = createAsyncThunk(
  `${SLICE_NAME}/fetchWebhooks`,
  async (params: { businessId: string }) => {
    const {
      data: { data }
    } = await ApiClient().endpoints.list(params.businessId)

    return data
  }
)

export const fetchWebhook = createAsyncThunk(
  `${SLICE_NAME}/fetchWebhook`,
  async (params: { businessId: string; id: string }) => {
    const {
      data: { data }
    } = await ApiClient().endpoints.fetchSingle(params.businessId, params.id)

    return data
  }
)

export const fetchEventTypes = createAsyncThunk(
  `${SLICE_NAME}/fetchEventTypes`,
  async () => {
    const {
      data: { data }
    } = await ApiClient().event_types.list()

    return data
  }
)

export const fetchDeliveries = createAsyncThunk(
  `${SLICE_NAME}/fetchWebhookDeliveries`,
  async (params: { businessId: string; id: string; page?: string }) => {
    const {
      data: { data, paging }
    } = await ApiClient().endpoints.fetchDeliveries(
      params.businessId,
      params.id,
      params.page
    )

    return {
      data,
      paging,
      page: params.page
    }
  }
)

export const createWebhook = createAsyncThunk(
  `${SLICE_NAME}/createWebhook`,
  async (params: {
    businessId: string
    data: {
      callback_url: string
      description: string
      event_type_ids: string[]
    }
  }) => {
    const {
      data: { data }
    } = await ApiClient().endpoints.create(params.businessId, params.data)

    return data
  }
)

export const updateWebhook = createAsyncThunk(
  `${SLICE_NAME}/updateWebhook`,
  async (params: {
    businessId: string
    id: string
    data: {
      description: string
      is_disabled: boolean
    }
  }) => {
    const {
      data: { data }
    } = await ApiClient().endpoints.update(
      params.businessId,
      params.id,
      params.data
    )

    return data
  }
)

export const deleteWebhook = createAsyncThunk(
  `${SLICE_NAME}/deleteWebhook`,
  async (params: { businessId: string; id: string }) => {
    await ApiClient().endpoints.delete(params.businessId, params.id)

    return params.id
  }
)

export const createSubscription = createAsyncThunk(
  `${SLICE_NAME}/createSubscription`,
  async (params: {
    businessId: string
    data: { endpoint_id: string; event_type_id: string }
  }) => {
    await ApiClient().subscriptions.create(params.businessId, params.data)
  }
)

export const deleteSubscription = createAsyncThunk(
  `${SLICE_NAME}/deleteSubscription`,
  async (params: {
    businessId: string
    data: { endpoint_id: string; event_type_id: string }
  }) => {
    await ApiClient().subscriptions.delete(params.businessId, params.data)
  }
)

const webhookSlice: Slice<
  WebhookState,
  SliceCaseReducers<WebhookState>,
  typeof SLICE_NAME
> = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebhooks.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchWebhooks.fulfilled, (state, action) => {
        state.loading = false
        state.webhooks = action.payload
      })
      .addCase(fetchWebhooks.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchEventTypes.pending, (state) => {
        state.loading = true
        state.eventTypes = []
      })
      .addCase(fetchEventTypes.fulfilled, (state, action) => {
        state.loading = false
        state.eventTypes = action.payload
      })
      .addCase(fetchWebhook.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchWebhook.fulfilled, (state, action) => {
        state.loading = false
        state.webhook = action.payload
      })
      .addCase(fetchWebhook.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(fetchDeliveries.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchDeliveries.fulfilled, (state, action) => {
        const { data, paging, page } = action.payload
        state.loading = false
        if (page) {
          state.webhookDeliveries = [...state.webhookDeliveries, ...data]
        } else {
          state.webhookDeliveries = data
        }
        state.webhookDeliveriesPaging = paging
      })
      .addCase(fetchDeliveries.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(createWebhook.pending, (state) => {
        state.loading = true
      })
      .addCase(createWebhook.fulfilled, (state, action) => {
        state.loading = false
        const webhook = action.payload
        state.webhooks = [...state.webhooks, webhook]
      })
      .addCase(updateWebhook.fulfilled, (state, action) => {
        state.loading = false

        state.webhooks = state.webhooks.map((webhook) => {
          if (webhook.id === action.payload.id) {
            return action.payload
          }

          return webhook
        })

        state.webhook = action.payload
      })
      .addCase(deleteWebhook.fulfilled, (state, action) => {
        state.loading = false
        state.webhooks = state.webhooks.filter(
          (webhook) => webhook.id !== action.payload
        )
      })
    builder.addDefaultCase((state) => {
      state.error = null
      state.loading = false
    })
  }
})

export default webhookSlice.reducer
