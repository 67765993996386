import styled from '@emotion/styled'
import { Typography } from 'antd'

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0 0;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`

export const BodyText = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  display: inline-block;
  text-align: center;
`

export const FooterContainer = styled.div`
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
