import React, { useState } from 'react'

import { Box } from '@src/components/EmotionLayout'

export type Props = { username: string }
const maxChars = 22

export const BrandUsername: React.FC<Props> = ({
  username = ''
}): JSX.Element => {
  const [user, setUser] = useState('')

  React.useEffect(() => {
    if (username) {
      const user = username
      if (user.length > maxChars)
        setUser(`@${user.substring(0, maxChars)} ${user.substring(maxChars)}`)
      else setUser(`@${user}`)
    }
  }, [username])

  return (
    <Box fontSize="14" fontWeight="500" data-cy="username">
      {user}
    </Box>
  )
}

export default BrandUsername
