import { sumBy, map, isFinite, values, orderBy } from 'lodash'
import moment from 'moment'

import 'moment-duration-format'
import { randInt, randFloat } from './random'

/**
 * Adds in missing reports within a date range.
 * Will only set default number values for those defined in dataInitFields.
 * @param {object} params
 *        {array} data Array of data
 *        {array} dataInitFields Fields to assign default values (number types only)
 *        {string} dateKey Key for the date field
 *        {string} startDate Start date
 *        {string} endDate End date
 * @return {array} Data with missing reports filled in
 */
export const fillMissingDates = ({
  data = [],
  dataInitFields = {},
  dateKey = 'report_date',
  startDate,
  endDate
}) => {
  if (!startDate || !endDate) throw Error('Invalid date')

  const newData = [...data]
  const curr = moment.utc(startDate)
  const end = moment.utc(endDate)
  while (!curr.isSameOrAfter(end)) {
    const currDate = curr.format('YYYY-MM-DD')

    // Add new entry if missing in data
    if (!newData.find((obj) => obj[dateKey] === currDate)) {
      newData.push({
        [dateKey]: currDate,
        ...dataInitFields
      })
    }

    // Increment date
    curr.add(1, 'days')
  }

  return sortByDate({ desc: false, key: dateKey, stats: newData })
}

/**
 * Aggregates stats number fields with existing stats object.
 * @param {object} stats Existing stats
 * @param {object} statToAdd Stat to aggregate
 * @param {string} key Key to segment stats by. If not truthy, it will
 *                     aggregate all root stats.
 * @return {object} Aggregated stats
 */
export const aggregateNumberFields = (stats = {}, statToAdd = {}, key) => {
  const newStats = { ...stats }
  const statKey = key && statToAdd[key]
  Object.keys(statToAdd).forEach((k) => {
    if (isFinite(statToAdd[k])) {
      if (statKey) {
        // Init empty obj
        if (!newStats[statKey]) newStats[statKey] = { [key]: statToAdd[key] }

        // Aggregate by key if truthy
        const prev = newStats[statKey][k] || 0
        const curr = statToAdd[k]
        newStats[statKey][k] = prev + curr
      } else {
        // Aggregate all root keys
        const prev = newStats[k] || 0
        const curr = statToAdd[k]
        newStats[k] = prev + curr
      }
    }
  })
  return newStats
}

/**
 * Accumulates and inserts the cumulative stats for each day.
 * Assumes the ordering of the data is by report_date desc.
 * @param {array} stats Stats by date
 * @param {array} fields Fields to accumulate
 * @return {array} Stats with added cumulative totals
 */
export const accumulateTotals = (stats, fields) => {
  const cumulative = {}
  fields.forEach((f) => (cumulative[f] = 0))

  const cloned = [...stats].reverse()
  cloned.forEach((obj, i) => {
    Object.keys(obj).forEach((k) => {
      if (k in cumulative) {
        cumulative[k] += obj[k] || 0
        cloned[i] = {
          ...cloned[i],
          [`cumulative_${k}`]: cumulative[k]
        }
      }
    })
  })
  return cloned.reverse()
}

/**
 * Sums all the number fields for an array of objects based on the same keyField.
 * @param {array} arr Array to sum
 * @param {string} keyField Field to use as the joining key
 * @param {array} order Fields to order by, e.g. [['user', 'age'], ['asc', 'desc']]
 * @return {array} Stats with added cumulative totals
 */
export const sumObjects = (arr, keyField, order) => {
  let summed = {}

  arr.forEach((o) => {
    // Init object if not found
    const key = `${o[keyField]}`
    if (!summed[key]) summed[key] = {}

    // Loop through each key and sum any number values
    Object.keys(o).forEach((k) => {
      if (k in summed[key]) {
        if (isFinite(o[k])) {
          summed[key][k] += o[k]
        } else {
          summed[key][k] = o[k]
        }
      } else {
        summed[key][k] = o[k]
      }
    })
  })
  summed = values(summed)

  // Sort array if specified order
  if (order) {
    summed = orderBy(summed, order[0], order[1])
  }

  return summed
}

/**
 * Accumulates the first 0...x entries of a field.
 * @param {array} data Data set
 * @param {string} field Field to accumulate
 * @param {number} amount Amount of entries to accumulate
 */
export const sumFirstXFields = (
  data = [],
  field = '',
  amount = 7,
  offset = 0
) => {
  return Math.round(
    data.slice(offset, offset + amount).reduce((a, b) => {
      const toAdd = isFinite(b[field]) ? b[field] : 0
      return a + toAdd
    }, 0)
  )
}

export const sumLastXFields = (
  data = [],
  field = '',
  amount = 7,
  offset = 0
) => {
  const sumOnField = (data) => {
    return data.reduce((a, b) => {
      const toAdd = isFinite(b[field]) ? b[field] : 0
      return a + toAdd
    }, 0)
  }
  return Math.round(
    data.length < offset + amount
      ? sumOnField(data)
      : sumOnField(
          data.slice(data.length - offset - amount, data.length - offset)
        )
  )
}

/**
 * Calculates the percentage change of week over week.
 * @param {array} data Array of data to calculate
 * @param {string} field Field in data to calculate
 * @return {string} Week over week percentage
 */
export const getWeekOverWeekStr = (data, field) => {
  if (!data) throw Error('Data is invalid')
  if (!field) throw Error('Field is invalid')
  if (data.length < 14) return '-'

  const thisWeek = sumLastXFields(data, field, 7)
  const lastWeek = sumLastXFields(data, field, 7, 7)
  if (lastWeek === 0) return '-'
  if (thisWeek > lastWeek)
    return '+' + (Math.round((100 * thisWeek) / lastWeek) - 100) + '%'
  if (thisWeek < lastWeek)
    return '-' + (100 - Math.round((100 * thisWeek) / lastWeek)) + '%'
}

/**
 * Sorts the array of objects by 'date' field.
 * @param {object} params Params for sorting
 *        {array} stats Stats array of objects
 *        {boolean} desc Descending order
 *        {string} key Key to sort by
 * @return {array} Sorted array
 */
export const sortByDate = ({
  stats = [],
  desc = true,
  key = 'report_date'
}) => {
  const cloned = [...stats]
  if (desc) {
    cloned.sort((a, b) => new Date(b[key]) - new Date(a[key]))
  } else {
    cloned.sort((a, b) => new Date(a[key]) - new Date(b[key]))
  }
  return cloned
}

/**
 * Returns the top video categories and splits the top X with the rest.
 * @param {array} data Array of data
 * @param {number} num Number of top X videos to return
 * @return {array} Top X videos and others
 */
export const getTopCategories = (data, num) => {
  if (!data) throw Error('Data cannot be empty')
  if (!num) throw Error('Must specify num')

  // Calculate total video views
  const videoViewsTotal = sumBy(data, 'video_views')

  // Separate top videos with others
  let top = data.slice(0, num)
  const others = data.slice(num)
  top.push({
    tag_name: 'others',
    display_name: 'Others',
    video_views: sumBy(others, 'video_views'),
    total_minutes_watched: sumBy(others, 'total_minutes_watched')
  })
  top = map(top, (obj) => {
    return {
      ...obj,
      percentage: obj.video_views / videoViewsTotal
    }
  })
  return top
}

/**
 * Generates a stat.
 * @param {array} fields All the fields to populate. e.g.
 *                       { type: 'integer', key: 'some_int', max: 100 },
 *                       { type: 'float', key: 'some_float', max: 100 },
 *                       { type: 'enum', key: 'some_enum', options: ['one', 'two] },
 *                       { type: 'object', options: [{ a: 1 }, { b: 2 }] }
 */
export const generateStat = (fields = []) => {
  const stat = {}
  fields.forEach((obj) => {
    switch (obj.type) {
      case 'integer': {
        stat[obj.key] = randInt(obj.max)
        break
      }
      case 'float': {
        stat[obj.key] = randFloat(obj.max)
        break
      }
      case 'enum': {
        stat[obj.key] = obj.options[randInt(obj.options.length)]
        break
      }
      case 'object': {
        const randOpt = obj.options[randInt(obj.options.length)]
        Object.keys(randOpt).forEach((key) => {
          stat[key] = randOpt[key]
        })
        break
      }
      default: {
      }
    }
  })
  return stat
}

/**
 * Generates stats for a given date range.
 * @param {string|Moment} startDate Starting date, e.g. '2020-01-01'
 * @param {string|Moment} endDate Ending date, e.g. '2020-12-31'
 * @param {array} fields All the fields to populate. e.g.
 *                       { type: 'integer', key: 'some_int', max: 100 },
 *                       { type: 'float', key: 'some_float', max: 100 },
 *                       { type: 'enum', key: 'some_enum', options: ['one', 'two] },
 *                       { type: 'object', options: [{ a: 1 }, { b: 2 }] }
 * @param {object} opts Options to apply:
 *                      - {string} order Ordering of dates: [asc|desc]
 */
export const generateStatsForDateRange = (
  startDate,
  endDate,
  fields = [],
  opts = {}
) => {
  const { order = 'desc' } = opts
  const isOrderDesc = order === 'desc'
  const currentDate = moment(isOrderDesc ? endDate : startDate)

  // Create data for each day
  const stats = []
  while (
    isOrderDesc
      ? currentDate.isSameOrAfter(startDate)
      : currentDate.isSameOrBefore(endDate)
  ) {
    // Create stat
    const stat = {
      date: currentDate.format('YYYY-MM-DD'),
      ...generateStat(fields)
    }

    // Add to array
    stats.push(stat)

    // Go to next day
    if (isOrderDesc) currentDate.subtract(1, 'days')
    else currentDate.add(1, 'days')
  }

  return stats
}

export const convertTimeUnit = (totalSeconds) => {
  const formatUnit = (time, unit) =>
    moment.duration(time, unit).format('h[h] m[m] s[s]', { trim: 'both' })
  if (totalSeconds < 60) return formatUnit(totalSeconds, 'seconds')
  if (totalSeconds < 3600)
    return formatUnit((totalSeconds / 60).toFixed(2), 'minutes')
  else return formatUnit((totalSeconds / 3600).toFixed(2), 'hours')
}
/*
  Convert minutes to a time unit
  @param {number} minutes
  @param {string} unit
  @return {string} Converted time unit
*/
export const convertTimeMinute = (minutes, unit) => {
  return moment.duration(minutes, 'minutes').format(unit, { trim: 'both' })
}
/*
 Sort an array of objects based on a key in each object
 Default sort is descending.
 If type != 'desc', the function is ascending sort function
*/
export const sortObjectByKey = (data = [], key, type = 'desc') => {
  const compare = (a, b) => {
    return type === 'asc' ? a[key] - b[key] : b[key] - a[key]
  }
  return [...data].sort(compare)
}
/*
  Return first x elements in the data and the 'Other' element
  which is the sum of rest elements by keys in the array key
*/
export const segmentTopX = (data = [], key = [], label, x = 9) => {
  if (data.length <= x || x <= 0) return data
  else {
    const head = data.slice(0, x)
    const tail = data.slice(x, data.length)
    const keyObject = {}
    key.forEach((k) => {
      if (k) keyObject[k] = sumBy(tail, k)
    })
    const other = {
      [label]: 'Other',
      ...keyObject
    }
    return [...head, other].filter((e) => key.length > 0 && e[key[0]] > 0)
  }
}

/*
  Add watch_time to object which has total_seconds_watched key
  watch_time is used in tooltip of charts which have high total_seconds_watch value
*/
export const addWatchTime = (data) => {
  if (data.length) {
    return data.map((d) => {
      if (d['total_seconds_watched'])
        return { ...d, watch_time: d.total_seconds_watched }
      else return d
    })
  }
  return []
}

/**
 * Fill up empty data and add 'other' to indexKey for object data which does not have indexKey
 * @param {array} data Input data which is missing some object.
 * @param {string} indexKey Use to identify missing data
 * @param {string|number} value Use this value to fill up all keys for filled object except at indexKey
 * @param {array} arrKeys An array of all keys.
 *                        This array use to identify missing keys
 *
 */
export const fillUpData = (data, indexKey, value = 0, arrKeys) => {
  if (data.length) {
    const fillKeys = [...arrKeys]
    const filteredData = data.map((d) => {
      const mainValue = d[indexKey]
      if (mainValue && fillKeys.includes(mainValue))
        fillKeys.splice(fillKeys.indexOf(mainValue), 1)
      if (!mainValue || !arrKeys.includes(mainValue))
        return { ...d, [indexKey]: 'other' }
      else return d
    })

    const filledData = fillKeys.map((d) => {
      const dummy = { ...filteredData[0] }
      for (const i in dummy) dummy[i] = value
      dummy[indexKey] = d
      return dummy
    })

    return [...filteredData, ...filledData]
  } else return data
}
/*
  Check if 2 arrays have the same elements regardless of order
  [1, 2] = [2, 1]
*/

export const compareArrays = (a, b) => {
  const hash = {}
  if (a.length !== b.length) return false
  for (let i = 0; i < a.length; i++) {
    if (!hash[a[i]]) hash[a[i]] = 1
    else hash[a[i]] += 1
    if (!hash[b[i]]) hash[b[i]] = -1
    else hash[b[i]] -= 1
  }
  for (const k in hash) {
    if (hash[k] !== 0) return false
  }
  return true
}

export const trimText = (text, maxLength) => {
  if (text.length > maxLength) return `${text.substring(0, maxLength)}...`
  else return text
}
