import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { removeUndefined } from '@src/utils/sanitize'
import { Dispatch } from 'redux'

import api, { API_URL, selfErrorHandlerRequest } from '../utils/api'

type IProps = {
  subscriptionsByBusinessId: Record<string, globalLib.BusinessSubscription>
  subscriptionUsageByBusinessId: Record<string, any>
  meteredUsageByBusinessId: Record<string, any>
  plans: globalLib.BusinessPlan[]
}

const initialState: IProps = {
  subscriptionsByBusinessId: {},
  subscriptionUsageByBusinessId: {},
  meteredUsageByBusinessId: {},
  plans: []
}

export const CURRENT_SUBSCRIPTION_DEACTIVE_ERROR_CODE = 404

export const updateSubscription = createAsyncThunk(
  'subscriptions/updateSubscription',
  async (params: { plan: string; uid: string }) => {
    const { plan, uid } = params
    const { data } = await api.get<Partial<globalLib.BusinessSubscription>>(
      `${API_URL}/shopify/payment/select_plan`,
      {
        params: {
          plan,
          uid
        }
      }
    )

    return data
  }
)

const slice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    fetchPaymentSubscriptionsSuccess(state, action) {
      const { businessId, subscription } = action.payload
      state.subscriptionsByBusinessId[businessId] = subscription
    },
    fetchPlansSuccess(state, action) {
      const { plans } = action.payload
      state.plans = plans
    },
    fetchSubscriptionUsageSuccess(state, action) {
      const { businessId, data } = action.payload
      state.subscriptionUsageByBusinessId[businessId] = data
    },
    fetchMeteredUsageSuccess(state, action) {
      const { businessId, data } = action.payload
      state.meteredUsageByBusinessId[businessId] = data
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateSubscription.fulfilled, (state, { payload }) => {
      state.subscriptionsByBusinessId[payload.business_id] = {
        ...state.subscriptionsByBusinessId[payload.business_id],
        ...payload
      }
    })
  }
})

export default slice.reducer

export const {
  fetchPaymentSubscriptionsSuccess,
  fetchPlansSuccess,
  fetchSubscriptionUsageSuccess,
  fetchMeteredUsageSuccess
} = slice.actions

const fetchSessionRequest = createAction('subscriptions/fetchSessionRequest')
const fetchSessionFailure = createAction('subscriptions/fetchSessionFailure')

export function fetchSession(
  businessId: string,
  {
    callbackUrl,
    cancelUrl,
    couponId,
    priceId
  }: {
    callbackUrl?: string
    cancelUrl?: string
    couponId?: string
    priceId?: string
  }
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchSessionRequest())
      const response = await api.post(
        `/bus/${businessId}/subscriptions/session`,
        removeUndefined({
          callback_url: callbackUrl,
          cancel_url: cancelUrl,
          coupon_id: couponId,
          price_id: priceId
        })
      )

      return response
    } catch (error) {
      dispatch(fetchSessionFailure())

      return error
    }
  }
}

const fetchPaymentSubscriptionsRequest = createAction(
  'subscription/fetchPaymentSubscriptionsRequest'
)
const fetchPaymentSubscriptionsFailure = createAction(
  'subscription/fetchPaymentSubscriptionsFailure'
)

export function fetchPaymentSubscriptions(businessId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchPaymentSubscriptionsRequest())
      const response = await api.get(
        `/bus/${businessId}/subscriptions/current`,
        selfErrorHandlerRequest()
      )
      dispatch(
        fetchPaymentSubscriptionsSuccess({
          businessId,
          subscription: response.data
        })
      )

      return response
    } catch (error) {
      dispatch(fetchPaymentSubscriptionsFailure())

      return error
    }
  }
}

const fetchCustomerPortalRequest = createAction(
  'subscription/fetchCustomerPortalRequest'
)
const fetchCustomerPortalFailure = createAction(
  'subscription/fetchCustomerPortalFailure'
)

export function fetchCustomerPortal(businessId: string, returnUrl: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchCustomerPortalRequest())
      const response = await api.post(
        `/bus/${businessId}/subscriptions/customer_portal`,
        {
          return_url: returnUrl
        }
      )

      return response
    } catch (error) {
      dispatch(fetchCustomerPortalFailure())

      return error
    }
  }
}

const fetchPlansRequest = createAction('subscription/fetchPlansRequest')
const fetchPlansFailure = createAction('subscription/fetchPlansFailure')

export function fetchPlans(businessId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchPlansRequest())
      const response = await api.get(`/bus/${businessId}/subscriptions/plans`)
      const { plans } = response.data
      dispatch(fetchPlansSuccess({ plans }))

      return plans
    } catch (error) {
      dispatch(fetchPlansFailure())

      return error
    }
  }
}

const fetchSubscriptionUsageRequest = createAction(
  'subscriptions/fetchSubscriptionUsageRequest'
)
const fetchSubscriptionUsageFailure = createAction(
  'subscriptions/fetchSubscriptionUsageFailure'
)

export function fetchSubscriptionUsage(businessId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchSubscriptionUsageRequest())
      const response = await api.get(
        `/bus/${businessId}/meter/subscription_usages`
      )

      const { data } = response
      dispatch(
        fetchSubscriptionUsageSuccess({
          businessId: businessId,
          data
        })
      )
    } catch (error) {
      dispatch(fetchSubscriptionUsageFailure())
    }
  }
}

const fetchMeteredUsageRequest = createAction(
  'subscriptions/fetchMeteredUsageRequest'
)
const fetchMeteredUsageFailure = createAction(
  'subscriptions/fetchMeteredUsageFailure'
)

export function fetchMeteredUsage(businessId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchMeteredUsageRequest())
      const response = await api.get(
        `/bus/${businessId}/meter/business_usages`,
        selfErrorHandlerRequest()
      )

      const { data } = response
      dispatch(
        fetchMeteredUsageSuccess({
          businessId: businessId,
          data
        })
      )
    } catch (error) {
      dispatch(fetchMeteredUsageFailure())
    }
  }
}
