import React from 'react'

import { css } from '@emotion/react'
import { Typography } from 'antd'

type Props = {
  fontSize?: number
  noMargin?: boolean
  weight?:
    | 'normal'
    | 'bold'
    | 'light'
    | 'thin'
    | 'semiBold'
    | 'extraBold'
    | number
  cssStyles?: any
}

const SubTitle: React.FC<Props> = ({
  fontSize,
  noMargin = true,
  children,
  weight,
  cssStyles,
  ...props
}) => {
  return (
    <Typography.Text
      css={css`
        font-weight: ${weight || 'bold'} !important;
        font-size: ${fontSize ? `${fontSize}px` : 'inherit'} !important;
        margin-bottom: ${noMargin ? '0px' : '10px'} !important;
        margin-top: ${noMargin ? '0px' : '10px'} !important;
        ${cssStyles}
      `}
      {...props}
    >
      {children}
    </Typography.Text>
  )
}

export default SubTitle
