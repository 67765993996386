import { createAction, createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'

import api from '../utils/api'

type IProps = {
  externalVideoProjectIds: Record<string, string>
  externalVideoJobs: Record<string, any>
  externalVideosUploaded: Record<string, any>
}

const initialState: IProps = {
  externalVideoProjectIds: {},
  externalVideoJobs: {},
  externalVideosUploaded: {}
}

const slice = createSlice({
  name: 'videoUpload',
  initialState: initialState,
  reducers: {
    createExternalVideoImportSuccess(state, action) {
      const { channelId, projectId } = action.payload
      state.externalVideoProjectIds[channelId] = projectId
    },
    resetExternalVideoImport(state, action) {
      const { channelId } = action.payload
      state.externalVideoProjectIds[channelId] = null
    },
    fetchExternalVideoJobSuccess(state, action) {
      const { channelId, progress, videos } = action.payload
      state.externalVideoJobs[channelId] = { progress, videos }
    },
    resetExternalVideoJob(state, action) {
      const { channelId } = action.payload
      state.externalVideoJobs[channelId] = null
      state.externalVideosUploaded[channelId] = null
    },
    uploadExternalVideoSuccess(state, action) {
      const { channelId, mediaKey, videoUrl } = action.payload
      state.externalVideosUploaded[channelId] = {
        mediaKey,
        videoUrl
      }
    }
  }
})
export const {
  createExternalVideoImportSuccess,
  resetExternalVideoImport,
  fetchExternalVideoJobSuccess,
  resetExternalVideoJob,
  uploadExternalVideoSuccess
} = slice.actions
export default slice.reducer

const createExternalVideoImportRequest = createAction(
  'videoUpload/createExternalVideoImportRequest'
)
const createExternalVideoImportFailure = createAction(
  'videoUpload/createExternalVideoImportFailure'
)
export function createExternalVideoImport(
  businessId: string,
  channelId: string,
  params?: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createExternalVideoImportRequest())

      const res = await api.post(
        `/bus/${businessId}/long_form/create_external`,
        {
          variants: 4,
          ...params
        }
      )
      const { project_uid } = res.data
      dispatch(
        createExternalVideoImportSuccess({
          channelId,
          projectId: project_uid
        })
      )

      return res
    } catch (error) {
      dispatch(createExternalVideoImportFailure())

      return error
    }
  }
}
const fetchExternalVideoJobRequest = createAction(
  'videoUpload/fetchExternalVideoJobRequest'
)
const fetchExternalVideoJobFailure = createAction(
  'videoUpload/fetchExternalVideoJobFailure'
)
export function fetchExternalVideoJob(
  businessId: string,
  channelId?: string,
  projectId?: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchExternalVideoJobRequest())

      const res = await api.get(
        `/bus/${businessId}/long_form/${projectId}/videos`
      )
      const { progress, videos } = res.data
      dispatch(
        fetchExternalVideoJobSuccess({
          channelId,
          progress,
          videos
        })
      )

      return res
    } catch (error) {
      dispatch(fetchExternalVideoJobFailure())

      return error
    }
  }
}

const uploadExternalVideoRequest = createAction(
  'videoUpload/uploadExternalVideoRequest'
)
const uploadExternalVideoFailure = createAction(
  'videoUpload/uploadExternalVideoFailure'
)
export function uploadExternalVideo(
  businessId: string,
  channelId: string,
  params?: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(uploadExternalVideoRequest())
      const res = await api.post(`/bus/${businessId}/long_form/upload`, params)
      const { media_key: mediaKey } = res.data
      dispatch(
        uploadExternalVideoSuccess({
          channelId,
          mediaKey,
          videoUrl: params.url
        })
      )

      return res
    } catch (error) {
      dispatch(uploadExternalVideoFailure())

      return error
    }
  }
}
