import React from 'react'

import { LeftOutlined } from '@ant-design/icons'
import { Box, Flex } from '@src/components/EmotionLayout'
import { isMdScreen } from '@src/hooks'
import { useNavigate } from 'react-router'

interface Props {
  text?: string
  fontSize?: string
  destination?: string
  cb?: () => void
  className?: string
}

const BackButton: React.FC<Props> = ({
  text = 'Back',
  fontSize = '12px',
  destination,
  cb,
  className
}): JSX.Element => {
  const navigate = useNavigate()
  const onClick = () => {
    if (cb) cb()
    else {
      if (destination) {
        navigate(destination)
      } else {
        navigate(-1)
      }
    }
  }

  return (
    <Box
      cursor="pointer"
      display="inline-block"
      onClick={onClick}
      className={className}
    >
      <Flex alignItems="center" flexDirection="row">
        <LeftOutlined />
        {isMdScreen() ? (
          <Box fontSize={fontSize} ml="xxsmall">
            {text}
          </Box>
        ) : null}
      </Flex>
    </Box>
  )
}

export default BackButton
