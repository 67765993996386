import { sessionStorageProvider } from '@src/utils/storageProvider'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

// useBusinessId should only be used in the BusinessContext Provider. When used
// in other components it is unoptimized and may cause unnecessary re-renders.
// This is because useLocation causes this function to run even when the
// businessId does not change.
//
// As an alternative, a component should:
// - If a page, do
// const { businessId } = useParams()
// - else
// const businessId = useContext(BusinessContext)

export default function useBusinessId(businessList) {
  const { pathname } = useLocation()
  const businessIds = useSelector((state) => state.business.ids)
  let businessId = /^\/business/.test(pathname) && pathname.split('/')[2]
  if (businessId === 'create' || businessId === 'associate') {
    // businessId is not valid if route is /business/create
    businessId = undefined
  }

  if (businessId) {
    return businessId
  }

  //Look at local business list (most recent) first, and if that exists then
  // navigate to the first in the array first, otherwise grab the first one
  // from redux list. This means we do not have a most recent list for current
  // logged in user yet.

  //oauthApps integration will look pass a business id to default navigation to
  //it is coming from session storage, if present, return it and remove from
  // session storage
  const oauthDefaultBusinessId = sessionStorageProvider.getItem('businessId')
  if (oauthDefaultBusinessId) {
    return oauthDefaultBusinessId
  }

  if (businessList && businessList.length) {
    return businessList[0]
  } else {
    return businessIds[0]
  }
}
