import produce from 'immer'

const initialState = {}

function getState(requestState) {
  if (requestState === 'Request') {
    return 'pending'
  } else if (requestState === 'Success') {
    return 'resolved'
  } else if (requestState === 'Failure') {
    return 'rejected'
  }
}

export default function (
  state = initialState,
  action: Record<string, any>
): any {
  return produce(state, (draft) => {
    // example action type session/createSessionSuccess
    const matches = action.type.match(/(.*)(Request|Success|Failure)/) || []
    // ex: session/createSession
    const requestName = matches[1]
    // ex: Success
    const requestState = matches[2]
    if (requestName && requestState) {
      const state = getState(requestState)
      draft[requestName] = {
        state,
        loading: state === 'pending',
        error: action?.payload?.error
      }
    }

    return draft
  })
}
