import styled from '@emotion/styled'
import { Button, ButtonProps } from 'antd'

const FWButton = styled(Button)<ButtonProps>`
  font-weight: normal;
`

export const FWButtonNoPadding = styled(FWButton)`
  padding: 0;
`

export default FWButton
