/**
 * Formats and rounds numbers into shortened abbreviations.
 * @param {number} number Number to format
 * @return {string} Formatted number
 */
export function abbreviatedNumber(number = 0) {
  switch (true) {
    case number >= 100 && number < 500:
      return '100'
    case number >= 500 && number < 1000:
      return '500'
    case number >= 1000 && number < 5000:
      return '1K'
    case number >= 5000 && number < 10000:
      return '5K'
    case number >= 10000 && number < 50000:
      return '10K'
    case number >= 50000 && number < 100000:
      return '50K'
    case number >= 100000 && number < 500000:
      return '100K'
    case number >= 500000 && number < 1000000:
      return '500K'
    case number >= 1000000:
      /*
      t('Millions')
      */
      return 'Millions'
    default:
      return Number(number).toString()
  }
}

/**
 * Formats numbers into shortened abbreviations.
 * @param {number} number Number to format
 */
export function numberFormatter(value) {
  if (value < 1000) return value
  if (value < 1000000) {
    if (value >= 10000) return `${Math.trunc(value / 1000)}K`
    else return `${(value / 1000).toFixed(1)}K`
  }
  if (value < 10000000) return `${(value / 1000000).toFixed(2)}M`
  return `${Math.trunc(value / 1000000)}M`
}

/**
 * truncates numbers
 * @param {*} value
 * @returns
 */
export function truncateNumber(value) {
  if (value < 1000) return value
  if (value < 1000000) {
    if (value >= 10000) return `${Math.trunc(value / 1000)}K`
    else return `${(value / 1000).toFixed(1)}K`
  }
  if (value < 10000000) return `${(value / 1000000).toFixed(2)}M`
  return `${Math.trunc(value / 1000000)}M`
}

/**
 * Capitalizes the first character in the string.
 * @param {string} str String to capitalize
 * @return {string} Capitalized string
 */
export const capitalize = (str) => {
  if (!str) return str
  return str.charAt(0).toUpperCase() + str.substr(1)
}

/**
 * Truncate a string and append ending.
 * @param {string} str String to truncate
 * @param {length} length Length to truncate
 * @param {ending} ending Ending to append after shortened string
 * @return {string} Capitalized string
 */
export function truncate(str, length = null, ending = null) {
  if (!length) {
    length = 100
  }
  if (!ending) {
    ending = '...'
  }
  if (str && str.length > length) {
    return str.substring(0, length - ending.length) + ending
  } else {
    return str
  }
}

/**
 * Formats minutes into hours if it is more than 10k minutes.
 * @param {minutes} minutes Minutes
 * @return {string} Formatted
 */
export function minutesToHours(minutes) {
  if (minutes >= 10000)
    return Math.round(minutes / 60, 0).toLocaleString() + ' hours'
  return Math.round(minutes).toLocaleString() + ' mins'
}

/**
 * It takes a string, adds a zero to the front of it, and then returns the last two
 * characters of the resulting string
 * @param string - The string to be padded.
 * @returns the string with the last two characters.
 */
const pad = (string) => {
  return ('0' + string).slice(-2)
}

/**
 * It takes a number of seconds and returns a string formatted as
 * hours:minutes:seconds
 * @param seconds - The number of seconds to format.
 * @param [includeHours=true] - If true, the function will return a string with
 * hours, minutes, and seconds. If false, it will return a string with minutes and
 * seconds.
 * @returns A string with the format of HH:MM:SS
 */
export function formatSecondsToHours(seconds, includeHours = true) {
  const date = new Date(seconds * 1000)
  const hh = pad(date.getUTCHours())
  const mm = pad(date.getUTCMinutes())
  const ss = pad(date.getUTCSeconds())
  if (includeHours) {
    return `${hh}:${mm}:${ss}`
  }

  return `${mm}:${ss}`
}

export function formatDuration(duration) {
  const date = new Date(duration * 1000)
  const hours = date.getUTCHours() > 0 ? `${date.getUTCHours()}h` : null
  const minutes = date.getUTCMinutes() > 0 ? `${date.getUTCMinutes()}m` : null
  const seconds = date.getUTCSeconds() > 0 ? `${date.getUTCSeconds()}s` : null

  return [hours, minutes, seconds].join(' ')
}
