export type Spaces = {
  /**
   * Value: 0px
   */
  none?: string
  /**
   * Value: 4px
   */
  xxsmall?: string
  /**
   * Value: 8px
   */
  xsmall?: string
  /**
   * Value: 12px
   */
  small?: string
  /**
   * Value: 16px
   */
  medium?: string
  /**
   * Value: 20px
   */
  medium2?: string
  /**
   * Value: 24px
   */
  large?: string
  /**
   * Value: 32px
   */
  large2?: string
  /**
   * Value: 48px
   */
  xlarge?: string
  /**
   * Value: 64px
   */
  xlarge2?: string
  /**
   * Value: 96px
   */
  xxlarge?: string
  /**
   * Value: 128px
   */
  xxlarge2?: string
}

export const spaces = {
  none: 0,
  xxsmall: 4,
  xsmall: 8,
  small: 12,
  medium: 16,
  medium2: 20,
  large: 24,
  large2: 32,
  xlarge: 48,
  xlarge2: 64,
  xxlarge: 96,
  xxlarge2: 128
}

export default function useSpace(space: string): string {
  if (spaces[space] === 0) {
    return '0px'
  }

  return (spaces[space] || spaces.medium) + 'px'
}
