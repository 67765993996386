import { createSlice, Slice, SliceCaseReducers } from '@reduxjs/toolkit'

interface ActivationStatusState {
  uploadVideo: globalLib.ACTIVATION_STEP_STATUS
  createPlaylist: globalLib.ACTIVATION_STEP_STATUS
  embedPlaylist: globalLib.ACTIVATION_STEP_STATUS
  matchVideoToProduct: globalLib.ACTIVATION_STEP_STATUS
  openInstructions: globalLib.ACTIVATION_STEP_STATUS
  isActivationStatusModalOpen: boolean
  onboardingContext: 'home' | 'pdp'
  currentStep: 0 | 1 | 2
  isActivationStatusModalMinimized: boolean
}

const initialState: ActivationStatusState = {
  uploadVideo: 'active',
  createPlaylist: 'inactive',
  embedPlaylist: 'inactive',
  matchVideoToProduct: 'inactive',
  openInstructions: 'inactive',
  isActivationStatusModalOpen: false,
  onboardingContext: 'pdp',
  currentStep: 0,
  isActivationStatusModalMinimized: false
}

const SLICE_NAME = 'activationStatus'

const slice: Slice<
  ActivationStatusState,
  SliceCaseReducers<ActivationStatusState>,
  typeof SLICE_NAME
> = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setActivationStatus(state, action) {
      const {
        uploadVideo,
        createPlaylist,
        embedPlaylist,
        matchVideoToProduct,
        openInstructions
      } = action.payload
      state.uploadVideo = uploadVideo ?? state.uploadVideo
      state.createPlaylist = createPlaylist ?? state.createPlaylist
      state.embedPlaylist = embedPlaylist ?? state.embedPlaylist
      state.matchVideoToProduct =
        matchVideoToProduct ?? state.matchVideoToProduct
      state.openInstructions = openInstructions ?? state.openInstructions
    },
    setActivationStatusModalOpen(state, action) {
      state.isActivationStatusModalOpen =
        action.payload ?? !state.isActivationStatusModalOpen
    },
    setOnboardingContext(state, action: { payload: 'home' | 'pdp' }) {
      state.onboardingContext = action.payload
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload
    },
    setActivationStatusModalMinimized(state, action) {
      state.isActivationStatusModalMinimized =
        action.payload ?? !state.isActivationStatusModalMinimized
    }
  }
})

export default slice.reducer

export const {
  setActivationStatus,
  setActivationStatusModalOpen,
  setActivationStatusModalMinimized,
  setOnboardingContext,
  setCurrentStep
} = slice.actions
