import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  getIgAccountList,
  getIgCrawlerHashtagList,
  getIgHashtagList,
  getIgUsernameList,
  IgHashtag,
  IPublicPage,
  saveIgAccountList
} from '@src/components/IgHashtagImporter/igHashtagImporterModel'
import {
  getTiktokHashtagList,
  getTiktokUsernameList
} from '@src/components/SourceImporter/Tiktok/TiktokImporterModel'
import { FbToken, getFbToken, saveFbToken } from '@src/utils/fbAuth'
import {
  getGoogleToken,
  GoogleToken,
  saveGoogleToken
} from '@src/utils/googleAuth'
import { getTKToken, saveTKToken, TKToken } from '@src/utils/tkAuth'
import { Dispatch } from 'redux'

type IProps = {
  fbToken: FbToken
  igAccountList: IPublicPage[]
  igUsernameList: string[]
  igHashtagList: IgHashtag[]
  igCrawlerHashtagList: string[]
  tiktokHashtagList: string[]
  tiktokUsernameList: string[]
  tkToken: TKToken
  googleToken: GoogleToken
}

const initialState: IProps = {
  fbToken: undefined,
  igAccountList: [],
  igUsernameList: [],
  igHashtagList: [],
  igCrawlerHashtagList: [],
  tiktokHashtagList: [],
  tiktokUsernameList: [],
  tkToken: undefined,
  googleToken: undefined
}

const slice = createSlice({
  name: 'sourceImporter',
  initialState: initialState,
  reducers: {
    fetchLocalSourceImporterDataSuccess(state, action) {
      const {
        fbToken,
        igAccountList,
        igUsernameList,
        igHashtagList,
        igCrawlerHashtagList,
        tiktokHashtagList,
        tiktokUsernameList,
        tkToken,
        googleToken
      } = action.payload
      state.fbToken = fbToken
      state.igAccountList = igAccountList
      state.igUsernameList = igUsernameList
      state.igHashtagList = igHashtagList
      state.igCrawlerHashtagList = igCrawlerHashtagList
      state.tiktokHashtagList = tiktokHashtagList
      state.tiktokUsernameList = tiktokUsernameList
      state.tkToken = tkToken
      state.googleToken = googleToken
    },
    clearLocalInstagramImporterDataSuccess(state, action) {
      const { user } = action.payload
      saveFbToken(user.encoded_id, {})
      state.fbToken = undefined
      state.igAccountList = []
      state.igUsernameList = []
      state.igHashtagList = []
    },
    // crawler hashtag/username, so the hashtag and username cannot be cleared
    clearLocalInstagramAccountImporterDataSuccess(state, action) {
      const { user } = action.payload
      saveFbToken(user.encoded_id, {})
      state.fbToken = undefined
      state.igAccountList = []
    },
    clearLocalTiktokAccountImporterDataSuccess(state, action) {
      const { user } = action.payload
      saveTKToken(user.encoded_id, {})
      state.tkToken = undefined
    },
    removeInstagramAccount(state, action) {
      const { user, account } = action.payload
      if (user && state.igAccountList.length > 1) {
        const accountList = state.igAccountList.filter((item) => {
          return item.id !== account.id
        })
        state.igAccountList = accountList
        saveIgAccountList(user.encoded_id, accountList)
      }
    },
    clearLocalGoogleAccountImporterDataSuccess(state, action) {
      const { user } = action.payload
      saveGoogleToken(user.encoded_id, {})
      state.googleToken = undefined
    }
  }
})

export default slice.reducer

export const {
  fetchLocalSourceImporterDataSuccess,
  clearLocalInstagramImporterDataSuccess,
  clearLocalInstagramAccountImporterDataSuccess,
  clearLocalTiktokAccountImporterDataSuccess,
  removeInstagramAccount,
  clearLocalGoogleAccountImporterDataSuccess
} = slice.actions

const fetchLocalSourceImporterDataRequest = createAction(
  'sourceImporter/fetchLocalSourceImporterDataRequest'
)
const fetchLocalSourceImporterDataFailure = createAction(
  'sourceImporter/fetchLocalSourceImporterDataFailure'
)

export function fetchLocalSourceImporterData(userId: string) {
  return async (dispatch: Dispatch): Promise<IProps> => {
    try {
      dispatch(fetchLocalSourceImporterDataRequest())
      const fbToken = getFbToken(userId)
      const igAccountList = getIgAccountList(userId)
      const igUsernameList = getIgUsernameList(userId)
      const igHashtagList = getIgHashtagList(userId)
      const igCrawlerHashtagList = getIgCrawlerHashtagList(userId)

      const tiktokHashtagList = getTiktokHashtagList(userId)
      const tiktokUsernameList = getTiktokUsernameList(userId)
      const tkToken = getTKToken(userId)

      const googleToken = getGoogleToken(userId)

      dispatch(
        fetchLocalSourceImporterDataSuccess({
          fbToken,
          igAccountList,
          igUsernameList,
          igHashtagList,
          igCrawlerHashtagList,
          tiktokHashtagList,
          tiktokUsernameList,
          tkToken,
          googleToken
        })
      )

      return {
        fbToken,
        igAccountList,
        igUsernameList,
        igHashtagList,
        igCrawlerHashtagList,
        tiktokHashtagList,
        tiktokUsernameList,
        tkToken,
        googleToken
      }
    } catch (error) {
      dispatch(fetchLocalSourceImporterDataFailure())

      return null
    }
  }
}
