/* eslint-disable no-console */
export const localStorageProvider = {
  getItem: (key: string): any => {
    try {
      return window.localStorage.getItem(key)
    } catch (e) {
      console.log(e)

      return window.localStorageFallback[key] ?? null
    }
  },
  setItem: (key: string, value: any): any => {
    try {
      return window.localStorage.setItem(key, value)
    } catch (e) {
      console.log(e)

      return (window.localStorageFallback[key] = value)
    }
  },
  removeItem: (key: string) => {
    try {
      return window.localStorage.removeItem(key)
    } catch (e) {
      console.log(e)

      return delete window.localStorageFallback[key]
    }
  }
}

export const sessionStorageProvider = {
  getItem: (key: string): any => {
    try {
      return window.sessionStorage.getItem(key)
    } catch (error) {
      console.log(error)

      return window.sessionStorageFallback[key] ?? null
    }
  },
  setItem: (key: string, value: any) => {
    try {
      return window.sessionStorage.setItem(key, value)
    } catch (error) {
      console.log(error)

      return (window.sessionStorageFallback[key] = value)
    }
  },
  removeItem: (key: string) => {
    try {
      return window.sessionStorage.removeItem(key)
    } catch (error) {
      console.log(error)

      return delete window.sessionStorageFallback[key]
    }
  }
}
