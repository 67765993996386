export const debugInfoGradient = `
color: white;
padding: 2px 4px;
border-radius: 2px;
background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(164,15,244,1) 35%, rgba(254,23,122,1) 100%);
`

/**
 * Logs an info to the console when in debug mode.
 */
export const logInfo = (...args: unknown[]): void => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.info(
      typeof window !== 'undefined' && window.self !== window.top
        ? '%cFW-DEBUG-iframe'
        : '%cFW-DEBUG',
      debugInfoGradient,
      ...args
    )
  }
}
