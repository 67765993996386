import React from 'react'

import { PlusCircleFilled, DeleteOutlined } from '@ant-design/icons'
import { css } from '@emotion/core'
import theme from '@src/styles/theme'
import { Upload, Button, Spin } from 'antd'
import { Box, Text, Flex } from 'fwego'
import { useTranslation } from 'react-i18next'

import Poster from './Poster'

interface PosterDraggerProps {
  imgUrl: string
  isWide: boolean
  removePoster(): void
  posterUploading: boolean
  posterProgress: number
  size: number
}

const PosterDragger: React.FC<PosterDraggerProps> = (props) => {
  const { t } = useTranslation()
  const { Dragger } = Upload
  const { imgUrl, isWide, removePoster, posterUploading, size } = props

  const width = !isWide ? size : (size * 164) / 99
  const height = !isWide ? (size * 164) / 99 : size

  const handleDelete = (e) => {
    e.stopPropagation()
    removePoster()
  }

  const renderNoImage = () => (
    <Flex
      p="24px 10px"
      data-cy="poster-drop-zone"
      justifyContent="center"
      alignItems="center"
      minWidth={width}
      maxWidth={width}
      minHeight={height}
      maxHeight={height}
    >
      {posterUploading ? (
        <Spin />
      ) : (
        <Box>
          <Text size="xsmall">
            {t('Add a')} <br />
            {t('New Poster')}
          </Text>
          <PlusCircleFilled
            style={{
              color: theme.primary,
              fontSize: '24px',
              marginTop: '4px'
            }}
          />
        </Box>
      )}
    </Flex>
  )

  return (
    <Dragger
      {...props}
      css={css`
        max-width: ${width}px;
        min-height: ${height}px;
        max-height: ${height}px;
      `}
      className="poster-dragger"
    >
      {imgUrl ? (
        <Flex justifyContent="end" alignItems="end">
          <Poster {...props} width={width} height={height} />
          <Button
            ghost
            onClick={handleDelete}
            type="dashed"
            style={{
              position: 'absolute',
              margin: '2px',
              border: 'none'
            }}
            icon={<DeleteOutlined style={{ fontSize: '16px' }} />}
          />
        </Flex>
      ) : (
        renderNoImage()
      )}
    </Dragger>
  )
}

export default PosterDragger
