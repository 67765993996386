import React from 'react'

import { useNabooFeatureFlag } from '@src/hooks/useFeatureFlag'
import { Result } from 'antd'
import { useTranslation } from 'react-i18next'

import { Flex } from './EmotionLayout'

export const MaintenanceWindow: React.FC = ({ children }) => {
  const nff = useNabooFeatureFlag()
  const { t } = useTranslation()

  if (nff.cms_maintenance_mode) {
    return (
      <Flex
        flex="1"
        height="100vh"
        justifyContent="center"
        alignItems="center"
        overflowY="hidden"
      >
        <Result
          status="500"
          title={t('Maintenance Window')}
          subTitle={t(
            'The system is undergoing maintenance. Please check back soon.'
          )}
        />
      </Flex>
    )
  }

  return <>{children}</>
}
