import React from 'react'

import { DownloadOutlined } from '@ant-design/icons'
import { Flex, Box } from '@src/components/EmotionLayout'
import { Tooltip } from 'antd'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'

type IProps = {
  data: any[]
  filename?: string
  link?: string
}

const ExportCsvButton: React.FC<IProps> = ({
  data,
  link = null,
  filename,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Flex justifyContent="center" height="22" {...props}>
      <Tooltip
        title={
          <Box color="white" size="14">
            {t('Export')}
          </Box>
        }
        arrowPointAtCenter
      >
        {link ? (
          <Box as="a" href={link}>
            <DownloadOutlined />
          </Box>
        ) : (
          <CSVLink data={data} filename={filename}>
            <DownloadOutlined />
          </CSVLink>
        )}
      </Tooltip>
    </Flex>
  )
}

export default ExportCsvButton
