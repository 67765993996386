import React, { useCallback } from 'react'

type themeType = {
  white: string
  'text-color': string
  'secondary-text-color': string
  'background-color': string
  'primary-color': string
  'button-background': string
  'modal-background': string
  'icon-color': string
}

type ThemeContextType = {
  theme: themeType
  toggleTheme?: () => void
  selectTheme?: (theme: string) => void
  themeName?: string
}

export const ThemeContext = React.createContext<ThemeContextType | null>(null)

const themes: {
  light: themeType
  dark: themeType
} = {
  light: {
    white: '#fff',
    'text-color': '#404040',
    'secondary-text-color': '#808080',
    'background-color': '#fff',
    'primary-color': '#0F6BFF',
    'button-background': '#bdbdbd',
    'modal-background': '#fff',
    'icon-color': '#1a1a1a'
  },
  dark: {
    white: '#fff',
    'text-color': '#fff',
    'secondary-text-color': '#808080',
    'background-color': '#1a1a1a',
    'primary-color': '#0F6BFF',
    'button-background': '#bdbdbd',
    'modal-background': '#1a1a1a',
    'icon-color': '#fff'
  }
}

export const ThemeProvider: React.FC = ({ children }) => {
  const [themeName, setThemeName] = React.useState('light')
  const [theme, setTheme] = React.useState<themeType>(themes[themeName])

  const toggleTheme = useCallback(() => {
    if (themeName === 'light') {
      setTheme(themes.dark)
      setThemeName('dark')
    } else {
      setTheme(themes.light)
      setThemeName('light')
    }
  }, [themeName])

  const selectTheme = useCallback((themeName: string) => {
    setTheme(themes[themeName])
    setThemeName(themeName)
  }, [])

  const setCSSVariables = (theme: any) => {
    Object.keys(theme).forEach((key) => {
      const value = theme[key]
      document.documentElement.style.setProperty(`--${key}`, value)
    })
  }

  React.useEffect(() => {
    setCSSVariables(theme)
  }, [theme])

  return (
    <ThemeContext.Provider
      value={{ theme, toggleTheme, selectTheme, themeName }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = (): ThemeContextType => {
  const context = React.useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }

  return context
}
