import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  completeMultiPartUpload,
  getS3Signature,
  startMultiPartUpload,
  uploadFileToS3,
  uploadToS3
} from '@src/utils/s3'
import { removeUndefined, sanitizeInteractionData } from '@src/utils/sanitize'
import { replaceVideosById } from '@src/utils/video'
import { UploadFile as UploadFileAntd } from 'antd'
import axios from 'axios'
import qs from 'qs'
import { AnyAction, Dispatch } from 'redux'

import { CHANNEL, LIVESTREAM_STATUS, PLAYLIST, VIDEO } from '../constants'
import api, { selfErrorHandlerRequest } from '../utils/api'
import { resetSelections } from './bulkVideoSelection'

interface UploadFile extends UploadFileAntd {
  percentArray?: number[]
}

type IProps = {
  idsByBusiness: Record<string, any[]>
  channels: Record<string, any>
  channelCreatedVideoIds: Record<string, any[]>
  channelCreatedVideoPaging: Record<string, any>
  channelAllVideoIds: Record<string, any>
  channelAllVideoPaging: Record<string, any>
  channelAllLivestreams: Record<string, any[]>
  channelAllLivestreamsPaging: Record<string, any>
  channelAllVideoIdsPending: Record<string, globalLib.IVideo[]>
  channelArchivedVideos: Record<string, globalLib.IVideo[]>
  channelArchivedPaging: Record<string, any>
  channelLivestreamTestEvents: boolean
  channelLivestreamFilter: string
  searchedIds: Record<string, string[]>
  searchedPaging: Record<string, any>
  uploadedVideos: Record<string, string>
  uploadedPosters: Record<string, any>
  channelPlaylists: Record<string, globalLib.Playlist[]>
  channelPlaylistsPaging: Record<string, any>
  playlistVideos: Record<string, globalLib.IVideo[]>
  playlistVideosPaging: Record<string, any>
  searchedHashtagNames: string[]
  connectWordPress: Record<string, any>
  igImporterJobs: Record<string, any>
  lastIndexShiftSelect: null | number
  manageIsActive: boolean
  popularPlaylist: Record<string, any>
  videoSearchParams: Record<string, any>
  uploadingLiveStreamBaseVideos: Record<string, UploadFile[]>
  liveStreamBaseVideos: Record<string, any>
  channelLiveStreamBaseVideos: Record<string, { [key: string]: any }>
  channelVideoCount: Record<string, globalLib.VideoCount>
  currentChannelId: string
  isTiktokUrlImporterVisible: boolean
}

const FETCH_VIDEO_PAGE_SIZE = 50

const initialState: IProps = {
  idsByBusiness: {},
  channels: {},
  channelCreatedVideoIds: {},
  channelCreatedVideoPaging: {},
  channelAllVideoIds: {},
  channelAllVideoPaging: {},
  channelAllLivestreams: {},
  channelAllLivestreamsPaging: {},
  channelAllVideoIdsPending: {},
  channelArchivedVideos: {},
  channelArchivedPaging: {},
  channelLivestreamTestEvents: true,
  channelLivestreamFilter: 'all',
  searchedIds: {},
  searchedPaging: {},
  uploadedVideos: {},
  uploadedPosters: {},
  channelPlaylists: {},
  channelPlaylistsPaging: {},
  playlistVideos: {},
  playlistVideosPaging: {},
  searchedHashtagNames: [],
  connectWordPress: {},
  igImporterJobs: {},
  lastIndexShiftSelect: null,
  manageIsActive: false,
  popularPlaylist: {},
  videoSearchParams: {
    query: null,
    filter: null,
    sort: 'started_at_or_inserted_at_desc'
  },
  uploadingLiveStreamBaseVideos: {},
  liveStreamBaseVideos: {},
  channelLiveStreamBaseVideos: {},
  channelVideoCount: {},
  currentChannelId: null,
  isTiktokUrlImporterVisible: false
}

const slice = createSlice({
  name: 'channel',
  initialState: initialState,
  reducers: {
    fetchChannelsSuccess(state, action) {
      const { businessId, channels } = action.payload
      state.idsByBusiness[businessId] = channels.map(
        (channel) => channel.encoded_id
      )
      channels.forEach((channel) => {
        state.channels[channel.encoded_id] = channel
      })
    },
    fetchAggregatorChannelsSuccess(state, action) {
      const { businessId, channels } = action.payload
      state.idsByBusiness[businessId] = channels.map(
        (channel) => channel.encoded_id
      )
      channels.forEach((channel) => {
        state.channels[channel.encoded_id] = channel
      })
    },
    searchChannelsSuccess(state, action) {
      const { businessId, channels, page, paging } = action.payload
      if (page) {
        channels.forEach((channel) => {
          state.searchedIds[businessId].push(channel.encoded_id)
        })
      } else {
        state.searchedIds[businessId] = channels.map(
          (channel) => channel.encoded_id
        )
      }
      state.searchedPaging[businessId] = paging
      channels.forEach((channel) => {
        state.channels[channel.encoded_id] = channel
      })
    },
    fetchChannelSuccess(state, action) {
      const { channel } = action.payload
      state.channels[channel.encoded_id] = channel
    },
    createChannelSuccess(state, action) {
      const { businessId, channel } = action.payload
      const businessChannelIds = state.idsByBusiness[businessId] || []
      businessChannelIds.unshift(channel.encoded_id)
      state.idsByBusiness[businessId] = businessChannelIds
      state.channels[channel.encoded_id] = channel
    },
    updateChannelSuccess(state, action) {
      const { channel } = action.payload
      state.channels[channel.encoded_id] = channel
    },
    fetchChannelCreatedVideosSuccess(state, action) {
      const { channelId, videos, paging, page } = action.payload

      const memo =
        state.channelCreatedVideoIds[channelId]?.reduce((acc, v) => {
          acc[v.encoded_id] = true

          return acc
        }, {}) || {}

      if (page) {
        videos.forEach((v) => {
          if (!memo[v.encoded_id]) {
            state.channelCreatedVideoIds[channelId].push(v)
            memo[v.encoded_id] = true
          }
        })
      } else {
        state.channelCreatedVideoIds[channelId] = videos
      }
      state.channelCreatedVideoPaging[channelId] = paging
    },
    fetchChannelVideoSuccess(state, action) {
      const { channelId, video } = action.payload

      let data = [video]
      if (state.channelAllVideoIds[channelId]) {
        data = state.channelAllVideoIds[channelId]
          .filter((v) => v.encoded_id === video.encoded_id)
          .concat(data)
      }

      state.channelAllVideoIds[channelId] = data
    },
    fetchChannelAllVideosSuccess(state, action) {
      const { channelId, videos, paging, page } = action.payload

      const memo =
        state.channelAllVideoIds[channelId]?.reduce((acc, v) => {
          acc[v.encoded_id] = true

          return acc
        }, {}) || {}

      if (page) {
        videos.forEach((v) => {
          if (!memo[v.encoded_id]) {
            state.channelAllVideoIds[channelId].push(v)
            memo[v.encoded_id] = true
          }
        })
      } else {
        state.channelAllVideoIds[channelId] = videos
        state.connectWordPress.firstChannelVideoId = videos.filter(
          (v) => v.access !== 'private'
        )[0]?.encoded_id
      }
      state.channelAllVideoPaging[channelId] = paging
    },
    fetchChannelArchivedSuccess(state, action) {
      const { channelId, videos, paging, page } = action.payload

      const memo =
        state.channelArchivedVideos[channelId]?.reduce((acc, v) => {
          acc[v.encoded_id] = true

          return acc
        }, {}) || {}

      if (page) {
        videos.forEach((v) => {
          if (!memo[v.encoded_id]) {
            state.channelArchivedVideos[channelId].push(v)
            memo[v.encoded_id] = true
          }
        })
      } else {
        state.channelArchivedVideos[channelId] = videos
      }
      state.channelArchivedPaging[channelId] = paging
    },
    updateLocalChannelVideosSuccess(state, action) {
      const { channelId, videos } = action.payload
      const channelAllVideos = state.channelAllVideoIds[channelId]
      const channelAllPendingVideos = state.channelAllVideoIdsPending[channelId]
      const channelCreatedVideos = state.channelCreatedVideoIds[channelId]
      const channelAllLivestreams = state.channelAllLivestreams[channelId]

      state.channelAllVideoIds[channelId] = replaceVideosById(
        channelAllVideos,
        videos
      )
      state.channelAllVideoIdsPending[channelId] = replaceVideosById(
        channelAllPendingVideos,
        videos
      )
      state.channelCreatedVideoIds[channelId] = replaceVideosById(
        channelCreatedVideos,
        videos
      )
      state.channelAllLivestreams[channelId] = replaceVideosById(
        channelAllLivestreams,
        videos
      )
    },
    fetchLatestVideosSuccess(state, action) {
      const { channelId, videos, paging, page } = action.payload

      if (page) {
        state.channelAllVideoIds[channelId] = videos.reduce(
          (acc, v) => {
            if (!acc.includes((video) => v.encoded_id === video.encoded_id)) {
              acc.push(v)
            }

            return acc
          },
          [...state.channelAllVideoIds[channelId]]
        )
      } else {
        state.channelAllVideoIds[channelId] = videos
        state.connectWordPress.firstChannelVideoId = videos.filter(
          (v) => v.access !== 'private'
        )[0]?.encoded_id
      }
      state.channelAllVideoPaging[channelId] = paging
    },
    uploadChannelVideoSuccess(state, action) {
      const { key, channelId } = action.payload
      state.uploadedVideos[channelId] = key
    },
    createVideoPosterSuccess(state, action) {
      const { posterData } = action.payload
      state.uploadedPosters = posterData
    },
    createChannelVideoSuccess(state) {
      state.uploadedVideos = {}
    },
    updateChannelVideoSuccess(state) {
      state.uploadedVideos = {}
    },
    deleteChannelVideoSuccess(state, action) {
      const { channelId, videoId } = action.payload
      state.channelCreatedVideoIds[channelId] = (
        state.channelCreatedVideoIds[channelId] || []
      ).filter((video) => video.encoded_id !== videoId)
      state.channelAllVideoIds[channelId] = (
        state.channelAllVideoIds[channelId] || []
      ).filter((video) => video.encoded_id !== videoId)
      state.channelAllLivestreams[channelId] = (
        state.channelAllLivestreams[channelId] || []
      ).filter((video) => video.encoded_id !== videoId)
    },
    fetchChannelPlaylistsSuccess(state, action) {
      const { channelId, playlists, paging, page } = action.payload
      if (page) {
        playlists.forEach((playlist) => {
          state.channelPlaylists[channelId].push(playlist)
        })
      } else {
        state.channelPlaylists[channelId] = playlists
      }

      state.channelPlaylistsPaging[channelId] = paging
    },
    fetchPlaylistVideosSuccess(state, action) {
      const { playlistId, videos, paging, page } = action.payload
      if (page) {
        videos.forEach((video) => {
          state.playlistVideos[playlistId].push(video)
        })
      } else {
        state.playlistVideos[playlistId] = videos
        state.connectWordPress.firstPlaylistVideoId = videos.filter(
          (v) => v.access !== 'private'
        )[0]?.encoded_id
      }
      state.playlistVideosPaging[playlistId] = paging
      state.playlistVideos[playlistId] = state.playlistVideos[
        playlistId
      ].filter(
        (obj, pos, arr) =>
          pos === arr.findIndex((el) => el.encoded_id === obj.encoded_id)
      )
    },
    sortPlaylistVideosSuccess(state, action) {
      const { playlistId, videos } = action.payload

      state.playlistVideos[playlistId] = videos
    },
    uploadVideoSearchHashtagsSuccess(state, action) {
      const { hashtags } = action.payload
      state.searchedHashtagNames = hashtags.map((hashtag) => hashtag.name)
    },
    getConnectWordPressIntegrationSuccess(state, action) {
      state.connectWordPress = action.payload
    },
    getWordPressProfileSuccess(state, action) {
      const { display_name } = action.payload
      state.connectWordPress.display_name = display_name
    },
    createWordPressPostSuccess(state, action) {
      state.connectWordPress.landingUrl = action.payload.URL
    },
    checkChannelBulkSuccess(state, action) {
      const { jobs, channelId } = action.payload
      state.igImporterJobs[channelId] = jobs
    },
    setWordPressIntegrationDataSuccess(state, action) {
      state.connectWordPress = { ...state.connectWordPress, ...action.payload }
    },
    getPlayerShareLinkSuccess(state, action) {
      state.connectWordPress.videoPlayerLink = action.payload.url
    },
    fetchChannelAllLivestreamsSuccess(state, action) {
      const { channelId, videos, paging, page, append } = action.payload

      if (page && append) {
        videos.forEach((video) => {
          const videoFound = state.channelAllLivestreams[channelId].find(
            (v) => v.encoded_id === video.encoded_id
          )
          if (!videoFound) state.channelAllLivestreams[channelId].push(video)
        })
      } else {
        state.channelAllLivestreams[channelId] = videos
      }
      state.channelAllLivestreamsPaging[channelId] = paging
    },
    toggleLivestreamTestEvents(state) {
      state.channelLivestreamTestEvents = !state.channelLivestreamTestEvents
    },
    updateLivestreamFilter(state, action) {
      const { filter } = action.payload
      state.channelLivestreamFilter = filter
    },
    setLastIndex(state, action) {
      const { value } = action.payload
      state.lastIndexShiftSelect = value
    },
    setManageButtonStateSuccess(state, action) {
      const { bool } = action.payload
      state.manageIsActive = bool
    },
    setStorePopularPlaylist(state, action) {
      const { playlist, channelId, views } = action.payload
      state.popularPlaylist[channelId] = {
        playlist,
        views
      }
    },
    fetchChannelPendingVideosSuccess(state, action) {
      const { channelId, videos } = action.payload
      state.channelAllVideoIdsPending[channelId] = videos
    },
    clearPendingVideos(state, action) {
      const { channelId } = action.payload
      state.channelAllVideoIdsPending[channelId] = []
    },
    updateVideoDataByChannelAndVideoId(state, action) {
      const { channelId, videoId, data } = action.payload
      if (!state.channelAllVideoIds[channelId]) return
      const videoIndex = state.channelAllVideoIds[channelId].findIndex(
        (v) => v.encoded_id === videoId
      )
      if (videoIndex !== -1) {
        state.channelAllVideoIds[channelId][videoIndex] = {
          ...state.channelAllVideoIds[channelId][videoIndex],
          ...data
        }
      }
    },
    updateVideoSearchParams(state, action) {
      const { query, filter, sort } = action.payload
      state.videoSearchParams.query = query
      state.videoSearchParams.filter = filter
      state.videoSearchParams.sort = sort
    },
    resetVideoSearchParams(state) {
      state.videoSearchParams = {
        query: null,
        filter: null,
        sort: 'started_at_or_inserted_at_desc'
      }
    },
    updateVideoInteractionSuccess(state, action) {
      const { videoId, channelId, data } = action.payload
      const videoIndex = state.channelAllVideoIds[channelId]?.findIndex(
        (v) => v.encoded_id === videoId
      )
      if (videoIndex && videoIndex !== -1) {
        state.channelAllVideoIds[channelId][videoIndex] = {
          ...state.channelAllVideoIds[channelId][videoIndex],
          interactions: [{ ...data }]
        }
      }
    },
    createVideoInteractionSuccess(state, action) {
      const { videoId, channelId, data } = action.payload
      const videoIndex = state.channelAllVideoIds[channelId].findIndex(
        (v) => v.encoded_id === videoId
      )
      if (videoIndex !== -1) {
        state.channelAllVideoIds[channelId][videoIndex] = {
          ...state.channelAllVideoIds[channelId][videoIndex],
          interactions: [{ ...data }]
        }
      }
    },
    deleteVideoInteractionSuccess(state, action) {
      const { videoId, channelId, interactionId } = action.payload
      const videoIndex = state.channelAllVideoIds[channelId].findIndex(
        (v) => v.encoded_id === videoId
      )
      if (videoIndex !== -1) {
        state.channelAllVideoIds[channelId][videoIndex] = {
          ...state.channelAllVideoIds[channelId][videoIndex],
          interactions: state.channelAllVideoIds[channelId][
            videoIndex
          ].interactions.filter((i) => i.id !== interactionId)
        }
      }
    },
    updateLiveStreamBaseVideoProgress(state, action) {
      const {
        percent = 0,
        key,
        status = 'uploading',
        title,
        channelId
      } = action.payload

      const newBaseVideo = {
        uid: key,
        name: title,
        status: status,
        percent: percent
      }

      if (!state.uploadingLiveStreamBaseVideos[channelId]) {
        state.uploadingLiveStreamBaseVideos[channelId] = []
      }

      const ind = state.uploadingLiveStreamBaseVideos[channelId].findIndex(
        (v) => v.uid === key
      )
      if (ind === -1) {
        state.uploadingLiveStreamBaseVideos[channelId].push(newBaseVideo)
      } else {
        state.uploadingLiveStreamBaseVideos[channelId][ind] = newBaseVideo
      }
    },
    updateLiveStreamBaseVideoMultiPartProgress(state, action) {
      const {
        percent = 0,
        key,
        status = 'uploading',
        title,
        channelId,
        numParts = 1,
        part = 0
      } = action.payload

      if (!state.uploadingLiveStreamBaseVideos[channelId]) {
        state.uploadingLiveStreamBaseVideos[channelId] = []
      }

      const ind = state.uploadingLiveStreamBaseVideos[channelId].findIndex(
        (v) => v.uid === key
      )
      const currentVideo = state.uploadingLiveStreamBaseVideos[channelId][ind]
      if (!currentVideo) {
        state.uploadingLiveStreamBaseVideos[channelId].push({
          uid: key,
          name: title,
          status: status,
          percent: 0,
          percentArray: Array<number>(numParts).fill(0)
        })
      } else {
        currentVideo.percentArray[part] = percent
        const newPercent =
          currentVideo.percentArray.reduce((acc, val) => acc + val, 0) /
          numParts
        state.uploadingLiveStreamBaseVideos[channelId][ind] = {
          ...currentVideo,
          status: status,
          percent: newPercent
        }
      }
    },
    uploadLiveStreamBaseVideoSuccess(state, action) {
      const { key, channelId } = action.payload

      state.uploadingLiveStreamBaseVideos[
        channelId
      ] = state.uploadingLiveStreamBaseVideos[channelId].filter(
        (baseVideo) => baseVideo.uid !== key
      )
    },
    fetchChannelLiveStreamBaseVideosSuccess(state, action) {
      const { channelId, baseVideos, paging, paginated } = action.payload

      const newBaseVideos = paginated
        ? [...state.liveStreamBaseVideos[channelId].baseVideos, ...baseVideos]
        : baseVideos

      state.liveStreamBaseVideos[channelId] = {
        baseVideos: newBaseVideos,
        paging
      }
    },
    createChannelLiveStreamBaseVideoSuccess(state, action) {
      const { channelId, liveStreamBaseVideo } = action.payload
      state.liveStreamBaseVideos[channelId].push(liveStreamBaseVideo)
    },
    fetchChannelLiveStreamBaseVideoSuccess(state, action) {
      const { channelId, liveStreamBaseVideo } = action.payload
      state.channelLiveStreamBaseVideos[channelId] = {
        [liveStreamBaseVideo.id]: { ...liveStreamBaseVideo }
      }

      const index = state.liveStreamBaseVideos[channelId].baseVideos.findIndex(
        (v) => v.id === liveStreamBaseVideo.id
      )
      const newBaseVideos = [
        ...state.liveStreamBaseVideos[channelId].baseVideos
      ]
      newBaseVideos[index] = liveStreamBaseVideo
      state.liveStreamBaseVideos[channelId] = {
        ...state.liveStreamBaseVideos[channelId],
        baseVideos: newBaseVideos
      }
    },
    fetchChannelVideoCountSuccess(state, action) {
      const {
        channelId,
        live_streams_count = 0,
        short_videos_count = 0,
        all_count = 0
      } = action.payload
      state.channelVideoCount[channelId] = {
        liveStreamsCount: live_streams_count,
        shortVideosCount: short_videos_count,
        allVideosCount: all_count
      }
    },
    setCurrentChannelId(state, action) {
      state.currentChannelId = action.payload
    },
    showTiktokUrlImporter(state, action) {
      state.isTiktokUrlImporterVisible = action.payload
    }
  }
})

export default slice.reducer

export const {
  fetchChannelsSuccess,
  searchChannelsSuccess,
  fetchAggregatorChannelsSuccess,
  fetchChannelSuccess,
  createChannelSuccess,
  updateChannelSuccess,
  fetchChannelCreatedVideosSuccess,
  fetchChannelVideoSuccess,
  fetchChannelAllVideosSuccess,
  fetchChannelArchivedSuccess,
  updateLocalChannelVideosSuccess,
  fetchLatestVideosSuccess,
  uploadChannelVideoSuccess,
  createChannelVideoSuccess,
  updateChannelVideoSuccess,
  deleteChannelVideoSuccess,
  createVideoPosterSuccess,
  fetchChannelPlaylistsSuccess,
  fetchPlaylistVideosSuccess,
  sortPlaylistVideosSuccess,
  uploadVideoSearchHashtagsSuccess,
  getConnectWordPressIntegrationSuccess,
  getWordPressProfileSuccess,
  createWordPressPostSuccess,
  checkChannelBulkSuccess,
  setWordPressIntegrationDataSuccess,
  getPlayerShareLinkSuccess,
  fetchChannelAllLivestreamsSuccess,
  toggleLivestreamTestEvents,
  updateLivestreamFilter,
  setLastIndex,
  setManageButtonStateSuccess,
  setStorePopularPlaylist,
  fetchChannelPendingVideosSuccess,
  clearPendingVideos,
  updateVideoDataByChannelAndVideoId,
  updateVideoSearchParams,
  resetVideoSearchParams,
  updateVideoInteractionSuccess,
  createVideoInteractionSuccess,
  deleteVideoInteractionSuccess,
  updateLiveStreamBaseVideoProgress,
  updateLiveStreamBaseVideoMultiPartProgress,
  uploadLiveStreamBaseVideoSuccess,
  fetchChannelLiveStreamBaseVideosSuccess,
  fetchChannelLiveStreamBaseVideoSuccess,
  createChannelLiveStreamBaseVideoSuccess,
  fetchChannelVideoCountSuccess,
  setCurrentChannelId,
  showTiktokUrlImporter
} = slice.actions

const fetchChannelsRequest = createAction('channel/fetchChannelsRequest')
const fetchChannelsFailure = createAction('channel/fetchChannelsFailure')

export function fetchChannels(businessId: string, page_size = 10) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelsRequest())
      const response = await api.get(
        `/bus/${businessId}/channels?page_size=${page_size}`
      )
      const { channels } = response.data
      dispatch(fetchChannelsSuccess({ businessId, channels }))

      return channels
    } catch (error) {
      dispatch(fetchChannelsFailure())

      return error
    }
  }
}

const fetchAggregatorChannelsRequest = createAction(
  'channel/fetchAggregatorChannelsRequest'
)
const fetchAggregatorChannelsFailure = createAction(
  'channel/fetchAggregatorChannelsFailure'
)

export function fetchAggregatorChannels(businessId: string, channelId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchAggregatorChannelsRequest())
      const response = await api.get(
        `/bus/${businessId}/channels/${channelId}/aggregator_channels`
      )
      const { channels } = response.data
      dispatch(fetchAggregatorChannelsSuccess({ businessId, channels }))

      return channels
    } catch (error) {
      dispatch(fetchAggregatorChannelsFailure())

      return error
    }
  }
}

const searchChannelsRequest = createAction('channel/searchChannelsRequest')
const searchChannelsFailure = createAction('channel/searchChannelsFailure')

export function searchChannels(businessId: string, q?: string, page?: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(searchChannelsRequest())
      const params: Record<string, any> = {}
      if (q) params.q = q

      const response = await api.get(page || `/bus/${businessId}/channels`, {
        params
      })
      const { channels, paging } = response.data
      dispatch(searchChannelsSuccess({ businessId, channels, paging, page }))

      return response
    } catch (error) {
      dispatch(searchChannelsFailure())

      return error
    }
  }
}

const fetchChannelRequest = createAction('channel/fetchChannelRequest')
const fetchChannelFailure = createAction('channel/fetchChannelFailure')

export function fetchChannel(businessId: string, channelId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelRequest())

      const res = await api.get(`/bus/${businessId}/channels/${channelId}`)
      const channel = res.data
      dispatch(fetchChannelSuccess({ channel }))

      return res
    } catch (error) {
      dispatch(fetchChannelFailure())

      return error
    }
  }
}

const createChannelRequest = createAction('channel/createChannelRequest')
const createChannelFailure = createAction('channel/createChannelFailure')

export function createChannel(businessId: string, data: any) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createChannelRequest())

      const res = await api.post(
        `/bus/${businessId}/channels`,
        sanitizeChannelData(businessId, data)
      )
      const channel = res.data
      dispatch(createChannelSuccess({ businessId, channel }))

      return res
    } catch (error) {
      dispatch(createChannelFailure())

      return error
    }
  }
}

const updateChannelRequest = createAction('channel/updateChannelRequest')
const updateChannelFailure = createAction('channel/updateChannelFailure')

export function updateChannel(
  businessId: string,
  channelId: string,
  data: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateChannelRequest())

      const res = await api.patch(
        `/bus/${businessId}/channels/${channelId}`,
        sanitizeChannelData(businessId, data)
      )
      const channel = res.data
      dispatch(updateChannelSuccess({ businessId, channel }))

      return res
    } catch (error) {
      dispatch(updateChannelFailure())

      return error
    }
  }
}

const fetchChannelCreatedVideosRequest = createAction(
  'channel/fetchChannelCreatedVideosRequest'
)
const fetchChannelCreatedVideosFailure = createAction(
  'channel/fetchChannelCreatedVideosFailure'
)

export function fetchChannelCreatedVideos(
  businessId: string,
  channelId: string,
  page?: string,
  searchQuery: string | null = null,
  filter: string | null = null,
  sort: string | null = null,
  filterByOriginal?: boolean,
  filterByLinked?: boolean
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelCreatedVideosRequest())
      let url = `/bus/${businessId}/videos`
      let config = {
        params: {
          channel_id: channelId,
          is_live_stream: false,
          exclude_reposts: false,
          query: searchQuery,
          page_size: FETCH_VIDEO_PAGE_SIZE,
          filter: filter,
          sort: sort || 'started_at_or_inserted_at_desc',
          original_content: filterByOriginal,
          syndicated_content: filterByLinked,
          is_archived: false
        }
      }

      if (page) {
        url = page
        config = undefined
      }

      const res = await api.get(url, config)
      const { videos, paging } = res.data
      dispatch(
        fetchChannelCreatedVideosSuccess({ channelId, videos, paging, page })
      )
    } catch (error) {
      dispatch(fetchChannelCreatedVideosFailure())
    }
  }
}

const fetchChannelVideoRequest = createAction(
  'channel/fetchChannelVideoRequest'
)
const fetchChannelVideoFailure = createAction(
  'channel/fetchChannelVideoFailure'
)

export function fetchChannelVideo(videoId: string, channelId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelVideoRequest())
      const response = await api.get(`/videos/${videoId}`)
      const video = response.data
      dispatch(fetchChannelVideoSuccess({ channelId, video }))

      return response
    } catch (error) {
      dispatch(fetchChannelVideoFailure())
    }
  }
}

const fetchLatestVideosRequest = createAction(
  'channel/fetchLatestVideosRequest'
)
const fetchLatestVideosFailure = createAction(
  'channel/fetchLatestVideosFailure'
)

export function fetchLatestVideos(
  businessId: string,
  channelId: string,
  page?: string,
  pageSize = 100
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchLatestVideosRequest())
      const response = await api.get(
        page || `/bus/${businessId}/videos?page_size=${pageSize}`,
        {
          params: {
            channel_id: channelId,
            is_archived: false
          }
        }
      )
      const { paging, videos } = response.data
      dispatch(
        fetchLatestVideosSuccess({
          channelId,
          videos,
          paging,
          page
        })
      )

      return response
    } catch (error) {
      dispatch(fetchLatestVideosFailure())
    }
  }
}

const fetchChannelAllVideosRequest = createAction(
  'channel/fetchChannelAllVideosRequest'
)
const fetchChannelAllVideosFailure = createAction(
  'channel/fetchChannelAllVideosFailure'
)

export function fetchChannelAllVideos(
  businessId: string,
  channelId: string,
  page?: string | null,
  searchQuery: string | null = null,
  filter: string | null = null,
  sort: string | null = null,
  filterByOriginal?: boolean,
  filterByLinked?: boolean
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelAllVideosRequest())

      let url = `/bus/${businessId}/videos`
      let config = {
        params: {
          channel_id: channelId,
          query: searchQuery,
          filter: filter,
          page_size: FETCH_VIDEO_PAGE_SIZE,
          sort: sort || 'started_at_or_inserted_at_desc',
          original_content: filterByOriginal,
          syndicated_content: filterByLinked,
          is_archived: false
        }
      }

      if (page) {
        url = page
        config = undefined
      }

      const response = await api.get(url, config)
      const { paging, videos } = response.data
      dispatch(
        fetchChannelAllVideosSuccess({
          channelId,
          videos,
          paging,
          page
        })
      )

      return response
    } catch (error) {
      dispatch(fetchChannelAllVideosFailure())
    }
  }
}

const fetchChannelArchivedRequest = createAction(
  'channel/fetchChannelArchivedRequest'
)
const fetchChannelArchivedFailure = createAction(
  'channel/fetchChannelArchivedFailure'
)

export function fetchChannelArchived(
  businessId: string,
  channelId: string,
  page?: string | null
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelArchivedRequest())

      let url = `/bus/${businessId}/videos`
      let config = {
        params: {
          channel_id: channelId,
          page_size: FETCH_VIDEO_PAGE_SIZE,
          is_archived: true
        }
      }

      if (page) {
        url = page
        config = undefined
      }

      const response = await api.get(url, config)
      const { paging, videos } = response.data

      dispatch(
        fetchChannelArchivedSuccess({
          channelId,
          videos,
          paging,
          page
        })
      )

      return response
    } catch (error) {
      dispatch(fetchChannelArchivedFailure())
    }
  }
}

// just update the local data to avoid refreshing page
export function updateLocalChannelVideos(
  channelId: string,
  videos: globalLib.IVideo[]
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    dispatch(
      updateLocalChannelVideosSuccess({
        channelId,
        videos
      })
    )

    return videos
  }
}

const fetchChannelPendingVideosRequest = createAction(
  'channel/fetchChannelPendingVideosRequest'
)

const fetchChannelPendingVideosFailure = createAction(
  'channel/fetchChannelPendingVideosFailure'
)

export function fetchChannelPendingVideos(
  businessId: string,
  channelId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelPendingVideosRequest())
      const response = await api.get(
        `/bus/${businessId}/channels/${channelId}/videos/pending`
      )

      const { videos } = response.data
      dispatch(
        fetchChannelPendingVideosSuccess({
          channelId,
          videos
        })
      )
    } catch (error) {
      dispatch(fetchChannelPendingVideosFailure())
    }
  }
}

const updateChannelAvatarRequest = createAction(
  'channel/updateChannelAvatarRequest'
)
const updateChannelAvatarFailure = createAction(
  'channel/updateChannelAvatarFailure'
)

export function updateChannelAvatar(
  businessId: string,
  channelId: string,
  file: File,
  onProgress: (input: any) => void
) {
  return async (dispatch: Dispatch<AnyAction>): Promise<string | any> => {
    try {
      dispatch(updateChannelAvatarRequest())

      const signature = await getS3Signature(file)
      await uploadToS3(
        file,
        signature,
        onProgress,
        ({ key }) => {
          dispatch(
            updateChannel(businessId, channelId, {
              avatarKey: key
            }) as any
          )
        },
        (err) => {
          throw err
        }
      )
    } catch (error) {
      dispatch(updateChannelAvatarFailure())
    }
  }
}

const updateChannelCoverRequest = createAction(
  'channel/updateChannelCoverRequest'
)
const updateChannelCoverFailure = createAction(
  'channel/updateChannelCoverFailure'
)

export function updateChannelCover(
  businessId: string,
  channelId: string,
  file: File,
  onProgress: boolean
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateChannelCoverRequest())

      const signature = await getS3Signature(file)
      await uploadToS3(
        file,
        signature,
        onProgress,
        ({ key }) => {
          dispatch(
            updateChannel(businessId, channelId, { coverKey: key }) as any
          )
        },
        (err) => {
          throw err
        }
      )
    } catch (error) {
      dispatch(updateChannelCoverFailure())
    }
  }
}

const uploadChannelVideoRequest = createAction(
  'channel/uploadChannelVideoRequest'
)
const uploadChannelVideoFailure = createAction(
  'channel/uploadChannelVideoFailure'
)

export function uploadChannelVideo(
  businessId: string,
  channelId: string,
  file: File,
  onProgress: (input: { percent: string }) => void,
  onSuccess: () => void
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(uploadChannelVideoRequest())
      const signature = await getS3Signature(file)
      await uploadToS3(
        file,
        signature,
        (percent) => onProgress({ percent }),
        ({ key }) => {
          dispatch(uploadChannelVideoSuccess({ businessId, channelId, key }))
          onSuccess()
        },
        (err) => {
          throw err
        }
      )
    } catch (error) {
      dispatch(uploadChannelVideoFailure())

      return error
    }
  }
}

const createLivestreamClipRequest = createAction(
  'channel/createLivestreamClipRequest'
)
const createLivestreamClipFailure = createAction(
  'channel/createLivestreamClipFailure'
)

export function createLivestreamClip(livestreamId: string, data: any) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createLivestreamClipRequest())
      const response = await api.post(
        `/live_streams/${livestreamId}/short_clip`,
        data
      )
      dispatch(createChannelVideoSuccess())

      return response
    } catch (error) {
      dispatch(createLivestreamClipFailure())

      return error
    }
  }
}

const createChannelVideoRequest = createAction(
  'channel/createChannelVideoRequest'
)
const createChannelVideoFailure = createAction(
  'channel/createChannelVideoFailure'
)

export function createChannelVideo(
  businessId: string,
  channelId: string,
  data: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createChannelVideoRequest())
      const response = await api.post(
        `/bus/${businessId}/channels/${channelId}/videos`,
        data
      )
      dispatch(createChannelVideoSuccess())

      return response
    } catch (error) {
      dispatch(createChannelVideoFailure())

      return error
    }
  }
}

const updateChannelVideoRequest = createAction(
  'channel/updateChannelVideoRequest'
)
const updateChannelVideoFailure = createAction(
  'channel/createChannelVideoFailure'
)

export function updateChannelVideo(
  businessId: string,
  channelId: string,
  video: globalLib.IVideo,
  data: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      const videoChannelId = channelId || video.creator?.encoded_id
      dispatch(updateChannelVideoRequest())
      const response = await api.patch(
        `/bus/${businessId}/channels/${videoChannelId}/videos/${video.encoded_id}`,
        data
      )
      dispatch(updateChannelVideoSuccess())

      return response
    } catch (error) {
      dispatch(updateChannelVideoFailure())

      return error
    }
  }
}

const deleteChannelVideoRequest = createAction(
  'channel/deleteChannelVideoRequest'
)
const deleteChannelVideoFailure = createAction(
  'channel/deleteChannelVideoFailure'
)

export function deleteChannelVideo(
  businessId: string,
  channelId: string,
  videoId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteChannelVideoRequest())
      const response = await api.delete(
        `/bus/${businessId}/channels/${channelId}/videos/${videoId}`
      )
      dispatch(deleteChannelVideoSuccess({ channelId, videoId }))

      return response
    } catch (error) {
      dispatch(deleteChannelVideoFailure())

      return error
    }
  }
}

const archiveChannelVideoRequest = createAction(
  'channel/archiveChannelVideoRequest'
)
const archiveChannelVideoFailure = createAction(
  'channel/archiveChannelVideoFailure'
)

export function archiveChannelVideo(
  businessId: string,
  channelId: string,
  videoId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(archiveChannelVideoRequest())
      const response = await api.patch(
        `/bus/${businessId}/channels/${channelId}/videos/${videoId}/archive`
      )

      return response
    } catch (error) {
      dispatch(archiveChannelVideoFailure())

      return error
    }
  }
}

const unarchiveChannelVideoRequest = createAction(
  'channel/unarchiveChannelVideoRequest'
)
const unarchiveChannelVideoFailure = createAction(
  'channel/unarchiveChannelVideoFailure'
)

export function unarchiveChannelVideo(
  businessId: string,
  channelId: string,
  videoId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(unarchiveChannelVideoRequest())
      const response = await api.patch(
        `/bus/${businessId}/channels/${channelId}/videos/${videoId}/unarchive`
      )

      return response
    } catch (error) {
      dispatch(unarchiveChannelVideoFailure())

      return error
    }
  }
}

export function deleteChannelRepost(
  businessId: string,
  channelId: string,
  videoId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      const response = await api.delete(
        `/bus/${businessId}/channels/${channelId}/reposts`,
        { data: { video_id: videoId } }
      )
      dispatch(deleteChannelVideoSuccess({ channelId, videoId }))

      return response
    } catch (error) {
      return error
    }
  }
}

const onboardDefaultChannelRequest = createAction(
  'channel/onboardDefaultChannelRequest'
)
const onboardDefaultChannelFailure = createAction(
  'channel/onboardDefaultChannelFailure'
)

export function onboardDefaultChannel(businessId: string, data: any) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(onboardDefaultChannelRequest())

      return await api.patch(`bus/${businessId}/channels/convert`, data)
    } catch (error) {
      dispatch(onboardDefaultChannelFailure())

      return error
    }
  }
}

const sanitizeChannelData = (businessId: string, data: any) => {
  const sanitized = {
    analytics_tags: data.analytics_tags,
    avatar_key: data.avatarKey,
    bio: data.bio,
    business_id: businessId,
    branding: data.branding,
    country: data.country,
    cover_key: data.coverKey,
    ga_tracking_id: data.gaTrackingID,
    live_stream_nsfw_replacement: data.live_stream_nsfw_replacement,
    live_stream_terms_and_conditions: data.live_stream_terms_and_conditions,
    live_stream_username_type: data.live_stream_username_type,
    locale: data.locale,
    name: data.name?.trim(),
    promote_url: data.promoteUrl?.trim(),
    ui_border_style: data.ui_border_style,
    ui_button_color: data.ui_button_color,
    ui_button_font_color: data.ui_button_font_color,
    ui_font: data.ui_font,
    ui_full_cta: data.ui_full_cta,
    ui_theme: data.ui_theme,
    username: data.username?.trim()
  }

  return removeUndefined(sanitized)
}

const uploadVideoPosterRequest = createAction(
  'channel/uploadVideoPosterRequest'
)

const uploadVideoPosterFailure = createAction(
  'channel/uploadVideoPosterFailure'
)

export function uploadVideoPoster(
  file: File,
  onProgress: (input: { percent: string }) => void,
  onSuccess: (input: { key: string }) => void
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(uploadVideoPosterRequest())
      const signature = await getS3Signature(file)
      const { key } = await uploadFileToS3(file, signature, (percent) =>
        onProgress({ percent })
      )
      onSuccess({ key })
    } catch (error) {
      dispatch(uploadVideoPosterFailure())

      return error
    }
  }
}

const createVideoPosterRequest = createAction(
  'channel/createVideoPosterRequest'
)
const createVideoPosterFailure = createAction(
  'channel/createVideoPosterFailure'
)

export function createVideoPoster(
  businessId: string,
  channelId: string,
  videoId: string,
  data: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createVideoPosterRequest())

      const res = await api.post(
        `/bus/${businessId}/channels/${channelId}/videos/${videoId}/video_posters`,
        data
      )
      const posterData = res.data.video_posters
      await dispatch(createVideoPosterSuccess({ posterData }))

      return res
    } catch (error) {
      dispatch(createVideoPosterFailure())

      return error
    }
  }
}

const deleteVideoPosterRequest = createAction(
  'channel/deleteVideoPosterRequest'
)
const deleteVideoPosterFailure = createAction(
  'channel/deleteVideoPosterFailure'
)

export function deleteVideoPoster(
  businessId: string,
  channelId: string,
  videoId: string,
  posterId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteVideoPosterRequest())
      const response = await api.delete(
        `/bus/${businessId}/channels/${channelId}/videos/${videoId}/video_posters/${posterId}`
      )

      return response
    } catch (error) {
      dispatch(deleteVideoPosterFailure())

      return error
    }
  }
}

const fetchChannelPlaylistsRequest = createAction(
  'channel/fetchChannelPlaylistsRequest'
)
const fetchChannelPlaylistsFailure = createAction(
  'channel/fetchChannelPlaylistsFailure'
)

export function fetchChannelPlaylists(
  businessId: string,
  channelId: string,
  page?: string,
  pageSize = 100
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelPlaylistsRequest())
      const url = pageSize
        ? `/bus/${businessId}/channels/${channelId}/playlists?page_size=${pageSize}`
        : `/bus/${businessId}/channels/${channelId}/playlists`
      const res = await api.get(page || url)
      const { playlists, paging } = res.data
      dispatch(
        fetchChannelPlaylistsSuccess({
          channelId,
          playlists,
          paging,
          page
        })
      )

      return res
    } catch (error) {
      dispatch(fetchChannelPlaylistsFailure())
    }
  }
}

const fetchPlaylistVideosRequest = createAction(
  'channel/fetchPlaylistVideosRequest'
)
const fetchPlaylistVideosFailure = createAction(
  'channel/fetchPlaylistVideosFailure'
)

export function fetchPlaylistVideos(
  businessId: string,
  channelId: string,
  playlistId: string,
  page?: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchPlaylistVideosRequest())
      const res = await api.get(
        page ||
          `/bus/${businessId}/channels/${channelId}/playlists/${playlistId}/videos`,
        {
          params: {
            is_archived: false
          }
        }
      )
      const { videos, paging } = res.data
      dispatch(
        fetchPlaylistVideosSuccess({
          playlistId,
          videos,
          paging,
          page
        })
      )

      return res
    } catch (error) {
      dispatch(fetchPlaylistVideosFailure())

      return error
    }
  }
}

const searchPlaylistVideosRequest = createAction(
  'channel/searchPlaylistVideosRequest'
)
const searchPlaylistVideosFailure = createAction(
  'channel/searchPlaylistVideosFailure'
)

export function searchPlaylistVideos(
  businessId: string,
  channelId: string,
  playlistId: string,
  searchQuery: string | null = null,
  filter: string | null = null,
  sort: string | null = null
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(searchPlaylistVideosRequest())
      const res = await api.get(`/bus/${businessId}/videos`, {
        params: {
          channel_id: channelId,
          playlist_id: playlistId,
          query: searchQuery,
          filter,
          sort
        }
      })
      const { videos, paging } = res.data
      dispatch(
        fetchPlaylistVideosSuccess({
          playlistId,
          videos,
          paging: paging,
          page: null
        })
      )
    } catch (error) {
      dispatch(searchPlaylistVideosFailure())
    }
  }
}

const createChannelPlaylistRequest = createAction(
  'channel/createChannelPlaylistRequest'
)
const createChannelPlaylistFailure = createAction(
  'channel/createChannelPlaylistFailure'
)

export function createChannelPlaylist(
  businessId: string,
  channelId: string,
  data: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createChannelPlaylistRequest())

      return await api.post(
        `/bus/${businessId}/channels/${channelId}/playlists`,
        data
      )
    } catch (error) {
      dispatch(createChannelPlaylistFailure())

      return error
    }
  }
}

const updateChannelPlaylistRequest = createAction(
  'channel/updateChannelPlaylistRequest'
)
const updateChannelPlaylistFailure = createAction(
  'channel/updateChannelPlaylistFailure'
)

export function updateChannelPlaylist(
  businessId: string,
  channelId: string,
  playlistId: string,
  data: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateChannelPlaylistRequest())

      const res = await api.patch(
        `/bus/${businessId}/channels/${channelId}/playlists/${playlistId}`,
        data
      )

      return res
    } catch (error) {
      dispatch(updateChannelPlaylistFailure())

      return error
    }
  }
}

const insertChannelPlaylistVideoRequest = createAction(
  'channel/insertChannelPlaylistVideoRequest'
)
const insertChannelPlaylistVideoFailure = createAction(
  'channel/insertChannelPlaylistVideoFailure'
)

export function insertChannelPlaylistVideo(
  businessId: string,
  channelId: string,
  data: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(insertChannelPlaylistVideoRequest())
      const response = await api.patch(
        `/bus/${businessId}/channels/${channelId}/playlists/${data.playlistId}/insert_video/${data.videoId}`,
        {
          index: data.index
        }
      )

      return response
    } catch (error) {
      dispatch(insertChannelPlaylistVideoFailure())

      return error
    }
  }
}

const removeChannelPlaylistVideoRequest = createAction(
  'channel/removeChannelPlaylistVideoRequest'
)
const removeChannelPlaylistVideoFailure = createAction(
  'channel/removeChannelPlaylistVideoFailure'
)

export function removeChannelPlaylistVideo(
  businessId: string,
  channelId: string,
  playlistId: string,
  videoId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(removeChannelPlaylistVideoRequest())
      const response = await api.patch(
        `/bus/${businessId}/channels/${channelId}/playlists/${playlistId}/remove_video/${videoId}`
      )

      return response
    } catch (error) {
      dispatch(removeChannelPlaylistVideoFailure())

      return error
    }
  }
}

const deleteChannelPlaylistRequest = createAction(
  'channel/deleteChannelPlaylistRequest'
)
const deleteChannelPlaylistFailure = createAction(
  'channel/deleteChannelPlaylistFailure'
)

export function deleteChannelPlaylist(
  businessId: string,
  channelId: string,
  playlistId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteChannelPlaylistRequest())
      const response = await api.delete(
        `/bus/${businessId}/channels/${channelId}/playlists/${playlistId}`
      )

      return response
    } catch (error) {
      dispatch(deleteChannelPlaylistFailure())

      return error
    }
  }
}

export function sortPlaylistVideos(
  playlistId: string,
  videos: globalLib.IVideo[]
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    dispatch(sortPlaylistVideosSuccess({ playlistId, videos }))
  }
}

const duplicateChannelPlaylistRequest = createAction(
  'channel/duplicateChannelPlaylistRequest'
)
const duplicateChannelPlaylistFailure = createAction(
  'channel/duplicateChannelPlaylistFailure'
)

export function duplicateChannelPlaylist(
  businessId: string,
  channelId: string,
  playlistId: string,
  data: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(duplicateChannelPlaylistRequest())
      await api.post(
        `/api/bus/${businessId}/channels/${channelId}/playlists/${playlistId}/duplicate`,
        data
      )
    } catch (error) {
      dispatch(duplicateChannelPlaylistFailure())
    }
  }
}

const uploadVideoSearchHashtagsRequest = createAction(
  'channel/uploadVideoSearchHashtagsRequest'
)
const uploadVideoSearchHashtagsFailure = createAction(
  'channel/uploadVideoSearchHashtagsFailure'
)

export function uploadVideoSearchHashtags(q?: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(uploadVideoSearchHashtagsRequest())
      const params: Record<string, string | number> = { page_size: 5 }
      if (q) {
        params.q = q
        const response = await api.get('/hashtags', {
          params
        })
        const { hashtags } = response.data
        dispatch(uploadVideoSearchHashtagsSuccess({ hashtags }))
      } else {
        dispatch(uploadVideoSearchHashtagsSuccess({ hashtags: [] }))
      }
    } catch (error) {
      dispatch(uploadVideoSearchHashtagsFailure())
    }
  }
}

const getWordPressProfileRequest = createAction(
  'channel/getWordPressProfileRequest'
)
const getWordPressProfileFailure = createAction(
  'channel/getWordPressProfileFailure'
)

export function getWordPressProfile(token: string | any) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(getWordPressProfileRequest())
      const res = await axios.get(
        'https://public-api.wordpress.com/rest/v1/me/',
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      )
      dispatch(getWordPressProfileSuccess({ ...res.data }))
    } catch (error) {
      dispatch(getWordPressProfileFailure())
    }
  }
}

const createBulkImportRequest = createAction('channel/createBulkImportRequest')
const createBulkImportFailure = createAction('channel/createBulkImportFailure')

export function createBulkImport(
  businessId: string,
  channelId: string,
  posts: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createBulkImportRequest())
      const res = await api.post(
        `/bus/${businessId}/channels/${channelId}/videos/bulk`,
        {
          source: 'ig',
          bulk: posts
        }
      )

      return res
    } catch (error) {
      dispatch(createBulkImportFailure())
    }
  }
}

const createVideoInteractionRequest = createAction(
  'channel/createVideoInteractionRequest'
)
const createVideoInteractionFailure = createAction(
  'channel/createVideoInteractionFailure'
)

export function createVideoInteraction(
  videoId: string,
  data: any,
  channelId: any,
  selfErrorHandler = false
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createVideoInteractionRequest())
      const response = await api.post(
        `/videos/${videoId}/interactions`,
        sanitizeInteractionData(data),
        selfErrorHandler ? selfErrorHandlerRequest() : undefined
      )

      dispatch(
        createVideoInteractionSuccess({
          videoId,
          data: sanitizeInteractionData(data),
          channelId
        })
      )

      return response
    } catch (error) {
      dispatch(createVideoInteractionFailure())

      return error
    }
  }
}

const getConnectWordPressIntegrationRequest = createAction(
  'channel/getConnectWordPressIntegrationRequest'
)
const getConnectWordPressIntegrationFailure = createAction(
  'channel/getConnectWordPressIntegrationFailure'
)

export function getConnectWordPressIntegration(url: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(getConnectWordPressIntegrationRequest())
      const [contextId, data] = url.split('#')
      const [context, id] = Object.entries(qs.parse(contextId))[0]
      let result = qs.parse(data)
      result = { ...result, context, id }
      dispatch(getWordPressProfile(result.access_token) as any)
      dispatch(getConnectWordPressIntegrationSuccess({ ...result }))
    } catch (error) {
      dispatch(getConnectWordPressIntegrationFailure())
    }
  }
}
const checkChannelBulkRequest = createAction('channel/checkChannelBulkRequest')
const checkChannelBulkFailure = createAction('channel/checkChannelBulkFailure')

export function checkChannelBulk(businessId: string, channelId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(checkChannelBulkRequest())
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/videos/bulk`
      )

      const { jobs } = res.data
      if (jobs) {
        const processingJobs = jobs.filter((job) => job.status !== 'finished')
        dispatch(checkChannelBulkSuccess({ jobs: processingJobs, channelId }))
      }
    } catch (error) {
      dispatch(checkChannelBulkFailure())
    }
  }
}

const updateVideoInteractionRequest = createAction(
  'channel/updateVideoInteractionRequestRequest'
)
const updateVideoInteractionFailure = createAction(
  'channel/updateVideoInteractionFailure'
)

export function updateVideoInteraction(
  videoId: string,
  interactionId: string,
  data: any,
  channelId: string,
  selfErrorHandler = false
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateVideoInteractionRequest())
      const response = await api.patch(
        `/videos/${videoId}/interactions/${interactionId}`,
        sanitizeInteractionData(data),
        selfErrorHandler ? selfErrorHandlerRequest() : undefined
      )

      dispatch(
        updateVideoInteractionSuccess({
          videoId,
          interactionId,
          data: sanitizeInteractionData(data),
          channelId
        })
      )

      return response
    } catch (error) {
      dispatch(updateVideoInteractionFailure())

      return error
    }
  }
}

const createWordPressPostRequest = createAction(
  'channel/createWordPressPostRequest'
)
const createWordPressPostFailure = createAction(
  'channel/createWordPressPostFailure'
)

export function createWordPressPost(
  data: any
): (dispatch: Dispatch) => Promise<string | any> {
  const {
    site_id,
    access_token,
    description,
    title,
    channel,
    context,
    id,
    firstChannelVideoId,
    firstPlaylistVideoId
  } = data

  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createWordPressPostRequest())
      const contentChannel = `<!DOCTYPE html><html><head></head><body>
      [firework-video layout="storyblock" channel="${channel}"]<p>${description}</p></body></html>`
      const contentContext = `<!DOCTYPE html><html><head></head><body>
      [firework-video layout="storyblock" channel="${channel}" ${context}="${id}" ]<p>${description}</p></body></html>`
      const body = {
        content: context === CHANNEL ? contentChannel : contentContext,
        title
      }
      const res = await api.post(
        `https://public-api.wordpress.com/rest/v1/sites/${site_id}/posts/new`,
        body,
        {
          headers: {
            Authorization: `bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        }
      )
      dispatch(
        getPlayerShareLink(
          context,
          channel,
          id,
          res.data.URL,
          firstChannelVideoId,
          firstPlaylistVideoId
        ) as any
      )
      dispatch(createWordPressPostSuccess({ ...res.data }))
    } catch (error) {
      dispatch(createWordPressPostFailure())
    }
  }
}

const setWordPressIntegrationDataRequest = createAction(
  'channel/setWordPressIntegrationDataRequest'
)
const setWordPressIntegrationDataFailure = createAction(
  'channel/setWordPressIntegrationDataFailure'
)

export function setWordPressIntegrationData(data: any) {
  return (dispatch: Dispatch): void => {
    try {
      dispatch(setWordPressIntegrationDataRequest())
      dispatch(setWordPressIntegrationDataSuccess({ ...data }))
    } catch (error) {
      dispatch(setWordPressIntegrationDataFailure())
    }
  }
}

const duplicateLivestreamRequest = createAction(
  'channel/duplicateLivestreamRequestRequest'
)
const duplicateLivestreamFailure = createAction(
  'channel/duplicateLivestreamFailure'
)

export function duplicateLivestream(
  businessId: string,
  channelId: string,
  videoId: string,
  testMode: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(duplicateLivestreamRequest())
      const response = await api.post(
        `/api/bus/${businessId}/channels/${channelId}/videos/${videoId}/duplicate`,
        {
          test_mode: testMode
        }
      )

      return response
    } catch (error) {
      dispatch(duplicateLivestreamFailure())

      return error
    }
  }
}

const getPlayerShareLinkRequest = createAction(
  'channel/getPlayerShareLinkRequest'
)
const getPlayerShareLinkFailure = createAction(
  'channel/getPlayerShareLinkFailure'
)
export function getPlayerShareLink(
  context: string,
  channel: string,
  id: string,
  url: string,
  firstChannelVideoId: string,
  firstPlaylistVideoId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(getPlayerShareLinkRequest())
      const dict = {
        channel: 'channel_timeline',
        playlist: 'playlist',
        video: 'single_video'
      }
      const body = {
        base_url: url,
        feed_type: dict[context],
        channel,
        video_id:
          context === VIDEO
            ? id
            : context === CHANNEL
            ? firstChannelVideoId
            : firstPlaylistVideoId,
        playlist_id: context === PLAYLIST ? id : undefined
      }
      const res = await api.post('/player_urls', removeUndefined(body))
      dispatch(getPlayerShareLinkSuccess({ ...res.data }))
    } catch (error) {
      dispatch(getPlayerShareLinkFailure())
    }
  }
}

const fetchChannelAllLivestreamsRequest = createAction(
  'channel/fetchChannelAllLivestreamsRequest'
)
const fetchChannelAllLivestreamsFailure = createAction(
  'channel/fetchChannelAllLivestreamsFailure'
)

interface FetchAllLivestreamsParams {
  businessId: string
  channelId: string
  page?: string | null
  searchQuery?: string | null
  filter?: string | null
  sort?: string | null
  testMode?: boolean
  livestreamFilter?: string | string[]
  filterByOriginal?: boolean
  filterByLinked?: boolean
  since?: string
  before?: string
  page_size?: number
  live_stream_sources?: string[]
  append?: boolean
  ref_video_id?: string
}

export function fetchChannelAllLivestreams({
  businessId,
  channelId,
  page,
  searchQuery = null,
  filter = null,
  sort = null,
  testMode,
  livestreamFilter,
  filterByOriginal,
  filterByLinked,
  since,
  before,
  page_size,
  live_stream_sources,
  append = true,
  ref_video_id
}: FetchAllLivestreamsParams) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelAllLivestreamsRequest())
      // Determine the statuses based on the filter selection
      const statuses: string[] = []
      const addStatus = (status: string | string[]) => {
        if (Array.isArray(status)) {
          status.forEach((filter) => addStatus(filter))
        } else {
          if (status === 'ended') {
            statuses.push(
              LIVESTREAM_STATUS.REPLAY,
              LIVESTREAM_STATUS.COMPLETED,
              LIVESTREAM_STATUS.EXPIRED
            )
          } else if (status === 'upcoming') {
            statuses.push(LIVESTREAM_STATUS.IDLE)
          } else if (status === 'live') {
            statuses.push(LIVESTREAM_STATUS.PAUSED, LIVESTREAM_STATUS.ACTIVE)
          } else if (status === 'replay') {
            statuses.push(LIVESTREAM_STATUS.REPLAY)
          }
        }
      }
      addStatus(livestreamFilter)

      let url = `/bus/${businessId}/videos`
      let config = {
        params: {
          // don't send the test param unless testMode is false
          channel_id: channelId,
          live_stream_test_mode: testMode ? undefined : testMode,
          is_live_stream: true,
          live_stream_statuses: statuses,
          query: searchQuery,
          filter: filter,
          sort: sort || 'started_at_or_inserted_at_desc',
          original_content: filterByOriginal,
          syndicated_content: filterByLinked,
          since,
          before,
          page_size,
          live_stream_sources,
          ref_video_id
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        }
      }

      if (page) {
        url = page
        config = undefined
      }

      const response = await api.get(url, config)
      const { paging, videos } = response.data
      dispatch(
        fetchChannelAllLivestreamsSuccess({
          channelId,
          videos,
          paging,
          page,
          append
        })
      )

      return response
    } catch (error) {
      dispatch(fetchChannelAllLivestreamsFailure())
    }
  }
}

const deleteVideoInteractionRequest = createAction(
  'channel/deleteVideoInteractionRequest'
)
const deleteVideoInteractionFailure = createAction(
  'channel/deleteVideoInteractionFailure'
)

export function deleteVideoInteraction(
  videoId: string,
  interactionId: string,
  channelId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteVideoInteractionRequest())
      const response = await api.delete(
        `/videos/${videoId}/interactions/${interactionId}`
      )

      dispatch(
        deleteVideoInteractionSuccess({ videoId, interactionId, channelId })
      )

      return response
    } catch (error) {
      dispatch(deleteVideoInteractionFailure())

      return error
    }
  }
}

const getLatestActivityRequest = createAction(
  'channel/getLatestActivityRequest'
)
const getLatestActivityFailure = createAction(
  'channel/getLatestActivityFailure'
)

export function getLatestActivity(businessId: string, channelId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(getLatestActivityRequest())
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/latest_activity`
      )
      const { video } = res.data

      return video.inserted_at
    } catch (e) {
      dispatch(getLatestActivityFailure())
    }
  }
}

const deleteChannelBulkVideosRequest = createAction(
  'channel/deleteChannelBulkVideosRequest'
)
const deleteChannelBulkVideosFailure = createAction(
  'channel/deleteChannelBulkVideosFailure'
)

export function deleteChannelBulkVideos(
  businessId: string,
  channelId: string,
  params: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteChannelBulkVideosRequest())
      const response = await api.post(
        `/bus/${businessId}/channels/${channelId}/videos/bulk_biz`,
        params
      )

      return response
    } catch (error) {
      dispatch(deleteChannelBulkVideosFailure())
    }
  }
}

const updateHashtagsForBulkVideosRequest = createAction(
  'channel/updateHashtagsForBulkVideosRequest'
)
const updateHashtagsForBulkVideosFailure = createAction(
  'channel/updateHashtagsForBulkVideosFailure'
)

export function updateHashtagsForBulkVideos(
  businessId: string,
  channelId: string,
  params: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateHashtagsForBulkVideosRequest())
      const response = await api.patch(
        `/bus/${businessId}/channels/${channelId}/videos/bulk_biz`,
        params
      )

      return response
    } catch (e) {
      dispatch(updateHashtagsForBulkVideosFailure())
    }
  }
}

const updateBulkVisibilityRequest = createAction(
  'channel/updateBulkVisibilityRequest'
)
const updateBulkVisibilityFailure = createAction(
  'channel/updateBulkVisibilityFailure'
)

export function updateBulkVisibility(params: {
  businessId: string
  channelId: string
  access: string
  videoIds: string[]
}) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateBulkVisibilityRequest())
      const response = await api.patch(
        `bus/${params.businessId}/channels/${params.channelId}/videos/bulk_access`,
        { access: params.access, video_ids: params.videoIds }
      )

      return response
    } catch (e) {
      dispatch(updateBulkVisibilityFailure())
    }
  }
}

export function setLastIndexShiftSelect(value: number) {
  return (dispatch: Dispatch): void => dispatch(setLastIndex({ value })) as any
}

export function setManageButtonState(bool: boolean) {
  return (dispatch: Dispatch): void => {
    if (!bool) {
      dispatch(resetSelections())
    }
    dispatch(setManageButtonStateSuccess({ bool }))
  }
}

export function setPopularPlaylist(
  channelId: string,
  playlist: globalLib.Playlist | boolean,
  views: Record<string, any>
) {
  return (dispatch: Dispatch): void =>
    dispatch(setStorePopularPlaylist({ channelId, playlist, views })) as any
}

const uploadLiveStreamBaseVideoRequest = createAction(
  'channel/uploadLiveStreamBaseVideoRequest'
)

const uploadLiveStreamBaseVideoFailure = createAction(
  'channel/uploadLiveStreamBaseVideoFailure'
)

export function uploadLiveStreamBaseVideo(
  businessId: string,
  channelId: string,
  file: File,
  title: string
) {
  const uploadToS3Async = (
    signature,
    onProgress: (percent) => void
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      uploadToS3(
        file,
        signature,
        onProgress,
        ({ key }) => {
          resolve(key)
        },
        (err) => {
          reject(err)
        }
      )
    })
  }

  return async (dispatch: Dispatch): Promise<string | any> => {
    dispatch(uploadLiveStreamBaseVideoRequest())
    const signature = await getS3Signature(file)
    try {
      const key = await uploadToS3Async(signature, (percent) => {
        dispatch(
          updateLiveStreamBaseVideoProgress({
            channelId,
            percent,
            key: signature.key,
            title
          })
        )
      })
      dispatch(
        uploadLiveStreamBaseVideoSuccess({
          channelId,
          key,
          title
        })
      )
      await dispatch(
        createChannelLiveStreamBaseVideo(businessId, channelId, title, key)
      )
    } catch (error) {
      updateLiveStreamBaseVideoProgress({
        channelId,
        status: 'error',
        key: signature.key,
        title
      })
      dispatch(uploadLiveStreamBaseVideoFailure())

      return error
    }
  }
}

interface MultiPartParams {
  businessId: string
  channelId: string
  file: File
  title: string
  fileParts: File[]
}

const uploadLiveStreamBaseVideoMultiPartRequest = createAction(
  'channel/uploadLiveStreamBaseVideoMultiPartRequest'
)

const uploadLiveStreamBaseVideoMultiPartFailure = createAction(
  'channel/uploadLiveStreamBaseVideoMultiPartFailure'
)

export function uploadLiveStreamBaseVideoMultiPart({
  businessId,
  channelId,
  file,
  title,
  fileParts
}: MultiPartParams) {
  const uploadToS3Async = (
    signature,
    reader,
    onProgress: (percent) => void
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      reader.onload = async () => {
        // We have to convert the buffer to a blob:
        const videoBlob = new Blob([new Uint8Array(reader.result)], {
          type: 'video/mp4'
        })

        uploadToS3(
          videoBlob,
          signature,
          onProgress,
          ({ etag }) => {
            resolve(etag)
          },
          (err) => {
            reject(err)
          }
        )
      }
      reader.onerror = reject
    })
  }

  return async (dispatch: Dispatch): Promise<string | any> => {
    dispatch(uploadLiveStreamBaseVideoMultiPartRequest())
    const response = await startMultiPartUpload(file, fileParts.length)
    const { key, upload_id, parts } = response

    try {
      const responses = fileParts.map(async (fileChunk, index) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(fileChunk)

        const etag = await uploadToS3Async(
          parts[index].signature,
          reader,
          (percent) => {
            dispatch(
              updateLiveStreamBaseVideoMultiPartProgress({
                channelId,
                percent,
                key,
                title,
                part: parts[index].part - 1,
                numParts: parts.length
              })
            )
          }
        )

        return { etag, part: parts[index].part }
      })

      const responseParts = await Promise.all(responses)
      await completeMultiPartUpload(key, upload_id, responseParts)

      dispatch(
        uploadLiveStreamBaseVideoSuccess({
          channelId,
          key,
          title
        })
      )
      await dispatch(
        createChannelLiveStreamBaseVideo(businessId, channelId, title, key)
      )
    } catch (error) {
      dispatch(
        updateLiveStreamBaseVideoMultiPartProgress({
          channelId,
          status: 'error',
          key: response?.key,
          title
        })
      )
      dispatch(uploadLiveStreamBaseVideoMultiPartFailure())

      return error
    }
  }
}

const fetchChannelLiveStreamBaseVideoRequest = createAction(
  'channel/fetchChannelLiveStreamBaseVideoRequest'
)
const fetchChannelLiveStreamBaseVideoFailure = createAction(
  'channel/fetchChannelLiveStreamBaseVideoFailure'
)

export function fetchChannelLiveStreamBaseVideo(
  businessId: string,
  channelId: string,
  baseVideoId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelLiveStreamBaseVideoRequest())
      const response = await api.get(
        `/bus/${businessId}/channels/${channelId}/base_videos/${baseVideoId}`
      )

      dispatch(
        fetchChannelLiveStreamBaseVideoSuccess({
          channelId,
          liveStreamBaseVideo: response.data
        })
      )

      return response
    } catch (error) {
      dispatch(fetchChannelLiveStreamBaseVideoFailure())
    }
  }
}

const fetchChannelLiveStreamBaseVideosRequest = createAction(
  'channel/fetchChannelLiveStreamBaseVideosRequest'
)
const fetchChannelLiveStreamBaseVideosFailure = createAction(
  'channel/fetchChannelLiveStreamBaseVideosFailure'
)

export function fetchChannelLiveStreamBaseVideos(
  businessId: string,
  channelId: string,
  pagingNext?: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelLiveStreamBaseVideosRequest())
      const response = await api.get(
        pagingNext || `/bus/${businessId}/channels/${channelId}/base_videos`,
        { params: { page_size: 10 } }
      )

      dispatch(
        fetchChannelLiveStreamBaseVideosSuccess({
          baseVideos: response.data?.base_videos,
          paging: response.data?.paging,
          paginated: !!pagingNext,
          channelId
        })
      )

      return response
    } catch (error) {
      dispatch(fetchChannelLiveStreamBaseVideosFailure())
    }
  }
}

const createChannelLiveStreamBaseVideoRequest = createAction(
  'channel/createChannelLiveStreamBaseVideoRequest'
)
const createChannelLiveStreamBaseVideoFailure = createAction(
  'channel/createChannelLiveStreamBaseVideoFailure'
)

export function createChannelLiveStreamBaseVideo(
  businessId: string,
  channelId: string,
  title: string,
  key: string
): any {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createChannelLiveStreamBaseVideoRequest())
      const data = { channel_id: channelId, title, s3_key: key }

      const response = await api.post(
        `/bus/${businessId}/channels/${channelId}/base_videos`,
        data
      )
      dispatch(
        createChannelLiveStreamBaseVideoSuccess({
          channelId,
          liveStreamBaseVideo: response.data
        })
      )
    } catch (error) {
      dispatch(createChannelLiveStreamBaseVideoFailure())

      return error
    }
  }
}

export async function getChannelDataByShopifyDomain(
  shopifyDomain: string
): Promise<{
  business_id: string
  channelsData: globalLib.Channel[]
} | null> {
  try {
    const response = await api.get(
      `/shopify/business_id?myshopify_domain=${shopifyDomain}`
    )

    const { business_id } = response?.data
    if (business_id) {
      const channelData = await api.get(
        `/bus/${business_id}/channels?page_size:100`
      )
      const { channels } = channelData.data

      return {
        business_id,
        channelsData: channels
      }
    }

    return null
  } catch (error) {
    throw new Error('Error fetching channel data')
  }
}

export async function fetchChannelPlaylistsData(
  businessId: string,
  channelId: string
): Promise<globalLib.Playlist[] | null> {
  try {
    const res = await api.get(
      `/bus/${businessId}/channels/${channelId}/playlists`
    )
    const { playlists } = res.data

    return playlists || null
  } catch (error) {
    throw new Error('Error fetching channel playlists')
  }
}

const fetchChannelVideoCountRequest = createAction(
  'channel/fetchChannelVideoCountRequest'
)

const fetchChannelVideoCountFailure = createAction(
  'channel/fetchChannelVideoCountFailure'
)

export function fetchChannelVideoCount(businessId: string, channelId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelVideoCountRequest())
      const response = await api.post(
        `/bus/${businessId}/channels/${channelId}/videos/count`,
        {
          all: {},
          live_streams: {
            is_live_stream: true,
            original_content: true,
            syndicated_content: true
          },
          short_videos: { exclude_reposts: false, is_live_stream: false }
        }
      )

      const {
        live_streams_count,
        short_videos_count,
        all_count
      } = response.data
      dispatch(
        fetchChannelVideoCountSuccess({
          businessId,
          channelId,
          live_streams_count,
          short_videos_count,
          all_count
        })
      )
    } catch (error) {
      dispatch(fetchChannelVideoCountFailure())
    }
  }
}

const bulkUpdateChannelPlaylistRequest = createAction(
  'channel/bulkUpdateChannelPlaylistRequest'
)
const bulkUpdateChannelPlaylistFailure = createAction(
  'channel/bulkUpdateChannelPlaylistFailure'
)

export function bulkUpdateChannelPlaylist(
  action: 'add' | 'remove',
  businessId: string,
  channelId: string,
  playlistIds: string[],
  videoIds: string[]
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(bulkUpdateChannelPlaylistRequest())
      const response = await api.patch(
        `/bus/${businessId}/channels/${channelId}/playlists/bulk_update_videos`,
        {
          action,
          playlist_ids: playlistIds,
          video_ids: videoIds
        }
      )

      return response
    } catch (error) {
      dispatch(bulkUpdateChannelPlaylistFailure())

      return error
    }
  }
}
