import { createAction, createSlice } from '@reduxjs/toolkit'
import { values, sumBy } from 'lodash'
import { Dispatch } from 'redux'

import api from '../utils/api'
import { fillMissingDates } from '../utils/stats'

type IProps = {
  insights: any[]
  categoryInsights: any[]
  refererInsights: Record<string, any>
}

const initialState: IProps = {
  insights: [],
  categoryInsights: [],
  refererInsights: {}
}

const slice = createSlice({
  name: 'channelInsight',
  initialState: initialState,
  reducers: {
    fetchChannelInsightsSuccess(state, action) {
      const { endDate, startDate } = action.payload
      let { insights } = action.payload

      // Round watched numbers
      insights = insights.map((obj) => ({
        ...obj,
        total_minutes_watched: Math.round(obj.total_minutes_watched),
        total_seconds_watched: Math.round(obj.total_seconds_watched)
      }))

      // Add missing dates
      insights = fillMissingDates({
        data: insights,
        dataInitFields: [
          'cta_clicks',
          'cta_click_rate',
          'engaged_views',
          'followers',
          'total_minutes_watched',
          'total_seconds_watched',
          'total_views'
        ],
        endDate,
        startDate
      })

      state.insights = insights
    },

    fetchChannelCategoryInsightsSuccess(state, action) {
      const { insights } = action.payload

      // Short circuit logic here because the "empty" state depends on
      // the array being empty, but we will always push the "others" object.
      if (insights.length === 0) return

      // Aggregate totals by tag name
      let categoryInsights = insights.reduce((acc, i) => {
        const tagName = i.tag_name
        if (!acc[tagName]) {
          acc[tagName] = {
            display_name: i.display_name,
            tag_name: tagName,
            total_minutes_watched: 0,
            total_views: 0
          }
        }

        acc[tagName].total_views += i.total_views
        acc[tagName].total_minutes_watched += i.total_minutes_watched

        return acc
      }, {})

      // Flatten object by key
      categoryInsights = values(categoryInsights)

      // Sort by views
      categoryInsights = categoryInsights.sort(
        (a, b) => b.total_views - a.total_views
      )

      // Separate by top categories
      const totalViews = sumBy(categoryInsights, 'total_views')
      let top5 = categoryInsights.slice(0, 5)
      const others = categoryInsights.slice(5)
      top5.push({
        tag_name: 'others',
        display_name: 'Others',
        total_views: sumBy(others, 'total_views'),
        total_minutes_watched: sumBy(others, 'total_minutes_watched')
      })
      top5 = top5.map((o) => {
        return {
          ...o,
          percentage: o.total_views / totalViews
        }
      })

      state.categoryInsights = top5
    },

    fetchChannelRefererInsightsSuccess(state, action) {
      const { channelUsername, insights } = action.payload

      // Regex for aggregating all entries for channel
      const regex = new RegExp(
        `fw.tv/${channelUsername}|${channelUsername}.fw.tv`,
        'gi'
      )

      // Aggregate by referer
      let refererInsights: Record<string, any> = {}
      insights.forEach((i) => {
        let referer = i.referer

        // Aggregate all entries for channel
        if (referer.match(regex)) {
          referer = `${channelUsername} channel`
        }

        if (!refererInsights[referer]) {
          refererInsights[referer] = {
            engaged_views: 0,
            referer,
            total_minutes_watched: 0,
            total_seconds_watched: 0,
            total_views: 0
          }
        }

        refererInsights[referer].engaged_views += i.engaged_views
        refererInsights[referer].total_minutes_watched +=
          i.total_minutes_watched
        refererInsights[referer].total_seconds_watched +=
          i.total_seconds_watched
        refererInsights[referer].total_views += i.total_views
      })

      // Flatten object and sort
      refererInsights = values(refererInsights).sort(
        (a: Record<string, any>, b: Record<string, any>) =>
          b.total_views - a.total_views
      )

      // Take top 10
      refererInsights = refererInsights.slice(0, 10)

      state.refererInsights = refererInsights
    }
  }
})
export default slice.reducer
export const {
  fetchChannelInsightsSuccess,
  fetchChannelCategoryInsightsSuccess,
  fetchChannelRefererInsightsSuccess
} = slice.actions

const fetchChannelInsightsRequest = createAction(
  'channelInsight/fetchChannelInsightsRequest'
)
const fetchChannelInsightsFailure = createAction(
  'channelInsight/fetchChannelInsightsFailure'
)
export const fetchChannelInsights = (
  businessId: string,
  channelId: string,
  params: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelInsightsRequest())

      const { start_date, end_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights`,
        {
          params: {
            dimension: 'insights',
            end_date,
            start_date
          }
        }
      )

      dispatch(
        fetchChannelInsightsSuccess({
          endDate: end_date,
          startDate: start_date,
          ...res.data
        })
      )
    } catch (error) {
      dispatch(fetchChannelInsightsFailure())
    }
  }
}

const fetchChannelCategoryInsightsRequest = createAction(
  'channelInsight/fetchChannelCategoryInsightsRequest'
)
const fetchChannelCategoryInsightsFailure = createAction(
  'channelInsight/fetchChannelCategoryInsightsFailure'
)
export const fetchChannelCategoryInsights = (
  businessId: string,
  channelId: string,
  params: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelCategoryInsightsRequest())

      const { start_date, end_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights`,
        {
          params: {
            dimension: 'category',
            end_date,
            start_date
          }
        }
      )

      dispatch(fetchChannelCategoryInsightsSuccess(res.data))
    } catch (error) {
      dispatch(fetchChannelCategoryInsightsFailure())
    }
  }
}

const fetchChannelRefererInsightsRequest = createAction(
  'channelInsight/fetchChannelRefererInsightsRequest'
)
const fetchChannelRefererInsightsFailure = createAction(
  'channelInsight/fetchChannelRefererInsightsFailure'
)
export const fetchChannelRefererInsights = (
  businessId: string,
  channelId: string,
  params: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelRefererInsightsRequest())

      const { start_date, end_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights`,
        {
          params: {
            dimension: 'referer',
            end_date,
            start_date
          }
        }
      )

      dispatch(
        fetchChannelRefererInsightsSuccess({
          channelUsername: params.channelUsername,
          ...res.data
        })
      )
    } catch (error) {
      dispatch(fetchChannelRefererInsightsFailure())
    }
  }
}
