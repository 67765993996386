import React from 'react'

import { Box } from '@src/components/EmotionLayout'
import { beautyTitle } from '@src/pages/livestream/Analytics/ChartUtils'
import { Divider, Skeleton } from 'antd'
import { Chart, Tooltip, Axis, Geom, Coord, Label } from 'bizcharts'

import { Card, EmptyInsightsContent } from '.'
import { CHART_COLORS } from '../styles/theme'

type IProps = {
  barColor?: string
  colors?: any
  data: any[]
  dataIndexBy: string
  dataKey?: string
  footer?: JSX.Element
  header?: JSX.Element
  height?: number
  loading?: boolean
  padding?: any
  type?: string
  minXAxis?: string
  maxXAxis?: string
  range?: any[]
  cardPadding?: string
  showXAxis?: boolean
  showYAxis?: boolean
  direction?: string
  label?: boolean
  bgColor?: string
}

const BarChartCard: React.FC<IProps> = ({
  barColor = CHART_COLORS[0],
  colors,
  data = [],
  dataIndexBy,
  dataKey,
  header,
  height = 100,
  loading,
  footer,
  padding = [10, 10, 24, 70],
  type = 'Interval',
  minXAxis,
  maxXAxis,
  range,
  cardPadding = '20',
  showXAxis = true,
  showYAxis = true,
  direction = 'top',
  label = false,
  bgColor = '#FFF'
}: IProps) => {
  const scale = {
    [dataKey]: {
      min: 0
    },
    [dataIndexBy]: {
      min: minXAxis ?? null,
      max: maxXAxis ?? null,
      [range && 'range']: range
    }
  }

  return (
    <Card height="100%" m="0" p={cardPadding}>
      <Skeleton loading={loading}>
        {header}
        {data && data.length > 0 ? (
          <>
            <Chart
              scale={scale}
              height={height}
              data={data}
              padding={padding}
              style={{
                background: bgColor
              }}
              forceFit
            >
              {direction === 'right' && <Coord transpose />}
              <Tooltip />
              <Axis name={dataIndexBy} visible={showXAxis} key={dataIndexBy} />
              <Axis
                name={dataKey}
                key={dataKey}
                visible={showYAxis}
                label={
                  showYAxis && {
                    offset: 30
                  }
                }
              />
              {type === 'Interval' && (
                <Geom
                  type="interval"
                  position={`${dataIndexBy}*${dataKey}`}
                  color={colors || barColor}
                  tooltip={[
                    `${dataIndexBy}*${dataKey}`,
                    (name, value) => {
                      return {
                        title: name,
                        name: beautyTitle(dataKey),
                        value: value !== undefined ? value.toLocaleString() : ''
                      }
                    }
                  ]}
                >
                  {label && <Label content={dataKey} offset={5} />}
                </Geom>
              )}
              {type.includes('Area') && (
                <Geom
                  type="area"
                  color={colors || barColor}
                  position={`${dataIndexBy}*${dataKey}`}
                  tooltip={
                    type.includes('Line')
                      ? false
                      : [
                          `${dataIndexBy}*${dataKey}`,
                          (name, value) => {
                            return {
                              title: name,
                              name: beautyTitle(dataKey),
                              value:
                                value !== undefined
                                  ? value.toLocaleString()
                                  : ''
                            }
                          }
                        ]
                  }
                />
              )}
              {type.includes('Line') && (
                <Geom
                  type="line"
                  color={colors || barColor}
                  position={`${dataIndexBy}*${dataKey}`}
                  tooltip={[
                    `${dataIndexBy}*${dataKey}`,
                    (name, value) => {
                      return {
                        title: name,
                        name: beautyTitle(dataKey),
                        value: value !== undefined ? value.toLocaleString() : ''
                      }
                    }
                  ]}
                />
              )}
            </Chart>
            {footer && (
              <>
                <Divider />
                {footer}
              </>
            )}
          </>
        ) : (
          <Box>
            <EmptyInsightsContent />
          </Box>
        )}
      </Skeleton>
    </Card>
  )
}

export default BarChartCard
