import React from 'react'

import { Form } from 'antd'

type IProps = {
  children?: React.ReactNode
  style?: Record<string, any>
  label?: any
}

const FormItem: React.FC<IProps> = ({ children, ...props }) => {
  return <Form.Item {...props}>{children}</Form.Item>
}

export default FormItem
