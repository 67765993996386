import React, { useEffect, useState } from 'react'

import { StarFilled, StarOutlined } from '@ant-design/icons'
import css from '@emotion/css'
import BackButton from '@src/components/BackButton'
import { FWModal } from '@src/components/FWModal/FWModal'
import { usePixelAmpTracking, useWindowSize } from '@src/hooks'
import theme from '@src/styles/theme'
import { TRACKING_EVENTS } from '@src/utils/tracking'
import { Input, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { FWButton, Title } from '..'
import { Box, Flex } from '../EmotionLayout'
import { ModalCloseIcon } from '../ModalCloseIcon'
import FWLogoBlack from './fw_logo_black.svg'

export type ReviewModalV2Props = {
  open: boolean
  onClose: () => void
}

const starData = [
  { filled: 5, label: 'I love it!' },
  { filled: 4, label: 'I like it' },
  { filled: 3, label: "It's good" },
  { filled: 2, label: "I don't like it" }
]

export const ReviewModalV2: React.FC<ReviewModalV2Props> = ({
  open,
  onClose
}) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(open)
  const { pixelAmpTracking } = usePixelAmpTracking()
  const [rating, setRating] = useState(0)
  const [feedbackStep, setFeedbackStep] = useState<
    'rating' | 'feedback' | 'thanks'
  >('rating')
  const [feedback, setFeedback] = useState('')
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  useWindowSize((windowSize) => setWindowHeight(windowSize.innerHeight))

  useEffect(() => {
    if (open) {
      pixelAmpTracking(
        TRACKING_EVENTS.REVIEW_MODAL_EVENTS.BUSINESS_VIEW_REVIEW_MODAL
      )
    }
  }, [open, pixelAmpTracking])

  const handleSubmitFeedback = () => {
    pixelAmpTracking(
      TRACKING_EVENTS.REVIEW_MODAL_EVENTS.BUSINESS_SUBMIT_FEEDBACK,
      {
        rating,
        feedback: feedback
      }
    )
    setFeedbackStep('thanks')
  }
  const handleRatingClick = (value: number) => {
    setRating(value)
    if (value <= 4) {
      setFeedbackStep('feedback')
    } else {
      pixelAmpTracking(
        TRACKING_EVENTS.REVIEW_MODAL_EVENTS
          .BUSINESS_REDIRECT_TO_SHOPIFY_FEEDBACK
      )
      setShowModal(false)
      onClose?.()
      window.open(
        'https://apps.shopify.com/firework?reveal_new_review=true',
        '_blank'
      )
    }
  }
  const handleClose = () => {
    setShowModal(false)
    onClose?.()
    pixelAmpTracking(
      TRACKING_EVENTS.REVIEW_MODAL_EVENTS.BUSINESS_CLOSE_REVIEW_MODAL
    )
  }
  const handleBack = () => {
    setFeedbackStep('rating')
  }

  const Rating = ({ stars }) => {
    const [hoverRating, setHoverRating] = useState(false)
    const isSelected = rating === stars.filled

    return (
      <Flex
        borderRadius="8px"
        padding="8px 12px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={12}
        css={css`
          background-color: ${hoverRating || isSelected
            ? '#E5F0FF'
            : '#f0f2f5'};
          width: 184px;
          min-height: 100px;
        `}
        onClick={() => handleRatingClick(stars.filled)}
        onMouseEnter={() => setHoverRating(true)}
        onMouseLeave={() => setHoverRating(false)}
        cursor="pointer"
      >
        <Flex width="100%" justifyContent="space-evenly">
          {Array.from({ length: 5 }).map((_, index) => {
            if (index + 1 <= stars.filled) {
              return (
                <StarFilled
                  key={index}
                  style={{ fontSize: '2rem', color: theme.primary }}
                />
              )
            }

            return (
              <StarOutlined
                key={index}
                style={{
                  fontSize: '2rem',
                  color: theme.primary,
                  cursor: 'pointer'
                }}
              />
            )
          })}
        </Flex>
        <Typography.Text style={{ fontWeight: 600, fontSize: 24 }}>
          {stars.label}
        </Typography.Text>
      </Flex>
    )
  }

  return (
    <FWModal
      open={showModal}
      onCancel={handleClose}
      width="100%"
      footer={null}
      closeIcon={<ModalCloseIcon onClick={handleClose} />}
      css={css`
        min-height: ${windowHeight}px;
        width: 100vw;
        max-width: 100vw;
        top: 0;
        padding: 0;
        margin: 0;
        border-radius: 0;
        .ant-modal-content {
          border-radius: 0 !important;
          min-height: ${windowHeight}px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .ant-modal-body {
          border-radius: 0 !important;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 80px;
          width: 600px;
          height: 430px;
          padding: 0;
        }
        .ant-modal-close {
          margin-right: 16px;
          margin-top: 16px;
        }
      `}
    >
      {feedbackStep === 'rating' && (
        <>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            alignItems="flex-start"
            minHeight="100%"
            position="relative"
          >
            <Flex flexDirection="column" gap={12}>
              <Title fontSize={32} weight={600} noMargin>
                {t('How are you liking Firework?')}
              </Title>
              <Typography.Text style={{ fontWeight: 500, fontSize: 18 }}>
                {t(
                  'Your feedback is very important to us, and directly impacts future updates.'
                )}
              </Typography.Text>
            </Flex>
            <Box
              as="img"
              src={FWLogoBlack}
              alt="FW Logo"
              width={144}
              style={{ position: 'absolute', bottom: '0' }}
            />
          </Flex>
          <Flex flexDirection="column" gap={20}>
            {starData.map((star, idx) => {
              return <Rating stars={star} key={idx} />
            })}
          </Flex>
        </>
      )}
      {feedbackStep === 'feedback' && (
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          height="100%"
          width="100%"
          position="relative"
        >
          <Flex position="absolute" top="-50px">
            <BackButton
              text={t('Change rating')}
              fontSize="16px"
              cb={handleBack}
            />
          </Flex>
          <Flex flexDirection="column" gap={12} height="100%" width="100%">
            <Title fontSize={32} weight={600} noMargin>
              {t('What can we do better?')}
            </Title>
            <Typography.Text style={{ fontWeight: 500, fontSize: 18 }}>
              {t('Please let us know how to earn your 5-star review.')}
            </Typography.Text>
            <Box width="100%" mt="22">
              <Input.TextArea
                rows={4}
                style={{ resize: 'none', borderRadius: 4 }}
                onBlur={(e) => setFeedback(e.target.value)}
              />
            </Box>
            <Flex
              justifyContent="flex-end"
              css={css`
                margin-top: 16px;
              `}
            >
              <FWButton
                type="primary"
                onClick={handleSubmitFeedback}
                css={css`
                  align-self: right;
                `}
              >
                {t('Submit')}
              </FWButton>
            </Flex>
          </Flex>
          <Box
            as="img"
            src={FWLogoBlack}
            alt="FW Logo"
            mb="42"
            width={144}
            style={{ position: 'absolute', bottom: '20px' }}
          />
        </Flex>
      )}
      {feedbackStep === 'thanks' && (
        <Flex
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          height="100%"
          width="100%"
          position="relative"
        >
          {' '}
          <Title fontSize={32} weight={600} noMargin>
            {t('Thank you for your feedback!')}
          </Title>
          <Box
            as="img"
            src={FWLogoBlack}
            alt="FW Logo"
            mb="42"
            width={144}
            style={{ position: 'absolute', bottom: '20px' }}
          />
        </Flex>
      )}
    </FWModal>
  )
}
export default ReviewModalV2
