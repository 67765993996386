import React from 'react'

import { minutesToHours } from '@src/utils/format'
import { Flex, Text } from 'fwego'
import { useTranslation } from 'react-i18next'

import { sumLastXFields, getWeekOverWeekStr } from '../utils/stats'

type IProps = {
  data: any[]
  dataKey?: string
  dataType?: string
  numDays?: number
}

const combineDataForTimeWatched = (data, dateKey, dataKey) => {
  const days = {}
  data.forEach((day) => {
    if (!days[day[dateKey]]) days[day[dateKey]] = day[dataKey]
    else days[day[dateKey]] += day[dataKey]
  })

  return Object.keys(days).map((key) => ({
    [dateKey]: key,
    [dataKey]: days[key]
  }))
}
const LastXDaysStat: React.FC<IProps> = ({
  data = [],
  dataKey,
  numDays = 7,
  dataType
}) => {
  const { t } = useTranslation()
  const combinedData = combineDataForTimeWatched(data, 'report_date', dataKey)
  let lastXDays: string | number = sumLastXFields(
    combinedData,
    dataKey,
    numDays
  )
  const weekOverWeek = getWeekOverWeekStr(combinedData, dataKey)

  if (dataType === 'minutes') lastXDays = minutesToHours(lastXDays)

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="row">
        <Text flex={1} fontSize="14" color="#999">
          {t('Last {{numDays}} Days', { numDays })}
        </Text>
        <Text color="#777" fontWeight="bold">
          {lastXDays.toLocaleString()}
        </Text>
      </Flex>
      <Flex flexDirection="row">
        <Text flex={1} fontSize="14" color="#999">
          {t('Week Over Week')}
        </Text>
        <Text color="#777" fontWeight="bold">
          {weekOverWeek}
        </Text>
      </Flex>
    </Flex>
  )
}

export default LastXDaysStat
