import React from 'react'

import { css } from '@emotion/core'
import { Table as AntdTable, TableProps } from 'antd'

type IProps = {
  className?: string
  clickable?: boolean
} & TableProps<any>

const Table: React.FC<IProps> = ({ clickable, className, ...props }) => {
  return (
    <AntdTable
      css={css`
        ${clickable &&
        css`
          .ant-table-cell {
            cursor: pointer;
          }
        `}
        ${className}
      `}
      {...props}
    />
  )
}

export default Table
