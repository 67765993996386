import React from 'react'

import { css } from '@emotion/core'
import { Box, Flex } from '@src/components/EmotionLayout'
import { useNabooFeatureFlag } from '@src/hooks/useFeatureFlag'
import { isLgScreen } from '@src/hooks/useMediaQuery'
import theme from '@src/styles/theme'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { FWButton, Label } from '..'
import CardDisplay from './CardDisplay'
import ColorDisplay from './ColorDisplay'
import CornerDisplay from './CornerDisplay'

interface Props {
  config: globalLib.Channel['config']
  openModal?: () => void
  canEdit: boolean
}

const AppearanceDefault: React.FC<Props> = ({
  config,
  canEdit,
  openModal
}): JSX.Element => {
  const { t } = useTranslation()
  const { Text } = Typography
  const inline = isLgScreen()

  const nff = useNabooFeatureFlag()
  const withFont = nff.cms_branding_settings_font
  const withTheme = nff.cms_branding_settings_theme

  const isFullSize = typeof config?.ui_full_cta !== 'boolean'
  const themeStyle =
    { light: t('Light'), dark: t('Dark') }[config?.ui_theme] || t('Default')
  const ctaSize = isFullSize ? t('Large') : t('Small')
  const borderStyle = config?.ui_border_style ? t('Hard') : t('Round')
  const buttonFontColor = config?.ui_button_font_color ? t('Black') : t('White')
  const buttonColor = config?.ui_button_color || theme.primary
  const fontFamily = config?.ui_font || 'Avenir Next'

  return (
    <>
      <Flex justifyContent="space-between" flexDirection={!inline && 'column'}>
        <Box minWidth="260px">
          {withTheme && (
            <Box mb="medium">
              <Label>{t('Display theme')}</Label>
              {themeStyle}
            </Box>
          )}
          {withFont && (
            <Box mb="medium">
              <Label>{t('Font style')}</Label>
              {fontFamily}
            </Box>
          )}
          <Box mb="medium">
            <Label>{t('Primary color')}</Label>
            <Flex>
              <ColorDisplay selectedColor={buttonColor} label={buttonColor} />
            </Flex>
          </Box>
          <Box mb="medium">
            <Label>{t('Button font color')}</Label>
            <Flex>
              <ColorDisplay
                label={buttonFontColor}
                selectedColor={
                  config?.ui_button_font_color ? '#404040' : '#FFFFFF'
                }
              />
            </Flex>
          </Box>
          <Box mb="medium">
            <Label>{t('Border corner style')}</Label>
            <Box textAlign="center" display="inline-block">
              <CornerDisplay hard={config?.ui_border_style} />
              <Text>{borderStyle}</Text>
            </Box>
          </Box>
        </Box>
        <CardDisplay
          buttonFontColor={config?.ui_button_font_color}
          borderStyle={config?.ui_border_style}
          buttonColor={config?.ui_button_color}
          fontFamily={config?.ui_font}
          isFullSize={isFullSize}
          label={t('Your Button')}
        >
          <Box mb="medium">
            <Label>{t('CTA size')}</Label>
            <Box fontWeight="600"> {ctaSize}</Box>
          </Box>
        </CardDisplay>
      </Flex>
      {canEdit && (
        <Flex position="absolute" bottom="0" right="0" margin="32px">
          <FWButton
            css={css`
              font-weight: 400;
            `}
            type="primary"
            onClick={openModal}
          >
            {t('Edit')}
          </FWButton>
        </Flex>
      )}
    </>
  )
}

export default AppearanceDefault
