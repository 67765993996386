import { localStorageProvider } from '@src/utils/storageProvider'

export const GOOGLE_URL = 'https://www.googleapis.com/drive/v3'

export const GOOGLE_CLIENT_KEYS = {
  production:
    '831269275373-mb3j68p9clrmjs7hdi6gm7turekgo5qh.apps.googleusercontent.com',
  staging:
    '562995330156-sctqb2kkee9jpinm46ag7r70cr1qn2l2.apps.googleusercontent.com',
  localhost:
    '562995330156-sctqb2kkee9jpinm46ag7r70cr1qn2l2.apps.googleusercontent.com'
}

export enum LoginType {
  GoogleDrive = 'google_drive',
  YouTube = 'youtube'
}

export const GOOGLE_CLIENT_KEY =
  GOOGLE_CLIENT_KEYS[process.env.REACT_APP_ENV] ||
  (process.env.NODE_ENV === 'production'
    ? GOOGLE_CLIENT_KEYS['production']
    : GOOGLE_CLIENT_KEYS['staging'])

export type GoogleToken = {
  accessToken?: string
  idToken?: string
  expiresIn?: number
  refreshToken?: string
  username?: string
  avatar?: string
  scope?: string
  playlistId?: string
  channelId?: string
}

const getGoogleRedirectUri = (): string => {
  const url = window.location.origin

  return url + '/google/oauth'
}

const openGoogleWindow = (
  state: {
    containerId: string
    loginType: LoginType
  },
  scope = 'openid email profile https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/youtube.readonly'
): void => {
  const w = window.screen.width / 3
  const h = window.screen.height / 2
  const l = window.screen.width / 2 - w / 2
  const t = window.screen.height / 2 - h / 2

  const redirectUri = getGoogleRedirectUri()
  let clientId = GOOGLE_CLIENT_KEY
  if (redirectUri.indexOf('localhost') !== -1) {
    clientId = GOOGLE_CLIENT_KEYS.localhost
  }

  const url =
    `https://accounts.google.com/o/oauth2/v2/auth?` +
    `scope=${encodeURIComponent(scope)}&` +
    `include_granted_scopes=true&` +
    `access_type=offline&` +
    `response_type=code&` +
    `state=${encodeURIComponent(JSON.stringify(state))}&` +
    `redirect_uri=${encodeURIComponent(redirectUri)}&` +
    `client_id=${clientId}`

  window.open(
    url,
    'FwGoogleAuth',
    'resizable,scrollbars,width=' +
      w +
      ',height=' +
      h +
      ',left=' +
      l +
      ',top=' +
      t
  )
}

const saveGoogleToken = (userId: string, googleToken: GoogleToken): void => {
  localStorageProvider.setItem(
    `google_token_${userId}`,
    JSON.stringify(googleToken)
  )
}

const getGoogleToken = (userId: string): GoogleToken => {
  const token = JSON.parse(
    localStorageProvider.getItem(`google_token_${userId}`) || '{}'
  ) as GoogleToken

  return token
}

const isGoogleTokenExpired = (googleToken: GoogleToken): boolean => {
  if (!googleToken || !googleToken.accessToken || !googleToken.expiresIn) {
    return true
  }
  const expiresIn = googleToken.expiresIn
  const date = new Date()

  return expiresIn <= date.getTime() + 10 * 60 * 1000
}

export const getDownloadUrl = (videoId: string): string => {
  return `https://www.googleapis.com/drive/v3/files/${videoId}?alt=media`
}

export const downloadGoogleFileToBlob = async (
  videoId: string,
  token: string
): Promise<Blob> => {
  const url = getDownloadUrl(videoId)
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` }
  })

  return await response.blob()
}

export {
  getGoogleRedirectUri,
  openGoogleWindow,
  saveGoogleToken,
  getGoogleToken,
  isGoogleTokenExpired
}
