import { createAction, createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'

import api from '../utils/api'

type IProps = {
  embedCode: any
  channelButtonCode: {
    webcomponent: string
  }
  offlineEmbedCode: any
  embedType: string
}

const initialState: IProps = {
  embedCode: { webcomponent: '', js: '', js_react: '', amp: '' },
  channelButtonCode: { webcomponent: '' },
  offlineEmbedCode: {},
  embedType: ''
}

const slice = createSlice({
  name: 'embed',
  initialState: initialState,
  reducers: {
    fetchEmbedCodeSuccess(state, action) {
      const { embedCode } = action.payload
      state.embedCode = embedCode
    },
    fetchChannelButtonCodeSuccess(state, action) {
      const { channelButtonCode } = action.payload
      state.channelButtonCode = channelButtonCode
    },
    getOfflineEmbedCodeSuccess(state, action) {
      const { tab, code } = action.payload
      state.offlineEmbedCode[tab] = code
    },
    setEmbedTypeSuccess(state, action) {
      const { type } = action.payload
      state.embedType = type
    }
  }
})
export default slice.reducer

export const {
  fetchEmbedCodeSuccess,
  fetchChannelButtonCodeSuccess,
  getOfflineEmbedCodeSuccess,
  setEmbedTypeSuccess
} = slice.actions
const fetchEmbedCodeRequest = createAction('embed/fetchEmbedCodeRequest')
const fetchEmbedCodeFailure = createAction('embed/fetchEmbedCodeFailure')
const fetchChannelButtonCodeRequest = createAction(
  'embed/fetchChannelButtonCodeRequest'
)
const fetchChannelButtonCodeFailure = createAction(
  'embed/fetchChannelButtonCodeFailure'
)

export function fetchEmbedCode(
  businessId: string,
  feedId: string,
  embedParams: Record<string, any>
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchEmbedCodeRequest())

      // Sanitize embed params
      const params = Object.assign(
        {},
        {
          mode: embedParams.mode,
          max_videos: embedParams.maxVideos,
          placement: embedParams.placement
        }
      )

      const res = await api.get(
        `/bus/${businessId}/feeds/${feedId}/embed_code`,
        { params }
      )
      dispatch(fetchEmbedCodeSuccess({ embedCode: res.data }))

      return res
    } catch (error) {
      dispatch(fetchEmbedCodeFailure())

      return error
    }
  }
}

export function fetchChannelButtonCode(
  businessId: string,
  channelId: string,
  params: Record<string, any>
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelButtonCodeRequest())

      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/channel_button_code`,
        { params }
      )
      dispatch(fetchChannelButtonCodeSuccess({ channelButtonCode: res.data }))

      return res
    } catch (error) {
      dispatch(fetchChannelButtonCodeFailure())

      return error
    }
  }
}

export function getOfflineEmbedCode(tab: string, code: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    dispatch(getOfflineEmbedCodeSuccess({ tab, code }))
  }
}

export function setEmbedType(type: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    dispatch(setEmbedTypeSuccess({ type }))
  }
}
