import moment from 'moment'

export function isScheduledVideo(video: globalLib.IVideo): boolean {
  return (
    moment(video?.inserted_at).format() !==
      moment(video?.published_at).format() &&
    moment(video?.published_at).format() > moment().format()
  )
}

export const replaceVideosById = (
  originalVideos?: globalLib.IVideo[],
  videos?: globalLib.IVideo[]
): globalLib.IVideo[] => {
  return originalVideos?.map((item) => {
    const foundItem = videos?.find(
      (video) => video.encoded_id === item.encoded_id
    )

    return foundItem ? foundItem : item
  })
}

/**
 * Get video duration from a file by creating a video element on the fly.
 * @param file
 * @returns
 */
export const getVideoDuration = (
  file: Parameters<typeof window.URL.createObjectURL>[0]
): Promise<number> => {
  return new Promise((resolve) => {
    const videoElement = document.createElement('video')
    videoElement.preload = 'metadata'

    videoElement.onloadedmetadata = function () {
      window.URL.revokeObjectURL(videoElement.src)
      resolve(videoElement.duration)
    }

    videoElement.addEventListener(
      'error',
      () => {
        resolve(0)
      },
      true
    )

    videoElement.src = window.URL.createObjectURL(file)
  })
}

/**
 * Get video width and height from a file by creating a video element on the fly.
 * @param file
 * @returns
 */
export const getVideoWH = (
  file: Parameters<typeof window.URL.createObjectURL>[0]
): Promise<{ width: number; height: number } | undefined> => {
  return new Promise((resolve) => {
    const videoElement = document.createElement('video')
    videoElement.preload = 'metadata'

    videoElement.onloadedmetadata = () => {
      window.URL.revokeObjectURL(videoElement.src)
      resolve({
        width: videoElement.videoWidth,
        height: videoElement.videoHeight
      })
    }
    videoElement.onerror = () => {
      resolve(undefined)
    }

    videoElement.src = window.URL.createObjectURL(file)
  })
}
