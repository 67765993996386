import React, { useContext, useLayoutEffect } from 'react'

import { useAppSelector, useAppDispatch } from '@src/app/hooks'
import { BusinessContext } from '@src/contexts/BusinessContext'
import { useNabooFeatureFlag } from '@src/hooks'
import { useBusinessSubscription } from '@src/hooks/useBusinessSubscription'
import { useFeatureEnablement } from '@src/hooks/useFeatureEnablement'
import { useHandleOpenCommandPalette } from '@src/hooks/useHandleOpenCommandPallet'
import { setCmdBarIsClosed, setCmdBarIsOpen } from '@src/redux/ui'
import CommandPalette, {
  filterItems,
  JsonStructureItem,
  IconName,
  getItemIndex
} from 'react-cmdk'
import { useNavigate, useLocation } from 'react-router-dom'

enum CommandPalettePage {
  ROOT = 'root'
}

export const CommandBar: React.FC = () => {
  const dispatch = useAppDispatch()
  const businessId = useContext(BusinessContext)
  const { pathname } = useLocation()
  const { hasLivestream } = useBusinessSubscription(businessId)
  const navigate = useNavigate()
  const [search, setSearch] = React.useState('')
  const [page] = React.useState<CommandPalettePage>(CommandPalettePage.ROOT)

  const nff = useNabooFeatureFlag()
  const featureEnablement = useFeatureEnablement(businessId)
  const hasWebhooks = nff.cms_business_webhooks
  const hasShowrooms =
    featureEnablement.digital_showrooms && nff.cms_showroom_entrance

  const channelId = /^\/business/.test(pathname) && pathname.split('/')[4]

  const isOpen = useAppSelector((state) => state.ui.cmdBarIsOpen)

  useHandleOpenCommandPalette()

  const createItem = (
    id: string,
    children: string,
    icon: React.FC | IconName,
    path: string
  ) => ({
    id,
    children,
    icon,
    showType: false,
    closeOnSelect: true,
    onClick: () => {
      clearResults()
      navigate(path)
    }
  })

  const baseNavigationItems = [
    createItem('pages.home', 'Home', 'HomeIcon', '/'),
    createItem(
      'pages.teamMembers',
      'Team Members',
      'UserGroupIcon',
      `/business/${businessId}/settings/team-members`
    ),
    createItem(
      'pages.channelSearch',
      'Channel Search',
      'MagnifyingGlassIcon',
      `/business/${businessId}/channels`
    ),
    createItem(
      'pages.storeSearch',
      'Store Search',
      'MagnifyingGlassIcon',
      `/business/${businessId}/business_store_list`
    ),
    createItem(
      'pages.businessSearch',
      'Business Search',
      'MagnifyingGlassIcon',
      `/businesses`
    )
  ]

  const NavigationItems: JsonStructureItem[] = [...baseNavigationItems]

  const commandItems = [
    { heading: 'Navigation', id: 'pages', items: NavigationItems }
  ]

  if (channelId) {
    if (featureEnablement.short_videos) {
      NavigationItems.push(
        createItem(
          'pages.uploadVideo',
          'Upload Video',
          'ArrowUpTrayIcon',
          `/business/${businessId}/channel/${channelId}/video/create`
        )
      )
    }

    if (hasLivestream) {
      NavigationItems.push(
        createItem(
          'pages.createLivestream',
          'Create Livestream',
          'ArrowUpTrayIcon',
          `/business/${businessId}/channel/${channelId}/livestream/create`
        )
      )
    }

    commandItems.push({
      heading: 'Automation',
      id: 'automator',
      items: [
        createItem(
          'automator.manage',
          'Manage Automations',
          'BoltIcon',
          `/business/${businessId}/channel/${channelId}/automation`
        ),
        createItem(
          'automator.creates',
          'Create Automation',
          'BoltIcon',
          `/business/${businessId}/channel/${channelId}/automation/create`
        )
      ]
    })
  }

  if (hasWebhooks) {
    commandItems.push({
      heading: 'Webhooks',
      id: 'webhooks',
      items: [
        createItem(
          'webhooks.manage',
          'Manage Webhooks',
          'LinkIcon',
          `/business/${businessId}/webhooksv2`
        ),
        createItem(
          'webhooks.creates',
          'Create Webhooks',
          'LinkIcon',
          `/business/${businessId}/webhooksv2/create`
        )
      ]
    })
  }

  if (hasShowrooms) {
    commandItems.push({
      heading: 'Digital Showrooms',
      id: 'showrooms',
      items: [
        createItem(
          'showrooms.manage',
          'Manage Showrooms',
          'VideoCameraIcon',
          `/business/${businessId}/showrooms`
        ),
        createItem(
          'showrooms.creates',
          'Create Showrooms',
          'VideoCameraIcon',
          `/business/${businessId}/showrooms/create`
        )
      ]
    })
  }

  const clearResults = () => {
    dispatch(setCmdBarIsClosed())
    setSearch('')
  }

  const rootItems = filterItems(commandItems, search)

  useLayoutEffect(() => {
    const observerCallback = (mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const inputElement = document.getElementById(
            'command-palette-search-input'
          ) as HTMLInputElement | null
          if (inputElement) {
            inputElement.autocomplete = 'off'
            observer.disconnect()
          }
        }
      }
    }

    const observer = new MutationObserver(observerCallback)
    const config = { childList: true, subtree: true }
    const targetNode = document.body
    observer.observe(targetNode, config)

    return () => {
      observer.disconnect()
    }
  }, [])

  const handleOnChangeOpen = () => {
    if (!isOpen) {
      dispatch(setCmdBarIsOpen())
    } else {
      dispatch(setCmdBarIsClosed())
    }
  }

  return (
    <>
      <CommandPalette
        search={search}
        isOpen={isOpen}
        onChangeOpen={handleOnChangeOpen}
        onChangeSearch={setSearch}
        page={page}
      >
        <CommandPalette.Page id={CommandPalettePage.ROOT}>
          {rootItems.length
            ? rootItems.map((list) => (
                <CommandPalette.List key={list.id} heading={list.heading}>
                  {list.items.map(({ id, ...rest }) => (
                    <CommandPalette.ListItem
                      key={id}
                      index={getItemIndex(rootItems, id)}
                      closeOnSelect={true}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          dispatch(setCmdBarIsClosed())
                        }
                      }}
                      {...rest}
                    />
                  ))}
                </CommandPalette.List>
              ))
            : null}
        </CommandPalette.Page>
      </CommandPalette>
    </>
  )
}
