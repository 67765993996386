import { localStorageProvider } from '@src/utils/storageProvider'

export const IG_URL = 'https://graph.instagram.com'

const igClientId = '1375261269343398'

const igImporterUrls = {
  production: 'https://ig-importer.firework-prod.com',
  staging: 'https://ig-importer.firework-staging.com'
}

export const IG_IMPORTER_URL =
  igImporterUrls[process.env.REACT_APP_ENV] ||
  (process.env.NODE_ENV === 'production'
    ? igImporterUrls['production']
    : igImporterUrls['staging'])

export type IgToken = {
  accessToken?: string
  expiresIn?: number
  id?: string
  username?: string
}

const getRedirectUri = (): string => {
  const url = window.location.href
  const urlStr = url.split('/')

  const domain = urlStr[0] + '//' + urlStr[2]

  return domain + '/instagram/auth'
}

export type IgAuthState =
  | 'multi_ig_importer'
  | 'video_upload'
  | 'livestream_trailer'
  | 'poster'

const openIgAuthWindow = (
  state: IgAuthState | string,
  scope = 'user_profile,user_media'
): void => {
  const w = window.screen.width / 3
  const h = window.screen.height / 2
  const l = window.screen.width / 2 - w / 2
  const t = window.screen.height / 2 - h / 2

  const url =
    'https://www.instagram.com/oauth/authorize/' +
    '?client_id=' +
    igClientId +
    '&response_type=code' +
    '&scope=' +
    scope +
    '&state=' +
    state +
    '&redirect_uri=' +
    encodeURIComponent(getRedirectUri())

  window.open(
    url,
    'FwInstagramAuth',
    'resizable,scrollbars,width=' +
      w +
      ',height=' +
      h +
      ',left=' +
      l +
      ',top=' +
      t
  )
}

const saveIgToken = (userId: string, igToken: IgToken): void => {
  localStorageProvider.setItem(`ig_token_${userId}`, JSON.stringify(igToken))
}

const getIgToken = (userId: string): IgToken => {
  if (!userId) {
    return {}
  }

  return JSON.parse(localStorageProvider.getItem(`ig_token_${userId}`) || '{}')
}

const isIgTokenExpired = (igToken: IgToken): boolean => {
  if (!igToken || !igToken.accessToken || !igToken.expiresIn) {
    return true
  }
  const expiresIn = igToken.expiresIn
  const date = new Date()

  return expiresIn <= date.getTime() + 10 * 60 * 1000
}

export {
  igClientId,
  getRedirectUri,
  openIgAuthWindow,
  saveIgToken,
  getIgToken,
  isIgTokenExpired
}
