import { createAction, createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'

import api from '../utils/api'
import { removeUndefined } from '../utils/sanitize'

type IProps = {
  hashtags: Record<string, any>
  searchedHashtags: Record<string, any>
  searchedHashtagsPaging: Record<string, any>
}

const initialState: IProps = {
  hashtags: {},
  searchedHashtags: {},
  searchedHashtagsPaging: {}
}

const slice = createSlice({
  name: 'hashtag',
  initialState: initialState,
  reducers: {
    searchHashtagsSuccess(state, action) {
      const { businessId, hashtags, paging, page } = action.payload

      hashtags.forEach((hashtag) => {
        state.hashtags[hashtag.name] = hashtag
      })
      state.searchedHashtagsPaging[businessId] = paging

      const hashtagNames = hashtags.reduce(
        (acc, hashtag) => [...acc, hashtag.name],
        []
      )
      if (page) {
        state.searchedHashtags[businessId] = [
          ...state.searchedHashtags[businessId],
          hashtagNames
        ]
      } else {
        state.searchedHashtags[businessId] = [...hashtagNames]
      }
    }
  }
})

export default slice.reducer

export const { searchHashtagsSuccess } = slice.actions

const searchHashtagsRequest = createAction('hashtag/searchHashtagsRequest')
const searchHashtagFailure = createAction('hashtag/searchHashtagFailure')

export function searchHashtags(businessId: string, q?: string, page?: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(searchHashtagsRequest())

      const params = removeUndefined({ q })
      const response = await api.get(page || `/hashtags?page_size=30`, {
        params
      })
      const { hashtags, paging } = response.data

      dispatch(searchHashtagsSuccess({ businessId, hashtags, paging, page }))

      return response
    } catch (error) {
      dispatch(searchHashtagFailure())

      return error
    }
  }
}
