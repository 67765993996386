import { useMemo } from 'react'

export const useInteractionIcon = (video: globalLib.IVideo): string => {
  return useMemo(() => {
    if (video.products.length) return '/icon/shopping_v2.svg'
    if (video.action_url) return '/icon/cta_v2.svg'
    if (video?.interactions?.length) {
      const type = video.interactions[0].interaction_type
      if (type === 'question') return '/icon/question_v2.svg'
      if (type === 'poll') return '/icon/poll_v2.svg'
    }
  }, [video])
}
