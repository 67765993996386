import React, { useState, useEffect, useRef } from 'react'

import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons'
import { FWButton } from '@src/components'
import { Flex, Box } from '@src/components/EmotionLayout'
import { ModalFooter } from '@src/components/ModalFooter'
import {
  AnimatedPosterPayload,
  PosterPayload
} from '@src/components/Poster/FormPoster'
import { useToast } from '@src/hooks/useToast'
import { useVUContext } from '@src/pages/channel/ChannelVideoUpload/components/VUContext'
import theme from '@src/styles/theme'
import { Tooltip, Button, Slider, Modal, Typography, Upload } from 'antd'
import { Text } from 'fwego'
import throttle from 'lodash/throttle'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'

import PosterPreview from './PosterPreview'

interface CustomStaticPosterProps {
  enableHorizontalPoster?: boolean // feature flag
  imgUrl: string
  isWide: boolean
  video: any
  setNewPosterPayload: (payload: AnimatedPosterPayload) => void
  setChangedPosters: (value: boolean) => void
  posterProgress: number
  removePoster: () => void
  posterUploading
  onDiscardChanges?: () => void
  onSuccess?: (response: any, file: any, xhr: any) => void
  size: number
}

const getRatio = (value) => {
  const ratios = ['9:16', '3:4', '4:5', '1:1', '5:4', '4:3', '16:9']
  const ratios2 = [0.5625, 0.75, 0.8, 1.0, 1.25, 1.3333, 1.7778]
  const index = ratios2.reduce(
    (a, b, i) => Math.abs(b - value < Math.abs(ratios2[a] - value) ? i : a),
    0
  )

  return ratios[index]
}

const throttleSeek = throttle((player, value) => {
  if (player.current && player.current.getInternalPlayer()) {
    player.current.seekTo(value)
  }
}, 300)

const CustomStaticPoster = (props: CustomStaticPosterProps): JSX.Element => {
  const { t } = useTranslation()
  const {
    enableHorizontalPoster,
    isWide,
    imgUrl,
    video,
    setNewPosterPayload,
    setChangedPosters,
    posterProgress,
    removePoster,
    posterUploading,
    onDiscardChanges,
    onSuccess,
    size
  } = props
  const { state } = useVUContext()
  const {
    changedPosters,
    videoUrl,
    newWidePosterPayload,
    newPosterPayload,
    mediaKey
  } = state
  const [showCustomPosterModal, setShowCustomPosterModal] = useState(false)
  const [createdPoster, setCreatedPoster] = useState<boolean>(false)
  const [frameTime, setFrameTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [clickedUpload, setClickedUpload] = useState(false)
  const player = useRef<ReactPlayer>(null)
  const posterPayload = useRef<PosterPayload>()
  const savedPosterBasedOnOriginalVideo = useRef<boolean>(false)
  const width = !isWide ? size : (size * 164) / 99
  const height = !isWide ? (size * 164) / 99 : size
  const videoWidth = player.current?.getInternalPlayer()?.videoWidth
  const [isPlayerReady, setIsPlayerReady] = useState<boolean>(false)
  const { errorToast, infoToast } = useToast()

  const getVideoTime = (value: number) => Math.floor(value * 10) / 10

  useEffect(() => {
    if (newWidePosterPayload || newPosterPayload) {
      posterPayload.current = isWide ? newWidePosterPayload : newPosterPayload
    }
  }, [isWide, newWidePosterPayload, newPosterPayload])

  const onSaveClick = () => {
    if (posterUploading) {
      return
    }

    // @TODO: Wait for backend to define the payload for the animated poster and if we allow 2 types of poster (static and animated)
    const videoRef = player.current?.getInternalPlayer()
    if (!videoRef) {
      return
    }

    if (
      (isWide && videoRef && videoRef.videoWidth <= videoRef.videoHeight) ||
      (!isWide &&
        enableHorizontalPoster &&
        videoRef.videoWidth > videoRef.videoHeight)
    ) {
      errorToast(t('Poster type and thumbnail size does not match.'))

      return
    }

    setChangedPosters(true)
    setNewPosterPayload({
      key: mediaKey || undefined,
      height: videoRef.videoHeight,
      width: videoRef.videoWidth,
      start_time: getVideoTime(frameTime),
      duration: 0,
      format: 'jpg',
      aspect_ratio: getRatio(videoRef.videoWidth / videoRef.videoHeight),
      custom_video: mediaKey && video ? true : undefined
    })
    setCreatedPoster(true)
    setShowCustomPosterModal(false)

    savedPosterBasedOnOriginalVideo.current = true

    infoToast(
      video
        ? t(
            'The static poster preview will be available after you Save and Update.'
          )
        : t(
            'The static poster preview will be available after the video is created.'
          )
    )
  }
  const onCancel = () => {
    setShowCustomPosterModal(false)
  }

  const handleClickUpload = () => {
    setClickedUpload(true)
  }

  const handleDelete = () => {
    removePoster()
    setCreatedPoster(false)
  }

  const handleOnSuccess = (response: any, file: any, xhr: any) => {
    savedPosterBasedOnOriginalVideo.current = false
    if (onSuccess) {
      onSuccess(response, file, xhr)
    }
  }

  const uploadProps = { ...props, onSuccess: handleOnSuccess }

  const title = isWide ? t('Add Horizontal') : t('Add Vertical')

  useEffect(() => {
    if (isPlayerReady) {
      throttleSeek(player, getVideoTime(frameTime))
    }
  }, [frameTime, isPlayerReady])

  useEffect(() => {
    if (!showCustomPosterModal) {
      setIsPlayerReady(false)
    }
  }, [showCustomPosterModal])

  useEffect(() => {
    if (clickedUpload && imgUrl && changedPosters && posterProgress === 100) {
      setShowCustomPosterModal(false)
    }
  }, [clickedUpload, imgUrl, changedPosters, posterProgress])

  useEffect(() => {
    if (savedPosterBasedOnOriginalVideo.current) {
      setCreatedPoster(false)
      setFrameTime(0)
      setClickedUpload(false)
      onDiscardChanges?.()
      savedPosterBasedOnOriginalVideo.current = false
    }
  }, [mediaKey, onDiscardChanges])

  return (
    <>
      <Tooltip
        placement="top"
        title={
          videoUrl
            ? null
            : t(
                'The Posters will become available after you upload your main video.'
              )
        }
      >
        <Flex
          justifyContent="end"
          alignItems="end"
          mt={isWide ? 22 : 0}
          onClick={() =>
            !createdPoster &&
            !imgUrl &&
            videoUrl &&
            setShowCustomPosterModal(true)
          }
          cursor={videoUrl ? 'pointer' : 'default'}
          position="relative"
        >
          {createdPoster ? (
            <Flex
              borderRadius={4}
              width={width}
              height={height}
              overflow="hidden"
            >
              <PosterPreview
                playerUrl={videoUrl}
                startTime={getVideoTime(frameTime)}
                endTime={getVideoTime(frameTime)}
              />
            </Flex>
          ) : (
            <Flex
              justifyContent="center"
              textAlign="center"
              alignItems="center"
              height={height}
              minWidth={width}
              p={10}
              fontSize={12}
              fontWeight={500}
              bg="#fafafa"
              backgroundImage={`url("${imgUrl}")`}
              backgroundSize="cover"
              backgroundPosition="center center"
              borderRadius={4}
              border={imgUrl ? 'none' : '1px dashed #d9d9d9'}
            >
              {!imgUrl && (
                <Box>
                  <Text
                    size="xsmall"
                    style={{ color: videoUrl ? theme.text : '#8c8c8c' }}
                  >
                    {title} <br />
                    {t('Image Poster')}
                  </Text>
                  <PlusCircleFilled
                    style={{
                      color: videoUrl ? theme.primary : '#bfbfbf',
                      fontSize: '24px',
                      marginTop: '4px'
                    }}
                  />
                </Box>
              )}
            </Flex>
          )}
          {(imgUrl || createdPoster) && (
            <>
              <Button
                ghost
                type="dashed"
                style={{
                  position: 'absolute',
                  zIndex: 2,
                  margin: '4px',
                  border: 'none'
                }}
                onClick={handleDelete}
                icon={<DeleteOutlined style={{ fontSize: '16px' }} />}
              />
              <Box
                height={40}
                position="absolute"
                width={width}
                zIndex={1}
                borderRadius="0 0 4px 4px"
                bg="linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))"
              />
            </>
          )}
        </Flex>
      </Tooltip>
      <Modal
        open={showCustomPosterModal}
        maskClosable={false}
        onCancel={onCancel}
        destroyOnClose
        centered
        closable={false}
        footer={null}
        width={500}
        title={t('Custom Poster Selector')}
      >
        <Flex flexDirection="column" alignItems="center" width="100%">
          <Box mb="medium">
            <Typography.Paragraph>
              {t(
                'Use the scrubber below to select which part of the video you' +
                  ' want to use for your static poster.'
              )}
            </Typography.Paragraph>
          </Box>
          <Flex justifyContent="center" maxWidth="300px" maxHeight="400">
            <ReactPlayer
              ref={player}
              url={videoUrl}
              muted
              width={(videoWidth ?? 0) / 2 || undefined}
              playing={false}
              progressInterval={200}
              controls={true}
              stopOnUnmount={true}
              style={{
                maxWidth: '300px',
                maxHeight: '400px'
              }}
              onDuration={(duration) => {
                setDuration(Math.round(duration * 10) / 10)
              }}
              onReady={() => {
                setIsPlayerReady(true)
              }}
            />
          </Flex>
          <Flex width="100%" my="large">
            <Slider
              included={false}
              tooltip={{
                open: true,
                formatter(value) {
                  return `${value}s`
                }
              }}
              min={0}
              max={duration}
              step={0.1}
              value={frameTime}
              onChange={(value) => {
                setFrameTime(value)
              }}
              marks={{
                0: '0s',
                [duration]: `${duration}s`
              }}
              trackStyle={{
                backgroundColor: '#f5f5f5'
              }}
              handleStyle={{
                backgroundColor: `${theme.primary}`
              }}
              style={{
                width: '100%'
              }}
            />
          </Flex>
        </Flex>
        <Flex width="100%" justifyContent="flex-end">
          <Typography.Text>
            {t(
              'The change will be applied in a few minutes. If not, please try it again.'
            )}
          </Typography.Text>
        </Flex>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <Upload {...uploadProps}>
              <FWButton
                key="upload"
                onClick={handleClickUpload}
                loading={posterUploading}
              >
                {t('Upload')}
              </FWButton>
            </Upload>
            <Flex>
              <FWButton
                key="cancel"
                onClick={onCancel}
                style={{ marginRight: 10 }}
              >
                {t('Cancel')}
              </FWButton>
              <FWButton type="primary" key="save" onClick={onSaveClick}>
                {t('Save')}
              </FWButton>
            </Flex>
          </Flex>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default CustomStaticPoster
