import React, { ReactNode, useState, useRef, useMemo } from 'react'

import loadable from '@loadable/component'
import { useAppSelector } from '@src/app/hooks'
import { CHANNEL_TABS } from '@src/constants'
import { useNabooFeatureFlag } from '@src/hooks'
import { useInteractionIcon } from '@src/hooks/useInteractionIcon'
import { useVideoPoster } from '@src/hooks/useVideoPoster'
import { useParams, useSearchParams } from 'react-router-dom'

const VideoCard = loadable(
  () => import('@src/components/VideoAutoplayer/VideoCard'),
  {
    resolveComponent: (component) => component.VideoCard
  }
)

interface Props {
  height?: string
  video: globalLib.IVideo
  onClick: (e: any) => void
  showMore?: boolean
  onDelete: () => Promise<void>
  videoOverlay?: Element | ReactNode
  showStatus?: boolean
}

export const VideoAutoplayer: React.FC<Props> = ({
  height = '0px',
  video,
  onClick,
  showMore = false,
  onDelete,
  videoOverlay = null,
  showStatus = true
}): JSX.Element => {
  const [searchParams] = useSearchParams()
  const { getVideoPoster } = useVideoPoster(video)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const bulkSelections = useAppSelector(
    (state) => state.bulkVideoSelection.selectedIds
  )
  const checked = bulkSelections.includes(video.encoded_id)
  const manageIsActive = useAppSelector((state) => state.channel.manageIsActive)

  const checkboxRef = useRef(null)
  const { businessId, channelId } = useParams()
  const nff = useNabooFeatureFlag()

  const hrefPath = useMemo(() => {
    const livePath =
      video?.video_type === 'live_stream'
        ? nff.cms_showroom_fusion_phase_1
          ? `/showroom/${video.live_stream_id}`
          : `/livestream/${video.live_stream_id}`
        : ''

    return `/business/${businessId}/channel/${channelId}/video/${video.encoded_id}/${livePath}`
  }, [businessId, channelId, nff, video])

  const videosAll = useAppSelector(
    (state) => state.channel.channelAllVideoIds[channelId] || []
  )
  const videosPending = useAppSelector(
    (state) => state.channel.channelAllVideoIdsPending[channelId] || []
  )
  const videosOriginal = useAppSelector(
    (state) => state.channel.channelCreatedVideoIds[channelId] || []
  )
  const livestreamVideos = useAppSelector(
    (state) => state.channel.channelAllLivestreams[channelId] || []
  )

  const interIcon = useInteractionIcon(video)

  const videos = useMemo(() => {
    let videos = [...videosPending, ...videosAll]
    const tab = searchParams.get('activeTab')
    if (tab === CHANNEL_TABS.ORIGINAL)
      videos = [...videosPending, ...videosOriginal]
    if (tab === CHANNEL_TABS.LIVESTREAM) videos = livestreamVideos

    return videos
  }, [searchParams, videosPending, videosAll, videosOriginal, livestreamVideos])

  const handleOnPosterClick = (event) => {
    if (manageIsActive) {
      const customEvent = {
        ...event,
        target: {
          checked: !bulkSelections.includes(video?.encoded_id)
        },
        nativeEvent: { shiftKey: event.shiftKey }
      }
      checkboxRef.current.props.onChange(customEvent)
    } else onClick(event)
  }

  return (
    <VideoCard
      height={height}
      hrefPath={hrefPath}
      video={video}
      onDelete={onDelete}
      supportsPreview={true}
      checkboxRef={checkboxRef}
      videoOverlay={videoOverlay}
      manageIsActive={manageIsActive}
      videoPoster={getVideoPoster}
      onClick={onClick}
      onPosterClick={handleOnPosterClick}
      showMore={showMore}
      checked={checked}
      showStatus={showStatus}
      menuOpen={menuIsOpen}
      setMenuOpen={setMenuIsOpen}
      videos={videos}
      bulkSelections={bulkSelections}
      interactionIcon={interIcon}
    />
  )
}

export default VideoAutoplayer
