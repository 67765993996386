import { createSlice } from '@reduxjs/toolkit'

const initialState: { layout: string } = {
  layout: 'grid'
}

const slice = createSlice({
  name: 'display',
  initialState,
  reducers: {
    updateLayout(state, action) {
      state.layout = action.payload
    }
  }
})

export default slice.reducer

export const { updateLayout } = slice.actions
