import { createAction, createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'

import api from '../utils/api'

const initialState: { videos: globalLib.IVideo[] } = {
  videos: []
}

const slice = createSlice({
  name: 'home',
  initialState: initialState,
  reducers: {
    fetchVideosSuccess(state, action) {
      const { videos } = action.payload
      state.videos = videos
    }
  }
})
export default slice.reducer

export const { fetchVideosSuccess } = slice.actions

const fetchVideosRequest = createAction('home/fetchVideosRequest')
const fetchVideosFailure = createAction('home/fetchVideosFailure')

export function fetchVideos(username: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchVideosRequest())

      const res = await api.get(`/users/${username}/videos`)
      const { videos } = res.data
      dispatch(fetchVideosSuccess({ videos }))

      return res
    } catch (error) {
      dispatch(fetchVideosFailure())

      return error
    }
  }
}
