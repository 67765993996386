import { localStorageProvider } from '@src/utils/storageProvider'

const createBusinessTableList = (
  businessList: any[],
  favoriteBusinessList: {
    id: string
    name: string
  }[]
): {
  id: string
  name: string
  favorite: boolean
  website: string
}[] => {
  if (!businessList.length) {
    return []
  }

  return businessList.map((business) => {
    const favoriteBusiness = favoriteBusinessList.find((item) => {
      return item.id === business.id
    })

    return { ...business, favorite: !!favoriteBusiness }
  })
}

const getFavoriteBusinessList = (
  userId: string
): {
  id: string
  name: string
}[] => {
  return JSON.parse(
    localStorageProvider.getItem(`favorite_business_${userId}`) || '[]'
  )
}

const addLocalFavoriteBusiness = (
  userId: string,
  business: {
    id: string
    name: string
  }
): void => {
  const favoriteBusinessList = getFavoriteBusinessList(userId)
  const exist = favoriteBusinessList.find((item) => {
    return item.id === business.id
  })
  if (!exist) {
    favoriteBusinessList.push(business)
  }
  localStorageProvider.setItem(
    `favorite_business_${userId}`,
    JSON.stringify(favoriteBusinessList)
  )
}

const removeLocalFavoriteBusiness = (
  userId: string,
  encodeId: string
): void => {
  let favoriteBusinessList = getFavoriteBusinessList(userId)
  favoriteBusinessList = favoriteBusinessList.filter((item) => {
    return item.id !== encodeId
  })
  localStorageProvider.setItem(
    `favorite_business_${userId}`,
    JSON.stringify(favoriteBusinessList)
  )
}

const renameLocalFavoriteBusiness = (
  userId: string,
  business: {
    id: string
    name: string
  }
): void => {
  let favoriteBusinessList = getFavoriteBusinessList(userId)
  favoriteBusinessList = favoriteBusinessList.map((item) => {
    if (item.id === business.id) {
      return {
        id: business.id,
        name: business.name
      }
    } else {
      return item
    }
  })

  localStorageProvider.setItem(
    `favorite_business_${userId}`,
    JSON.stringify(favoriteBusinessList)
  )
}

export {
  getFavoriteBusinessList,
  createBusinessTableList,
  addLocalFavoriteBusiness,
  removeLocalFavoriteBusiness,
  renameLocalFavoriteBusiness
}
