import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  BatchImporterMedia,
  BatchImporterMediaType,
  ImporterSourceFrom
} from '@src/components/BatchImporter/BatchImporterMediaModel'
import { IgMediaRes, IgMedia } from '@src/components/IgImporter/igImporterModel'
import { getIgToken, IG_URL } from '@src/utils/igAuth'
import { Dispatch } from 'redux'

type IProps = {
  igMediaData: IgMediaRes
  igMediaList: IgMedia[]
  igMediaUrl: string[]
}

const initialState: IProps = {
  igMediaData: undefined,
  igMediaList: [],
  igMediaUrl: []
}

const slice = createSlice({
  name: 'igImporter',
  initialState: initialState,
  reducers: {
    fetchIgMediaSuccess(state, action) {
      const { igMediaData, url } = action.payload
      if (!url) {
        state.igMediaData = igMediaData
        state.igMediaList = igMediaData.data
        state.igMediaUrl = []
      } else {
        if (!state.igMediaUrl.includes(url)) {
          state.igMediaData = igMediaData
          state.igMediaList = state.igMediaList.concat(igMediaData.data)
          state.igMediaUrl = state.igMediaUrl.concat(url)
        }
      }
    },
    clearIgMediaData(state) {
      state.igMediaData = undefined
      state.igMediaList = []
      state.igMediaUrl = []
    }
  }
})

export default slice.reducer
export const { fetchIgMediaSuccess, clearIgMediaData } = slice.actions

const fetchIgMediaRequest = createAction('igImporter/fetchIgMediaRequest')
const fetchIgMediaFailure = createAction('igImporter/fetchIgMediaFailure')

export function fetchMedia(userId: string, url?: string) {
  return async (dispatch: Dispatch): Promise<IgMediaRes> => {
    try {
      dispatch(fetchIgMediaRequest())
      const igToken = getIgToken(userId)
      const response = await fetch(
        url
          ? url
          : `${IG_URL}/me/media?fields=children{id,media_type,media_url},id,caption,is_shared_to_feed,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=${igToken.accessToken}`
      )
      const data = (await response.json()) as IgMediaRes
      dispatch(
        fetchIgMediaSuccess({
          igMediaData: data,
          url
        })
      )

      return data
    } catch (error) {
      dispatch(fetchIgMediaFailure())

      return error
    }
  }
}

export const convertIgMediaList2BatchImporterMediaList = (
  list?: IgMedia[]
): BatchImporterMedia[] => {
  return (
    list?.map((item) => {
      const children = convertIgMediaList2BatchImporterMediaList(
        item?.children?.data
      )

      return {
        id: item.id,
        caption: item.caption,
        username: item?.username,
        mediaType: item.media_type as BatchImporterMediaType,
        mediaUrl: item.media_url,
        sourceUrl: item?.permalink,
        children: children?.length ? children : undefined,
        sourceFrom: ImporterSourceFrom.Instagram,
        timestamp: item?.timestamp
      }
    }) || []
  )
}
