import { useCallback, useEffect, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { fetchChannelVideoCount } from '@src/redux/channel'
import {
  getShortVideoDurationLimit,
  getShortVideoLimit,
  getShortVideoSizeLimit
} from '@src/selectors/subscription'

type IUseVideoLimitType = {
  isVideoLimitReached: (uploadVideoCount?: number) => boolean
  remainingVideoCount: number
  maxVideoSize: number
  maxVideoDuration: number
  maxVideoLimit: number
  maxAllowedVideoCount: number
}

export const useVideoLimit = ({
  channelId,
  businessId
}: {
  channelId: string
  businessId: string
}): IUseVideoLimitType => {
  const dispatch = useAppDispatch()

  const maxVideoLimit = useAppSelector((state) =>
    getShortVideoLimit(state, businessId)
  )

  const maxVideoSize = useAppSelector((state) =>
    getShortVideoSizeLimit(state, businessId)
  )

  const maxVideoDuration = useAppSelector((state) =>
    getShortVideoDurationLimit(state, businessId)
  )

  useEffect(() => {
    dispatch(fetchChannelVideoCount(businessId, channelId))
  }, [channelId, businessId, dispatch])

  const currentVideoCount = useAppSelector(
    (state) => state.channel.channelVideoCount[channelId]
  )?.shortVideosCount

  const remainingVideoCount = useMemo(() => {
    return maxVideoLimit - currentVideoCount || 0
  }, [currentVideoCount, maxVideoLimit])

  const isVideoLimitReached = useCallback(
    (uploadVideoCount = 0) => {
      return (
        uploadVideoCount + currentVideoCount >= maxVideoLimit &&
        maxVideoLimit !== 0
      )
    },
    [currentVideoCount, maxVideoLimit]
  )

  return {
    isVideoLimitReached,
    remainingVideoCount,
    maxVideoSize,
    maxVideoDuration,
    maxVideoLimit: maxVideoLimit - currentVideoCount,
    maxAllowedVideoCount: maxVideoLimit
  }
}
