import { createSlice } from '@reduxjs/toolkit'

const initialState: { columnsList: string[] } = {
  columnsList: undefined
}

const slice = createSlice({
  name: 'columnDisplay',
  initialState,
  reducers: {
    updateColumnList(state, action) {
      state.columnsList = action.payload
    }
  }
})

export default slice.reducer

export const { updateColumnList } = slice.actions
