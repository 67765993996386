import { useCallback } from 'react'

import { useCookies } from 'react-cookie'

export function useCookie(cookieNames = []) {
  const [cookies, setReactCookie, removeReactCookie] = useCookies(cookieNames)

  const setCookie = useCallback(
    (key, value, options) => {
      setReactCookie(key, value, options)
    },
    [setReactCookie]
  )

  const getCookie = useCallback(
    (key) => {
      return cookies[key]
    },
    [cookies]
  )

  const deleteCookie = useCallback(
    (key, options) => {
      removeReactCookie(key, options)
    },
    [removeReactCookie]
  )

  return {
    setCookie,
    getCookie,
    deleteCookie
  }
}
