import React, { useContext, useEffect, useRef, useState } from 'react'

import { FWButton } from '@src/components'
import { Box, Flex } from '@src/components/EmotionLayout'
import { KEY_ONBOARDING_STATUS, KEY_ACTIVATION_STATUS } from '@src/constants'
import { BusinessContext } from '@src/contexts/BusinessContext'
import { updateBusinessActivationStatus } from '@src/redux/business'
import {
  localStorageProvider,
  sessionStorageProvider
} from '@src/utils/storageProvider'
import { Card, Space, Input } from 'antd'
import { useTranslation } from 'react-i18next'

export const DevTools: React.FC = () => {
  const { t } = useTranslation()
  const businessId = useContext(BusinessContext)
  const [showTools, setShowTools] = React.useState(false)
  const [apiURL, setApiURL] = React.useState('')

  const [isDragging, setIsDragging] = useState(false)

  const buttonRef = useRef<HTMLDivElement | null>(null)
  const position = useRef({ x: 0, y: 0 })
  const [buttonPosition, setButtonPosition] = useState({
    bottom: 20,
    right: 20
  })

  const handleMouseDown = (e) => {
    setIsDragging(true)
    position.current = {
      x: e.clientX - buttonRef.current.getBoundingClientRect().left,
      y: e.clientY - buttonRef.current.getBoundingClientRect().top
    }
  }

  const handleMouseMove = (e) => {
    if (!isDragging) return
    const newBottom =
      window.innerHeight -
      e.clientY -
      (buttonRef.current.offsetHeight - position.current.y)
    const newRight =
      window.innerWidth -
      e.clientX -
      (buttonRef.current.offsetWidth - position.current.x)

    setButtonPosition({ bottom: newBottom, right: newRight })
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    } else {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging])

  const handleResetOnboarding = async () => {
    await updateBusinessActivationStatus(businessId, true)
    localStorageProvider.setItem(
      KEY_ONBOARDING_STATUS,
      JSON.stringify({ oq: false, wm: false, pb: false, pdp: false })
    )
    localStorageProvider.setItem(
      KEY_ACTIVATION_STATUS,
      JSON.stringify({
        uploadVideo: 'active',
        createPlaylist: 'inactive',
        embedPlaylist: 'inactive'
      })
    )
    window.location.reload()
  }

  if (process.env.REACT_APP_ENV === 'production') return null

  return (
    <>
      {showTools && (
        <Flex
          position="fixed"
          bottom={`${buttonPosition.bottom + 50}px`}
          right={`${buttonPosition.right}px`}
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="flex-end"
          background={showTools ? 'white' : 'transparent'}
          zIndex={100}
        >
          <Card bordered>
            <Flex flexDirection="column" gap="10">
              <Space>
                <Input
                  value={apiURL}
                  onChange={(e) => setApiURL(e.target.value)}
                  placeholder={t('API URL')}
                />
                <FWButton
                  onClick={() => {
                    sessionStorageProvider.setItem('apiURL', apiURL)
                    window.location.reload()
                  }}
                >
                  {t('Set API URL')}
                </FWButton>
              </Space>
              <FWButton onClick={handleResetOnboarding}>
                {t('Reset Onboarding')}
              </FWButton>
            </Flex>
          </Card>
        </Flex>
      )}
      <Box
        ref={buttonRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onClick={() => setShowTools(!showTools)}
        position="fixed"
        bottom={buttonPosition.bottom}
        right={buttonPosition.right}
        width="50px"
        height="50px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor={isDragging ? 'grabbing' : 'grab'}
        zIndex={101}
        color={showTools ? 'white' : 'black'}
        fontSize="12px"
        textAlign="center"
        style={{
          borderRadius: '50%',
          backgroundColor: showTools ? '#007bff' : '#d3d3d3'
        }}
      >
        {t('Dev Tools')}
      </Box>
    </>
  )
}
