import api, { API_URL, IMPORTER_PROXY_URL } from '@src/utils/api'
import { localStorageProvider } from '@src/utils/storageProvider'
import { delay } from '@src/utils/tools'

export const APIFY_RESULTS_PER_PAGE = 50

const INTERVAL = 3000

export type ApifyInput = {
  parameters?: any
  actionId?: string
  datasetId?: string
}

export type ApifyOutput = {
  datasetId?: string
  items?: any[]
}

const runActorAndGetResultsSync = async (
  input: ApifyInput
): Promise<ApifyOutput> => {
  if (input?.datasetId) {
    const items = await (
      await fetch(
        `${IMPORTER_PROXY_URL}/apify/dataset?datasetId=${input.datasetId}`
      )
    ).json()

    return {
      items,
      datasetId: input.datasetId
    }
  }

  // Run the Actor and wait for it to finish
  let run = (
    await (
      await fetch(`${IMPORTER_PROXY_URL}/apify/start`, {
        // POST urls to the API
        method: 'POST',
        headers: {
          Authorization: `bearer ${api.getToken()}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...input,
          fwHost: API_URL
        })
      })
    ).json()
  ).data
  // interval loop to check the status of the run
  while (run.status !== 'SUCCEEDED' && run.status !== 'FAILED') {
    run = (
      await (
        await fetch(`${IMPORTER_PROXY_URL}/apify/status?runId=${run.id}`)
      ).json()
    ).data
    await delay(INTERVAL)
  }
  const items = await (
    await fetch(
      `${IMPORTER_PROXY_URL}/apify/dataset?datasetId=${run.defaultDatasetId}`
    )
  ).json()

  return {
    items,
    datasetId: run.defaultDatasetId
  }
}

export const runTiktokUrlsCrawler = async (
  urls: string[]
): Promise<ApifyOutput> => {
  return await runActorAndGetResultsSync({
    parameters: {
      postURLs: urls,
      resultsPerPage: 100,
      shouldDownloadCovers: false,
      shouldDownloadSlideshowImages: false,
      shouldDownloadSubtitles: false,
      shouldDownloadVideos: true,
      videoKvStoreIdOrName: 'tiktok'
    },
    actionId: 'clockworks~free-tiktok-scraper'
  })
}

export const runTiktokHashtagCrawler = async (
  hashtag: string,
  datasetId?: string
): Promise<ApifyOutput> => {
  return await runActorAndGetResultsSync({
    datasetId: datasetId,
    parameters: {
      excludePinnedPosts: false,
      hashtags: [hashtag],
      resultsPerPage: APIFY_RESULTS_PER_PAGE,
      shouldDownloadCovers: false,
      shouldDownloadSlideshowImages: false,
      shouldDownloadSubtitles: false,
      shouldDownloadVideos: false
    },
    actionId: 'clockworks~free-tiktok-scraper'
  })
}

export const runTiktokUsernameCrawler = async (
  username: string,
  datasetId?: string
): Promise<ApifyOutput> => {
  return await runActorAndGetResultsSync({
    datasetId: datasetId,
    parameters: {
      excludePinnedPosts: false,
      profiles: [`@${username}`],
      resultsPerPage: APIFY_RESULTS_PER_PAGE,
      shouldDownloadCovers: false,
      shouldDownloadSlideshowImages: false,
      shouldDownloadSubtitles: false,
      shouldDownloadVideos: false
    },
    actionId: 'clockworks~free-tiktok-scraper'
  })
}

export const runInstagramUrlsCrawler = async (
  urls: string[]
): Promise<ApifyOutput> => {
  return await runActorAndGetResultsSync({
    parameters: {
      addParentData: false,
      directUrls: urls,
      enhanceUserSearchWithFacebookPage: false,
      isUserTaggedFeedURL: false,
      resultsLimit: 200,
      resultsType: 'posts',
      searchLimit: 1,
      searchType: 'hashtag'
    },
    actionId: 'apify~instagram-scraper'
  })
}

export const runInstagramHashtagCrawler = async (
  hashtag: string,
  datasetId?: string
): Promise<ApifyOutput> => {
  return await runActorAndGetResultsSync({
    datasetId: datasetId,
    parameters: {
      hashtags: [hashtag],
      resultsLimit: APIFY_RESULTS_PER_PAGE
    },
    actionId: 'apify~instagram-hashtag-scraper'
  })
}

export const runInstagramUsernameCrawler = async (
  username: string,
  datasetId?: string
): Promise<ApifyOutput> => {
  return await runActorAndGetResultsSync({
    datasetId: datasetId,
    parameters: {
      resultsLimit: APIFY_RESULTS_PER_PAGE,
      skipPinnedPosts: false,
      username: [username]
    },
    actionId: 'apify~instagram-post-scraper'
  })
}

export const runYouTubeUrlsCrawler = async (
  urls: string[]
): Promise<{ mediaMetaData: ApifyOutput; mediaUrlData: ApifyOutput }> => {
  const [mediaMetaData, mediaUrlData] = await Promise.all([
    runActorAndGetResultsSync({
      parameters: {
        downloadSubtitles: false,
        hasCC: false,
        hasLocation: false,
        hasSubtitles: false,
        is360: false,
        is3D: false,
        is4K: false,
        isBought: false,
        isHD: false,
        isHDR: false,
        isLive: false,
        isVR180: false,
        maxResultStreams: 0,
        maxResults: 10,
        maxResultsShorts: 0,
        preferAutoGeneratedSubtitles: false,
        saveSubsToKVS: false,
        startUrls: urls?.map((item) => {
          return {
            url: item,
            method: 'GET'
          }
        }),
        subtitlesLanguage: 'any',
        subtitlesFormat: 'srt'
      },
      actionId: 'streamers~youtube-scraper'
    }),
    runActorAndGetResultsSync({
      parameters: {
        includeFailedVideos: false,
        proxy: {
          useApifyProxy: true
        },
        quality: '720',
        startUrls: urls,
        useFfmpeg: false
      },
      actionId: 'epctex~youtube-video-downloader'
    })
  ])

  return { mediaMetaData, mediaUrlData }
}

export const defaultApifyDataSetIdExpiresIn = 24 * 60 * 60 * 1000

export type ApifyDataset = {
  id?: string
  expiresIn?: number
  type?: 'username' | 'hashtag'
  value?: string
}

export const saveDataset = (
  dataset: ApifyDataset,
  sourceFrom: 'tiktok' | 'instagram'
): void => {
  localStorageProvider.setItem(
    `fw_apify_${sourceFrom}_${dataset.type}_${dataset.value}`,
    JSON.stringify(dataset)
  )
}

export const getDataset = (
  dataset: ApifyDataset,
  sourceFrom: 'tiktok' | 'instagram'
): ApifyDataset => {
  return JSON.parse(
    localStorageProvider.getItem(
      `fw_apify_${sourceFrom}_${dataset.type}_${dataset.value}`
    ) || '{}'
  )
}

export const isDateSetExpired = (dataset: ApifyDataset): boolean => {
  if (!dataset || !dataset.id || !dataset.expiresIn) {
    return true
  }
  const expiresIn = dataset.expiresIn
  const date = new Date()

  return expiresIn <= date.getTime() + 10 * 60 * 1000
}

export const mockTiktokData = [
  {
    id: '7301190218646932738',
    text:
      '화요팅!!🩷 #dancechallenge #dance #fyp #추천 #챌린지 #추천간판 #2023tiktok #challenge #trending #김체리 #onepickent #원픽이엔티 ',
    createTime: 1699940823,
    createTimeISO: '2023-11-14T05:47:03.000Z',
    locationCreated: 'KR',
    authorMeta: {
      id: '6640950646532997122',
      profileUrl: 'https://www.tiktok.com/@k.cherryrrehc',
      name: 'k.cherryrrehc',
      nickName: '김체리🍒',
      verified: false,
      signature:
        '🇰🇷\nIG : @k.cherryrrehc\n \nBusiness : kcherryrrehc@gmail.com',
      bioLink: null,
      avatar:
        'https://p16-sign-sg.tiktokcdn.com/aweme/720x720/tos-alisg-avt-0068/7d9e7f9a02879f9b9e3700986f69a74f.jpeg?lk3s=a5d48078&nonce=43514&refresh_token=64b8f6d4c4c6af6e800a1a0385e20092&x-expires=1727244000&x-signature=aCnHICP%2BpHij%2Fzi7%2BYSxNk0O2hU%3D&shp=a5d48078&shcp=81f88b70',
      privateAccount: false,
      roomId: '',
      ttSeller: false,
      commerceUserInfo: {
        commerceUser: false
      },
      following: 13,
      friends: 6,
      fans: 2100000,
      heart: 26900000,
      video: 439,
      digg: 0
    },
    musicMeta: {
      musicName: 'original sound - Fatin Athirah',
      musicAuthor: '_dekten',
      musicOriginal: false,
      musicAlbum: 'Fatin Athirah',
      playUrl:
        'https://v16-webapp-prime.us.tiktok.com/video/tos/useast5/tos-useast5-ve-27dcd7c799-tx/oQe2SC2DkQBhFDMhsEebBBgGzrEXT0InJqnKUO/?a=1988&bti=ODszNWYuMDE6&ch=0&cr=0&dr=0&er=0&lr=default&cd=0%7C0%7C0%7C0&br=124&bt=62&ft=GcAInInz7ThouaGrXq8Zmo&mime_type=audio_mpeg&qs=3&rc=ZzU3Z2hpOGY5ZThmOzdkNUBpM21xeTg6Zm03NzMzODU8NEAyMGIuMWFhNjQxXjIxYzI2YSNuYWQ2cjRnNjZgLS1kMS1zcw%3D%3D&btag=e00088000&expire=1727095364&l=20240923064231E788D3F8CB61AC6D6598&ply_type=3&policy=3&signature=d8289984871bcc79b0405f2a9cd15a64&tk=0',
      coverMediumUrl:
        'https://p16-sg.tiktokcdn.com/aweme/200x200/tiktok-obj/8e57001370f7881a60b60.jpeg',
      musicId: '6599473116945058561'
    },
    webVideoUrl:
      'https://www.tiktok.com/@k.cherryrrehc/video/7301190218646932738',
    mediaUrls: [
      'https://api.apify.com/v2/key-value-stores/CoLQ60nB1WlbGDwGw/records/video-kcherryrre-20231114054703-7301190218646932738'
    ],
    videoMeta: {
      height: 1280,
      width: 720,
      duration: 14,
      coverUrl:
        'https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/c7309177b6634e1fa459abe343ba1fdd_1699940825?lk3s=81f88b70&x-expires=1727244000&x-signature=3jlal7juTCaMwcxk1e8EiioA58k%3D&shp=81f88b70&shcp=-',
      originalCoverUrl:
        'https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/c7309177b6634e1fa459abe343ba1fdd_1699940825?lk3s=81f88b70&x-expires=1727244000&x-signature=3jlal7juTCaMwcxk1e8EiioA58k%3D&shp=81f88b70&shcp=-',
      definition: '720p',
      format: 'mp4',
      originalDownloadAddr:
        'https://v16-webapp-prime.us.tiktok.com/video/tos/alisg/tos-alisg-pve-0037/ogjA2mEbAeOEhIgKrJuCDU8e7IIsQgLmIyEpeG/?a=1988&bti=ODszNWYuMDE6&ch=0&cr=3&dr=0&lr=all&cd=0%7C0%7C0%7C&cv=1&br=2350&bt=1175&cs=0&ds=3&ft=4KJMyMzm8Zmo0wA6a-4jVAm~dpWrKsd.&mime_type=video_mp4&qs=0&rc=ZjRlMzZoaDk7OjdpOjllPEBpam1ycGw5cm1qbzMzODgzNEAzYjFjXzJgXjQxLjU2YTJhYSNfaG80MmRjZzZgLS1kLy1zcw%3D%3D&btag=e00088000&expire=1727095365&l=20240923064231E788D3F8CB61AC6D6598&ply_type=2&policy=2&signature=474124a3c715a76129b03bfe76a78306&tk=tt_chain_token',
      downloadAddr:
        'https://api.apify.com/v2/key-value-stores/CoLQ60nB1WlbGDwGw/records/video-kcherryrre-20231114054703-7301190218646932738',
      subtitleLinks: []
    },
    diggCount: 321700,
    shareCount: 6149,
    playCount: 8500000,
    collectCount: 21590,
    commentCount: 1548,
    mentions: [],
    hashtags: [
      {
        id: '85832',
        name: 'dancechallenge',
        title: '',
        cover: ''
      },
      {
        id: '5054',
        name: 'dance',
        title: '',
        cover: ''
      },
      {
        id: '229207',
        name: 'fyp',
        title: '',
        cover: ''
      },
      {
        id: '30513583',
        name: '추천',
        title: '',
        cover: ''
      },
      {
        id: '83730745',
        name: '챌린지',
        title: '',
        cover: ''
      },
      {
        id: '1618380048354305',
        name: '추천간판',
        title: '',
        cover: ''
      },
      {
        id: '1689979760351234',
        name: '2023tiktok',
        title: '',
        cover: ''
      },
      {
        id: '19011',
        name: 'challenge',
        title: '',
        cover: ''
      },
      {
        id: '20105',
        name: 'trending',
        title:
          'Discover the top viral trends, sounds and effects of the week! 🔥',
        cover: ''
      },
      {
        id: '1598077042199554',
        name: '김체리',
        title: '',
        cover: ''
      },
      {
        id: '7244372589312180232',
        name: 'onepickent',
        title: '',
        cover: ''
      },
      {
        id: '7281474529149321234',
        name: '원픽이엔티',
        title: '',
        cover: ''
      }
    ],
    effectStickers: [],
    isSlideshow: false,
    isPinned: false,
    submittedVideoUrl:
      'https://www.tiktok.com/@k.cherryrrehc/video/7301190218646932738'
  }
] as any
