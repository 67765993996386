import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  BatchImporterMedia,
  BatchImporterMediaType,
  ConsentStatus,
  ImporterSourceFrom
} from '@src/components/BatchImporter/BatchImporterMediaModel'
import { TiktokItem } from '@src/components/TiktokImporter/TiktokUrlImporter'
import {
  ConsentRequestsData,
  getMediaKeyFromConsentRequestsMedia
} from '@src/redux/consentRequests'
import {
  defaultApifyDataSetIdExpiresIn,
  getDataset,
  isDateSetExpired,
  runTiktokHashtagCrawler,
  runTiktokUsernameCrawler,
  saveDataset
} from '@src/utils/apify'
import { fetchConsentRequests } from '@src/utils/consentRequestUtils'
import { Dispatch } from 'redux'

type IProps = {
  hashtag: string
  hashtagMediaList: TiktokItem[]
  username: string
  usernameMediaList: TiktokItem[]
}

const initialState: IProps = {
  hashtag: undefined,
  hashtagMediaList: [],
  username: undefined,
  usernameMediaList: []
}

const slice = createSlice({
  name: 'tiktokImporter',
  initialState: initialState,
  reducers: {
    fetchTiktokHashtagMediaSuccess(state, action) {
      const { items, hashtag, datasetId } = action.payload
      const date = new Date()
      state.hashtag = hashtag
      state.hashtagMediaList = items
      if (datasetId) {
        saveDataset(
          {
            id: datasetId,
            type: 'hashtag',
            value: hashtag,
            expiresIn: date.getTime() + defaultApifyDataSetIdExpiresIn
          },
          'tiktok'
        )
      }
    },
    fetchTiktokUsernameMediaSuccess(state, action) {
      const { items, username, datasetId } = action.payload
      const date = new Date()
      state.username = username
      state.usernameMediaList = items
      if (datasetId) {
        saveDataset(
          {
            id: datasetId,
            type: 'username',
            value: username,
            expiresIn: date.getTime() + defaultApifyDataSetIdExpiresIn
          },
          'tiktok'
        )
      }
    }
  }
})

export default slice.reducer

export const {
  fetchTiktokHashtagMediaSuccess,
  fetchTiktokUsernameMediaSuccess
} = slice.actions

const getDatasetByHashtag = (hashtag: string) => {
  const dataset = getDataset(
    {
      type: 'hashtag',
      value: hashtag
    },
    'tiktok'
  )
  if (!isDateSetExpired(dataset)) {
    return dataset
  }

  return undefined
}

const getDatasetByUsername = (username: string) => {
  const dataset = getDataset(
    {
      type: 'username',
      value: username
    },
    'tiktok'
  )
  if (!isDateSetExpired(dataset)) {
    return dataset
  }

  return undefined
}

const splitArrayIntoChunks = (array, chunkSize) => {
  const result = []
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }

  return result
}

const fetchConsentManagement = async (
  businessId: string,
  tiktokCrawlerItems: TiktokItem[]
): Promise<TiktokItem[]> => {
  let newTiktokItems = tiktokCrawlerItems
  // fetch consent management
  if (businessId.length) {
    const ids = newTiktokItems.map((item) => item.id)
    const chunks = splitArrayIntoChunks(ids, 20)
    let consentResultList: ConsentRequestsData[] = []
    for (const chunk of chunks) {
      const data = await fetchConsentRequests({
        businessId,
        mediaSource: 'tiktok',
        mediaExternalIds: chunk
      })
      consentResultList = consentResultList.concat(data?.entries || [])
    }
    if (consentResultList.length > 0) {
      newTiktokItems = newTiktokItems.map((item) => {
        const exist = consentResultList?.find((consentResult) => {
          return `${consentResult?.media?.external_id}` === `${item.id}`
        })
        const existMedia = exist?.media

        return {
          ...item,
          consentStatus: exist?.status,
          consentId: exist?.id,
          signature: getMediaKeyFromConsentRequestsMedia(existMedia)
        }
      })
    }
  }

  return newTiktokItems
}

const fetchTiktokHashtagMediaRequest = createAction(
  'tiktokImporter/fetchTiktokHashtagMediaRequest'
)
const fetchTiktokHashtagMediaFailure = createAction(
  'tiktokImporter/fetchTiktokHashtagMediaFailure'
)

export function fetchTiktokHashtagMedia(params: {
  hashtag: string
  businessId?: string
  needConsent?: boolean
}) {
  return async (dispatch: Dispatch): Promise<TiktokItem[]> => {
    try {
      dispatch(fetchTiktokHashtagMediaRequest())
      const { businessId, hashtag, needConsent } = params

      const dataset = getDatasetByHashtag(hashtag)

      const apifyOutput = await runTiktokHashtagCrawler(hashtag, dataset?.id)

      let newTiktokItems =
        apifyOutput?.items?.filter((item) => {
          return !item.error && item?.videoMeta?.coverUrl
        }) || []

      if (needConsent) {
        newTiktokItems = await fetchConsentManagement(
          businessId,
          newTiktokItems
        )
      }

      if (apifyOutput.datasetId) {
        dispatch(
          fetchTiktokHashtagMediaSuccess({
            items: newTiktokItems,
            hashtag,
            datasetId:
              apifyOutput.datasetId === dataset?.id
                ? undefined
                : apifyOutput.datasetId
          })
        )

        return newTiktokItems
      } else {
        dispatch(fetchTiktokHashtagMediaFailure())

        return []
      }
    } catch (error) {
      dispatch(fetchTiktokHashtagMediaFailure())

      return error
    }
  }
}

const fetchTiktokUsernameMediaRequest = createAction(
  'tiktokImporter/fetchTiktokUsernameMediaRequest'
)
const fetchTiktokUsernameMediaFailure = createAction(
  'tiktokImporter/fetchTiktokUsernameMediaFailure'
)

export function fetchTiktokUsernameMedia(params: {
  username: string
  businessId?: string
  needConsent?: boolean
}) {
  return async (dispatch: Dispatch): Promise<TiktokItem[]> => {
    try {
      dispatch(fetchTiktokUsernameMediaRequest())
      const { businessId, username, needConsent } = params

      const dataset = getDatasetByUsername(username)

      const apifyOutput = await runTiktokUsernameCrawler(username, dataset?.id)

      let newTiktokItems =
        apifyOutput?.items?.filter((item) => {
          return !item.error && item?.videoMeta?.coverUrl
        }) || []

      if (needConsent) {
        newTiktokItems = await fetchConsentManagement(
          businessId,
          newTiktokItems
        )
      }

      if (apifyOutput.datasetId) {
        dispatch(
          fetchTiktokUsernameMediaSuccess({
            items: newTiktokItems,
            username,
            datasetId:
              apifyOutput.datasetId === dataset?.id
                ? undefined
                : apifyOutput.datasetId
          })
        )

        return newTiktokItems
      } else {
        dispatch(fetchTiktokUsernameMediaFailure())

        return []
      }
    } catch (error) {
      dispatch(fetchTiktokHashtagMediaFailure())

      return error
    }
  }
}

export const convertTiktokItemList2BatchImporterMediaList = (
  list?: TiktokItem[]
): BatchImporterMedia[] => {
  const newList = list?.filter((item) => {
    return !item.error && item?.videoMeta?.coverUrl
  })

  return (
    newList?.map((item) => {
      const sourceUrl = `${item.authorMeta.profileUrl}/video/${item.id}`
      const firstMediaUrl = item?.mediaUrls?.[0]
      const needCrawlerVideo =
        !firstMediaUrl || firstMediaUrl?.indexOf('apify') === -1
      const mediaUrl = needCrawlerVideo ? sourceUrl : firstMediaUrl

      return {
        id: item.id,
        caption: item.text,
        mediaType: 'VIDEO' as BatchImporterMediaType,
        thumbnailUrl: item.videoMeta.coverUrl,
        username: item.authorMeta.name,
        displayName: item.authorMeta.nickName,
        userId: item.authorMeta.id,
        avatar: item.authorMeta.avatar,
        mediaUrl,
        sourceUrl,
        likeCount: item.diggCount,
        timestamp: item.createTimeISO,
        hashtags: item.hashtags
          ?.map((h) => {
            return h.name
          })
          ?.filter((h) => {
            return !!h.length
          }),
        sourceFrom: ImporterSourceFrom.Tiktok,
        needCrawlerVideo,
        consentStatus: item?.consentStatus as ConsentStatus,
        consentId: item?.consentId,
        signature: item?.signature
      }
    }) || []
  )
}
