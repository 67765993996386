import axios from 'axios'

const WEBHOOK_CREATE_BUSINESS =
  'https://hooks.zapier.com/hooks/catch/3720291/on4vzse'

export default function useZapierTracking() {
  const zapierTrack = ({ url = WEBHOOK_CREATE_BUSINESS, props = {} }) => {
    if (process.env.NODE_ENV === 'production') {
      axios.get(url, { params: props })
    } else {
      // eslint-disable-next-line no-console
      console.log('zapierTrack', url, props)
    }
  }

  return { zapierTrack }
}
