import { useState, useEffect } from 'react'

import { sessionStorageProvider } from '@src/utils/storageProvider'

export function useShopifyFullPage() {
  const [isFullPage, setIsFullPage] = useState(false)

  useEffect(() => {
    const url = new URL(window.location.href)
    const params = url.searchParams
    const shopifyRedirectUrl = params.get('shopify_redirect_url')
    if (shopifyRedirectUrl) {
      sessionStorageProvider.setItem('shopify_redirect_url', shopifyRedirectUrl)
    }
    if (sessionStorageProvider.getItem('shopify_redirect_url')) {
      setIsFullPage(true)
    }
    if (/business_store_connect/.test(url.pathname)) {
      setIsFullPage(true)
    }
  }, [])

  return isFullPage
}
