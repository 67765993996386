/* eslint-disable no-console */
import { createSlice, createAction } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'

import api from '../utils/api'

type IProps = {
  annotations: Record<string, any>
  isEditing: boolean
  editingDate: string | null
}

const initialState: IProps = {
  annotations: {},
  isEditing: false,
  editingDate: null
}

const slice = createSlice({
  name: 'insightAnnotations',
  initialState,
  reducers: {
    fetchInsightAnnotationsSuccess(state, action) {
      const { insight_annotations } = action.payload
      state.annotations = {}
      insight_annotations.forEach((annotation) => {
        if (annotation.report_date in state.annotations)
          console.warn('annotations returned by API have a date conflict')
        state.annotations[annotation.report_date] = annotation
      })
    },
    createInsightAnnotationSuccess(state, action) {
      const insight_annotation = action.payload
      if (insight_annotation.report_date in state.annotations)
        console.warn('created an annotation on overlapping date')
      state.annotations[insight_annotation.report_date] = insight_annotation
      state.isEditing = false
    },
    updateInsightAnnotationSuccess(state, action) {
      const insight_annotation = action.payload
      state.annotations[insight_annotation.report_date] = insight_annotation
      state.isEditing = false
    },
    deleteInsightAnnotationSuccess(state, action) {
      const annotationDate = action.payload
      delete state.annotations[annotationDate]
    },
    beginEditing(state, action) {
      state.isEditing = true
      state.editingDate = action.payload
    },
    cancelEditing(state) {
      state.isEditing = false
      state.editingDate = null
    }
  }
})

export default slice.reducer

export const {
  fetchInsightAnnotationsSuccess,
  createInsightAnnotationSuccess,
  deleteInsightAnnotationSuccess,
  updateInsightAnnotationSuccess,
  beginEditing,
  cancelEditing
} = slice.actions

const fetchInsightAnnotationsRequest = createAction(
  'insightAnnotation/fetchInsightAnnotationsRequest'
)
const fetchInsightAnnotationsFailure = createAction(
  'insightAnnotation/fetchInsightAnnotationsFailure'
)

export function fetchInsightAnnotations(
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchInsightAnnotationsRequest())

      const { start_date, end_date, dashboard_name, video_id } = params
      const response = await api.get(
        `/bus/${businessId}/channels/${channelId}/insight_annotations`,
        {
          params: {
            end_date,
            start_date,
            dashboard_name,
            video_id
          }
        }
      )

      dispatch(fetchInsightAnnotationsSuccess(response.data))

      return response
    } catch (error) {
      dispatch(fetchInsightAnnotationsFailure())

      return error
    }
  }
}

const createInsightAnnotationRequest = createAction(
  'insightAnnotation/createInsightAnnotationRequest'
)
const createInsightAnnotationFailure = createAction(
  'insightAnnotation/createInsightAnnotationFailure'
)

export function createInsightAnnotation(
  businessId: string,
  channelId: string,
  annotation: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createInsightAnnotationRequest())
      const response = await api.post(
        `/bus/${businessId}/channels/${channelId}/insight_annotations`,
        annotation
      )
      dispatch(createInsightAnnotationSuccess({ ...response.data }))

      return response
    } catch (error) {
      dispatch(createInsightAnnotationFailure())

      return error
    }
  }
}

const deleteInsightAnnotationRequest = createAction(
  'insightAnnotation/deleteInsightAnnotationRequest'
)
const deleteInsightAnnotationFailure = createAction(
  'insightAnnotation/deleteInsightAnnotationFailure'
)

export function deleteInsightAnnotation(
  businessId: string,
  channelId: string,
  annotationDate: string
) {
  return async (
    dispatch: Dispatch,
    getState: () => any
  ): Promise<string | any> => {
    try {
      dispatch(deleteInsightAnnotationRequest())
      const { id } = getState().insightAnnotations.annotations[annotationDate]
      const response = await api.delete(
        `/bus/${businessId}/channels/${channelId}/insight_annotations/${id}`
      )
      dispatch(deleteInsightAnnotationSuccess(annotationDate))

      return response
    } catch (error) {
      dispatch(deleteInsightAnnotationFailure())

      return error
    }
  }
}

const updateInsightAnnotationRequest = createAction(
  'insightAnnotation/updateInsightAnnotationRequest'
)
const updateInsightAnnotationFailure = createAction(
  'insightAnnotation/updateInsightAnnotationFailure'
)

export function updateInsightAnnotation(
  businessId: string,
  channelId: string,
  annotationId: string,
  data: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateInsightAnnotationRequest())
      const response = await api.patch(
        `/bus/${businessId}/channels/${channelId}/insight_annotations/${annotationId}`,
        data
      )
      dispatch(updateInsightAnnotationSuccess({ ...response.data }))

      return response
    } catch (error) {
      dispatch(updateInsightAnnotationFailure())

      return error
    }
  }
}
