import React from 'react'

import { Box } from '@src/components/EmotionLayout'

import theme from '../styles/theme'

type IProps = {
  children: React.ReactNode
}

const FormLabel: React.FC<IProps> = ({ children }) => {
  return (
    <Box color={theme.text} fontSize="medium">
      {children}
    </Box>
  )
}

export default FormLabel
