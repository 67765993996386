import React, { Dispatch, useCallback } from 'react'

import { isXsScreen } from '@src/hooks'
import theme from '@src/styles/theme'
import { truncateNumber } from '@src/utils/format'
import { Skeleton } from 'antd'
import { Chart, Axis, Geom, Tooltip } from 'bizcharts'

import { Card, EmptyInsightsContent } from '.'

function defaultTooltipValueFormatter(value, unit) {
  return `${value?.toLocaleString()} ${unit}`
}

type IProps = {
  data: any
  dataKey: string
  format?: string
  header?: JSX.Element
  loading: boolean
  tooltipUnit: string
  tooltipLabel: string
  setSelectedDate: Dispatch<null | string>
  selectedDate: string
  setFocusedDate: Dispatch<null | string>
  focusedDate: string
  tooltipValueFormatter: (value: number, unit: string) => string
  axisFormatter: (value: any) => string
}

export default function VideoPerformanceLineChartCard({
  data = {},
  dataKey,
  header,
  loading,
  tooltipUnit = '',
  tooltipLabel,
  selectedDate,
  setSelectedDate,
  focusedDate,
  setFocusedDate,
  axisFormatter = truncateNumber,
  tooltipValueFormatter = defaultTooltipValueFormatter
}: IProps): JSX.Element {
  const isMobile = isXsScreen()
  const scale = {
    date: { tickCount: isMobile ? 3 : 14 },
    total_seconds_watched: { tickCount: 5 }
  }

  // we set a small timeout for state updates so that we don't block
  // the chart rendering thread with a react update
  const onPlotClick = useCallback(() => {
    if (selectedDate !== null && selectedDate === focusedDate) {
      setTimeout(() => setSelectedDate(null), 100)
    } else if (focusedDate !== null) {
      setTimeout(() => setSelectedDate(focusedDate), 100)
    }
  }, [selectedDate, setSelectedDate, focusedDate])
  const onPointClick = useCallback(
    (evt) => {
      const clickedDate = evt.data._origin.report_date
      if (selectedDate !== null && selectedDate === clickedDate) {
        setTimeout(() => setSelectedDate(null), 100)
      } else {
        setTimeout(() => setSelectedDate(clickedDate), 100)
      }
    },
    [selectedDate, setSelectedDate]
  )
  const onTooltipHide = useCallback(() => {
    setTimeout(() => setFocusedDate(null), 20)
  }, [setFocusedDate])
  const onTooltipChange = useCallback(
    (evt) => {
      setTimeout(() => setFocusedDate(evt.items[0].title), 20)
    },
    [setFocusedDate]
  )

  return (
    <Card height="100%" m="0">
      <Skeleton loading={loading} style={{ margin: 0, padding: 0 }}>
        {header}
        {data && data.length > 0 ? (
          <Chart
            onPlotClick={onPlotClick}
            onPointClick={onPointClick}
            onTooltipHide={onTooltipHide}
            onTooltipChange={onTooltipChange}
            data={data}
            scale={scale}
            height={300}
            padding={isMobile ? [10, 0, 50, 50] : [10, 20, 25, 60]}
            forceFit
          >
            <Axis
              name="report_date"
              label={{
                formatter(text) {
                  return text.split('-')[1] + '/' + text.split('-')[2]
                }
              }}
            />
            <Axis
              key={dataKey}
              name={dataKey}
              label={{ formatter: axisFormatter }}
            />
            <Tooltip />
            <Geom
              type="line"
              position={`report_date*${dataKey}`}
              size={2}
              tooltip={[
                `report_date*${dataKey}`,
                (report_date, value) => {
                  return {
                    title: report_date,
                    name: tooltipLabel,
                    value: tooltipValueFormatter(
                      tooltipUnit === '%' ? value * 100 : value,
                      tooltipUnit
                    )
                  }
                }
              ]}
            />
            <Geom
              type="point"
              position={`report_date*${dataKey}`}
              active={false}
              size={9}
              shape="hollowCircle"
              color={[
                'selected',
                (selected) => {
                  if (selected) return theme.primary
                }
              ]}
              opacity={[
                'selected*hasAnnotation',
                // @ts-expect-error type check
                (selected, hasAnnotation) => {
                  if (selected || hasAnnotation) return 1
                  else return 0
                }
              ]}
              tooltip={false}
            />

            <Geom
              key="point"
              type="point"
              position={`report_date*${dataKey}`}
              shape="circle"
              color={[
                `selected`,
                (selected) => {
                  if (selected) {
                    return theme.primary
                  }
                }
              ]}
              tooltip={[
                `report_date*${dataKey}`,
                (report_date, value) => {
                  return {
                    title: report_date,
                    name: tooltipLabel,
                    value: tooltipValueFormatter(value, tooltipUnit)
                  }
                }
              ]}
            />
          </Chart>
        ) : (
          <EmptyInsightsContent />
        )}
      </Skeleton>
    </Card>
  )
}
