import React from 'react'

import { CameraFilled } from '@ant-design/icons'
import { css } from '@emotion/core'
import { Box, Flex } from '@src/components/EmotionLayout'
import { Upload, Progress } from 'antd'
import { useTranslation } from 'react-i18next'

import { FWButton } from '.'
import { isSmScreen } from '../hooks'

const ACCEPTED_IMAGE_TYPES = 'image/*'

type IProps = {
  src: string
  uploadProgress: number
  onFileSelected: (file: string | Blob) => void
}

const Banner: React.FC<IProps> = ({
  src,
  uploadProgress,
  onFileSelected,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  return (
    <Flex
      width="100%"
      height="200px !important"
      overflow="hidden"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-end"
      borderRadius="12px"
      backgroundSize="cover"
      backgroundPosition="center center"
      backgroundImage={
        src
          ? `url(${src})`
          : 'linear-gradient(180deg, rgba(0,0,0,0.5), rgba(18,18,18,1) 80%, rgba(0,0,0,1))'
      }
      zIndex={1}
      css={css`
        background-repeat: no-repeat;
      `}
      {...props}
    >
      <Flex
        width="100%"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Upload
          accept={ACCEPTED_IMAGE_TYPES}
          showUploadList={false}
          customRequest={({ file }) => onFileSelected(file)}
        >
          <FWButton
            css={css`
              margin: 10px;
              z-index: 2;
              background: rgba(0, 0, 0, 0.2);
              variant: outline;
            `}
          >
            <Flex flexDirection="row" alignItems="center">
              <CameraFilled
                css={css`
                  font-size: ${isSmScreen() ? '22px' : '16px'};
                  color: #ffffff;
                `}
              />
              <Box
                ml={['xsmall', 'small']}
                fontSize={['xsmall', 'medium']}
                fontWeight="bold"
                color="#ffffff"
              >
                {t('Edit cover photo')}
              </Box>
            </Flex>
          </FWButton>
        </Upload>
        <Progress
          size="small"
          percent={uploadProgress}
          showInfo={false}
          css={css`
            width: 50px;
            margin: 10px;
            display: ${uploadProgress > 0 && uploadProgress < 100
              ? 'block'
              : 'none'};
            z-index: 2;
          `}
        />
      </Flex>
    </Flex>
  )
}

export default Banner
