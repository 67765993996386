import { CHANNEL_URLS, CHANNEL_URL_DEFAULT } from '@src/constants'

export function formChannelUrl(username: string, path?: string): string {
  const env = process.env.REACT_APP_ENV || 'staging'
  const baseUrl = `https://${username}.${
    CHANNEL_URLS[env] ?? CHANNEL_URL_DEFAULT
  }`
  const url = path ? `${baseUrl}${path}?is_preview=true` : baseUrl

  return url
}
