import React from 'react'

import { css } from '@emotion/core'
import { Table, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import { Card, CardHeader } from '.'
import { convertTimeUnit } from '../utils/stats'

const KEY_REFERER = 'referer'
const KEY_VIEWS = 'total_views'
const KEY_WATCH_TIME = 'total_seconds_watched'
const KEY_CTR = 'thumbnail_click_rate'

type PerformanceTableIProps = {
  data: any[]
  startDate?: string
  endDate?: string
}

type InternalRefererTableCardIProps = {
  data: any[]
  endDate?: string
  loading?: boolean
  startDate?: string
  headerExtra?: any
}

const PerformanceTable: React.FC<PerformanceTableIProps> = ({ data }) => {
  const { t } = useTranslation()
  const tableColumns: Record<string, any>[] = [
    {
      align: 'left',
      dataIndex: KEY_REFERER,
      key: KEY_REFERER,
      title: t('Url')
    },
    {
      align: 'left',
      dataIndex: KEY_VIEWS,
      key: KEY_VIEWS,
      title: t('Views'),
      width: 150,
      render: (value) => value.toLocaleString()
    },
    {
      align: 'left',
      dataIndex: KEY_WATCH_TIME,
      key: KEY_WATCH_TIME,
      title: t('Watch Time'),
      render: function renderWatchTime(value) {
        return <span>{convertTimeUnit(value)}</span>
      },
      width: 150
    },
    {
      align: 'left',
      dataIndex: KEY_CTR,
      key: KEY_CTR,
      title: t('CTR'),
      render: function renderCTR(value) {
        return <span>{`${(value * 100).toFixed(1)}%`}</span>
      },
      width: 150
    }
  ]

  return (
    <Table
      columns={tableColumns}
      css={css`
        .ant-table-row {
          cursor: pointer;
        }
        .anticon {
          vertical-align: middle;
          padding-bottom: 6px;
        }
      `}
      dataSource={data}
      pagination={{ pageSize: 5, total: data.length }}
    />
  )
}

const InternalRefererTableCard: React.FC<InternalRefererTableCardIProps> = ({
  loading,
  data = [],
  endDate,
  startDate,
  headerExtra
}) => {
  const { t } = useTranslation()

  return (
    <Card m="0">
      <Skeleton active loading={loading}>
        <CardHeader
          extra={headerExtra}
          title={t('Top URLs on my site with key metrics')}
          tooltip={t(
            'Sections of your sites which got the most views in ranked order. This includes your Firework channel'
          )}
        />
        <PerformanceTable data={data} endDate={endDate} startDate={startDate} />
      </Skeleton>
    </Card>
  )
}

export default InternalRefererTableCard
