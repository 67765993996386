import { createSlice } from '@reduxjs/toolkit'

export type GlobalPopupModals = 'shop_app' | 'tour'

type InitialState = {
  showSearch: boolean
  cmdBarIsOpen: boolean
  showPlaylistToWebsiteBanner: boolean
  showPDPEmbedBanner: boolean
  sidebarCollapsed: boolean
  popupModals: GlobalPopupModals[]
  disableSidebarAnimation: boolean
  hasNewNotifications: boolean
  isLiveChatButtonVisible: boolean
}

const initialState: InitialState = {
  showSearch: false,
  cmdBarIsOpen: false,
  showPlaylistToWebsiteBanner: false,
  showPDPEmbedBanner: false,
  sidebarCollapsed: true,
  popupModals: ['tour', 'shop_app'],
  disableSidebarAnimation: false,
  hasNewNotifications: false,
  isLiveChatButtonVisible: true
}

const slice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    updateShowSearch(state, action) {
      state.showSearch = action.payload
    },
    setCmdBarIsOpen(state) {
      state.cmdBarIsOpen = true
    },
    setCmdBarIsClosed(state) {
      state.cmdBarIsOpen = false
    },
    setShowPlaylistToWebsiteBanner(state, action) {
      state.showPlaylistToWebsiteBanner = action.payload
    },
    setShowPDPEmbedBanner(state, action) {
      state.showPDPEmbedBanner = action.payload
    },
    setIsSidebarCollapsed(state, action) {
      state.sidebarCollapsed = action.payload
    },
    updatePopupModalsHasShown(state, action) {
      if (state.popupModals.includes(action.payload)) {
        state.popupModals = state.popupModals.filter(
          (modal) => modal !== action.payload
        )
      }
    },
    setDisableSidebarAnimation(state, action) {
      state.disableSidebarAnimation = action.payload
    },
    setHasNewNotification(state, action) {
      state.hasNewNotifications = action.payload
    },
    showLiveChatButton(state, action) {
      state.isLiveChatButtonVisible = action.payload
    }
  }
})

export default slice.reducer

export const {
  updateShowSearch,
  setCmdBarIsOpen,
  setCmdBarIsClosed,
  setShowPlaylistToWebsiteBanner,
  setShowPDPEmbedBanner,
  setIsSidebarCollapsed,
  setDisableSidebarAnimation,
  updatePopupModalsHasShown,
  setHasNewNotification,
  showLiveChatButton
} = slice.actions
