import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  BatchImporterMedia,
  BatchImporterMediaType,
  ConsentStatus,
  ImporterSourceFrom
} from '@src/components/BatchImporter/BatchImporterMediaModel'
import api from '@src/utils/api'
import { S3Result } from '@src/utils/s3'
import qs from 'qs'
import { Dispatch } from 'redux'

const SLICE_NAME = 'consentRequests'

export type ConsentRequestsMedia = {
  avatar_url?: string
  caption?: string
  description?: string
  duration?: number
  external_id?: string
  hashtags?: string[]
  likes_count?: number
  published_at?: string
  source?: string
  thumbnail_url?: string
  type?: string
  url?: string
  media_url?: string
  user_id?: string
  username?: string
  children?: {
    data?: ConsentRequestsMedia[]
  }
}

type Business = {
  id: string
  name: string
  website: string
}

export type ConsentRequestsData = {
  business?: Business
  id?: string
  media?: ConsentRequestsMedia
  status?: string
}

type IProps = {
  mediaDataList: ConsentRequestsData[]
  paging?: {
    next?: string
  }
}

const initialState: IProps = {
  mediaDataList: []
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchConsentRequestsMediaListSuccess(state, action) {
      const { mediaDataList, paging, page } = action.payload
      if (page) {
        state.mediaDataList = state.mediaDataList.concat(mediaDataList || [])
        state.paging = paging
      } else {
        state.mediaDataList = mediaDataList
        state.paging = paging
      }
    },
    removeConsentRequestsMediaListSuccess(state, action) {
      const { ids, all } = action.payload
      if (all) {
        state.mediaDataList = []
      } else {
        state.mediaDataList = state.mediaDataList?.filter((item) => {
          return !ids.includes(item.id)
        })
      }
    }
  }
})

export default slice.reducer
export const {
  fetchConsentRequestsMediaListSuccess,
  removeConsentRequestsMediaListSuccess
} = slice.actions

const removeConsentRequestsMediaListRequest = createAction(
  `${SLICE_NAME}/removeConsentRequestsMediaListRequest`
)
const removeConsentRequestsMediaListFailure = createAction(
  `${SLICE_NAME}/removeConsentRequestsMediaListFailure`
)

export function removeConsentRequestsMediaList(params: {
  businessId: string
  ids?: string[]
  all?: boolean
}) {
  const { businessId, ids, all } = params

  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(removeConsentRequestsMediaListRequest())

      const url = `bus/${businessId}/consent_requests/bulk`
      const config = {
        params: {
          ids,
          all: all ? true : undefined
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        }
      }

      const response = await api.delete(url, config)

      dispatch(
        removeConsentRequestsMediaListSuccess({
          businessId,
          ids,
          all
        })
      )

      return response.data
    } catch (error) {
      dispatch(removeConsentRequestsMediaListFailure())

      return error
    }
  }
}

const fetchConsentRequestsMediaListRequest = createAction(
  `${SLICE_NAME}/fetchConsentRequestsMediaListRequest`
)
const fetchConsentRequestsMediaListFailure = createAction(
  `${SLICE_NAME}/fetchConsentRequestsMediaListFailure`
)

export function fetchConsentRequestsMediaList(params: {
  businessId: string
  statuses?: string[]
  page?: string
}) {
  const { businessId, page, statuses } = params

  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchConsentRequestsMediaListRequest())

      if (!page && !statuses?.length) {
        const response = {
          entries: [],
          paging: {}
        }
        const { entries, paging } = response

        dispatch(
          fetchConsentRequestsMediaListSuccess({
            mediaDataList: entries,
            paging,
            page
          })
        )

        return response
      }

      let url = `bus/${businessId}/consent_requests`
      let config = {
        params: {
          statuses,
          page_size: 20
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        }
      }
      if (page) {
        url = page
        config = undefined
      }
      const response = await api.get(url, config)
      const { entries, paging } = response.data
      dispatch(
        fetchConsentRequestsMediaListSuccess({
          mediaDataList: entries,
          paging,
          page
        })
      )

      return response.data
    } catch (error) {
      dispatch(fetchConsentRequestsMediaListFailure())

      return error
    }
  }
}

export const convertConsentRequestsData2BatchImporterMedia = (
  consentRequestsMediaData?: ConsentRequestsData
): BatchImporterMedia | undefined => {
  if (!consentRequestsMediaData) {
    return undefined
  }

  const item = consentRequestsMediaData.media

  const needCrawlerVideo =
    (!item?.media_url || item?.media_url?.indexOf('apify') === -1) &&
    item?.source === ImporterSourceFrom.Tiktok

  return {
    id: item.external_id,
    caption: item.caption,
    mediaType:
      item.type === 'album'
        ? BatchImporterMediaType.CAROUSEL_ALBUM
        : (item.type.toUpperCase() as BatchImporterMediaType),
    thumbnailUrl: item.thumbnail_url,
    username: item.username,
    userId: item.user_id,
    avatar: item.avatar_url,
    mediaUrl: item.media_url,
    signature: getMediaKeyFromConsentRequestsMedia(item),
    sourceUrl: item.url,
    likeCount: item.likes_count,
    timestamp: item.published_at,
    sourceFrom: item.source as ImporterSourceFrom,
    needCrawlerVideo,
    needConsent: true,
    consentStatus: consentRequestsMediaData.status as ConsentStatus,
    consentId: consentRequestsMediaData.id,
    children: item?.children?.data?.map((childrenItem) => {
      return convertConsentRequestsData2BatchImporterMedia({
        id: consentRequestsMediaData.id,
        status: consentRequestsMediaData.status,
        media: childrenItem
      })
    })
  }
}

export const convertBatchImporterMedia2ConsentRequestsData = (
  media: BatchImporterMedia
): ConsentRequestsData => {
  const children = media?.children?.map((item) => {
    const consentRequestsData = convertBatchImporterMedia2ConsentRequestsData(
      item
    )

    return consentRequestsData?.media
  })

  return {
    id: undefined,
    status: media.consentStatus,
    media: {
      type:
        media?.mediaType === BatchImporterMediaType.CAROUSEL_ALBUM
          ? 'album'
          : media?.mediaType?.toLowerCase(),
      description: media?.caption,
      source: media.sourceFrom,
      url: media?.sourceUrl,
      media_url: media?.mediaUrl,
      hashtags: media?.hashtags,
      username: media?.username,
      thumbnail_url: media?.thumbnailUrl,
      avatar_url: media?.avatar,
      published_at: media?.timestamp,
      external_id: media?.id,
      user_id: media?.userId,
      caption: media?.caption,
      children: {
        data: children
      },
      likes_count: media?.likeCount
    }
  }
}

export const getMediaKeyFromConsentRequestsMedia = (
  media?: ConsentRequestsMedia
): S3Result | undefined => {
  if (media?.media_url?.startsWith('medias/')) {
    return { key: media.media_url }
  }

  return undefined
}
