import React from 'react'

import { css } from '@emotion/core'
import theme from '@src/styles/theme'

const FormErrorText = (msg: string | undefined): JSX.Element => {
  if (!msg) return <></>

  return (
    <div
      css={css`
        color: ${theme.danger};
      `}
    >
      {msg}
    </div>
  )
}

export default FormErrorText
