import React from 'react'

import { css } from '@emotion/core'
import theme from '@src/styles/theme'
import { Tabs as AntdTabs, TabsProps, TabPaneProps } from 'antd'
import { useSpace } from 'fwego'

type Props = {
  className?: string
}

const Tabs: React.FC<Props & TabsProps> = ({
  className = null,
  children,
  ...props
}) => {
  return (
    <AntdTabs
      css={css`
        overflow: visible;
        padding: 0;
        margin: 0;
        .ant-tabs-bar {
          padding: 0 ${useSpace('large')};
          margin: 0;
          background: ${theme.white};
        }
        ${className}
      `}
      {...props}
    >
      {children}
    </AntdTabs>
  )
}

export const TabPane: React.FC<TabPaneProps> = ({ children, ...props }) => (
  <AntdTabs.TabPane {...props}>{children}</AntdTabs.TabPane>
)

export default Tabs
