import React, { useState, useEffect, useRef } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { css } from '@emotion/core'
import { Tag, Input, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

interface Props {
  tags: string[]
  setTags: (tags: string[]) => void
  placeholder?: string
  onAddTag?: (tag: string) => void
  onRemoveTag?: (tag: string) => void
  disabled?: boolean
}

const FormTag: React.FC<Props> = ({
  tags,
  setTags,
  placeholder,
  disabled = false,
  onAddTag,
  onRemoveTag
}): JSX.Element => {
  const { t } = useTranslation()

  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [editInputIndex, setEditInputIndex] = useState(-1)
  const [editInputValue, setEditInputValue] = useState('')

  useEffect(() => {
    if (inputVisible) {
      inputRef?.current?.focus()
    }
  }, [inputVisible])

  const handleClose = (removedTag) => {
    const filteredTags = tags.filter((tag) => tag !== removedTag)
    setTags(filteredTags)
    if (onRemoveTag) {
      onRemoveTag(removedTag)
    }
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleInputConfirm = () => {
    let newTags = tags.slice()
    if (inputValue && tags.indexOf(inputValue) === -1) {
      const inputTags = inputValue.split(',')
      newTags = [...tags, ...inputTags]
    }
    setTags(newTags)
    setInputVisible(false)
    setInputValue('')
    if (onAddTag) {
      onAddTag(inputValue)
    }
  }

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value)
  }

  const handleEditInputConfirm = () => {
    const newTags = [...tags]
    newTags[editInputIndex] = editInputValue

    return {
      tags: newTags,
      editInputIndex: -1,
      editInputValue: ''
    }
  }

  const inputRef = useRef<any>(null)
  const editInputRef = useRef<any>(null)

  return (
    <div
      css={css`
        padding: 6.5px 11px;
        width: 100%;
        color: #404040;
        font-size: 14px;
        line-height: 1.5715;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
      `}
    >
      {tags.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={editInputRef}
              maxLength={255}
              key={tag}
              size="small"
              className="tag-input"
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          )
        }

        const isLongTag = tag.length > 20

        const tagElem = (
          <Tag
            className="edit-tag"
            key={tag}
            closable={!disabled}
            onClose={() => handleClose(tag)}
            css={css`
              margin-bottom: 10px;
            `}
          >
            <span
              onDoubleClick={(e) => {
                if (index !== 0) {
                  setEditInputIndex(index)
                  setEditInputValue(tag)
                  setTimeout(() => {
                    editInputRef?.current?.focus()
                  }, 0)
                  e.preventDefault()
                }
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        )

        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}
      {inputVisible && (
        <Input
          ref={inputRef}
          maxLength={255}
          type="text"
          size="small"
          className="tag-input"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && !disabled && (
        <Tag className="site-tag-plus" onClick={showInput}>
          <PlusOutlined /> {placeholder || t('New tag')}
        </Tag>
      )}
    </div>
  )
}

export default FormTag
