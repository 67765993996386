import React from 'react'

import { Modal, ModalProps } from 'antd'

export const FWModal: React.FC<ModalProps> = ({
  children,
  ...props
}): JSX.Element => {
  return <Modal {...props}>{children}</Modal>
}
