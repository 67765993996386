import React from 'react'

import { css } from '@emotion/core'

const InputErrorText = (message: string): JSX.Element => {
  return (
    <div
      data-cy="input-error-text"
      css={css`
        color: red;
      `}
    >
      {message}
    </div>
  )
}

export default InputErrorText
