import React from 'react'

import { css } from '@emotion/core'
import { Box, Flex } from '@src/components/EmotionLayout'

type IProps = {
  visible?: boolean
  onClose?: () => void
  modalProps?: Record<string, any>
  children?: React.ReactNode
  zIndex?: number
}

const PopupModal = ({
  visible,
  onClose,
  modalProps,
  children,
  ...props
}: IProps): JSX.Element => {
  const onCloseButtonClick = () => onClose()

  return (
    <Box
      width="100%"
      height="100%"
      pt="xxxlarge"
      position="fixed"
      top={0}
      right={0}
      bottom={0}
      left={0}
      bg="rgba(0, 0, 0, 0.8)"
      display={visible ? 'block' : 'none'}
      overflowY="scroll"
      zIndex={2}
      onClick={onCloseButtonClick}
      css={css`
        backdrop-filter: blur(8px);
      `}
      {...props}
    >
      <Flex
        width="100%"
        maxWidth="500"
        mx="auto"
        flexDirection="column"
        alignItems="center"
        borderRadius="12"
        bg="#ffffff"
        onClick={(e) => e.stopPropagation()}
        {...modalProps}
      >
        {children}
      </Flex>
    </Box>
  )
}

export default PopupModal
