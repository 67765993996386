import { useCallback, useEffect } from 'react'

import { useAppSelector } from '@src/app/hooks'
import { GlobalPopupModals, updatePopupModalsHasShown } from '@src/redux/ui'
import { useDispatch } from 'react-redux'

export const isModalShownFromStorage = (modalName: GlobalPopupModals) => {
  return localStorage.getItem(`fw_global_modal_${modalName}`) === 'true'
}

export const setModalShownInStorage = (modalName: GlobalPopupModals) => {
  localStorage.setItem(`fw_global_modal_${modalName}`, 'true')
}

export const useGlobalPopupModals = (modalName: GlobalPopupModals) => {
  const dispatch = useDispatch()
  const { popupModals } = useAppSelector((state) => state.ui)

  const shouldShowModalGlobal =
    popupModals.indexOf(modalName) === 0 && !isModalShownFromStorage(modalName)

  const hasShowModal = useCallback(() => {
    dispatch(updatePopupModalsHasShown(modalName))
  }, [dispatch, modalName])

  useEffect(() => {
    if (isModalShownFromStorage(modalName)) {
      hasShowModal()
    }
  }, [hasShowModal, modalName])

  return { shouldShowModalGlobal, hasShowModal }
}
