import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { EditOutlined, PlusCircleFilled } from '@ant-design/icons'
import { css } from '@emotion/core'
import { FWButton } from '@src/components'
import { Box, Flex } from '@src/components/EmotionLayout'
import { ModalFooter } from '@src/components/ModalFooter'
import { AnimatedPosterPayload } from '@src/components/Poster/FormPoster'
import { MIN_VIDEO_DURATION, MIN_FILE_SIZE } from '@src/constants'
import { useToast } from '@src/hooks/useToast'
import { useVideoLimit } from '@src/hooks/useVideoLimit'
import { useVUContext } from '@src/pages/channel/ChannelVideoUpload/components/VUContext'
import theme from '@src/styles/theme'
import { getS3Signature, uploadFileToS3 } from '@src/utils/s3'
import type { UploadFile, UploadProps } from 'antd'
import { Button, Modal, Tooltip, Typography, Upload } from 'antd'
import { Text } from 'fwego'
import throttle from 'lodash/throttle'
import type {
  UploadRequestOption,
  UploadProgressEvent
} from 'rc-upload/lib/interface'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import { useParams } from 'react-router'

import PosterPreview from './PosterPreview'
import Slider from './Slider'

interface CustomPosterProps {
  video?: any
  // maybe horizontal if video is vertical or maybe vertical if video is horizontal
  animatedVideoPoster?: globalLib.VideoPoster
  enableHorizontalPoster?: boolean
  isHorizontalVideo?: boolean // true if the video is horizontal
  setNewAnimatedPosterPayload?: (payload: AnimatedPosterPayload) => void
  setCustomGifAnimatedPosterPayload?(
    posterPayload?: AnimatedPosterPayload | null
  ): void
  setChangedPosters: (value: boolean) => void
  onDiscardChanges?: () => void
  size?: number
}

const MIN = 1
const DEFAULT = 3
const MAX = 10

const throttleSeek = throttle((player, value) => {
  if (player.current && player.current.getInternalPlayer()) {
    player.current.seekTo(value)
  }
}, 300)

const getRatio = (value) => {
  const ratios = ['9:16', '3:4', '4:5', '1:1', '5:4', '4:3', '16:9']
  const ratios2 = [0.5625, 0.75, 0.8, 1.0, 1.25, 1.3333, 1.7778]
  const index = ratios2.reduce(
    (a, b, i) => Math.abs(b - value < Math.abs(ratios2[a] - value) ? i : a),
    0
  )

  return ratios[index]
}

const CustomAnimatedPoster = (props: CustomPosterProps): JSX.Element => {
  const { t } = useTranslation()
  const { state } = useVUContext()
  const { videoUrl, mediaKey, newAnimatedPosterPayload } = state
  const {
    video,
    enableHorizontalPoster = false,
    isHorizontalVideo = false,
    animatedVideoPoster,
    setChangedPosters,
    setNewAnimatedPosterPayload,
    setCustomGifAnimatedPosterPayload,
    onDiscardChanges,
    size
  } = props
  const [showCustomPosterModal, setShowCustomPosterModal] = useState(false)
  const [leftValue, setLeftValue] = useState(0)
  const [rightValue, setRightValue] = useState(DEFAULT)
  const [createdPoster, setCreatedPoster] = useState<boolean>(false)
  const [duration, setDuration] = useState(0)
  const player = useRef<ReactPlayer>(null)
  const posterPayload = useRef<AnimatedPosterPayload>()
  const savedPosterBasedOnOriginalVideo = useRef<boolean>(false)
  const [customVideoUrl, setCustomVideoUrl] = useState<string | null>(null)
  const [customVideoKey, setCustomVideoKey] = useState<string | null>(null)
  const [uploadFileWH, setUploadFileWH] = useState<
    { width: number; height: number } | undefined
  >()
  const [uploading, setUploading] = useState(false)
  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([])
  const accept = ['.mp4']
  const currentPlayedSeconds = useRef<number>(0)
  const initialPlaybackSeconds = useRef<number>(0)
  const [isPlayerReady, setIsPlayerReady] = useState<boolean>(false)
  const playerUrl = customVideoUrl || videoUrl
  const videoWidth = player.current?.getInternalPlayer()?.videoWidth

  const { errorToast, warningToast, infoToast } = useToast()
  const { businessId, channelId } = useParams()
  const { maxVideoSize, maxVideoDuration } = useVideoLimit({
    businessId,
    channelId
  })

  const isWide = useMemo(() => {
    if (!enableHorizontalPoster) {
      return false
    }

    if (createdPoster && customVideoKey) {
      return uploadFileWH?.width > uploadFileWH?.height ?? false
    } else if (createdPoster) {
      return isHorizontalVideo
    } else if (animatedVideoPoster) {
      return animatedVideoPoster.orientation === 'horizontal'
    } else {
      return isHorizontalVideo
    }
  }, [
    enableHorizontalPoster,
    customVideoKey,
    animatedVideoPoster,
    uploadFileWH,
    createdPoster,
    isHorizontalVideo
  ])

  const width = !isWide ? size : (size * 164) / 99
  const height = !isWide ? (size * 164) / 99 : size

  const getVideoTime = (value: number) => Math.floor(value * 10) / 10

  const handleCustomRequest = async ({
    file,
    onProgress,
    onError,
    onSuccess
  }: UploadRequestOption) => {
    try {
      const signature = await getS3Signature(file as File)
      const { key } = await uploadFileToS3(
        file as File,
        signature,
        (percent) => {
          onProgress({ percent } as UploadProgressEvent)
        }
      )
      onSuccess({ key }, undefined)
    } catch (error) {
      onError(error)
    }
  }

  const handleRemoveCustomVideo = () => {
    setUploadFileList([])
    setCustomVideoUrl(null)
    setCustomVideoKey(null)
    setUploadFileWH(null)
    setLeftValue(0)
    setRightValue(DEFAULT)
    setDuration(0)
  }

  const uploadProps: UploadProps = {
    name: 'file',
    accept: accept.join(','),
    disabled: uploading,
    multiple: false,
    fileList: uploadFileList,
    showUploadList: { showRemoveIcon: false },
    beforeUpload: async (file) => {
      const fileExtension = `.${file.name.split('.').pop().toLowerCase()}`
      if (!accept.includes(fileExtension)) {
        errorToast(
          t('{{file}} file is in the wrong format', {
            file: file.name
          })
        )

        return false
      }

      if (file.size < MIN_FILE_SIZE || file.size > maxVideoSize * 1_000_000) {
        const tooSmall = file.size < MIN_FILE_SIZE
        const fileSize = tooSmall
          ? `${MIN_FILE_SIZE / 1000}Kb`
          : `${maxVideoSize}MB`

        const errorMsg = tooSmall
          ? t('{{file}} file is smaller than {{fileSize}}', {
              file: file.name,
              fileSize: fileSize
            })
          : t('{{file}} file is larger than {{fileSize}}', {
              file: file.name,
              fileSize: fileSize
            })

        errorToast(errorMsg)

        return false
      } else {
        setUploading(true)
      }
    },
    customRequest: (options) => {
      handleCustomRequest(options)
    },
    onChange: (info) => {
      let newFileList = [...info.fileList]
      if (newFileList.length > 1) {
        newFileList = newFileList.slice(-1)
      }
      const { status } = info.file
      setUploadFileList(newFileList)
      if (status === 'done') {
        setUploading(false)
        const URL = window.URL || window.webkitURL
        const { key } = info.file.response
        setCustomVideoUrl(URL.createObjectURL(info.file.originFileObj))
        setCustomVideoKey(key)
        setLeftValue(0)
        setRightValue(DEFAULT)
      } else if (status === 'error') {
        setUploading(false)
        errorToast(t('{{file}} file upload failed', { file: info.file.name }))
      }
    }
  }

  const checkCustomVideoDuration = (duration: number) => {
    if (duration < MIN_VIDEO_DURATION) {
      warningToast(
        t('Please upload a video longer than {{second}} seconds', {
          second: MIN_VIDEO_DURATION
        })
      )

      return false
    } else if (duration > maxVideoDuration) {
      warningToast(
        t(
          'Please upload a video no longer than' +
            ' {{maxVideoDuration}} seconds',
          { maxVideoDuration }
        )
      )

      return false
    }

    return true
  }

  const onCancel = () => {
    setShowCustomPosterModal(false)
  }
  useEffect(() => {
    if (newAnimatedPosterPayload) {
      posterPayload.current = newAnimatedPosterPayload
    }
  }, [newAnimatedPosterPayload])

  useEffect(() => {
    if (isPlayerReady) {
      throttleSeek(player, getVideoTime(initialPlaybackSeconds.current))
    }
  }, [isPlayerReady])

  useEffect(() => {
    if (!showCustomPosterModal) {
      setIsPlayerReady(false)
    }
  }, [showCustomPosterModal])

  useEffect(() => {
    if (savedPosterBasedOnOriginalVideo.current) {
      setUploadFileList([])
      setCustomVideoUrl(null)
      setCustomVideoKey(null)
      setUploadFileWH(null)
      setLeftValue(0)
      setRightValue(DEFAULT)
      setCreatedPoster(false)
      onDiscardChanges?.()
      savedPosterBasedOnOriginalVideo.current = false
    }
  }, [mediaKey, onDiscardChanges])

  const onSaveClick = () => {
    const videoWidth = player.current?.getInternalPlayer()?.videoWidth
    const videoHeight = player.current?.getInternalPlayer()?.videoHeight
    setChangedPosters(true)
    const key = customVideoKey ? customVideoKey : mediaKey || undefined
    setNewAnimatedPosterPayload?.({
      key,
      height: videoHeight,
      width: videoWidth,
      start_time: getVideoTime(leftValue),
      duration: getVideoTime(rightValue - leftValue),
      format: 'webp',
      aspect_ratio:
        videoWidth && videoHeight
          ? getRatio(videoWidth / videoHeight)
          : undefined,
      custom_video: customVideoKey || (mediaKey && video) ? true : undefined
    })
    setCustomGifAnimatedPosterPayload?.(null)
    setCreatedPoster(true)
    setShowCustomPosterModal(false)
    savedPosterBasedOnOriginalVideo.current = !customVideoKey

    infoToast(
      animatedVideoPoster
        ? t(
            'The animated poster preview will be available after you Save and Update shortly.'
          )
        : t(
            'The animated poster preview will be available after the video is created shortly.'
          )
    )
  }

  const handleLeftChange = useCallback(
    (value) => {
      const distance = rightValue - value
      if (distance >= MIN && distance <= MAX) {
        setLeftValue(value)
        throttleSeek(player, getVideoTime(value))
      } else if (distance < MIN) {
        const newStart = Math.floor(value * 10) / 10
        const newEnd = parseFloat((newStart + MIN).toFixed(1))
        if (newEnd <= duration) {
          setLeftValue(newStart)
          setRightValue(newEnd)
          throttleSeek(player, getVideoTime(newStart))
        } else {
          setLeftValue(duration - MIN)
          setRightValue(duration)
          throttleSeek(player, getVideoTime(duration - MIN))
        }
      } else if (distance > MAX) {
        const newStart = Math.floor(value * 10) / 10
        const newEnd = parseFloat((newStart + MAX).toFixed(1))
        setLeftValue(newStart)
        setRightValue(newEnd)
        throttleSeek(player, getVideoTime(newStart))
      }
    },
    [rightValue, duration]
  )

  const handleRightChange = useCallback(
    (value) => {
      const distance = value - leftValue
      if (distance >= MIN && distance <= MAX) {
        setRightValue(value)
        throttleSeek(player, getVideoTime(value))
      } else if (distance < MIN) {
        const newEnd = Math.floor(value * 10) / 10
        const newStart = parseFloat((newEnd - MIN).toFixed(1))
        if (newStart >= 0) {
          setLeftValue(newStart)
          setRightValue(newEnd)
          throttleSeek(player, getVideoTime(newEnd))
        } else {
          setLeftValue(0)
          setRightValue(MIN)
          throttleSeek(player, getVideoTime(MIN))
        }
      } else if (distance > MAX) {
        const newEnd = Math.floor(value * 10) / 10
        const newStart = parseFloat((newEnd - MAX).toFixed(1))
        setLeftValue(newStart)
        setRightValue(newEnd)
        throttleSeek(player, getVideoTime(newEnd))
      }
    },
    [leftValue]
  )

  const handleOnPlay = () => {
    if (currentPlayedSeconds.current >= getVideoTime(rightValue)) {
      throttleSeek(player, getVideoTime(leftValue))
    }
  }

  const handleOnProgress = (state) => {
    const { playedSeconds } = state
    if (playedSeconds >= getVideoTime(rightValue)) {
      player.current?.getInternalPlayer().pause()
    }

    currentPlayedSeconds.current = playedSeconds
  }

  const title = isWide ? t('Add Horizontal') : t('Add Vertical')

  return (
    <>
      <Tooltip
        placement="top"
        title={
          videoUrl
            ? null
            : t(
                'The Posters will become available after you upload your main video.'
              )
        }
      >
        <Flex
          justifyContent="end"
          alignItems="end"
          onClick={() =>
            animatedVideoPoster || videoUrl
              ? setShowCustomPosterModal(true)
              : setShowCustomPosterModal(false)
          }
          cursor={videoUrl ? 'pointer' : 'default'}
          position="relative"
        >
          {createdPoster ? (
            <Flex
              borderRadius={4}
              width={width}
              height={height}
              overflow="hidden"
            >
              <PosterPreview
                playerUrl={playerUrl}
                startTime={getVideoTime(leftValue)}
                endTime={getVideoTime(rightValue)}
              />
            </Flex>
          ) : (
            <Flex
              justifyContent="center"
              textAlign="center"
              alignItems="center"
              height={height}
              minWidth={width}
              p={10}
              fontSize={12}
              fontWeight={500}
              bg="#fafafa"
              backgroundImage={`url("${animatedVideoPoster?.url}")`}
              backgroundSize="cover"
              backgroundPosition="center center"
              borderRadius={4}
              border={animatedVideoPoster ? 'none' : '1px dashed #d9d9d9'}
            >
              {!animatedVideoPoster && (
                <Box>
                  <Text
                    size="xsmall"
                    style={{ color: videoUrl ? theme.text : '#8c8c8c' }}
                  >
                    {title} <br />
                    {t('Poster')}
                  </Text>
                  <PlusCircleFilled
                    style={{
                      color: videoUrl ? theme.primary : '#bfbfbf',
                      fontSize: '24px',
                      marginTop: '4px'
                    }}
                  />
                </Box>
              )}
            </Flex>
          )}
          {(animatedVideoPoster || createdPoster) && (
            <>
              <Button
                ghost
                type="dashed"
                style={{
                  position: 'absolute',
                  zIndex: 2,
                  margin: '4px',
                  border: 'none'
                }}
                icon={<EditOutlined style={{ fontSize: '20px' }} />}
              />
              <Box
                height={40}
                position="absolute"
                width={width}
                zIndex={1}
                bottom={0}
                borderRadius="0 0 4px 4px"
                bg="linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))"
              />
            </>
          )}
        </Flex>
      </Tooltip>
      <Modal
        open={showCustomPosterModal}
        maskClosable={false}
        onCancel={onCancel}
        destroyOnClose
        centered
        closable={false}
        footer={null}
        width={500}
        title={t('Custom Poster Selector')}
      >
        <Flex flexDirection="column" alignItems="center" width="100%">
          <Box mb="medium">
            <Typography.Paragraph>
              {t(
                'Use the scrubber below to select which part of the video you' +
                  ' want to use for your 1 - 10 seconds animated poster.'
              )}
            </Typography.Paragraph>
          </Box>
          <Flex
            justifyContent="center"
            maxWidth="300px"
            maxHeight="400"
            style={{ position: 'relative' }}
          >
            <ReactPlayer
              ref={player}
              url={playerUrl}
              muted
              width={(videoWidth ?? 0) / 2 || undefined}
              playing={false}
              onPlay={handleOnPlay}
              onProgress={handleOnProgress}
              progressInterval={200}
              controls={true}
              stopOnUnmount={true}
              style={{
                maxWidth: '300px',
                maxHeight: '400px'
              }}
              onDuration={(duration) => {
                if (customVideoUrl && !checkCustomVideoDuration(duration)) {
                  setUploadFileList([])
                  setCustomVideoUrl(null)
                  setCustomVideoKey(null)
                  setUploadFileWH(null)
                  setLeftValue(0)
                  setRightValue(DEFAULT)
                  setDuration(0)

                  return
                }

                setDuration(Math.round(duration * 10) / 10)
              }}
              onReady={() => {
                initialPlaybackSeconds.current = leftValue
                setIsPlayerReady(true)
                const videoWidth = player.current?.getInternalPlayer()
                  ?.videoWidth
                const videoHeight = player.current?.getInternalPlayer()
                  ?.videoHeight
                setUploadFileWH({
                  width: videoWidth,
                  height: videoHeight
                })
              }}
            />
            <Box
              as="img"
              src="/delete_icon.svg"
              position="absolute"
              right="10"
              top="10"
              zIndex="100"
              width="40"
              height="40"
              bg="grey"
              borderRadius="50%"
              opacity="0.5"
              cursor="pointer"
              onClick={handleRemoveCustomVideo}
              display={customVideoUrl ? 'block' : 'none'}
              css={css`
                &:hover {
                  background: black;
                }
              `}
            />
          </Flex>
          <Flex width="100%" my="large">
            <Slider
              max={duration}
              onLeftChange={handleLeftChange}
              onRightChange={handleRightChange}
              leftValue={leftValue}
              rightValue={rightValue}
              maxRange={MAX}
              minRange={MIN}
              marks={{
                0: '0s',
                3: '3s',
                [duration]: `${duration}s`
              }}
            />
          </Flex>
        </Flex>
        <Flex width="100%" justifyContent="flex-end">
          <Typography.Text>
            {t(
              'The change will be applied in a few minutes. If not, please try it again.'
            )}
          </Typography.Text>
        </Flex>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%" gap={10}>
            <Box overflow="hidden">
              <Upload {...uploadProps}>
                <FWButton key="upload">{t('Upload')}</FWButton>
              </Upload>
            </Box>
            <Flex flexShrink="0">
              <FWButton
                key="cancel"
                onClick={onCancel}
                style={{ marginRight: 10 }}
              >
                {t('Cancel')}
              </FWButton>
              <FWButton type="primary" key="save" onClick={onSaveClick}>
                {t('Save')}
              </FWButton>
            </Flex>
          </Flex>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default CustomAnimatedPoster
