import { css } from '@emotion/core'
import styled from '@emotion/styled'
import {
  Menu,
  MenuButton,
  MenuLink,
  MenuList,
  MenuItem
} from '@reach/menu-button'

const StyledMenuList = styled(MenuList)`
  border: 0;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 1px rgba(0, 21, 41, 0.08);
  width: 210px;
  margin-left: 11px;
`

const StyledMenuItem = styled<any>(MenuItem)`
  padding-top: 12px;
  padding-bottom: 12px;
  ${({ selected }) =>
    selected &&
    css`
      color: #fff !important;
      background-color: #f0f2f5 !important;
      color: black !important;
    `};
  }
  &[data-selected] {
    color: inherit;
    background-color: rgb(215, 230, 250);
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
  }
`

const StyledMenuLink = StyledMenuItem.withComponent(MenuLink)

export {
  Menu,
  MenuButton,
  StyledMenuLink as MenuLink,
  StyledMenuList as MenuList,
  StyledMenuItem as MenuItem
}
