import React, { useEffect } from 'react'

import { MessageOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { useAppSelector } from '@src/app/hooks'
import { showLiveChatButton } from '@src/redux/ui'
import { Flex } from 'fwego'
import { useDispatch } from 'react-redux'

export const Widget: React.FC = () => {
  const dispatch = useDispatch()
  const isLiveChatButtonVisible = useAppSelector(
    (state) => state.ui.isLiveChatButtonVisible
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const startChat = () => {
    if (window._fwn && window._fwn.liveHelper) {
      // _fwn is available, use the API immediately
      window._fwn.liveHelper.actions.startChat()
    } else {
      // Wait for fw:ready event
      document.addEventListener('fw:ready', () => {
        window._fwn.liveHelper.actions.startChat()
      })
    }
    dispatch(showLiveChatButton(false))
  }

  // Inject One to One script
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://asset.fwcdn3.com/js/live-helper.js'
    script.async = true
    script.dataset.fwparam_chat_channel_id = 'Zg20d5'
    document.head.appendChild(script)
  }, [])

  return (
    <>
      {isLiveChatButtonVisible && (
        <fw-agent-status action="startChat">
          {isLiveChatButtonVisible && (
            <Flex
              slot="available"
              position="fixed"
              bottom="42"
              right="42"
              width="56"
              height="56"
              borderRadius="100"
              zIndex={100}
              onClick={startChat}
              cursor="pointer"
              css={css`
                background-color: #001b40;
                box-shadow: 0px 2px 8px rgb(0, 0, 0, 0.25);
              `}
              justifyContent="center"
              alignItems="center"
            >
              <MessageOutlined style={{ fontSize: '32px', color: '#fff' }} />
            </Flex>
          )}
        </fw-agent-status>
      )}
    </>
  )
}
