import React from 'react'

import { Box, Flex } from '@src/components/EmotionLayout'

interface Props {
  hard?: boolean
}

const CornerDisplay: React.FC<Props> = ({ hard = false }): JSX.Element => {
  return (
    <Flex
      bg="#175a99"
      width="63px"
      height="80px"
      borderRadius={`${hard ? '0' : '6'}px`}
      justifyContent="center"
      alignItems="center"
    >
      <Box as="img" width="1.2em" height="1.2em" src="/play_white_icon.svg" />
    </Flex>
  )
}

export default CornerDisplay
