import {
  createAction,
  createAsyncThunk,
  createSlice,
  Slice,
  SliceCaseReducers
} from '@reduxjs/toolkit'
import api, { getBasePath } from '@src/utils/api'

const ApiClient = (businessId, channelId) => {
  const resource = 'playlists'
  const basePath = getBasePath(businessId, channelId)
  const path = `${basePath}${resource}`

  return {
    playlists: {
      list: (params: { q: string; sort: string; page_size?: number }) =>
        api.get(path, { params }),
      listNext: (next: string) => api.get(next)
    },
    playlistGroups: {
      listGroups: (params: { page_size?: number } = null) =>
        api.get(`${path}/groups`, { params }),
      listGroupsNext: (next: string) => api.get(next),
      getGroup: (groupId: string) => api.get(`${path}/groups/${groupId}`),
      createGroup: (data: { name: string }) => api.post(`${path}/groups`, data),
      updateGroup: (id: string, data: { name: string }) =>
        api.patch(`${path}/groups/${id}`, data),
      deleteGroup: (id: string) => api.delete(`${path}/groups/${id}`),
      addPlaylistsToGroup: (
        groupId: string,
        data: { playlist_ids: string[] }
      ) => api.patch(`${path}/groups/${groupId}`, data),
      movePlaylistInGroup: (
        groupId: string,
        playlistId: string,
        data: { index: number }
      ) => api.put(`${path}/groups/${groupId}/playlist/${playlistId}`, data),
      removePlaylistFromGroup: (groupId: string, playlistId: string) =>
        api.delete(`${path}/groups/${groupId}/playlist/${playlistId}`)
    }
  }
}

const SLICE_NAME = 'playlists'

type PlaylistState = {
  searchedPlaylist: globalLib.Playlist[]
  searchedPlaylistPaging: globalLib.Paging
  channelPlaylistGroups: globalLib.PlaylistGroup[]
  channelPlaylistGroupsPaging: globalLib.Paging
  channelPlaylistGroup: globalLib.PlaylistGroup
  loading: boolean
}

const initialState: PlaylistState = {
  searchedPlaylist: [],
  searchedPlaylistPaging: null,
  channelPlaylistGroups: [],
  channelPlaylistGroupsPaging: null,
  channelPlaylistGroup: null,
  loading: false
}

export const searchAndSortPlaylists = createAsyncThunk(
  `${SLICE_NAME}/searchAndSortPlaylists`,
  async (params: {
    businessId: string
    channelId: string
    q: string
    sort: string
    page?: globalLib.Paging
  }) => {
    const client = ApiClient(params.businessId, params.channelId)
    const response = params.page
      ? await client.playlists.listNext(params.page.next)
      : await client.playlists.list({
          q: params.q?.length ? params.q : undefined,
          sort: params.sort,
          page_size: 5
        })
    const { data } = response
    const { playlists, paging } = data

    return { playlists, paging, page: params.page }
  }
)

export const fetchPlaylistGroups = createAsyncThunk(
  `${SLICE_NAME}/fetchPlaylistGroups`,
  async (params: {
    businessId: string
    channelId: string
    page?: globalLib.Paging
    pageSize?: number
  }) => {
    const client = ApiClient(params.businessId, params.channelId)
    const response = params?.page?.next
      ? await client.playlistGroups.listGroupsNext(params.page.next)
      : await client.playlistGroups.listGroups({
          page_size: params.pageSize || 100
        })
    const { data } = response
    const { playlist_groups, paging } = data

    return { playlist_groups, paging }
  }
)

export const fetchPlaylistGroup = createAsyncThunk(
  `${SLICE_NAME}/fetchPlaylistGroup`,
  async (params: {
    businessId: string
    channelId: string
    groupId: string
  }) => {
    const client = ApiClient(params.businessId, params.channelId)
    const response = await client.playlistGroups.getGroup(params.groupId)
    const { data: playlist_group } = response

    return playlist_group
  }
)

export const createPlaylistGroup = createAsyncThunk(
  `${SLICE_NAME}/createPlaylistGroup`,
  async (params: { businessId: string; channelId: string; name: string }) => {
    const client = ApiClient(params.businessId, params.channelId)
    const response = await client.playlistGroups.createGroup({
      name: params.name
    })
    const { data: playlistGroup } = response

    return playlistGroup
  }
)

export const editPlaylistGroup = createAsyncThunk(
  `${SLICE_NAME}/editPlaylistGroup`,
  async (params: {
    businessId: string
    channelId: string
    groupId: string
    name: string
  }) => {
    const client = ApiClient(params.businessId, params.channelId)
    const response = await client.playlistGroups.updateGroup(params.groupId, {
      name: params.name
    })
    const { data: playlistGroup } = response

    return playlistGroup
  }
)

export const deletePlaylistGroup = createAsyncThunk(
  `${SLICE_NAME}/deletePlaylistGroup`,
  async (params: {
    businessId: string
    channelId: string
    groupId: string
  }) => {
    const client = ApiClient(params.businessId, params.channelId)
    await client.playlistGroups.deleteGroup(params.groupId)

    return params.groupId
  }
)

export const addPlaylistsToPlaylistGroup = createAsyncThunk(
  `${SLICE_NAME}/addPlaylistsToPlaylistGroup`,
  async (params: {
    businessId: string
    channelId: string
    groupId: string
    playlistIds: string[]
  }) => {
    const client = ApiClient(params.businessId, params.channelId)
    await client.playlistGroups.addPlaylistsToGroup(params.groupId, {
      playlist_ids: params.playlistIds
    })
  }
)

export const insertPlaylistInPlaylistGroup = createAsyncThunk(
  `${SLICE_NAME}/insertPlaylistInPlaylistGroup`,
  async (params: {
    businessId: string
    channelId: string
    groupId: string
    playlistId: string
    index: number
  }) => {
    const client = ApiClient(params.businessId, params.channelId)
    const response = await client.playlistGroups.movePlaylistInGroup(
      params.groupId,
      params.playlistId,
      {
        index: params.index ?? 0
      }
    )

    return {
      playlistGroup: {
        ...response.data
      }
    }
  }
)

export const removePlaylistInPlaylistGroup = async (params: {
  businessId: string
  channelId: string
  groupId: string
  playlistId: string
}): Promise<void> => {
  const client = ApiClient(params.businessId, params.channelId)
  await client.playlistGroups.removePlaylistFromGroup(
    params.groupId,
    params.playlistId
  )
}

export const searchPlaylists = async (params: {
  businessId: string
  channelId: string
  q: string
  page_size?: number
}): Promise<globalLib.Playlist[]> => {
  const client = ApiClient(params.businessId, params.channelId)
  const response = await client.playlists.list({
    q: params.q.length ? params.q : undefined,
    sort: '',
    page_size: params.page_size || 30
  })
  const { data } = response
  const { playlists } = data

  return playlists
}

const slice: Slice<
  PlaylistState,
  SliceCaseReducers<PlaylistState>,
  typeof SLICE_NAME
> = createSlice({
  name: SLICE_NAME,
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(searchAndSortPlaylists.fulfilled, (state, action) => {
        const { playlists, paging, page } = action.payload
        state.searchedPlaylist = page
          ? [...state.searchedPlaylist, ...playlists]
          : playlists
        state.searchedPlaylistPaging = paging
      })
      .addCase(fetchPlaylistGroups.fulfilled, (state, action) => {
        const { playlist_groups, paging } = action.payload
        state.channelPlaylistGroups = paging?.next
          ? [...state.channelPlaylistGroups, ...playlist_groups]
          : playlist_groups
        state.channelPlaylistGroupsPaging = paging
      })
      .addCase(fetchPlaylistGroup.pending, (state) => {
        state.channelPlaylistGroup = null
        state.loading = true
      })
      .addCase(fetchPlaylistGroup.fulfilled, (state, action) => {
        state.loading = false
        state.channelPlaylistGroup = action.payload
      })
      .addCase(createPlaylistGroup.fulfilled, (state, action) => {
        state.channelPlaylistGroups = [
          action.payload,
          ...state.channelPlaylistGroups
        ]
      })
      .addCase(editPlaylistGroup.fulfilled, (state, action) => {
        const { id, name } = action.payload
        const index = state.channelPlaylistGroups.findIndex(
          (group) => group.id === id
        )
        state.channelPlaylistGroups[index].name = name
      })
      .addCase(deletePlaylistGroup.fulfilled, (state, action) => {
        const groupId = action.payload
        state.channelPlaylistGroups = state.channelPlaylistGroups.filter(
          (group) => group.id !== groupId
        )
      })
      .addCase(insertPlaylistInPlaylistGroup.fulfilled, (state, action) => {
        const { playlistGroup } = action.payload
        state.channelPlaylistGroup = playlistGroup
      })
  },
  reducers: {
    resetPlaylistSearchAndPaging: (state) => {
      state.searchedPlaylist = []
      state.searchedPlaylistPaging = null
    },
    duplicatePlaylistGroupSuccess(state, action) {
      const { playlistGroup } = action.payload
      state.channelPlaylistGroups.push(playlistGroup)
    },
    sortPlaylistsInPlaylistGroupSuccess(state, action) {
      state.channelPlaylistGroup.playlists = action.payload.playlists
    }
  }
})

export default slice.reducer

export const {
  resetPlaylistSearchAndPaging,
  duplicatePlaylistGroupSuccess,
  sortPlaylistsInPlaylistGroupSuccess
} = slice.actions

const duplicatePlaylistGroupRequest = createAction(
  'playlists/duplicatePlaylistGroupRequest'
)
const duplicatePlaylistGroupFailure = createAction(
  'playlists/duplicatePlaylistGroupFailure'
)

export function duplicatePlaylistGroup(
  businessId,
  channelId,
  playlistId,
  data
) {
  return async (dispatch) => {
    try {
      dispatch(duplicatePlaylistGroupRequest())
      // TODO: Duplicate playlist group is not implemented in the API yet
      // This is a fake implementation
      dispatch(
        duplicatePlaylistGroupSuccess({
          playlistGroup: {
            id: 'dup',
            name: data.name,
            playlists: []
          }
        })
      )
    } catch (e) {
      dispatch(duplicatePlaylistGroupFailure())
    }
  }
}
