import { createAction, createSlice } from '@reduxjs/toolkit'
import { STORE_PROVIDERS } from '@src/constants'
import { getS3Signature, uploadToS3 } from '@src/utils/s3'
import { removeUndefined } from '@src/utils/sanitize'
import { Dispatch } from 'redux'

import api from '../utils/api'

type IProps = {
  products: globalLib.Product[]
  paging: Record<string, globalLib.Paging>
  productFeedResults: globalLib.Product[]
  productFeedPaging: Record<string, globalLib.Paging>
  productsData: globalLib.Product[]
}

const initialState: IProps = {
  products: [],
  paging: {},
  productFeedResults: [],
  productFeedPaging: {},
  productsData: []
}

const slice = createSlice({
  name: 'product',
  initialState: initialState,
  reducers: {
    fetchProductsSuccess(state, action) {
      const { products, paging } = action.payload
      state.products = state.products
        .filter((p) => !products.map((pro) => pro.id).includes(p.id))
        .concat(products)
      if (typeof paging !== 'undefined') {
        state.paging = paging
      }
    },
    fetchProductFeedSuccess(state, action) {
      const { products, paging, resetList } = action.payload
      if (resetList) {
        state.productFeedResults = products
      } else {
        state.productFeedResults = state.productFeedResults.concat(products)
      }
      if (typeof paging !== 'undefined') {
        state.productFeedPaging = paging
      }
    },
    createProductSuccess(state, action) {
      const { product } = action.payload
      // The product may have been an update of an existing product
      // so find and replace that product with the new version
      state.products = [product].concat(
        state.products.filter((p) => p.id !== product.id)
      )
    },
    removeProductFeedProductSuccess(state, action) {
      const { productExtId } = action.payload
      state.productFeedResults = state.productFeedResults.filter(
        (p) => p.product_ext_id !== productExtId
      )
      if (!state.productFeedResults.length) {
        state.productFeedPaging = {}
      }
    },
    deleteProductsSuccess(state, action) {
      const { productIds } = action.payload
      state.products = state.products.filter((p) => !productIds.includes(p.id))
    },
    fetchProductsByIdsSuccess(state, action) {
      const { products } = action.payload
      state.productsData = products
    },
    resetProducts(state) {
      state.products = []
      state.paging = {}
      state.productFeedResults = []
      state.productFeedPaging = {}
      state.productsData = []
    }
  }
})

export default slice.reducer

export const {
  fetchProductsSuccess,
  fetchProductFeedSuccess,
  createProductSuccess,
  removeProductFeedProductSuccess,
  deleteProductsSuccess,
  fetchProductsByIdsSuccess,
  resetProducts
} = slice.actions

const fetchProductsRequest = createAction('product/fetchProductsRequest')
const fetchProductsFailure = createAction('product/fetchProductsFailure')

export function fetchProducts(businessId: string, page?: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchProductsRequest())
      let url = `bus/${businessId}/products?page_size=20`
      if (page) {
        url += `&page=${page}`
      }
      const response = await api.get(url)
      const { products, paging } = response.data
      dispatch(fetchProductsSuccess({ products, paging }))

      return response
    } catch (error) {
      dispatch(fetchProductsFailure())

      return error
    }
  }
}

const fetchProductsByIdsRequest = createAction(
  'product/fetchProductByIdRequest'
)
const fetchProductsByIdsFailure = createAction(
  'product/fetchProductByIdFailure'
)

export function fetchProductsByProductIds(productIds: string | string[]) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchProductsByIdsRequest())
      const response = await api.get(
        `products?product_ids=${productIds.toString()}`
      )

      const products = response.data
      dispatch(fetchProductsByIdsSuccess({ products }))

      return response
    } catch (error) {
      dispatch(fetchProductsByIdsFailure())

      return error
    }
  }
}

export function fetchProductsByProductFeed(
  businessId: string,
  businessStoreId: string,
  provider?: string,
  query?: string,
  page?: number,
  cursor?: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchProductsRequest())
      let url = `bus/${businessId}/search_products?page_size=20&business_store=${businessStoreId}&query=${query}`
      // Set category_id to all categories here for Boutir
      if (provider === STORE_PROVIDERS.BOUTIR) {
        url += '&category_id=all_cats'
      }
      if (page) {
        url += `&page=${page}`
      }
      if (cursor) {
        url += `&next_cursor=${cursor}`
      }
      const response = await api.get(url)
      const { products, paging } = response.data
      // Page will equal 1 for paginated store or cursor will be null for cursor based store any time the search button is clicked, causing reset to occur
      const resetList =
        (page &&
          page === 1 &&
          [STORE_PROVIDERS.SHOPIFY, STORE_PROVIDERS.TEMP_SHOPIFY].indexOf(
            provider
          ) === -1) ||
        (!cursor &&
          [STORE_PROVIDERS.SHOPIFY, STORE_PROVIDERS.TEMP_SHOPIFY].indexOf(
            provider
          ) !== -1)
      dispatch(fetchProductFeedSuccess({ products, paging, resetList }))

      return response
    } catch (error) {
      dispatch(fetchProductsFailure())

      return error
    }
  }
}

const createProductRequest = createAction('product/createProductRequest')
const createProductFailure = createAction('product/createProductFailure')

export function createProduct(businessId: string, data?: any) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createProductRequest())
      const body = sanitizeProduct(data)
      const response = await api.post(`bus/${businessId}/create_product`, body)
      const product = response.data
      dispatch(createProductSuccess({ product }))

      return response
    } catch (error) {
      dispatch(createProductFailure())

      return error
    }
  }
}

const sanitizeProduct = (data) => {
  const sanitized = {
    name: data.product_name,
    vendor_id: data.vendor_id,
    currency: data.product_currency,
    brand: data.product_brand,
    hide_primary_cta: data.hide_primary_cta,
    custom_cta_title: data.custom_cta_title,
    custom_cta_url: data.custom_cta_url,
    custom_cta_target: '_blank',
    description: data.product_description,
    options: data.product_options,
    external_id: data.product_ext_id,
    handle: data.product_handle,
    subtitle: data.product_subtitle,
    product_units: data.product_units?.map((productUnit) =>
      sanitizeProductUnit(productUnit)
    ),
    product_images: data.product_images?.map((productImage) =>
      sanitizeProductImage(productImage)
    ),
    hide_price: data.hide_product_price
  }

  return removeUndefined(sanitized)
}

const sanitizeProductImage = (data) => {
  const sanitized = {
    position: data.image_position,
    url: data.image_src,
    unit_ids: data.unit_ids,
    external_id: data.image_ext_id,
    unit_names: data.unit_names,
    external: data.external,
    unit_identifiers: data.unit_identifiers
  }

  return removeUndefined(sanitized)
}

const sanitizeProductUnit = (data) => {
  const sanitized = {
    name: data.unit_name,
    price: data.unit_price,
    original_price: data.unit_original_price,
    url: data.unit_url,
    position: data.unit_position,
    barcode: data.barcode,
    image_id: data.image_id,
    image: data.unit_image, // { url: string, position: number }
    external_id: data.unit_ext_id,
    options: data.unit_options,
    magento_options: data.unit_options_magento
  }

  return removeUndefined(sanitized)
}

export function removeProductFromProductFeed(productExtId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    dispatch(removeProductFeedProductSuccess({ productExtId }))
  }
}

const updateProductRequest = createAction('product/updateProductRequest')
const updateProductFailure = createAction('product/updateProductFailure')

export function updateProduct(
  businessId: string,
  productId: string,
  data?: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateProductRequest())
      const response = await api.patch(
        `bus/${businessId}/products/${productId}`,
        sanitizeProduct(data)
      )
      // const product = response.data

      return response
    } catch (error) {
      dispatch(updateProductFailure())

      return error
    }
  }
}

const uploadImageRequest = createAction('product/uploadImageRequest')
const uploadImageFailure = createAction('product/uploadImageFailure')

export function uploadImage(
  file: File,
  onProgress: (input: any) => void,
  onSuccess: (input: any) => void
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(uploadImageRequest())
      const signature = await getS3Signature(file)
      await uploadToS3(
        file,
        signature,
        (percent) => onProgress({ percent }),
        ({ key }) => {
          onSuccess({ key })
        },
        (err) => {
          throw err
        }
      )
    } catch (error) {
      dispatch(uploadImageFailure())

      return error
    }
  }
}

const deleteProductsRequest = createAction('product/deleteProductsRequest')
const deleteProductsFailure = createAction('product/deleteProductsFailure')

export function deleteProducts(businessId: string, productIds: string[]) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteProductsRequest())
      const response = await api.delete(
        `bus/${businessId}/products?product_ids=${productIds.join(',')}`
      )
      dispatch(deleteProductsSuccess({ productIds }))

      return response
    } catch (error) {
      dispatch(deleteProductsFailure())

      return error
    }
  }
}

const scrapeProductRequest = createAction('product/scrapeProductRequest')
const scrapeProductFailure = createAction('product/scrapeProductFailure')

export function scrapeProduct(businessId: string, url = '') {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(scrapeProductRequest())
      const res = await api.get(`/bus/${businessId}/scrape_product?url=${url}`)
      const { data } = res

      return data
    } catch (e) {
      dispatch(scrapeProductFailure())

      return e
    }
  }
}
