import React, { useState } from 'react'

import { css } from '@emotion/core'
import { Table, Skeleton } from 'antd'
import { Select, SelectOption } from 'fwego'
import { useTranslation } from 'react-i18next'

import { Card, CardHeader } from '.'
import { convertTimeUnit } from '../utils/stats'

const KEY_VIDEO_CAPTION = 'video_caption'
const KEY_VIEWS = 'total_views'
const KEY_WATCH_TIME = 'total_seconds_watched'

type PerformanceTableIProps = {
  data: any[]
  endDate?: string
  startDate?: string
}

type HashtagVideoPerformanceCardIProps = {
  data: any[]
  endDate?: string
  loading?: boolean
  startDate?: string
  headerExtra?: any
}

const PerformanceTable: React.FC<PerformanceTableIProps> = ({ data }) => {
  const [pageSize, setPageSize] = useState(10)
  const { t } = useTranslation()
  const tableColumns: Record<string, any>[] = [
    {
      align: 'left',
      dataIndex: KEY_VIDEO_CAPTION,
      key: KEY_VIDEO_CAPTION,
      title: t('Video')
    },
    {
      align: 'left',
      dataIndex: KEY_VIEWS,
      key: KEY_VIEWS,
      title: t('Views'),
      width: 150,
      render: (value) => value.toLocaleString()
    },
    {
      align: 'left',
      dataIndex: KEY_WATCH_TIME,
      key: KEY_WATCH_TIME,
      title: t('Watch Time'),
      render: function render(value) {
        return <span>{convertTimeUnit(value)}</span>
      },
      width: 150
    }
  ]

  return (
    <Table
      columns={tableColumns}
      css={css`
        .ant-table-row {
          cursor: pointer;
        }
        .anticon {
          vertical-align: middle;
          padding-bottom: 6px;
        }
      `}
      dataSource={data}
      pagination={{ pageSize }}
      rowKey={(record) => record.video_id}
      onChange={({ pageSize }) => setPageSize(pageSize)}
    />
  )
}

const HashtagVideoPerformanceCard: React.FC<HashtagVideoPerformanceCardIProps> = ({
  loading,
  data = [],
  endDate,
  startDate,
  headerExtra
}) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState(0)
  const hashtagData = data[filter]

  const sanitizeData = (data = []) =>
    data.map((entry) => {
      if (!entry.video_caption) {
        return {
          ...entry,
          video_caption: t('(No title)')
        }
      }

      return entry
    })
  const sanitizedData = sanitizeData(hashtagData?.insights)

  return (
    <Card m="0">
      <Skeleton active loading={loading}>
        <CardHeader
          extra={headerExtra}
          title={t('Top 10 Hashtag Performance')}
          tooltip={t('Key metrics for your hashtags')}
        />
        <Select
          mb="large"
          width="250"
          onChange={(e) => setFilter(e.target.value)}
        >
          {data.map((hashtag, i) => (
            <SelectOption key={i} value={i}>
              {hashtag.name}
            </SelectOption>
          ))}
        </Select>
        <PerformanceTable
          data={sanitizedData}
          endDate={endDate}
          startDate={startDate}
        />
      </Skeleton>
    </Card>
  )
}

export default HashtagVideoPerformanceCard
