import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { serializeError } from 'serialize-error'

import rootReducer from './reducers'

// Serializes errors in action payloads so the error object can be stored in
// redux. This is necessary because Immer prevents putting non-serializable
// values in state.
const errorSerializer = () => (next) => (action) => {
  const error = action?.payload?.error
  if (error) {
    action.payload.error = serializeError(error)
  }

  return next(action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: [errorSerializer, ...getDefaultMiddleware()]
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch

export type AppDispatch = ThunkDispatch<RootState, unknown, AnyAction>

export default store
