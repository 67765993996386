import React, { useState } from 'react'

import { css } from '@emotion/core'
import { Card, Box, Flex } from '@src/components/EmotionLayout'
import { isMdScreen } from '@src/hooks/useMediaQuery'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import {
  useRolesAndPermissions,
  PERMISSIONS
} from '../../hooks/useRolesAndPermissions'
import BrandDefault from './BrandDefault'
import BrandEdit from './BrandEdit'

export type Props = {
  channel: globalLib.Channel
  avatarUploadProgress: number
  viewCount: number
  handleAvatarUpload: (file: any) => void
}

export const Brand: React.FC<Props> = ({
  channel,
  avatarUploadProgress,
  handleAvatarUpload,
  viewCount,
  ...props
}): JSX.Element => {
  const [editable, setEditable] = useState(false)
  const { userCan } = useRolesAndPermissions()
  const canEdit = userCan(PERMISSIONS.CHANNEL_EDIT)
  const { t } = useTranslation()
  const mdScreen = isMdScreen()

  const toggleEditable = () => {
    setEditable(!editable)
  }

  return channel?.config ? (
    <Card
      maxWidth={mdScreen ? '340' : 'auto'}
      minHeight="700"
      position="relative"
    >
      <Flex justifyContent="space-between" alignItems="center" mb="large">
        <Typography.Title
          level={4}
          style={{
            margin: 0
          }}
        >
          {t('Brand Profile')}
        </Typography.Title>
        <Box textAlign="center" flexDirection="column" ml="large">
          {!!viewCount && (
            <Typography.Text
              css={css`
                font-size: 14px;
              `}
            >
              {t('{{views}} Video Views', { views: viewCount })}
            </Typography.Text>
          )}
        </Box>
      </Flex>
      {!editable ? (
        <BrandDefault
          channel={channel}
          canEdit={canEdit}
          openModal={toggleEditable}
          {...props}
        />
      ) : (
        <BrandEdit
          channel={channel}
          closeModal={toggleEditable}
          uploadProgress={avatarUploadProgress}
          onFileSelected={handleAvatarUpload}
          {...props}
        />
      )}
    </Card>
  ) : null
}

export default Brand
