import { useAppSelector } from '@src/app/hooks'

export const useBusinessSubscription = (
  businessId: string
): {
  hasLivestream: boolean
} => {
  const businessSubscription = useAppSelector((state) =>
    state.business.businesses[businessId]
      ? state.business.businesses[businessId].subscription
      : null
  )

  const hasLivestream =
    businessSubscription && businessSubscription.can_create_live_stream

  return {
    hasLivestream
  }
}
