import React, { useEffect, useState } from 'react'

import { useAppDispatch } from '@src/app/hooks'
import { FWButton, FWModal } from '@src/components'
import { Box } from '@src/components/EmotionLayout'
import Title from '@src/components/Title'
import { AUTO_EMBED_TABS } from '@src/constants'
import { useNabooFeatureFlag } from '@src/hooks'
import {
  isModalShownFromStorage,
  setModalShownInStorage,
  useGlobalPopupModals
} from '@src/hooks/useGlobalPopupModals'
import { useStoreFeatureEnablement } from '@src/hooks/useStoreFeatureEnablement'
import {
  BodyContainer,
  BodyText,
  FooterContainer,
  HeaderContainer,
  TitleContainer
} from '@src/pages/channel/Tabs/Integrations/ShopApp/NewShopModal.styles'
import { setIntegrationsActiveTab } from '@src/redux/autoEmbed'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

const NewShopModal: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { businessId, channelId } = useParams()
  const nff = useNabooFeatureFlag()
  const storeFeatureEnablement = useStoreFeatureEnablement(businessId)

  const [newShopModalVisible, setNewShopModalVisible] = useState(false)
  const { shouldShowModalGlobal } = useGlobalPopupModals('shop_app')

  const shopMinisV2 =
    nff.cms_integrations_tab &&
    nff.cms_shop_minis_v2 &&
    storeFeatureEnablement.shopify

  useEffect(() => {
    const shopAppV2Modal = isModalShownFromStorage('shop_app')
    if (!shopAppV2Modal && shopMinisV2) {
      setNewShopModalVisible(true)
    }
  }, [shopMinisV2])

  const handleCancelClick = () => {
    setNewShopModalVisible(false)
  }

  return (
    <FWModal
      width={588}
      mask
      visible={newShopModalVisible && shouldShowModalGlobal}
      onCancel={handleCancelClick}
      closable={false}
      title={null}
      footer={null}
    >
      <BodyContainer>
        <HeaderContainer>
          <Box width={64} height={28} as="img" src="/images/shop.svg" />
        </HeaderContainer>
        <TitleContainer>
          <Title fontSize={20} weight={600} noMargin>
            {t('New Shop App Integration')}
          </Title>
          <BodyText>
            {t(
              'Publish your Firework videos to Shop App Homepage, Brand Page, and Product Pages.'
            )}
          </BodyText>
        </TitleContainer>
        <Box
          width={250}
          height={150}
          as="img"
          src="/images/shop_brand_page_2.svg"
        />
        <FooterContainer>
          <FWButton
            type="default"
            onClick={() => {
              setModalShownInStorage('shop_app')
              handleCancelClick()
            }}
          >
            {t('Not Now')}
          </FWButton>
          <FWButton
            style={{ marginLeft: '12px' }}
            type="primary"
            onClick={() => {
              setModalShownInStorage('shop_app')
              dispatch(setIntegrationsActiveTab(AUTO_EMBED_TABS.SHOP_APP))
              navigate(
                `/business/${businessId}/channel/${channelId}/integrations`
              )
              handleCancelClick()
            }}
          >
            {t('Get Started')}
          </FWButton>
        </FooterContainer>
      </BodyContainer>
    </FWModal>
  )
}

export default NewShopModal
