import React from 'react'

import { css } from '@emotion/core'
import Avatar from '@src/components/Avatar'
import { Box, Flex } from '@src/components/EmotionLayout'
import FWButton from '@src/components/FWButton/FWButton'
import { formChannelUrl } from '@src/utils/formChannelUrl'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import BrandUsername from './BrandUsername'

export type Props = {
  canEdit: boolean
  channel: globalLib.Channel
  openModal: () => void
}

export const BrandDefault: React.FC<Props> = ({
  channel,
  canEdit,
  openModal
}): JSX.Element => {
  const { t } = useTranslation()
  const promotedUrl =
    channel?.promote_url && channel?.promote_url.replace('https://', '')

  const openPage = () => window.open(formChannelUrl(channel?.username))

  return (
    <Flex flexDirection="column" alignItems="center" width="100%">
      <Flex
        mb="medium"
        pb="large"
        alignItems="center"
        width="100%"
        borderBottom="1px solid #d9d9d9"
      >
        <Avatar
          src={channel?.avatar_url}
          uploadProgress={undefined}
          onFileSelected={undefined}
          noShadow={false}
          onAvatarClick={null}
          disabled
        />
        <Flex ml="large2" flex="1" flexDirection="column">
          <Typography.Title
            data-cy="name"
            level={4}
            css={css`
              margin: 0 !important;
            `}
          >
            {channel?.name}
          </Typography.Title>

          <BrandUsername username={channel?.name} />
          <Box fontSize="14">{promotedUrl}</Box>
          <Box fontSize="14">{`Locale: ${channel?.locale}`}</Box>
        </Flex>
      </Flex>
      <Box>
        {channel?.bio ? (
          <Typography.Text>{channel?.bio}</Typography.Text>
        ) : (
          <Typography.Text
            css={css`
              opacity: 0.5;
            `}
          >
            {t(
              '(Your bio looks empty! Make sure to fill this out to create a more engaging profile.)'
            )}
          </Typography.Text>
        )}
      </Box>
      <Flex
        justifyContent="flex-end"
        position="absolute"
        bottom="0"
        right="0"
        m="large2"
      >
        <FWButton
          css={css`
            font-weight: 400;
          `}
          onClick={openPage}
        >
          {t('View Site')}
        </FWButton>
        {canEdit && (
          <Box ml="medium">
            <FWButton
              type="primary"
              css={css`
                font-weight: 400;
              `}
              onClick={openModal}
            >
              {t('Edit')}
            </FWButton>
          </Box>
        )}
      </Flex>
    </Flex>
  )
}

export default BrandDefault
