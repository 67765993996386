import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  BatchImporterMedia,
  BatchImporterMediaType,
  ImporterSourceFrom
} from '@src/components/BatchImporter/BatchImporterMediaModel'
import api, { IMPORTER_PROXY_URL } from '@src/utils/api'
import {
  getGoogleRedirectUri,
  getGoogleToken,
  saveGoogleToken,
  GOOGLE_CLIENT_KEY,
  GoogleToken,
  getDownloadUrl
} from '@src/utils/googleAuth'
import { Base64 } from 'js-base64'
import { Dispatch } from 'redux'

const SLICE_NAME = 'googleAuth'

export type GoogleDriveVideo = {
  mimeType: string
  thumbnailLink?: string
  iconLink?: string
  size: string
  id: string
  name: string
  capabilities: {
    canDownload: boolean
  }
}

export type YouTubeVideo = {
  etag: string
  id: {
    kind: string
    videoId: string
  }
  kind: string
  snippet: {
    channelId: string
    channelTitle: string
    description: string
    liveBroadcastContent: string
    publishTime: string
    publishedAt: string
    thumbnails: {
      default: {
        height: number
        width: number
        url: string
      }
      high: {
        height: number
        width: number
        url: string
      }
    }
    title: string
  }
}

export enum GoogleDriveOption {
  ME = 'ME',
  SHARED_WITH_ME = 'SHARED_WITH_ME',
  ALL_DRIVES = 'ALL_DRIVES'
}

type IProps = {
  googleDriveVideos: GoogleDriveVideo[]
  googleDrivePageToken: string
  googleDriveHasMore: boolean
  googleDriveFileNameKey: string
  googleDriveOption: GoogleDriveOption
  youtubeVideos: YouTubeVideo[]
  youtubePageToken: string
  youtubeHasMore: boolean
  youtubeFileNameKey: string
}

const initialState: IProps = {
  googleDriveVideos: [],
  googleDrivePageToken: '',
  googleDriveHasMore: false,
  googleDriveFileNameKey: '',
  googleDriveOption: GoogleDriveOption.ME,
  youtubeVideos: [],
  youtubePageToken: '',
  youtubeHasMore: false,
  youtubeFileNameKey: ''
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    fetchGoogleVideoListSuccess(state, action) {
      const {
        videos,
        reqPageToken,
        pageToken,
        hasMore,
        fileNameKey,
        option
      } = action.payload
      if (reqPageToken?.length) {
        state.googleDriveVideos = (state.googleDriveVideos || []).concat(videos)
      } else {
        state.googleDriveVideos = videos || []
      }

      state.googleDriveVideos = state.googleDriveVideos.filter((item) => {
        return item?.capabilities?.canDownload
      })

      state.googleDriveFileNameKey = fileNameKey
      state.googleDrivePageToken = pageToken
      state.googleDriveHasMore = hasMore
      state.googleDriveOption = option || GoogleDriveOption.ME
    },
    fetchYouTubeVideoListSuccess(state, action) {
      const {
        videos: list,
        reqPageToken,
        pageToken,
        hasMore,
        key
      } = action.payload

      const videos: YouTubeVideo[] = list
        ?.filter((item) => {
          return item?.status?.privacyStatus === 'public'
        })
        ?.map((item) => {
          return {
            ...item,
            id: {
              ...item?.snippet?.resourceId
            }
          }
        })

      if (reqPageToken?.length) {
        state.youtubeVideos = (state.youtubeVideos || []).concat(videos)
      } else {
        state.youtubeVideos = videos || []
      }
      state.youtubeFileNameKey = key
      state.youtubePageToken = pageToken
      state.youtubeHasMore = hasMore
    },
    searchYouTubeVideoSuccess(state, action) {
      const { videos, reqPageToken, pageToken, hasMore, key } = action.payload

      if (reqPageToken?.length) {
        state.youtubeVideos = (state.youtubeVideos || []).concat(videos)
      } else {
        state.youtubeVideos = videos || []
      }
      state.youtubeFileNameKey = key
      state.youtubePageToken = pageToken
      state.youtubeHasMore = hasMore
    }
  }
})

export default slice.reducer
export const {
  fetchGoogleVideoListSuccess,
  fetchYouTubeVideoListSuccess,
  searchYouTubeVideoSuccess
} = slice.actions

const fetchGoogleTokenRequest = createAction(
  'googleAuth/fetchGoogleTokenRequest'
)
const fetchGoogleTokenFailure = createAction(
  'googleAuth/fetchGoogleTokenFailure'
)

export function code2Token(userId: string, code: string, clientKey?: string) {
  return async (dispatch: Dispatch): Promise<GoogleToken> => {
    try {
      dispatch(fetchGoogleTokenRequest())

      const clientId = clientKey || GOOGLE_CLIENT_KEY
      const response = await fetch(
        `${IMPORTER_PROXY_URL}/google/exchange?client_id=${clientId}&redirect_uri=${getGoogleRedirectUri()}&code=${code}`,
        {
          method: 'GET',
          headers: {
            Authorization: `bearer ${api.getToken()}`,
            'Content-Type': 'application/json'
          }
        }
      )
      const data = (await response.json()) as any

      const {
        access_token,
        expires_in,
        id_token: idToken,
        refresh_token,
        scope
      } = data
      const userInfo = JSON.parse(
        Base64.decode(idToken?.split('.')?.[1]) || '{}'
      )

      // Get uploads playlist ID first
      const { channelId, playlistId } = await getYouTubeUploadsPlaylistId(
        access_token
      )

      const date = new Date()
      const googleToken: GoogleToken = {
        accessToken: access_token,
        idToken,
        expiresIn: date.getTime() + expires_in * 1000,
        refreshToken: refresh_token,
        scope,
        username: userInfo?.name,
        avatar: userInfo?.picture,
        playlistId,
        channelId
      }

      saveGoogleToken(userId, googleToken)

      return googleToken
    } catch (error) {
      dispatch(fetchGoogleTokenFailure())

      return error
    }
  }
}

export const refreshGoogleToken = (userId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(fetchGoogleTokenRequest())

      const localGoogleToken = getGoogleToken(userId)

      const response = await fetch(
        `${IMPORTER_PROXY_URL}/google/refresh?refresh_token=${localGoogleToken.refreshToken}`,
        {
          method: 'GET',
          headers: {
            Authorization: `bearer ${api.getToken()}`,
            'Content-Type': 'application/json'
          }
        }
      )
      const data = (await response.json()) as any

      if (!data?.access_token) {
        throw new Error(data.error)
      }

      // id_token in data, no username
      const { access_token, expires_in, scope } = data
      const date = new Date()

      const googleToken: GoogleToken = {
        accessToken: access_token,
        idToken: localGoogleToken.idToken,
        expiresIn: date.getTime() + expires_in * 1000,
        refreshToken: localGoogleToken.refreshToken,
        scope,
        username: localGoogleToken?.username,
        avatar: localGoogleToken?.avatar,
        playlistId: localGoogleToken?.playlistId,
        channelId: localGoogleToken?.channelId
      }

      saveGoogleToken(userId, googleToken)

      return googleToken
    } catch (error) {
      dispatch(fetchGoogleTokenFailure())

      return error
    }
  }
}

const fetchGoogleVideoListRequest = createAction(
  'googleAuth/fetchGoogleVideoListRequest'
)
const fetchGoogleVideoListFailure = createAction(
  'googleAuth/fetchGoogleVideoListFailure'
)

export function fetchGoogleVideoList(params: {
  userId: string
  pageSize?: number
  fileNameKey?: string
  pageToken?: string
  option?: GoogleDriveOption
}) {
  const { userId, pageSize = 20, fileNameKey, pageToken, option } = params

  return async (dispatch: Dispatch) => {
    try {
      dispatch(fetchGoogleVideoListRequest())
      const googleToken = getGoogleToken(userId)

      let query = `(mimeType contains 'video/mp4' or mimeType contains 'video/quicktime')${
        fileNameKey ? ` and name contains '${fileNameKey}'` : ''
      }`

      if (option === GoogleDriveOption.ME) {
        query = `'me' in owners and ${query}`
      } else if (option === GoogleDriveOption.SHARED_WITH_ME) {
        query = `sharedWithMe=true and ${query}`
      }

      let urlQuery = {
        pageSize,
        fields: `files(id,name,mimeType,size,iconLink,thumbnailLink,capabilities/canDownload),nextPageToken`,
        q: query,
        pageToken,
        supportsAllDrives: undefined,
        includeItemsFromAllDrives: undefined
      }

      if (option === GoogleDriveOption.ALL_DRIVES) {
        urlQuery = {
          ...urlQuery,
          supportsAllDrives: true,
          includeItemsFromAllDrives: true
        }
      }

      const stringifiedParams = Object.fromEntries(
        Object.entries(urlQuery)
          .filter(([key, value]) => !!key && !!value)
          .map(([key, value]) => [key, value.toString()])
      )
      const queryString = new URLSearchParams(stringifiedParams).toString()

      const response = await fetch(
        `https://www.googleapis.com/drive/v3/files?${queryString}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${googleToken.accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      const data = await response.json()

      const { files, nextPageToken } = data

      dispatch(
        fetchGoogleVideoListSuccess({
          videos: files,
          reqPageToken: pageToken,
          pageToken: nextPageToken,
          hasMore: !!nextPageToken,
          fileNameKey,
          option
        })
      )

      return data
    } catch (error) {
      dispatch(fetchGoogleVideoListFailure())
    }
  }
}

const fetchYouTubeVideoListRequest = createAction(
  'googleAuth/fetchYouTubeVideoListRequest'
)
const fetchYouTubeVideoListFailure = createAction(
  'googleAuth/fetchYouTubeVideoListFailure'
)

async function getYouTubeUploadsPlaylistId(
  accessToken: string
): Promise<{ channelId: string; playlistId: string }> {
  const response = await fetch(
    'https://www.googleapis.com/youtube/v3/channels?part=contentDetails&mine=true',
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }
  )
  /**
   * {
   *   "kind": "youtube#channelListResponse",
   *   "etag": "W9_8M-ZSBEvdIActj9vMIgROmgE",
   *   "pageInfo": {
   *     "totalResults": 1,
   *     "resultsPerPage": 5
   *   },
   *   "items": [
   *     {
   *       "kind": "youtube#channel",
   *       "etag": "bbxodFIA5l7iOFG51z-tcmiOZe8",
   *       "id": "UCTqJ8P_70M4pNiKGUHelR9A",
   *       "contentDetails": {
   *         "relatedPlaylists": {
   *           "likes": "LL",
   *           "uploads": "UUTqJ8P_70M4pNiKGUHelR9A"
   *         }
   *       }
   *     }
   *   ]
   * }
   */
  const data = await response.json()

  return {
    channelId: data.items[0]?.id,
    playlistId: data.items[0]?.contentDetails?.relatedPlaylists?.uploads
  }
}

export function fetchYouTubeVideoList(params: {
  userId: string
  maxResults?: number
  pageToken?: string
}) {
  const { userId, maxResults = 20, pageToken } = params

  return async (dispatch: Dispatch) => {
    try {
      dispatch(fetchYouTubeVideoListRequest())
      const googleToken = getGoogleToken(userId)

      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,status&maxResults=${maxResults}&playlistId=${
          googleToken?.playlistId
        }${pageToken ? `&pageToken=${pageToken}` : ''}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${googleToken.accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      const data = await response.json()

      if (data.error) {
        throw new Error(data.error.message)
      }

      const { items, nextPageToken } = data

      dispatch(
        fetchYouTubeVideoListSuccess({
          videos: items,
          reqPageToken: pageToken,
          pageToken: nextPageToken,
          hasMore: !!nextPageToken
        })
      )

      return data
    } catch (error) {
      dispatch(fetchYouTubeVideoListFailure())

      return error
    }
  }
}

const searchYouTubeVideoRequest = createAction(
  'googleAuth/searchYouTubeVideoRequest'
)
const searchYouTubeVideoFailure = createAction(
  'googleAuth/searchYouTubeVideoFailure'
)

export function searchYouTubeVideo(params: {
  userId: string
  key?: string
  maxResults?: number
  pageToken?: string
}) {
  const { userId, key, maxResults = 20, pageToken } = params

  return async (dispatch: Dispatch) => {
    try {
      dispatch(searchYouTubeVideoRequest())
      const googleToken = getGoogleToken(userId)

      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=${maxResults}${
          key ? `&q=intitle:"${encodeURIComponent(key)}"` : ''
        }&channelId=${googleToken?.channelId}&videoEmbeddable=true&type=video${
          pageToken ? `&pageToken=${pageToken}` : ''
        }`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${googleToken.accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      )
      const data = await response.json()

      if (data.error) {
        throw new Error(data.error.message)
      }

      const { items, nextPageToken } = data

      dispatch(
        searchYouTubeVideoSuccess({
          videos: items,
          reqPageToken: pageToken,
          pageToken: nextPageToken,
          hasMore: !!nextPageToken,
          key
        })
      )

      return data
    } catch (error) {
      dispatch(searchYouTubeVideoFailure())

      return error
    }
  }
}

export const convertGoogleDriveVideoList2BatchImporterMediaList = (params: {
  list?: GoogleDriveVideo[]
  googleToken?: GoogleToken
}): BatchImporterMedia[] => {
  const { list, googleToken } = params

  if (!list || !list.length) {
    return []
  }

  const newList = list?.filter((item) => {
    return !!item?.id
  })

  return (
    newList?.map((item) => {
      const thumbnailUrl =
        item?.thumbnailLink?.replace('=s220', '=s960') || item?.iconLink
      const displayUrl = `${IMPORTER_PROXY_URL}/apify/ig-picture?url=${encodeURIComponent(
        thumbnailUrl
      )}`
      const mediaUrl = `${IMPORTER_PROXY_URL}/google/pipe?url=${encodeURIComponent(
        getDownloadUrl(item.id)
      )}&access_token=${googleToken.accessToken}`

      return {
        id: item.id,
        caption: item.name,
        mediaType: 'VIDEO' as BatchImporterMediaType,
        thumbnailUrl: displayUrl,
        username: googleToken?.username,
        avatar: googleToken?.avatar,
        displayName: googleToken?.username,
        mediaUrl,
        sourceFrom: ImporterSourceFrom.GoogleDrive,
        disabled: !item?.capabilities?.canDownload
      }
    }) || []
  )
}

export const convertYouTubeVideoList2BatchImporterMediaList = (params: {
  list?: YouTubeVideo[]
  googleToken?: GoogleToken
}): BatchImporterMedia[] => {
  const { list, googleToken } = params

  if (!list || !list.length || !googleToken) {
    return []
  }

  const newList = list?.filter((item) => {
    return !!item?.id?.videoId
  })

  return (
    newList?.map((item) => {
      const sourceUrl = `https://www.youtube.com/watch?v=${item.id.videoId}`

      return {
        id: item.id.videoId,
        caption: item?.snippet?.title,
        mediaType: 'VIDEO' as BatchImporterMediaType,
        thumbnailUrl:
          item?.snippet?.thumbnails?.high?.url ||
          item?.snippet?.thumbnails?.default?.url,
        username: item?.snippet?.channelTitle || googleToken?.username,
        avatar: googleToken?.avatar,
        displayName: item?.snippet?.channelTitle || googleToken?.username,
        mediaUrl: sourceUrl,
        sourceUrl,
        sourceFrom: ImporterSourceFrom.YouTube,
        needCrawlerVideo: true
      }
    }) || []
  )
}
