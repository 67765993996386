import { createAction, createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'

import api from '../utils/api'

type IProps = {
  feedEarnings: any
  paging: any
}

const initialState: IProps = {
  feedEarnings: {},
  paging: {}
}

const slice = createSlice({
  name: 'earning',
  initialState: initialState,
  reducers: {
    fetchEarningsSuccess(state, action) {
      const { feedId, earnings, paging } = action.payload
      state.feedEarnings[feedId] = earnings
      state.paging[feedId] = paging
    }
  }
})

export default slice.reducer

export const { fetchEarningsSuccess } = slice.actions

const fetchEarningsRequest = createAction('earning/fetchEarningsRequest')
const fetchEarningsFailure = createAction('earning/fetchEarningsFailure')

export function fetchEarnings(
  businessId: string,
  feedId: string,
  page?: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchEarningsRequest())
      const response = await api.get(
        page || `/bus/${businessId}/feeds/${feedId}/earnings`
      )
      const { earnings, paging } = response.data
      dispatch(fetchEarningsSuccess({ feedId, earnings, paging }))
    } catch (error) {
      dispatch(fetchEarningsFailure())
    }
  }
}
