import { createAction, createSlice } from '@reduxjs/toolkit'
import api from '@src/utils/api'
import { Dispatch } from 'redux'

type IProps = {
  channelAllStores: Record<string, any[]>
}

const initialState: IProps = {
  channelAllStores: {}
}

const slice = createSlice({
  name: 'channelStore',
  initialState: initialState,
  reducers: {
    fetchChannelAllStoresSuccess(state, action) {
      const { channelId, business_stores } = action.payload
      state.channelAllStores[channelId] = business_stores || []
    }
  }
})

export default slice.reducer
export const { fetchChannelAllStoresSuccess } = slice.actions

const fetchChannelAllStoresRequest = createAction(
  'channelStore/fetchChannelAllStoresRequest'
)
const fetchChannelAllStoresFailure = createAction(
  'channelStore/fetchChannelAllStoresFailure'
)

export function fetchChannelAllStores(businessId: string, channelId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChannelAllStoresRequest())
      const response = await api.get(
        `/bus/${businessId}/channels/${channelId}/business_stores`
      )
      const { business_stores } = response.data
      dispatch(
        fetchChannelAllStoresSuccess({
          channelId,
          business_stores
        })
      )

      return response
    } catch (error) {
      dispatch(fetchChannelAllStoresFailure())
    }
  }
}
