import { createSelector } from '@reduxjs/toolkit'

const selectRequest = (state, requestName) => state.request[requestName]

const returnRequest = (request) => ({
  error: request?.error,
  state: request?.state || 'idle',
  loading: request?.loading || false
})

export const makeGetRequest = () => createSelector(selectRequest, returnRequest)

export const getRequest = createSelector(selectRequest, returnRequest)
