import {
  createAction,
  createSlice,
  PayloadAction,
  Slice
} from '@reduxjs/toolkit'

type SliceState = {
  message?: string
}

const initialState: SliceState = {
  message: null
}

type ISlice = {
  setErrorMessageSuccess: (state, action) => void
  resetErrorSuccess: (state, action) => void
}

// This slice is used specifically for axios interceptors in api.js
const slice: Slice<SliceState, ISlice, 'errors'> = createSlice({
  name: 'errors',
  initialState: initialState,
  reducers: {
    setErrorMessageSuccess: (
      state: Partial<SliceState>,
      action: PayloadAction<Partial<SliceState>>
    ) => {
      const { message } = action.payload
      state.message = message
    },

    resetErrorSuccess: (state: Partial<SliceState>) => {
      state.message = null
    }
  }
})

export default slice.reducer

const { setErrorMessageSuccess, resetErrorSuccess } = slice.actions

const setErrorMessageRequest = createAction('errors/setErrorRequest')

export function setErrorMessage(message: string) {
  return async (dispatch: any) => {
    dispatch(setErrorMessageRequest())
    dispatch(setErrorMessageSuccess({ message }))
  }
}

const resetErrorRequest = createAction('errors/resetErrorRequest')

export function resetError() {
  return async (dispatch: any) => {
    dispatch(resetErrorRequest())
    dispatch(resetErrorSuccess({}))
  }
}
