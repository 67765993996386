export const url = Object.freeze({
  helpCenter: 'https://docs.firework.tv/',
  helpCenterEmbedTopic: 'https://docs.firework.tv/web/tutorials'
})

export const KEYS = Object.freeze({
  AMPLITUDE_API_KEY_PROD: '346f62aca5a1078bb5e2d2106dcdecc4',
  AMPLITUDE_API_KEY_STAGING: 'db3e50de980da95e2f09cbef6736aaa8',
  SENTRY_DSN:
    'https://7e8c0f91736647bf891d61091882c5a8@o141156.ingest.sentry.io/5216889',
  GOOGLE_MERCHANT_CLIENT_ID_PROD:
    '831269275373-u39g6fd8ufu8avos5ak3s8jkd71vjj5t.apps.googleusercontent.com',
  GOOGLE_MERCHANT_CLIENT_ID_STAGING:
    '562995330156-98ei3td5pmub3a1tq8n18e6tofg7cn9b.apps.googleusercontent.com'
})
