import { useCallback, useContext, useEffect } from 'react'

import { CHANNEL_TABS } from '@src/constants'
import { DataTrackingContext } from '@src/contexts/DataTrackingContext'
import sendVisitorEventPixelTracking from '@src/utils/pixelTracking'
import { localTime } from '@src/utils/sanitize'
import { TRACKING_EVENTS, getLocale } from '@src/utils/tracking'
import { useLocation } from 'react-router-dom'

import usePixelAmpTracking from './usePixelAmpTracking'

const FIREWORK_INTERNAL_USER_SUFFIX = 'fireworkhq.com'

const usePageViewDataTracking = () => {
  const { pathname, search } = useLocation()
  const { basicTrackingData } = useContext(DataTrackingContext)
  const { amplitudeTracking } = usePixelAmpTracking()

  const channelId =
    pathname.indexOf('channel') > 0 ? pathname.split('/')[4] : null

  const pageViewPixelAmpTracking = useCallback(
    (event, extraProps = {}) => {
      if (basicTrackingData.userId && basicTrackingData.primaryEmail) {
        const trackingProps = {
          ...basicTrackingData.defaultTracking,
          ...extraProps,
          _channel_id: channelId,
          app_page_url: window.location?.href,
          client_local_time: localTime(),
          locale: getLocale()
        }
        sendVisitorEventPixelTracking({
          data: {
            _user_id: basicTrackingData.userId,
            event_properties: trackingProps,
            internal_user: basicTrackingData.primaryEmail?.includes(
              FIREWORK_INTERNAL_USER_SUFFIX
            )
          },
          event
        })
        amplitudeTracking(event, {
          ...trackingProps,
          internal_user: basicTrackingData.primaryEmail?.includes(
            FIREWORK_INTERNAL_USER_SUFFIX
          )
        })
      }
    },
    // eslint-disable-next-line
    [JSON.stringify(basicTrackingData)]
  )

  useEffect(() => {
    if (
      /^\/business\/[^/]+\/channel\/[^/]+$/.test(pathname) ||
      /^\/business\/[^/]+\/channel\/[^/]+\/videos$/.test(pathname)
    ) {
      const searchParams = new URLSearchParams(search)
      const activeTab = searchParams.get('activeTab')
      const eventMapping = {
        [CHANNEL_TABS.ALL]:
          TRACKING_EVENTS.BUSINESS_LANDING_PAGE_EVENT.ALL_VIDEOS,
        [CHANNEL_TABS.LIVESTREAM]:
          TRACKING_EVENTS.BUSINESS_LANDING_PAGE_EVENT.LIVE_STREAM,
        [CHANNEL_TABS.ORIGINAL]:
          TRACKING_EVENTS.BUSINESS_LANDING_PAGE_EVENT.SHORT_VIDEO,
        [CHANNEL_TABS.PLAYLIST]:
          TRACKING_EVENTS.BUSINESS_LANDING_PAGE_EVENT.PLAYLIST,
        [CHANNEL_TABS.PLAYLIST_OVERVIEW]:
          TRACKING_EVENTS.BUSINESS_LANDING_PAGE_EVENT.PLAYLIST,
        [CHANNEL_TABS.PLAYLIST_COLLECTION]:
          TRACKING_EVENTS.BUSINESS_LANDING_PAGE_EVENT.PLAYLIST_COLLECTION,
        [CHANNEL_TABS.PLAYLIST_COLLECTION_OVERVIEW]:
          TRACKING_EVENTS.BUSINESS_LANDING_PAGE_EVENT.PLAYLIST_COLLECTION
      }
      const event = eventMapping[activeTab]
      if (event) {
        pageViewPixelAmpTracking(event)
      }
    } else if (/^\/business\/[^/]+\/showrooms$/.test(pathname)) {
      pageViewPixelAmpTracking(
        TRACKING_EVENTS.BUSINESS_LANDING_PAGE_EVENT.SHOWROOM
      )
    } else if (/^\/business\/[^/]+\/ava$/.test(pathname)) {
      pageViewPixelAmpTracking(
        TRACKING_EVENTS.BUSINESS_LANDING_PAGE_EVENT.AVA_DASHBOARD
      )
    } else if (/^\/business\/[^/]+\/store\/[^/]+$/.test(pathname)) {
      pageViewPixelAmpTracking(
        TRACKING_EVENTS.BUSINESS_LANDING_PAGE_EVENT.STORE
      )
    } else if (
      /^\/business\/[^/]+\/chat_channel\/[^/]+\/visitors$/.test(pathname)
    ) {
      pageViewPixelAmpTracking(
        TRACKING_EVENTS.BUSINESS_LANDING_PAGE_EVENT.ONE_TO_ONE
      )
    }
  }, [pathname, search, pageViewPixelAmpTracking])
}

export default usePageViewDataTracking
