import React from 'react'

import { css } from '@emotion/core'
import { Alert } from 'antd'

type IProps = {
  error: Record<string, any>
}

const FormErrors: React.FC<IProps> = ({ error }) => {
  let errorDescription

  const errors = error?.data?.errors

  if (errors) {
    const errorMessages = Object.keys(errors)
      .map((key) => {
        return errors[key].map((detail) => `${key} ${detail}`)
      })
      .flat()
    errorDescription = (
      <ul
        css={css`
          margin: 0;
          padding: 0;
          list-style-type: none;
        `}
      >
        {errorMessages.map((message) => (
          <li key={message}>{message}</li>
        ))}
      </ul>
    )
  }

  if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="Error"
        description={errorDescription || 'Unable to complete request'}
        css={css`
          margin-bottom: 24px;
        `}
      />
    )
  }

  return null
}

export default FormErrors
