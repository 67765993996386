import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit'
import { createGenericSlice } from '@src/redux/bulkSelection'

const slice = createGenericSlice<globalLib.IVideo>({
  name: 'bulkVideoSelection',
  reducers: {}
})

export default slice.reducer

const { actions } = slice
// we have to explicitly type this as ActionCreatorWithoutPayload to allow calling it without arguments
export const resetSelections = actions.resetSelections as ActionCreatorWithoutPayload
export const { addToSelections, removeFromSelections, selectVideos } = actions
