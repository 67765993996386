import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  playFunnelData,
  interactionRateData,
  pageViewData,
  channelOnOData,
  playlistPerformanceData
} from '@src/pages/channel/ChannelInsights/InsightsV3/mockData'
import { playerVisibilityFillData } from '@src/pages/livestream/Analytics/makeupData'
import { addWatchTime } from '@src/utils/stats'
import { sumBy } from 'lodash'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { Dispatch } from 'redux'

import api from '../utils/api'
import { toHumanReadableDuration } from '../utils/sanitize'
import {
  convertTimeUnit,
  fillMissingDates,
  convertTimeMinute
} from '../utils/stats'

type IProps = {
  videoInsightsAggregated: {
    businessOoInsights: globalLib.VideoAggregateInsight[] | null
    ooInsights: globalLib.VideoAggregateInsight[] | null
    repostInsights: globalLib.VideoAggregateInsight[] | null
  }
  thumbnailInsightsByCountry: globalLib.ThumbnailByCountry[]
  thumbnailInsightsByDevice: globalLib.ThumbnailByDevice[]
  videoInsights: Record<string, globalLib.IVideo[]>
  videoInsightsByVideo: Record<string, globalLib.IVideo>
  playlistInsights: globalLib.PlaylistInsight[]
  hashtagInsights: globalLib.HashtagInsight[]
  categoryViewInsights: globalLib.CategoryViewInsight[]
  videoUrlInsightsByVideo: globalLib.VideoUrlInsight[]
  internalRefererInsights: globalLib.VideoUrlInsight[]
  presentationInsights: globalLib.VideoInsight[]
  externalRefererInsights: globalLib.VideoUrlInsight[]
  pageViewInsights: globalLib.PageViewInsight[]
  visorMetrics: {
    last_week: globalLib.VisorMetricInsight
    this_week: globalLib.VisorMetricInsight
  }
  embedFunnelInsights: Partial<globalLib.VideoInsight>[]
  thumbnailDailyInsights: globalLib.ThumbnailDailyInsight[]
  insightsV3RangeSelection: Record<string, any>
  playerVisibility: Record<string, any> // InsightsV3: PlayerVisibilityChart Custom
  playFunnel: Record<string, any> // InsightsV3: PlayFunnelChart Custom
  interactionRate: Record<string, any> // InsightsV3: InteractionRateV3 Custom
  playlistPerformanceV3: Record<string, any>
  pageViewInsightsV3: Record<string, any>
  videoPerformanceV3: Record<string, any>
  showVideoDetailsV3: Record<string, any>
  shortVideoInsightV3: boolean
}

const initialState: IProps = {
  videoInsightsAggregated: {
    businessOoInsights: null,
    ooInsights: null,
    repostInsights: null
  },
  thumbnailInsightsByCountry: [],
  thumbnailInsightsByDevice: [],
  videoInsights: {},
  videoInsightsByVideo: {},
  playlistInsights: [],
  hashtagInsights: [],
  categoryViewInsights: [],
  videoUrlInsightsByVideo: [],
  internalRefererInsights: [],
  presentationInsights: [],
  externalRefererInsights: [],
  pageViewInsights: [],
  visorMetrics: {
    last_week: {
      average_completion_rate: 0,
      average_watch_time_minutes: 0,
      cta_click_rate: 0,
      cta_clicks: 0,
      engagement_rate: 0,
      total_minutes_watched: 0,
      total_num_of_viewers: 0,
      total_views: 0
    },
    this_week: {
      average_completion_rate: 0,
      average_watch_time_minutes: 0,
      cta_click_rate: 0,
      cta_clicks: 0,
      engagement_rate: 0,
      total_minutes_watched: 0,
      total_num_of_viewers: 0,
      total_views: 0
    }
  },
  embedFunnelInsights: [],
  thumbnailDailyInsights: [],
  insightsV3RangeSelection: {},
  playerVisibility: {},
  playFunnel: {},
  interactionRate: {},
  playlistPerformanceV3: {},
  pageViewInsightsV3: {},
  videoPerformanceV3: {},
  showVideoDetailsV3: {},
  shortVideoInsightV3: true
}

const slice = createSlice({
  name: 'insight',
  initialState: initialState,
  reducers: {
    fetchVisorMetricsSuccess(state, action) {
      state.visorMetrics = action.payload
    },

    fetchAggregateVideoInsightsDetailsSuccess(state, action) {
      const {
        endDate,
        startDate,
        video_insights: videoInsights
      } = action.payload
      let ooInsights = videoInsights.oo_insights.map((i) => ({
        ...i,
        total_minutes_watched: Math.round(i.total_minutes_watched),
        total_seconds_watched: Math.round(i.total_seconds_watched),
        source: 'Channel O&O'
      }))
      ooInsights = fillMissingDates({
        data: ooInsights,
        dataInitFields: {
          source: 'Channel O&O',
          engaged_views: 0,
          total_minutes_watched: 0,
          total_seconds_watched: 0,
          total_views: 0,
          cta_clicks: 0,
          cta_click_rate: 0
        },
        endDate: moment(endDate).add(1, 'days'),
        startDate
      })

      let businessOoInsights
      if ('business_oo_insights' in videoInsights) {
        businessOoInsights = videoInsights.business_oo_insights.map((i) => ({
          ...i,
          total_minutes_watched: Math.round(i.total_minutes_watched),
          total_seconds_watched: Math.round(i.total_seconds_watched),
          source: 'Business O&O'
        }))
        businessOoInsights = fillMissingDates({
          data: businessOoInsights,
          dataInitFields: {
            source: 'Business O&O',
            engaged_views: 0,
            total_minutes_watched: 0,
            total_seconds_watched: 0,
            total_views: 0,
            cta_clicks: 0,
            cta_click_rate: 0
          },
          endDate: moment(endDate).add(1, 'days'),
          startDate
        })
      }
      let repostInsights
      if ('repost' in videoInsights) {
        repostInsights = videoInsights.repost.map((i) => ({
          ...i,
          total_minutes_watched: Math.round(i.total_minutes_watched),
          total_seconds_watched: Math.round(i.total_seconds_watched),
          source: 'Repost'
        }))
        repostInsights = fillMissingDates({
          data: repostInsights,
          dataInitFields: {
            source: 'Repost',
            engaged_views: 0,
            total_minutes_watched: 0,
            total_seconds_watched: 0,
            total_views: 0,
            cta_clicks: 0,
            cta_click_rate: 0
          },
          endDate: moment(endDate).add(1, 'days'),
          startDate
        })
      }
      // ** @TODO remove this mock data when backend is available
      const max = 100
      const min = 50
      ooInsights = ooInsights.map((i) => ({
        ...i,
        impressions: Math.floor(Math.random() * (max - min) + min),
        engaged_views: Math.floor(Math.random() * (max - min) + min)
      }))
      businessOoInsights = businessOoInsights.map((i) => ({
        ...i,
        impressions: Math.floor(Math.random() * (max - min) + min),
        engaged_views: Math.floor(Math.random() * (max - min) + min)
      }))
      repostInsights = repostInsights.map((i) => ({
        ...i,
        impressions: Math.floor(Math.random() * (max - min) + min),
        engaged_views: Math.floor(Math.random() * (max - min) + min)
      }))
      // ** end mock data

      state.videoInsightsAggregated = {
        ooInsights,
        businessOoInsights,
        repostInsights
      }
    },

    fetchThumbnailVideoInsightsDetailsSuccess(state, action) {
      const {
        country_thumbnail_insights: countryThumbnailInsights,
        device_thumbnail_insights: deviceThumbnailInsights,
        daily_thumbnail_insights: dailyThumbnailInsights
      } = action.payload

      state.thumbnailInsightsByCountry = countryThumbnailInsights
        .map((i) => ({
          ...i,
          thumbnail_click_rate: i.thumbnail_click_rate * 100
        }))
        .sort((a, b) => b.thumbnail_click_rate - a.thumbnail_click_rate)

      state.thumbnailInsightsByDevice = deviceThumbnailInsights
        .map((i) => ({
          ...i,
          thumbnail_click_rate: i.thumbnail_click_rate * 100
        }))
        .sort((a, b) => b.thumbnail_click_rate - a.thumbnail_click_rate)
      state.thumbnailDailyInsights = dailyThumbnailInsights
    },

    fetchVideoInsightsDetailsSuccess(state, action) {
      const { video_insights: videoInsights, channelId } = action.payload
      // Process by video stats
      state.videoInsights[channelId] = videoInsights
        .map((i) => ({
          ...i,
          avg_watch_time: convertTimeUnit(
            i.total_seconds_watched / i.total_views
          ),
          watch_time: toHumanReadableDuration(
            i.total_minutes_watched,
            'minutes'
          )
        }))
        .sort((a, b) => b.total_views - a.total_views)
    },

    fetchVideoInsightsDetailForVideoSuccess(state, action) {
      momentDurationFormatSetup(moment)
      const { video_insight: videoInsight, endDate, startDate } = action.payload
      // Compute averages for all stats
      videoInsight.watch_time = convertTimeMinute(
        videoInsight.total_minutes_watched,
        'h[h]'
      )
      videoInsight.average_watch_time = convertTimeMinute(
        videoInsight.total_minutes_watched / videoInsight.total_views,
        's[s]'
      )
      // Convert average completion rates to integer percentages
      videoInsight.source_insights = videoInsight.source_insights.map((i) => ({
        ...i,
        average_comp_100p: Math.round(i.average_comp_100p * 100),
        average_comp_25p: Math.round(i.average_comp_25p * 100),
        average_comp_50p: Math.round(i.average_comp_50p * 100),
        average_comp_75p: Math.round(i.average_comp_75p * 100),
        average_watch_time_seconds: Math.round(i.average_watch_time_seconds),
        average_watch_time_minutes: Math.round(i.average_watch_time_minutes),
        total_minutes_watched: Math.round(i.total_minutes_watched),
        total_seconds_watched: Math.round(i.total_seconds_watched)
      }))

      if ('insights' in videoInsight) {
        videoInsight.insights = fillMissingDates({
          data: videoInsight.insights,
          dataInitFields: {
            engaged_views: 0,
            total_minutes_watched: 0,
            total_seconds_watched: 0,
            total_views: 0,
            cta_click_rate: 0
          },
          endDate,
          startDate
        })
      }

      state.videoInsightsByVideo[videoInsight.video_id] = videoInsight
    },

    fetchPlaylistInsightsSuccess(state, action) {
      const { playlist_insights: playlistInsights } = action.payload
      state.playlistInsights = playlistInsights
    },

    fetchHashtagInsightsSuccess(state, action) {
      const { hashtag_insights: hashtagInsights } = action.payload
      state.hashtagInsights = hashtagInsights
    },

    fetchExternalRefererInsightsSuccess(state, action) {
      const { referer_insights: externalRefererInsights } = action.payload
      state.externalRefererInsights = externalRefererInsights
    },

    fetchCategoryViewInsightsSuccess(state, action) {
      const { category_insights: categoryInsights } = action.payload
      // Short circuit logic here because the "empty" state depends on
      // the array being empty, but we will always push the "others" object.
      if (categoryInsights.length === 0) return
      // Separate by top categories
      const totalViews = sumBy(categoryInsights, 'total_views')
      let top5 = categoryInsights.slice(0, 5)
      const others = categoryInsights.slice(5)
      top5.push({
        tag_name: 'others',
        display_name: 'Others',
        total_views: sumBy(others, 'total_views'),
        total_minutes_watched: sumBy(others, 'total_minutes_watched')
      })
      top5 = top5.map((o) => {
        return {
          ...o,
          percentage: o.total_views / totalViews
        }
      })
      state.categoryViewInsights = top5
    },

    fetchVideoUrlInsightsForVideoSuccess(state, action) {
      const { referer_insights: videoUrlInsightsByVideo } = action.payload
      state.videoUrlInsightsByVideo = videoUrlInsightsByVideo
    },

    fetchInternalRefererInsightsSuccess(state, action) {
      const { referer_insights: internalRefererInsights } = action.payload
      state.internalRefererInsights = internalRefererInsights
    },

    fetchPresentationInsightsSuccess(state, action) {
      const { presentation_insights: presentationInsights } = action.payload
      state.presentationInsights = presentationInsights
    },

    fetchPageViewInsightsSuccess(state, action) {
      const { insights: pageViewInsights } = action.payload
      state.pageViewInsights = pageViewInsights
    },

    fetchEmbedFunnelInsightsSuccess(state, action) {
      const { embed_funnel_insights: embedFunnelInsights } = action.payload
      state.embedFunnelInsights = embedFunnelInsights
    },
    setInsightsV3RangeSelectionSuccess(state, action) {
      const { channelId, level, startDate, endDate } = action.payload
      state.insightsV3RangeSelection[channelId] = { level, startDate, endDate }
    },
    fetchPlayerVisibilityInsightsSuccess(state, action) {
      const { channelId, data } = action.payload
      state.playerVisibility[channelId] = data
    },
    fetchPlayFunnelInsightsSuccess(state, action) {
      const { channelId, data } = action.payload
      state.playFunnel[channelId] = data
    },
    fetchInteractionRateInsightsSuccess(state, action) {
      const { channelId, data } = action.payload
      state.interactionRate[channelId] = data
    },
    fetchPlaylistPerformanceV3Success(state, action) {
      const { channelId, data } = action.payload
      state.playlistPerformanceV3[channelId] = data
    },
    fetchPageViewInsightsV3Success(state, action) {
      const { insights: data, channelId } = action.payload
      state.pageViewInsightsV3[channelId] = data
    },
    fetchVideoPerformanceV3Success(state, action) {
      const { channelId, data } = action.payload
      state.videoPerformanceV3[channelId] = data
    },
    setShowVideoDetailsV3Success(state, action) {
      const { channelId, bool, video } = action.payload
      state.showVideoDetailsV3[channelId] = {
        active: bool,
        video
      }
    },
    fetchUrlPerformanceV3Success(state, action) {
      const { referer_insights: videoUrlInsightsByVideo } = action.payload
      state.videoUrlInsightsByVideo = videoUrlInsightsByVideo
    }
  }
})
export default slice.reducer
export const {
  fetchAggregateVideoInsightsDetailsSuccess,
  fetchThumbnailVideoInsightsDetailsSuccess,
  fetchVideoInsightsDetailsSuccess,
  fetchVideoInsightsDetailForVideoSuccess,
  fetchPlaylistInsightsSuccess,
  fetchHashtagInsightsSuccess,
  fetchCategoryViewInsightsSuccess,
  fetchVideoUrlInsightsForVideoSuccess,
  fetchInternalRefererInsightsSuccess,
  fetchPresentationInsightsSuccess,
  fetchExternalRefererInsightsSuccess,
  fetchPageViewInsightsSuccess,
  fetchVisorMetricsSuccess,
  fetchEmbedFunnelInsightsSuccess,
  setInsightsV3RangeSelectionSuccess,
  fetchPlayerVisibilityInsightsSuccess,
  fetchPlayFunnelInsightsSuccess,
  fetchInteractionRateInsightsSuccess,
  fetchPlaylistPerformanceV3Success,
  fetchPageViewInsightsV3Success,
  fetchVideoPerformanceV3Success,
  setShowVideoDetailsV3Success,
  fetchUrlPerformanceV3Success
} = slice.actions

const fetchAggregateVideoInsightsDetailsRequest = createAction(
  'insight/fetchAggregateVideoInsightsDetailsRequest'
)
const fetchAggregateVideoInsightsDetailsFailure = createAction(
  'insight/fetchAggregateVideoInsightsDetailsFailure'
)
export const fetchAggregateVideoInsightsDetails = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchAggregateVideoInsightsDetailsRequest())

      const { start_date, end_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/video_insights_details`,
        {
          params: {
            dimension: 'aggregate',
            end_date,
            start_date
          }
        }
      )

      dispatch(
        fetchAggregateVideoInsightsDetailsSuccess({
          ...res.data,
          endDate: end_date,
          startDate: start_date
        })
      )
    } catch (error) {
      dispatch(fetchAggregateVideoInsightsDetailsFailure())
    }
  }
}

export const fetchAggregateVideoInsightsDetailsV3 = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchAggregateVideoInsightsDetailsRequest())

      const { start_date, end_date } = params
      // const res = await api.get(
      //   `/bus/${businessId}/channels/${channelId}/insights_v3/video_insights_details`,
      //   {
      //     params: {
      //       dimension: 'aggregate',
      //       end_date,
      //       start_date
      //     }
      //   }
      // )

      // ** This is mock data
      const res = channelOnOData(start_date, end_date)

      dispatch(
        fetchAggregateVideoInsightsDetailsSuccess({
          ...res.data,
          endDate: end_date,
          startDate: start_date
        })
      )
    } catch (error) {
      dispatch(fetchAggregateVideoInsightsDetailsFailure())
    }
  }
}

const fetchThumbnailVideoInsightsDetailsRequest = createAction(
  'insight/fetchThumbnailVideoInsightsDetailsRequest'
)
const fetchThumbnailVideoInsightsDetailsFailure = createAction(
  'insight/fetchThumbnailVideoInsightsDetailsFailure'
)
export const fetchThumbnailVideoInsightsDetails = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchThumbnailVideoInsightsDetailsRequest())

      const { start_date, end_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/video_insights_details`,
        {
          params: {
            dimension: 'thumbnail',
            end_date,
            start_date
          }
        }
      )
      dispatch(fetchThumbnailVideoInsightsDetailsSuccess({ ...res.data }))
    } catch (error) {
      dispatch(fetchThumbnailVideoInsightsDetailsFailure())
    }
  }
}

const fetchVideoInsightsDetailsRequest = createAction(
  'insight/fetchVideoInsightsDetailsRequest'
)
const fetchVideoInsightsDetailsFailure = createAction(
  'insight/fetchVideoInsightsDetailsFailure'
)
export const fetchVideoInsightsDetails = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchVideoInsightsDetailsRequest())

      const { start_date, end_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/video_insights_details`,
        {
          params: {
            dimension: 'videos',
            end_date,
            start_date
          }
        }
      )
      dispatch(fetchVideoInsightsDetailsSuccess({ ...res.data, channelId }))

      return res
    } catch (error) {
      dispatch(fetchVideoInsightsDetailsFailure())
    }
  }
}

const fetchVideoInsightsDetailForVideoRequest = createAction(
  'insight/fetchVideoInsightsDetailForVideoRequest'
)
const fetchVideoInsightsDetailForVideoFailure = createAction(
  'insight/fetchVideoInsightsDetailForVideoFailure'
)
export const fetchVideoInsightsDetailForVideo = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchVideoInsightsDetailForVideoRequest())

      const { end_date, start_date, video_id } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/video_insights_details`,
        {
          params: {
            dimension: 'video',
            end_date,
            start_date,
            video_id
          }
        }
      )
      dispatch(
        fetchVideoInsightsDetailForVideoSuccess({
          video_insight: res.data,
          endDate: end_date,
          startDate: start_date
        })
      )

      return res
    } catch (error) {
      dispatch(fetchVideoInsightsDetailForVideoFailure())

      return error
    }
  }
}

const fetchPlaylistInsightsRequest = createAction(
  'insight/fetchPlaylistInsightsRequest'
)
const fetchPlaylistInsightsFailure = createAction(
  'insight/fetchPlaylistInsightsFailure'
)
export const fetchPlaylistInsights = (
  businessId: string,
  channelId: string,
  params: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchPlaylistInsightsRequest())
      const { end_date, start_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/video_insights_details`,
        {
          params: {
            dimension: 'playlist',
            end_date,
            start_date
          }
        }
      )
      dispatch(fetchPlaylistInsightsSuccess({ ...res.data }))
    } catch (error) {
      dispatch(fetchPlaylistInsightsFailure())
    }
  }
}

const fetchHashtagInsightsRequest = createAction(
  'insight/fetchHashtagInsightsRequest'
)
const fetchHashtagInsightsFailure = createAction(
  'insight/fetchHashtagInsightsFailure'
)
export const fetchHashtagInsights = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchHashtagInsightsRequest())
      const { end_date, start_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/video_hashtag_insights`,
        {
          params: {
            end_date,
            start_date
          }
        }
      )
      dispatch(fetchHashtagInsightsSuccess({ ...res.data }))
    } catch (error) {
      dispatch(fetchHashtagInsightsFailure())
    }
  }
}

const fetchExternalRefererInsightsRequest = createAction(
  'insight/fetchExternalRefererInsightsRequest'
)
const fetchExternalRefererInsightsFailure = createAction(
  'insight/fetchExternalRefererInsightsFailure'
)
export const fetchExternalRefererInsights = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchExternalRefererInsightsRequest())
      const { end_date, start_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/external_referer_insights`,
        {
          params: {
            end_date,
            start_date
          }
        }
      )
      dispatch(fetchExternalRefererInsightsSuccess({ ...res.data }))
    } catch (error) {
      dispatch(fetchExternalRefererInsightsFailure())
    }
  }
}

const fetchCategoryViewInsightsRequest = createAction(
  'insight/fetchCategoryViewInsightsRequest'
)
const fetchCategoryViewInsightsFailure = createAction(
  'insight/fetchCategoryViewInsightsFailure'
)
export const fetchCategoryViewInsights = (
  businessId: string,
  channelId: string,
  params: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchCategoryViewInsightsRequest())
      const { end_date, start_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/video_category_insights`,
        {
          params: {
            end_date,
            start_date
          }
        }
      )
      dispatch(fetchCategoryViewInsightsSuccess({ ...res.data }))
    } catch (error) {
      dispatch(fetchCategoryViewInsightsFailure())
    }
  }
}

const fetchVideoUrlInsightsForVideoRequest = createAction(
  'insight/fetchVideoUrlInsightsForVideoRequest'
)
const fetchVideoUrlInsightsForVideoFailure = createAction(
  'insight/fetchVideoUrlInsightsForVideoFailure'
)
export const fetchVideoUrlInsightsForVideo = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchVideoUrlInsightsForVideoRequest())
      const { end_date, start_date, video_id } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/video_referer_insights`,
        {
          params: {
            end_date,
            start_date,
            video_id
          }
        }
      )
      dispatch(fetchVideoUrlInsightsForVideoSuccess({ ...res.data }))
    } catch (error) {
      dispatch(fetchVideoUrlInsightsForVideoFailure())
    }
  }
}

const fetchInternalRefererInsightsRequest = createAction(
  'insight/fetchInternalRefererInsightsRequest'
)
const fetchInternalRefererInsightsFailure = createAction(
  'insight/fetchInternalRefererInsightsFailure'
)
export const fetchInternalRefererInsights = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchInternalRefererInsightsRequest())
      const { end_date, start_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/internal_referer_insights`,
        {
          params: {
            end_date,
            start_date
          }
        }
      )
      dispatch(fetchInternalRefererInsightsSuccess({ ...res.data }))
    } catch (error) {
      dispatch(fetchInternalRefererInsightsFailure())
    }
  }
}

const fetchPresentationInsightsRequest = createAction(
  'insight/fetchPresentationInsightsRequest'
)
const fetchPresentationInsightsFailure = createAction(
  'insight/fetchPresentationInsightsFailure'
)
export const fetchPresentationInsights = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchPresentationInsightsRequest())
      const { end_date, start_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/video_insights_details`,
        {
          params: {
            dimension: 'presentation',
            end_date,
            start_date
          }
        }
      )
      dispatch(fetchPresentationInsightsSuccess({ ...res.data }))
    } catch (error) {
      dispatch(fetchPresentationInsightsFailure())
    }
  }
}

const fetchPageViewInsightsRequest = createAction(
  'insight/fetchPageViewInsightsRequest'
)
const fetchPageViewInsightsFailure = createAction(
  'insight/fetchPageViewInsightsFailure'
)

export const fetchPageViewInsights = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchPageViewInsightsRequest())
      const { end_date, start_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/page_view_insights`,
        {
          params: {
            start_date,
            end_date
          }
        }
      )
      dispatch(fetchPageViewInsightsSuccess({ ...res.data }))
    } catch (error) {
      dispatch(fetchPageViewInsightsFailure())
    }
  }
}

const fetchPageViewInsightsV3Request = createAction(
  'insight/fetchPageViewInsightsV3Request'
)
const fetchPageViewInsightsV3Failure = createAction(
  'insight/fetchPageViewInsightsV3Failure'
)

export const fetchPageViewInsightsV3 = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchPageViewInsightsV3Request())
      const { end_date, start_date } = params
      // const res = await api.get(
      //   `/bus/${businessId}/channels/${channelId}/insights_v3/page_view_insights`,
      //   {
      //     params: {
      //       start_date,
      //       end_date
      //     }
      //   }
      // )
      // ** use mock data for testing in staging
      const res = pageViewData(start_date, end_date)
      dispatch(fetchPageViewInsightsV3Success({ ...res.data, channelId }))
    } catch (error) {
      dispatch(fetchPageViewInsightsV3Failure())
    }
  }
}

const fetchVisorMetricsRequest = createAction(
  'insight/fetchVisorMetricsRequest'
)
const fetchVisorMetricsFailure = createAction(
  'insight/fetchVisorMetricsFailure'
)
export const fetchVisorMetrics = (
  businessId: string,
  channelId: string,
  offset?: number
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchVisorMetricsRequest())

      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/visor_metrics`,
        {
          params: {
            aggregate: 7,
            offset
          }
        }
      )

      dispatch(fetchVisorMetricsSuccess(res.data))
    } catch (error) {
      dispatch(fetchVisorMetricsFailure())
    }
  }
}

const fetchEmbedFunnelInsightsRequest = createAction(
  'insight/fetchEmbedFunnelInsightsRequest'
)

const fetchEmbedFunnelInsightsFailure = createAction(
  'insight/fetchEmbedFunnelInsightsFailure'
)
export const fetchEmbedFunnelInsights = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchEmbedFunnelInsightsRequest())
      const { end_date, start_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/page_view_insights`,
        {
          params: {
            start_date,
            end_date
          }
        }
      )

      dispatch(fetchEmbedFunnelInsightsSuccess({ ...res.data }))
    } catch (error) {
      dispatch(fetchEmbedFunnelInsightsFailure())
    }
  }
}

export const setInsightsV3RangeSelection = ({
  channelId,
  level,
  startDate,
  endDate
}: {
  channelId: string
  level: string
  startDate: string
  endDate: string
}) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    dispatch(
      setInsightsV3RangeSelectionSuccess({
        channelId,
        level,
        startDate,
        endDate
      })
    )
  }
}

const fetchPlayerVisibilityInsightsRequest = createAction(
  'insight/fetchPlayerVisibilityInsightsRequest'
)
const fetchPlayerVisibilityInsightsFailure = createAction(
  'insight/fetchPlayerVisibilityInsightsFailure'
)

export const fetchPlayerVisibilityInsights = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchPlayerVisibilityInsightsRequest())
      const { start_date, end_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/v3/channel_presentation_insights`,
        {
          params: {
            start_date,
            end_date
          }
        }
      )
      dispatch(
        fetchPlayerVisibilityInsightsSuccess({
          data: playerVisibilityFillData(
            res.data.presentation_insights,
            start_date,
            end_date
          ),
          channelId
        })
      )
    } catch (error) {
      dispatch(fetchPlayerVisibilityInsightsFailure())
    }
  }
}

const fetchPlayFunnelInsightsRequest = createAction(
  'insight/fetchPlayFunnelInsightsRequest'
)
const fetchPlayFunnelInsightsFailure = createAction(
  'insight/fetchPlayFunnelInsightsFailure'
)

export const fetchPlayFunnelInsights = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchPlayFunnelInsightsRequest())
      const { start_date, end_date } = params
      // const res = await api.get('')
      // ** This is mock data
      const res = {
        data: playFunnelData(start_date, end_date)
      }
      dispatch(fetchPlayFunnelInsightsSuccess({ data: res.data, channelId }))
    } catch (error) {
      dispatch(fetchPlayFunnelInsightsFailure())
    }
  }
}

const fetchInteractionRateInsightsRequest = createAction(
  'insight/fetchInteractionRateInsightsRequest'
)
const fetchInteractionRateInsightsFailure = createAction(
  'insight/fetchInteractionRateInsightsFailure'
)
export const fetchInteractionRateInsights = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchInteractionRateInsightsRequest())
      const { start_date, end_date } = params
      // const res = await api.get('')
      // ** This is mock data
      const res = {
        data: interactionRateData(start_date, end_date)
      }
      dispatch(
        fetchInteractionRateInsightsSuccess({ data: res.data, channelId })
      )
    } catch (error) {
      dispatch(fetchInteractionRateInsightsFailure())
    }
  }
}

const fetchPlaylistPerformanceV3Request = createAction(
  'insight/fetchPlaylistPerformanceV3Request'
)
const fetchPlaylistPerformanceV3Failure = createAction(
  'insight/fetchPlaylistPerformanceV3Failure'
)
export const fetchPlaylistPerformanceV3 = (
  businessId: string,
  channelId: string
  // params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchPlaylistPerformanceV3Request())
      // const { start_date, end_date } = params
      // const res = await api.get(
      //   `/bus/${businessId}/channels/${channelId}/insights_v3/video_insights_details`,
      //   {
      //     params: {
      //       dimension: 'playlist',
      //       end_date,
      //       start_date
      //     }
      //   }
      // )
      const res = playlistPerformanceData()
      let modifiedData = addWatchTime(res.playlist_insights)
      modifiedData = modifiedData.map((playlist) => {
        return {
          ...playlist,
          engaged_views: Math.floor(Math.random() * (100 - 50) + 50),
          engaged_view_rate: Math.random()
        }
      })
      dispatch(
        fetchPlaylistPerformanceV3Success({
          data: modifiedData,
          channelId
        })
      )
    } catch (error) {
      dispatch(fetchPlaylistPerformanceV3Failure())
    }
  }
}

const fetchVideoPerformanceV3Request = createAction(
  'insight/fetchVideoPerformanceV3Request'
)
const fetchVideoPerformanceV3Failure = createAction(
  'insight/fetchVideoPerformanceV3Failure'
)
export const fetchVideoPerformanceV3 = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchVideoPerformanceV3Request())
      const { start_date, end_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/video_insights_details`,
        {
          params: {
            dimension: 'videos',
            end_date,
            start_date
          }
        }
      )
      dispatch(
        fetchVideoPerformanceV3Success({
          data: res.data.video_insights,
          channelId
        })
      )
    } catch (error) {
      dispatch(fetchVideoPerformanceV3Failure())
    }
  }
}

const fetchVideoDetailsV3Request = createAction(
  'insight/fetchVideoDetailsV3Request'
)
const fetchVideoDetailsV3Failure = createAction(
  'insight/fetchVideoDetailsV3Failure'
)

const fetchVideoDetailsV3 = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
): any => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchVideoDetailsV3Request())
      const { start_date, end_date, video_id, bool } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/video_insights_details`,
        {
          params: {
            dimension: 'video',
            end_date,
            start_date,
            video_id
          }
        }
      )
      dispatch(
        setShowVideoDetailsV3Success({ channelId, bool, video: res.data })
      )
    } catch (error) {
      dispatch(fetchVideoDetailsV3Failure())
    }
  }
}
export const setShowVideoDetailsV3 = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    const { bool, start_date, video } = params
    if (bool && start_date) {
      dispatch(fetchVideoDetailsV3(businessId, channelId, params))
    } else dispatch(setShowVideoDetailsV3Success({ channelId, bool, video }))
  }
}

const fetchUrlPerformanceV3Request = createAction(
  'insight/fetchUrlPerformanceV3Request'
)
const fetchUrlPerformanceV3Failure = createAction(
  'insight/fetchUrlPerformanceV3Failure'
)
export const fetchUrlPerformanceV3 = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchUrlPerformanceV3Request())
      const { end_date, start_date, video_id } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/video_referer_insights`,
        {
          params: {
            end_date,
            start_date,
            video_id
          }
        }
      )
      dispatch(fetchUrlPerformanceV3Success({ ...res.data }))
    } catch (error) {
      dispatch(fetchUrlPerformanceV3Failure())
    }
  }
}
