import { createSlice, Draft, Slice, SliceCaseReducers } from '@reduxjs/toolkit'

type IProps<T> = {
  selections: T[]
  selectedIds: string[]
}

/**
 * Function to create a generic slice using Redux Toolkit's createSlice function.
 * This is meant only for bulk selection.
 *
 * @param {object} options - The options object for creating the slice.
 * @param {string} options.name - The name of the slice.
 * @param {object} options.initialState - The initial state of the slice.
 * @param {object} options.reducers - The reducers object for the slice.
 *
 * @returns {object} - The created slice.
 */
export const createGenericSlice = <T>({
  name = '',
  reducers
}: {
  name: string
  reducers: SliceCaseReducers<IProps<T>>
}): Slice => {
  return createSlice({
    name,
    initialState: {
      selections: [],
      selectedIds: []
    },
    reducers: {
      addToSelections(
        state,
        action: { payload: Draft<T> & { encoded_id: string } }
      ) {
        const { selections, selectedIds } = state
        state.selections = [...selections, action.payload]
        state.selectedIds = [...selectedIds, action.payload.encoded_id]
      },
      removeFromSelections(
        state,
        action: { payload: Draft<T> & { encoded_id: string } }
      ) {
        const entity = action.payload
        const updatedData = [...state.selections]
        const updatedIds = [...state.selectedIds]
        const itemIndex = updatedIds.findIndex(
          (item) => item === entity.encoded_id
        )
        updatedData.splice(itemIndex, 1)
        updatedIds.splice(itemIndex, 1)
        state.selections = updatedData
        state.selectedIds = updatedIds
      },
      resetSelections(state) {
        state.selections = []
        state.selectedIds = []
      },
      selectVideos(
        state,
        action: { payload: (Draft<T> & { encoded_id: string })[] }
      ) {
        state.selections = action.payload || []
        state.selectedIds = (action.payload || []).map((item) => {
          return item.encoded_id
        })
      },
      ...reducers
    }
  })
}
