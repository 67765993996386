import React, { useState } from 'react'

import { css } from '@emotion/core'
import { Flex, Box } from '@src/components/EmotionLayout'
import { Radio, Skeleton } from 'antd'
import { Chart, Coord, Tooltip, Legend, Axis, Geom, Guide } from 'bizcharts'

import { Card, CardHeader, EmptyInsightsContent } from '.'
import { capitalize } from '../utils/format'
import { sortObjectByKey, segmentTopX } from '../utils/stats'

type CRTProps = {
  data?: any[]
  dataKey?: string
  sortKey?: string
  dimension?: string
}

type SegmentedPieChartCardProps = {
  chartHeight?: number
  children?: React.ReactNode
  data: any
  dataKey: string
  dataTotalKey: string
  dataLegendKey?: string
  loading?: boolean
  title?: string
  titleTotal?: string
  tooltip?: string
}
const CTR = ({ data, dataKey, sortKey, dimension }: CRTProps) => {
  const sortedData = sortObjectByKey(data, sortKey, 'desc')
    .slice(0, Math.min(data.length, 10))
    .filter((e) =>
      dimension === 'device'
        ? e[dataKey] <= 100
        : e[dataKey] <= 100 && e[dataKey] > 0
    )

  return (
    <Flex
      height="250"
      justifyContent="center"
      flexDirection="column"
      position="absolute"
      right="5%"
    >
      <Flex flexDirection="column" bg="#58bec01a" borderRadius="4" p="small">
        <Flex justifyContent="center">CTR</Flex>
        {sortedData.map((row, i) => (
          <Flex
            fontSize="xsmall"
            opacity="0.6"
            key={i}
            justifyContent="space-between"
          >
            <Box mr="4">{row[dimension]}</Box>
            <Flex justifyContent="space-between" width="50">
              <Box>-</Box>
              <Box>{`${row[dataKey].toFixed(2)}%`}</Box>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

const SegmentedPieChartCard = ({
  children,
  loading,
  title,
  titleTotal,
  tooltip,
  data = {},
  dataKey,
  dataLegendKey,
  dataTotalKey,
  chartHeight = 250
}: SegmentedPieChartCardProps): JSX.Element => {
  const dimensions = Object.keys(data)
  const hasData = dimensions.length > 0
  const [currentDimension, setCurrentDimension] = useState(
    hasData ? dimensions[0] : null
  )

  const handleDimensionChange = (e) => {
    setCurrentDimension(e.target.value)
  }
  const listTotal = () => {
    return Math.round(
      data[currentDimension].reduce((acc, obj) => {
        return acc + obj[dataTotalKey]
      }, 0)
    ).toLocaleString()
  }

  const sortedData = sortObjectByKey(
    [...data[currentDimension]],
    dataTotalKey,
    'desc'
  )
  const topData = segmentTopX(
    sortedData,
    [dataKey, dataTotalKey],
    currentDimension,
    9
  )

  return (
    <Card height="100%" m="0">
      <Skeleton active loading={loading}>
        <CardHeader title={title} tooltip={tooltip} />
        {hasData ? (
          <>
            <Radio.Group
              defaultValue={currentDimension}
              onChange={handleDimensionChange}
              css={css`
                .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                  background: #bbb;
                  color: black;
                  border: 1px solid #bbb;
                }
                .ant-radio-button-wrapper {
                  border: 1px solid #bbb;
                }
                .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-of-type {
                  border: 1px solid #bbb;
                }
              `}
            >
              {dimensions.map((dimen) => (
                <Radio.Button key={dimen} value={dimen}>
                  {capitalize(dimen)}
                </Radio.Button>
              ))}
            </Radio.Group>
            <Chart
              height={chartHeight}
              data={topData}
              padding={[0, 0, 0, 0]}
              forceFit
              css={css`
                position: relative;
              `}
            >
              <Coord type="theta" radius={0.8} innerRadius={0.7} />
              <Tooltip showTitle={false} />
              {dataLegendKey ? (
                <CTR
                  data={sortedData}
                  dataKey={dataKey}
                  sortKey={dataLegendKey}
                  dimension={currentDimension}
                />
              ) : (
                <Legend
                  position="right-center"
                  layout="vertical"
                  itemFormatter={(val) => {
                    return `${val} - ${topData
                      .filter((obj) => obj[currentDimension] === val)[0]
                      [dataKey].toFixed(2)}%`
                  }}
                />
              )}
              <Flex>
                <Box></Box>
              </Flex>
              <Axis name={dataKey} />
              <Geom
                type="intervalStack"
                position={dataTotalKey}
                color={currentDimension}
                style={{ lineWidth: 4, stroke: '#fff' }}
                tooltip={[
                  `${dataTotalKey}*${currentDimension}`,
                  (value, name) => {
                    return {
                      name: name,
                      value: value.toLocaleString()
                    }
                  }
                ]}
              />
              <Guide>
                <Guide.Text
                  style={{
                    textAlign: 'center',
                    fontSize: 14,
                    fontWeight: '200'
                  }}
                  content={titleTotal || title}
                  position={['50%', '45%']}
                />
                <Guide.Text
                  style={{
                    textAlign: 'center',
                    fontSize: 24,
                    fontWeight: '300',
                    fill: 'black'
                  }}
                  content={listTotal()}
                  position={['50%', '55%']}
                />
              </Guide>
              {children}
            </Chart>
          </>
        ) : (
          <EmptyInsightsContent />
        )}
      </Skeleton>
    </Card>
  )
}

export default SegmentedPieChartCard
