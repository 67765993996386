import { useCallback } from 'react'

export default function useGoogleTracking() {
  const gtagTrack = useCallback((event, eventName, params) => {
    const gtag = window?.gtag
    if (typeof gtag === 'function') gtag(event, eventName, params)
  }, [])

  return {
    gtagTrack
  }
}
