import { useContext } from 'react'

import {
  NabooFFContext,
  NabooFeatureFlag
} from '@src/contexts/NabooFeatureFlag'

export const useNabooFeatureFlag = (): NabooFeatureFlag => {
  return useContext(NabooFFContext)
}
