import { createSlice, Slice, SliceCaseReducers } from '@reduxjs/toolkit'
import { WEB_SOCKET_STATUS } from '@src/constants'

interface WebSocketState {
  status: string
  error: string
  topicsJoined: string[]
}

const initialState: WebSocketState = {
  status: WEB_SOCKET_STATUS.CLOSED,
  error: null,
  topicsJoined: []
}

const SLICE_NAME = 'websocket'

const slice: Slice<
  WebSocketState,
  SliceCaseReducers<WebSocketState>,
  typeof SLICE_NAME
> = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setStatus(state, action) {
      const { status, error } = action.payload
      state.status = status
      state.error = error
    },
    joinWSTopic(state, action) {
      const { topic } = action.payload
      state.topicsJoined.push(topic)
    },
    leaveWSTopic(state, action) {
      const { topic } = action.payload
      state.topicsJoined = state.topicsJoined.filter((t) => t !== topic)
    }
  }
})

export default slice.reducer

export const { setStatus, joinWSTopic, leaveWSTopic } = slice.actions
