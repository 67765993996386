import { createAction, createSlice } from '@reduxjs/toolkit'
import { removeUndefined } from '@src/utils/sanitize'
import { Dispatch } from 'redux'

import api from '../utils/api'

type IProps = {
  avaDomainsByBusinessId: {
    [businessId: string]: {
      nextPage: Record<string, number> | null
      avaDomains: AvaDomainAssistant[]
    }
  }
}

const initialState: IProps = { avaDomainsByBusinessId: {} }

const slice = createSlice({
  name: 'avaDomainAssistant',
  initialState: initialState,
  reducers: {
    fetchAvaDomainAssistantsSuccess(state, action) {
      const { avaDomains, nextPage, businessId } = action.payload
      const domainAssistant = state.avaDomainsByBusinessId[businessId] ?? {
        nextPage: null,
        avaDomains: []
      }
      domainAssistant.nextPage = nextPage
      domainAssistant.avaDomains = domainAssistant.avaDomains.concat(avaDomains)
      state.avaDomainsByBusinessId[businessId] = domainAssistant
    },
    fetchAvaDomainAssistantSuccess(state, action) {
      const { avaDomain, businessId } = action.payload
      const index = state.avaDomainsByBusinessId[
        businessId
      ]?.avaDomains?.findIndex((item) => item.id === avaDomain.id)
      if (index >= 0) {
        state.avaDomainsByBusinessId[businessId].avaDomains[index] = avaDomain
      }
    },
    createAvaDomainAssistantSuccess(state, action) {
      const { avaDomain, businessId } = action.payload
      const domainAssistant = state.avaDomainsByBusinessId[businessId] ?? {
        nextPage: null,
        avaDomains: []
      }
      domainAssistant.avaDomains = [avaDomain].concat(
        domainAssistant.avaDomains
      )

      state.avaDomainsByBusinessId[businessId] = domainAssistant
    },
    updateAvaDomainAssistantSuccess(state, action) {
      const { avaDomain, businessId } = action.payload
      const index = state.avaDomainsByBusinessId[
        businessId
      ]?.avaDomains?.findIndex((item) => item.id === avaDomain.id)
      if (index >= 0) {
        state.avaDomainsByBusinessId[businessId].avaDomains[index] = avaDomain
      }
    },
    deleteAvaDomainAssistantSuccess(state, action) {
      const { domainAssistantId, businessId } = action.payload
      const index = state.avaDomainsByBusinessId[
        businessId
      ]?.avaDomains?.findIndex((item) => item.id === domainAssistantId)
      if (index >= 0) {
        state.avaDomainsByBusinessId[businessId].avaDomains.splice(index, 1)
      }
    }
  }
})

export default slice.reducer

export const {
  fetchAvaDomainAssistantsSuccess,
  fetchAvaDomainAssistantSuccess,
  createAvaDomainAssistantSuccess,
  updateAvaDomainAssistantSuccess,
  deleteAvaDomainAssistantSuccess
} = slice.actions

const fetchAvaDomainAssistantsRequest = createAction(
  'avaDomainAssistant/fetchAvaDomainAssistantsRequest'
)
const fetchAvaDomainAssistantsFailure = createAction(
  'avaDomainAssistant/fetchAvaDomainAssistantsFailure'
)

export function fetchAvaDomainAssistants(
  businessId: string,
  pageSize = 10,
  nextPage?: Record<string, number>
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchAvaDomainAssistantsRequest())
      const response = await api.get(
        `/api/bus/${businessId}/domain_assistants`,
        {
          params: {
            page_size: pageSize,
            ...nextPage
          }
        }
      )
      const { entries, next_page } = response.data
      dispatch(
        fetchAvaDomainAssistantsSuccess({
          avaDomains: entries,
          nextPage: next_page,
          businessId
        })
      )

      return response
    } catch (error) {
      dispatch(fetchAvaDomainAssistantsFailure())

      return error
    }
  }
}

const fetchAvaDomainAssistantRequest = createAction(
  'avaDomainAssistant/fetchAvaDomainAssistantRequest'
)
const fetchAvaDomainAssistantFailure = createAction(
  'avaDomainAssistant/fetchAvaDomainAssistantFailure'
)

export function fetchAvaDomainAssistant(
  businessId: string,
  domainAssistantId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchAvaDomainAssistantRequest())
      const response = await api.get(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}`
      )
      dispatch(
        fetchAvaDomainAssistantSuccess({
          avaDomain: response.data,
          businessId
        })
      )

      return response.data
    } catch (error) {
      dispatch(fetchAvaDomainAssistantFailure())

      return error
    }
  }
}

const createAvaDomainAssistantRequest = createAction(
  'avaDomainAssistant/createAvaDomainAssistantRequest'
)
const createAvaDomainAssistantFailure = createAction(
  'avaDomainAssistant/createAvaDomainAssistantFailure'
)

export type Status = 'active' | 'inactive' | 'draft'
export type DomainType = 'product'

type StarterQuestion = {
  question: string
}

export type AvaDomainAssistantRequest = {
  assistantProfileId: string
  domainInstruction?: string
  domainType: DomainType
  locale: string
  productId?: string
  status?: Status
  title: string
  url?: string
  starterQuestions?: StarterQuestion[]
}

export function createAvaDomainAssistant(
  businessId: string,
  data: AvaDomainAssistantRequest
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createAvaDomainAssistantRequest())
      const response = await api.post(
        `/api/bus/${businessId}/domain_assistants`,
        {
          ...sanitizeDomainAssistantParams(data)
        }
      )
      dispatch(
        createAvaDomainAssistantSuccess({
          avaDomain: response.data,
          businessId
        })
      )

      return response
    } catch (error) {
      dispatch(createAvaDomainAssistantFailure())

      return error
    }
  }
}

const updateAvaDomainAssistantRequest = createAction(
  'avaDomainAssistant/updateAvaDomainAssistantRequest'
)
const updateAvaDomainAssistantFailure = createAction(
  'avaDomainAssistant/updateAvaDomainAssistantFailure'
)

export function updateAvaDomainAssistant(
  businessId: string,
  domainAssistantId: string,
  data: AvaDomainAssistantRequest
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateAvaDomainAssistantRequest())
      const response = await api.put(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}`,
        {
          ...sanitizeDomainAssistantParams(data)
        }
      )
      dispatch(
        updateAvaDomainAssistantSuccess({
          avaDomain: response.data,
          businessId
        })
      )

      return response
    } catch (error) {
      dispatch(updateAvaDomainAssistantFailure())

      return error
    }
  }
}

const deleteAvaDomainAssistantRequest = createAction(
  'avaDomainAssistant/deleteAvaDomainAssistantRequest'
)
const deleteAvaDomainAssistantFailure = createAction(
  'avaDomainAssistant/deleteAvaDomainAssistantFailure'
)

export function deleteAvaDomainAssistant(
  businessId: string,
  domainAssistantId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteAvaDomainAssistantRequest())
      const response = await api.delete(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}`
      )
      dispatch(
        deleteAvaDomainAssistantSuccess({
          domainAssistantId,
          businessId
        })
      )

      return response
    } catch (error) {
      dispatch(deleteAvaDomainAssistantFailure())

      return error
    }
  }
}

const sanitizeDomainAssistantParams = (data) => {
  const sanitized = {
    assistant_profile_id: data.assistantProfileId,
    domain_instruction: data.domainInstruction,
    domain_type: data.domainType,
    locale: data.locale,
    product_id: data?.productId,
    status: data.status,
    title: data.title,
    url: data.url,
    start_questions: data.starterQuestions
  }

  return removeUndefined(sanitized)
}
