import React, { createContext, useContext, useEffect, useState } from 'react'

import { useAppSelector } from '@src/app/hooks'
import { fetchEmails } from '@src/redux/profile'
import { useDispatch } from 'react-redux'

import { BusinessContext } from './BusinessContext'

// Define the shape of the data tracking context
interface DataTrackingContextProps {
  basicTrackingData: {
    userId?: Record<string, any>
    primaryEmail?: string
    defaultTracking?: Record<string, any>
  }
}

// Create the data tracking context
export const DataTrackingContext = createContext<DataTrackingContextProps>({
  basicTrackingData: {}
})

// Create the data tracking provider component
export const DataTrackingProvider: React.FC = ({ children }) => {
  const [basicTrackingData, setBasicTrackingData] = useState<any>({})

  const user = useAppSelector((state) => state.profile.user)

  const primaryEmail = useAppSelector((state) => state.profile.primaryEmail)

  const businessId = useContext(BusinessContext)
  const { businesses: businessIds } = useAppSelector((state) => state.business)

  const dispatch = useDispatch()

  useEffect(() => {
    // suppport phone login, primaryEmail is optinal
    if (user) {
      dispatch(fetchEmails())
    }
  }, [dispatch, user])

  useEffect(() => {
    const businessType = businessIds[businessId]?.type
    setBasicTrackingData({
      userId: user?.encoded_id,
      primaryEmail,
      defaultTracking: {
        _business_id: businessId,
        _business_type: businessType
      }
    })
  }, [user, primaryEmail, businessId, businessIds])

  return (
    <DataTrackingContext.Provider value={{ basicTrackingData }}>
      {user && children}
    </DataTrackingContext.Provider>
  )
}
