import React from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import { Flex, Box } from '@src/components/EmotionLayout'
import { Tooltip as AntTooltip } from 'antd'

type IProps = {
  extra?: string | JSX.Element
  title?: string | JSX.Element
  tooltip?: string | JSX.Element
  mb?: string | number
  mt?: string | number
  width?: string | number
  justifyContent?: string
  onHover?: () => void
}
const CardHeader = ({
  extra,
  title,
  tooltip,
  onHover,
  ...props
}: IProps): JSX.Element => {
  return (
    <Flex alignItems="center" flexDirection="row" mb="small" {...props}>
      <Box
        size="small"
        color="rgba(0, 0, 0, .45)"
        fontWeight="normal"
        flex="1"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {title}
      </Box>
      {extra}
      {tooltip && (
        <Box ml="xsmall">
          <AntTooltip
            overlayInnerStyle={{
              padding: 20
            }}
            onOpenChange={(visible) => onHover && visible && onHover()}
            title={
              <Box color="white" size="14">
                {tooltip}
              </Box>
            }
            placement="topRight"
            arrowPointAtCenter
          >
            <InfoCircleOutlined />
          </AntTooltip>
        </Box>
      )}
    </Flex>
  )
}

export default CardHeader
