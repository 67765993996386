import { ChatChannelConfigRequest } from '@src/oneToOne/redux/chatChannel'

const transformRuleExpressionForUI = (
  expression?: ChatChannelRuleExpression
): ChatChannelRuleExpression | undefined => {
  if (!expression) {
    return undefined
  }

  const transformedRuleGroups: ChatChannelRuleGroup[] = expression.rule_groups.map(
    (ruleGroup) => {
      const transformedRules: ChatChannelRule[] = ruleGroup.rules.map(
        (rule) => {
          if (rule.field === undefined) {
            // If the rule is ChatChannelCountryStateRule, add field = 'country'.
            // Different rule components will be switched based on this field.
            return {
              ...rule,
              field: 'country'
            }
          }

          return rule
        }
      )

      return {
        logical_connective: ruleGroup.logical_connective,
        rules: transformedRules
      }
    }
  )

  return {
    logical_connective: expression.logical_connective,
    rule_groups: transformedRuleGroups
  }
}

const transformRuleExpressionForAPI = (
  expression?: ChatChannelRuleExpression,
  fromV1?: boolean
): ChatChannelRuleExpression | undefined => {
  if (!expression) {
    return undefined
  }

  const transformedRuleGroups: ChatChannelRuleGroup[] = expression.rule_groups.map(
    (ruleGroup) => {
      const transformedRules: ChatChannelRule[] = ruleGroup.rules.map(
        (rule) => {
          if (rule.field === 'country') {
            // If the rule is country rule in v1 schema
            if (fromV1) {
              const ruleV1: ChatChannelGeneralRule = rule as ChatChannelGeneralRule
              const ruleV2: ChatChannelCountryStateRule = {
                country: ruleV1.value
              }

              return ruleV2
            }

            // If the rule is ChatChannelCountryStateRule, remove field
            rule.field = undefined

            return rule
          }

          return rule
        }
      )

      return {
        logical_connective: ruleGroup.logical_connective,
        rules: transformedRules
      }
    }
  )

  return {
    logical_connective: expression.logical_connective,
    rule_groups: transformedRuleGroups
  }
}

/**
 * Visibility Rules Helpers
 * @description: A util which provides Visibility Rules object converting between API and UI
 */
const convertChatChannelVisibilityForUI = (
  rules?: ChatChannelWidgetVisibilityRules
): ChatChannelWidgetVisibilityRules => {
  return {
    show: transformRuleExpressionForUI(rules?.show),
    hide: transformRuleExpressionForUI(rules?.hide)
  }
}

const convertChatChannelVisibilityForAPI = (
  rules?: ChatChannelWidgetVisibilityRules
): ChatChannelWidgetVisibilityRules => {
  return {
    show: transformRuleExpressionForAPI(rules?.show),
    hide: transformRuleExpressionForAPI(rules?.hide)
  }
}

/**
 * Segment Rules Helpers
 * @description: A util which provides Segment Rules object converting between API and UI
 */
const convertChatChannelSegmentCriteriaForUI = (
  criteria?: ChatChannelSegmentCriteria
): ChatChannelSegmentCriteria => {
  return {
    expression: transformRuleExpressionForUI(criteria?.expression)
  }
}

const convertChatChannelSegmentCriteriaForAPI = (
  criteria?: ChatChannelSegmentCriteria
): ChatChannelSegmentCriteria => {
  return {
    expression: transformRuleExpressionForAPI(criteria?.expression)
  }
}

const convertChatChannelSegmentToV2IfNeeded = (segment: any) => {
  if (segment) {
    const { criteria, ...reset } = segment

    // Segment in v1 has no `expression`
    if (criteria?.expression === undefined) {
      const criteriaV2: ChatChannelSegmentCriteria = {
        expression: transformRuleExpressionForAPI(criteria, true)
      }

      return { ...reset, criteria: criteriaV2 }
    }
  }

  return segment
}

/**
 * Camera Permission Helpers
 * @description: A util which provides camera permission converting between API and UI
 */
const cameraPermissionMap: Record<
  ChatChannelCameraPermission,
  ChatChannelConfigRequest['camera_permission']
> = {
  CAMERA_PERMISSION_ALWAYS_ALLOW: 'always_allow',
  CAMERA_PERMISSION_INVITE_ONLY: 'invite_only',
  CAMERA_PERMISSION_NEVER_ALLOW: 'never_allow'
}

const convertToCameraPermissionRequest = (
  permission: ChatChannelCameraPermission
): ChatChannelConfigRequest['camera_permission'] => {
  return cameraPermissionMap[permission]
}

const convertToCameraPermission = (
  permission: ChatChannelConfigRequest['camera_permission']
): ChatChannelCameraPermission => {
  return Object.keys(cameraPermissionMap).find(
    (key) => cameraPermissionMap[key] === permission
  ) as ChatChannelCameraPermission
}

export {
  convertChatChannelVisibilityForUI,
  convertChatChannelVisibilityForAPI,
  convertChatChannelSegmentCriteriaForUI,
  convertChatChannelSegmentCriteriaForAPI,
  convertChatChannelSegmentToV2IfNeeded,
  convertToCameraPermissionRequest,
  convertToCameraPermission
}
