import { createAction, createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'

import api from '../utils/api'

const initialState: { impressions: any[] } = {
  impressions: []
}

const slice = createSlice({
  name: 'impression',
  initialState: initialState,
  reducers: {
    fetchImpressionsSuccess(state, action) {
      const { impressions } = action.payload
      state.impressions = impressions
    }
  }
})

export default slice.reducer

export const { fetchImpressionsSuccess } = slice.actions

const fetchImpressionsRequest = createAction(
  'impression/fetchImpressionsRequest'
)
const fetchImpressionsFailure = createAction(
  'impression/fetchImpressionsFailure'
)

export function fetchImpressions() {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchImpressionsRequest())
      setTimeout(async () => {
        const response = await api.mock('/impressions')
        dispatch(fetchImpressionsSuccess(response.data))
      }, 1000)
    } catch (error) {
      dispatch(fetchImpressionsFailure())
    }
  }
}
